<ngx-smart-modal identifier="medMijTokenExpiredModal" customClass="include-padding small-modal" [closable]="false">
  <div class="session-expired-popup-wrapper">
    <div (click)="modal.close('medMijTokenExpiredModal')" class="close-button" inlineSVG="icn_close.svg"></div>
    <h3
      class="session-expired-popup-title">{{ 'HISTORY.MEDICAL_FILES.SHARED.MEDMIJ_TOKEN_EXPIRED.TITLE' | translate }}</h3>
    <p
      class="session-expired-instructions">{{ 'HISTORY.MEDICAL_FILES.SHARED.MEDMIJ_TOKEN_EXPIRED.DESCRIPTION' | translate }}</p>
    <div class="buttons-wrapper session-expired-button">
      <app-button class="reauthenticate-button" [buttonText]="'SHARED.REAUTHENTICATE' | translate" (click)="closeExpireModal()"></app-button>
      <app-button (click)="modal.close('medMijTokenExpiredModal')" [buttonText]="'SHARED.CANCEL' | translate"
                  [styles]="[BUTTON_STYLES.secondary]"></app-button>
    </div>
  </div>
</ngx-smart-modal>
