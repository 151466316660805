import { Component, ElementRef, EventEmitter, forwardRef, HostBinding, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'toggle-checkbox-item',
  templateUrl: './toggle-checkbox-item.component.html',
  styleUrls: ['./toggle-checkbox-item.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ToggleCheckboxItemComponent),
    multi: true
  }]
})
export class ToggleCheckboxItemComponent implements ControlValueAccessor {
  @Input() isChecked = false;
  private _isDisabled = false;
  @Input() set isDisabled(val: boolean) {
    this._isDisabled = val;
    this.isDisabledClassOnHost = val;
  }
  get isDisabled() {
    return this._isDisabled;
  }
  private onChange: (val: boolean) => void = null;
  @HostBinding('class.is-disabled') private isDisabledClassOnHost = false;

  constructor(private elementRef: ElementRef<HTMLElement>) {}

  get nativeElement() {
    return this.elementRef.nativeElement;
  }

  writeValue(obj: boolean): void {
    this.isChecked = obj;
  }

  registerOnChange(fn: (val: boolean) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {

  }

  onChanged(): void {
    this.isChecked = !this.isChecked;
    this.onChange?.(this.isChecked);
  }
}
