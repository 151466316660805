<div #refSwiperWrapper
     class="calendar-wrapper">
  <div #refSwiperContainer
       (mousedown)="lock($event)"
       (touchstart)="lock($event)"
       (mousemove)="drag($event)"
       (touchmove)="drag($event)"
       (mouseup)="move($event)"
       (touchend)="move($event)"
       class="calendar-list-wrapper swiper-container">
    <div class="calendar-item-wrapper">
      <full-calendar
        *ngIf="calendarOptions"
        #fullCalendarPrev
        [options]="calendarOptions"
      >
      </full-calendar>
    </div>
    <div class="calendar-item-wrapper">
      <full-calendar
        *ngIf="calendarOptions"
        #fullCalendar
        id="main-calendar"
        [options]="calendarOptions"
      >
      </full-calendar>
    </div>
    <div class="calendar-item-wrapper">
      <full-calendar
        *ngIf="calendarOptions"
        #fullCalendarNext
        [options]="calendarOptions"
      >
      </full-calendar>
    </div>
  </div>
</div>
