<div class="text-field-wrapper" (click)="clickTextField()">
  <label>{{ labelText }}{{ labelText && required ? ' *' : '' }}</label>
  <div class="input-element-container">
    <textarea
      #textFieldRef
      [disabled]="disabled"
      [placeholder]="placeholder"
      [ngModel]="value"
      (input)="onInput($event)"
      [required]="required"
      [maxLength]="maxCharacters"
      [ngClass]="[backgroundStyle ? styles[backgroundStyle] ? styles[backgroundStyle] : '' : '', fakeDisabled ? 'fake-disabled': '']"
      autocomplete="off"
      class="input-element"></textarea>
    <div *ngIf="showGreenCheckMarkWhenValid" class="green-checkmark-when-valid" [inlineSVG]="'icn_checkmark.svg'"></div>
  </div>
  <div *ngIf="maxCharacters" class="characters-left">{{ 'SHARED.CHARACTERS_LEFT' | translate:{count: (maxCharacters - (value ? value.length : 0))} }}</div>
</div>
