import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DateService } from '../utils/date.service';

@Pipe({
  name: 'dateTimeFormat'
})
export class DateTimeFormatPipe implements PipeTransform {
  constructor(
    public readonly dateService: DateService,
  ) {}

  transform(value: Date, includeTime = true): any {
      if(value.toString() === '0001-01-01T00:00:00+00:00') {
        return null;
      }

    return value ? this.dateService.getLocalizedDateString(value, includeTime) : null;
  }
}
