<ngx-smart-modal
  class="add-connection-popup-wrapper"
  customClass="include-padding small-modal grey-background"
  [identifier]="identifier"
  [closable]="false"
  (onOpen)="checkInviteLock()"
  (onClose)="clearInterval(lockedTimeInterval)"
  (onOpenFinished)="focus()">
  <div (click)="modal.close(identifier)" class="close-button" inlineSVG="icn_close.svg"></div>
  <h3 class="add-connection-header">{{ 'NETWORK.CONNECTIONS.ADD_CONNECTION' | translate }}</h3>
  <p class="invite-connection">{{ 'NETWORK.CONNECTIONS.INVITE_CONNECTION' | translate }}</p>
  <label class="email-form-input-wrapper">
    <p class="add-connection-label">{{ 'NETWORK.CONNECTIONS.EMAIL_ADDRESS' | translate }}</p>
    <input
      [placeholder]="'NETWORK.CONNECTIONS.EMAIL_PLACEHOLDER' | translate"
      [formControl]="mailForm"
      class="add-connection-email-input"
      type="text"
      (input)="onEmailChanged()"
      #input>
  </label>
  <p [ngClass]="{ 'hidden': !showErrorMessage }" class="error-message">{{ errorMessage }}</p>
  <ng-container *ngIf="isCaregiver">
    <label class="email-form-input-wrapper" *ngIf="languagesOptions?.length">
      <p class="add-connection-label">{{ 'NETWORK.CONNECTIONS.LANGUAGE_INVITE' | translate }}</p>
      <select-dropdown
        class="select-dropdown"
        dropdownTitle="SETTINGS.LANGUAGE_THEME.LANGUAGE"
        description="SETTINGS.LANGUAGE_THEME.SELECT"
        [selectableOptions]="languagesOptions"
        [selectedOption]="selectedLanguage"
        buttonText="SETTINGS.LANGUAGE_THEME.OK"
        identifier="selectLanguage"
        (onOptionSelected)="onLanguageSelected($event)"
        [flagCountryCode]="selectedLanguage.id"
      ></select-dropdown>
    </label>
    <p class="error-message" *ngIf="invitesLocked">{{'NETWORK.CONNECTIONS.LOCK_COUNTDOWN' | translate: {invitesLockedTime: invitesLockedTime} }}</p>
    <p class="language-warning">{{'NETWORK.CONNECTIONS.INVITE_LANGUAGE_WARNING' | translate}}</p>
  </ng-container>
  <div class="add-connection-button-wrapper">
    <app-button
      (click)="isFormValid ? addConnection() : showErrorMessage = true"
      [fakeDisabled]="!isFormValid || invitesLocked"
      [buttonText]="'NETWORK.CONNECTIONS.SEND' | translate"></app-button>
  </div>
</ngx-smart-modal>
