import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { OidcService } from '../oidc/oidc.service';
import { PartnerService } from '../shared/utils/partner.service';
import {ApiRegistrationService} from "../shared/api/api.registration.service";

/**
 * Class that redirects a user to /dashboard/home if he was still logged in and navigated to /login
 */
@Injectable()
export class LoginGuardService implements CanActivate {
  constructor(
    private oidcService: OidcService,
    private router: Router,
    private partnerService: PartnerService,
    private apiRegistrationService: ApiRegistrationService
  ) {
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    if (this.oidcService.isAuthenticated()) {
      const incompleteRegistrationSteps = await this.apiRegistrationService.getIncompleteRegistrationSteps();
      if(incompleteRegistrationSteps) {
        return true;
      }
      if (this.partnerService.isPartnerVital10()) {
        if (state.url.indexOf('partner') !== -1 || state.url.indexOf('login-vital10') !== -1) {
          return true;
        } else {
          return false;
        }
      }
      return this.router.parseUrl('/dashboard/home');
    }
    return true;
  }
}
