import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'badge-hoverable',
  templateUrl: './badge-hoverable.component.html',
  styleUrls: ['./badge-hoverable.component.scss']
})

/** This is a hoverable (mouse) or clickable (touch) small badge that includes a tooltip on hover or on click */
export class BadgeHoverableComponent implements OnInit {
  @Input() color: string;
  @Input() badgeLabelKey: string;
  @Input() svgPath: string;

  // when you click the badge, the label stays active
  isClicked = false;

  @HostBinding('style')
  get colorStyle(): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(
      `--badge-color: ${this.color}`
    );
  }

  constructor(
    public readonly sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
  }

}
