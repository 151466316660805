import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {DashboardSection, DashboardTile} from '../../../shared/models/dashboard/DashboardResponse';
import {ApiService} from "../../../shared/api/api.service";
import {ActivatedRoute} from "@angular/router";
import { SharedContactData } from '../network/connections/connections.component';

@Component({
  selector: 'shared-contact-data',
  templateUrl: './shared-contact-data.component.html',
  styleUrls: ['./shared-contact-data.component.scss']
})
export class SharedContactDataComponent implements OnInit {
  @Input() sharedContactData: SharedContactData;

  @Output() onResetContactIdData = new EventEmitter();
  @Output() onGoToConversation = new EventEmitter();
  dashboardSections: DashboardSection[] = [];

  isDataLoaded = false;
  selectedMeasurementTypeId: any;
  selectedMeasurementSupplierName: any;

  customId = '';

  constructor(private apiService: ApiService,
              private activatedRoute: ActivatedRoute) { }

  async ngOnInit(): Promise<void> {
    const contactDashboardData = await this.apiService.getContactDashboard(this.sharedContactData.contactId);
    this.dashboardSections = contactDashboardData.sections;

    this.isDataLoaded = true;
  }

  setSelectedMeasurementType(measurementTile: DashboardTile) {
    this.selectedMeasurementTypeId = measurementTile.measurementType;
    this.customId = measurementTile.customName;
  }

  resetContactIdData() {
    this.onResetContactIdData.emit();
  }

  goToConversation() {
    this.onGoToConversation.emit(this.sharedContactData.contactId);
  }
}
