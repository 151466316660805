import { Pipe, PipeTransform } from '@angular/core';
import { DateService } from '../../../utils/date.service';

@Pipe({
  name: 'timeString'
})
export class TimeStringPipe implements PipeTransform {
  constructor(
    public readonly dateService: DateService,
  ) {}

  transform(value: Date): string {
    return this.dateService.getTimeString(value);
  }
}
