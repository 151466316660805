<div class="flex-row">
  <div class="left-column-icon">
    <div *ngIf="isSvg" [inlineSVG]="imgSrc" class="info-block-img svg"></div>
    <img *ngIf="!isSvg" [src]="imgSrc" class="info-block-img">
  </div>
  <div class="right-column right-column-username">
    <div class="title">{{ title }}</div>
    <div class="subtitle">
      <span *ngIf="!translateSubtitle">{{ subtitle }}</span>
      <span *ngIf="translateSubtitle">{{ translateSubtitle | translate }}</span>
      <ng-content></ng-content>
    </div>
  </div>
</div>
