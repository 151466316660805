<div class="chat-panel-wrapper">
  <div
    *ngIf="activeRoom"
    class="panel-top-wrapper"
  >
    <div class="name-wrapper">
      {{ activeRoom.roomName }}
    </div>
    <div class="icon-wrapper" (click)="openPermissionsModal(activeRoom)" *ngIf="!groupChat(activeRoom)">
      <img src="/assets/img/chat/icn_ellipsis.svg">
    </div>
  </div>

  <message-list
    id="message-list-host-container"
    [activeRoom]="activeRoom"
    [activeRoomMessages]="activeRoomMessages"
  >
  </message-list>
  <!--    [messagesPerDay]="activeRoomMessages"-->
<!--  *ngIf="activeRoom"-->
  <chat-input-field
    *ngIf="activeRoom"
    [activeRoom]="activeRoom"
    (messageSentEmitter)="messageSentEmitter.emit($event)"
    placeholder="{{ 'NETWORK.MESSAGES.INPUT_CHAT_MESSAGE_PLACEHOLDER' | translate }}"
  >
  </chat-input-field>
</div>
