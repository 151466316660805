import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { DatePickerComponent, IDayTimeCalendarConfig } from 'ng2-date-picker';
import { TranslateService } from '@ngx-translate/core';
import { v4 as uuidv4 } from 'uuid';
import * as moment from 'moment';
import { DateService } from '../../utils/date.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
// import { DatePipe } from '@angular/common';
import { dateValidator } from '../../utils/formValidators';
import { DatePipe } from '@angular/common';
import * as dayjs from 'dayjs';

require('dayjs/locale/en.js');
require('dayjs/locale/nl.js');

// todo: there is only two versions: 1 with time and 1 without (the without one is keyboard editable)
export enum DateStringFormat {
  FULL_MONTH,
  FULL_MONTH_WITH_TIME,
  NUMBERS,
  NUMBERS_WITH_TIME,
}

export interface DateTimeObject {
  date: Date;
  time: string;
}

@Component({
  selector: 'date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: [ './date-time-picker.component.scss' ],
  providers: [ DatePipe ],
})

export class DateTimePickerComponent implements OnChanges, AfterViewInit {
  @ViewChild('datePicker') datePicker: DatePickerComponent;
  // date input that is editable with keyboard events (actually an input type text)
  @ViewChild('manualDateInput') manualDateInput: ElementRef;

  // whether time picker is included
  @Input() includeTime = true;
  // whether the user can manually enter his date in the input. If false, only date picker can be used.
  @Input() dateInputIsEditable = false;
  @Input() modalId = uuidv4();
  @Input() includeBoxShadow = false;
  // the real date
  @Input() date = new Date();
  // the selectable range allowed to be picked. Null means any date is allowed
  @Input() minStartDate: null | Date = null;
  @Input() reTriggerValidationOnMinStartDateChange = false;
  @Input() maxEndDate: null | Date = null;
  @Input() isRequired = true;
  @Input() invalidMessage = this.translate.instant('DATE_TIME_PICKER.ERROR_MESSAGES.INVALID_MESSAGE');
  @Input() placeholderIncluded = true;
  @Input() dateStringFormat = DateStringFormat.NUMBERS_WITH_TIME;
  @Input() whiteBackground = false;

  @Input() focusOnInit = false;

  // whether this picker is part of a set (start date end date): it affects the styling.
  // todo: it's own start + end date picker component
  @Input() isPartOfStartEndDateCombo = false;

  DateStringFormat = DateStringFormat;

  // todo leave until tested on mobile
  maxTimeInput: string | null = null;
  invalidTime = false;

  // vars used internally for the picker popup
  initialDate: any; // todo import Moment type
  initialTime: string;
  showErrorMessage = false;

  dateInput = new UntypedFormControl('', dateValidator(this.minStartDate, this.maxEndDate, this.isRequired));
  dateInputError = '';
  datePickerForm: UntypedFormGroup;
  buttonEnabled = false;
  datePickerActive = false;

  errorTranslationKeys: { [index: string]: string } = {
    invalid: 'DATE_TIME_PICKER.ERROR_MESSAGES.INVALID_DATE',
    belowMinimum: 'DATE_TIME_PICKER.ERROR_MESSAGES.LATER_DATE',
    aboveMaximum: 'DATE_TIME_PICKER.ERROR_MESSAGES.EARLIER_DATE',
  };

  // note: this is only nicely formatted string as visual input for the user. Not the actual date.
  inputDateValueString = '';

  @Output() dateTimeSaved = new EventEmitter<DateTimeObject>();
  @Output() onErrorThrown = new EventEmitter();

  datePickerConfig: IDayTimeCalendarConfig = {
    firstDayOfWeek: 'mo',
    // firstDayOfWeek: 'su',
    weekDayFormat: 'ddd',
    monthFormat: 'MMMM YYYY',
    yearFormat: 'YYYY',
    showMultipleYearsNavigation: true,
    multipleYearsNavigateBy: 5,
    showNearMonthDays: false,
    enableMonthSelector: true,
    unSelectOnClick: false,
    // min: moment(this.startDatePickerMinStartDate),
    // max: moment(this.endDatePickerMaxEndDate),
    // displayDate: new Date(),
  };

  constructor(
    public modal: NgxSmartModalService,
    public translate: TranslateService,
    public dateService: DateService,
    public formBuilder: UntypedFormBuilder,
    private datePipe: DatePipe,
  ) {
  }

  // ngOnInit(): void {
  // }

  ngAfterViewInit(): void {
    if (this.focusOnInit) this.manualDateInput.nativeElement.focus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.datePickerForm) {
      this.datePickerForm = this.formBuilder.group({
        date: [ null, Validators.required ],
        time: [ null, this.includeTime ? Validators.required : '' ],
      });

      this.datePickerForm.valueChanges.subscribe(() => {
        // console.log(this.datePickerForm);
        if (this.includeTime && this.maxEndDate) {
          // console.log('time changes', this.datePickerForm.get('time').value);
          this.checkIfValidTime(this.datePickerForm.get('time').value, this.datePickerForm.get('date').value);
        }
        this.showErrorMessage = !this.datePickerForm.get('date').value;
        this.buttonEnabled = this.datePickerForm.valid;
      });
    }

    if (changes.date) {
      if (!changes.date.currentValue && !changes.date.firstChange) {
        this.inputDateValueString = '';
        this.datePickerForm.reset();
      } else if (changes.date.currentValue) {
        if (!this.datePickerForm.get('date').value) this.initialDate = moment(changes.date.currentValue);
        this.datePickerForm.get('date').setValue(moment(changes.date.currentValue));


        if (this.includeTime) {
          if (!this.initialTime) this.initialTime = `${ this.date.getHours().toString().padStart(2, '0') }:${ this.date.getMinutes().toString().padStart(2, '0') }`;
          this.datePickerForm.get('time').setValue(`${ this.date.getHours().toString().padStart(2, '0') }:${ this.date.getMinutes().toString().padStart(2, '0') }`);
        }

        // if (this.datePicker) this.datePicker.moveCalendarTo(this.selectedDate);
        this.setDateTime(false, false);
        // this.movePickerToSelectedDate(selectedDate);
      }
    }

    if (changes.isRequired) {
      this.dateInput.setValidators(dateValidator(this.minStartDate, this.maxEndDate, this.isRequired));
    }

    if (changes.minStartDate && changes.minStartDate.currentValue) {
      this.datePickerConfig.min = dayjs(changes.minStartDate.currentValue);

      if (this.datePickerForm.value) this.dateInput.setValidators(dateValidator(this.minStartDate, this.maxEndDate, this.isRequired));
      this.reloadDatePickerConfig();
      if (this.reTriggerValidationOnMinStartDateChange) {
        this.handleDateInput();
      }
    }

    if (changes.maxEndDate && changes.maxEndDate.currentValue) {
      // this.setMaxTimeInput();

      this.datePickerConfig.max = dayjs(changes.maxEndDate.currentValue);
      this.dateInput.setValidators(dateValidator(this.minStartDate, this.maxEndDate, this.isRequired));
      this.reloadDatePickerConfig();
    }

    if (changes.includeTime && !changes.includeTime.currentValue && this.dateStringFormat === DateStringFormat.NUMBERS_WITH_TIME || this.dateStringFormat === DateStringFormat.FULL_MONTH_WITH_TIME && this.date) {
      this.dateStringFormat = DateStringFormat.NUMBERS;
      this.setDateTime(false, false);
    }
  }

  handleDateInput(): void {
    this.dateInput.setValue(this.dateInput.value);

    this.setInputError();

    if (!this.dateInput.errors || !this.dateInput.value) this.saveDateInput();
  }

  checkIfValidTime(timeString: string, date: any): boolean {
    if (!timeString) {
      this.invalidTime = false;
      return false;
    }

    // this.setMaxTimeInput();

    // const maxEndDate = this.maxEndDate;
    const maxEndDate = new Date();

    if(date?.$d) {
      date._d = date.$d;
    }

    if (this.dateService.isDateXAfterDateY(maxEndDate, date._d)) {
      // don't check time if the selected date is already earlier than the max
      this.invalidTime = false;
      return false;
    }

    const timeStringSplit = timeString.split(':');
    let hour: string | number = timeStringSplit[0];
    let minute: string | number = timeStringSplit[1];

    if (hour.length === 0 || minute.length === 0) {
      this.invalidTime = false;
      return false;
    }

    // remove leading 0's from the time input
    if (hour.length === 2 && hour[0] === '0') hour = hour[1];
    if (minute.length === 2 && minute[0] === '0') minute = minute[1];

    hour = parseInt(hour);
    minute = parseInt(minute);

    if ((hour > maxEndDate.getHours()) || ((hour === maxEndDate.getHours()) && (minute > maxEndDate.getMinutes()))) {
      this.invalidTime = true;
      return true;
    } else {
      this.invalidTime = false;
      return false;
    }
  }

  getDateInputError(errors: ValidationErrors): string {
    const translation = this.errorTranslationKeys[Object.keys(errors)
      .find(errorKey => this.dateInput.errors[errorKey])];

    if (translation === this.errorTranslationKeys.invalid) {
      return this.translate.instant(translation);
    }
    if (translation === this.errorTranslationKeys.belowMinimum) {
      return this.translate.instant(translation, { minStartDate: this.datePipe.transform(this.minStartDate, 'dd-MM-yyyy') });
    }
    if (translation === this.errorTranslationKeys.aboveMaximum) {
      return this.translate.instant(translation, { maxEndDate: this.datePipe.transform(this.maxEndDate, 'dd-MM-yyyy') });
    }
    return undefined;
  }

  setInputError(): void {
    if (this.dateInput.errors) {
      this.dateInput.setErrors(this.dateInput.errors);
      this.dateInputError = this.getDateInputError(this.dateInput.errors);
    } else {
      this.dateInput.setErrors(null);
      this.dateInputError = '';
    }

    // console.log('this.dateInputError', this.dateInputError);

    if (this.dateInput.errors) {
      this.onErrorThrown.emit(this.dateInput.errors);
    }
  }

  handleDateKeypress(event: KeyboardEvent): void {
    // Only accept digits and dashes
    if (!/[\d-]+/.test(event.key)) event.preventDefault();
  }

  saveDateInput(): void {
    const date = this.dateInput.value ? DateService.europeanToIso8601(this.dateInput.value, DateService.getDivisionSign(this.dateInput.value)) : null;

    this.datePickerForm.get('date').setValue(this.dateInput.value ? moment(date) : null);
    this.setDateTime(false, true);
  }

  // getDivisionDashes(string: string): string {
  //   let divisionSign = DateService.getDivisionSign(string);
  //   divisionSign = divisionSign ? divisionSign : '-';
  //   if ((string.split(divisionSign).length - 1) >= 2) return string;
  //
  //   if (string.length > 2 && string[2] !== divisionSign) return this.replaceOnIndex(string, 2, divisionSign);
  //   if (string.length > 5 && string[5] !== divisionSign) return this.replaceOnIndex(string, 5, divisionSign);
  //
  //   return string;
  // }

  // replaceOnIndex(string: string, index: number, replacement: string): string {
  //   return string.substr(0, index) + replacement + string.substr(index, string.length);
  // }

  /**
   * Reset values when closed
   */
  closeModal(): void {
    if (this.buttonEnabled) {
      this.datePickerForm.get('date').setValue(this.initialDate);
      this.datePickerForm.get('time').setValue(this.initialTime);
    }
    this.buttonEnabled = false;
    this.invalidTime = false;
    this.modal.close(this.modalId);
    this.datePickerActive = false;
  }

  movePickerToSelectedDate(selectedDate: any): void {
    if (this.datePicker && selectedDate) this.datePicker.moveCalendarTo(selectedDate);
  }

  // Use after changing the config
  reloadDatePickerConfig(): void {
    dayjs.locale(this.translate.getBrowserCultureLang());
    this.datePickerConfig = { ...this.datePickerConfig };
  }

  setDateTime(closeModal = true, emitToParent = true): void {
    const selectedDate: any = this.datePickerForm.get('date').value;

    if(!selectedDate?._d && !selectedDate?.$d) {
      return;
    }

    if(selectedDate?.$d) {
      selectedDate._d = selectedDate.$d;
    }

    if (this.includeTime && this.datePickerForm) {
      const timeInputSplit = this.datePickerForm.get('time').value.split(':');

      selectedDate._d.setHours(parseInt(timeInputSplit[0]));
      selectedDate._d.setMinutes(parseInt(timeInputSplit[1]));
      this.datePickerForm.get('date').setValue(selectedDate);
    }
    const dateTimeObject: DateTimeObject = {
      date: this.datePickerForm.get('date').value ? selectedDate._d : null,
      time: this.includeTime ? this.datePickerForm.get('time').value : null,
    };

    // console.log('dateTimeObject', dateTimeObject);

    if (dateTimeObject.date) {
      // if date is selected through date picker, remove all errors, it will be valid always by definition
      this.dateInput.setErrors(null);
    }

    this.inputDateValueString = this.getInputDateValueString(dateTimeObject);

    if (!this.includeTime) this.dateInput.setValue(this.inputDateValueString);

    if (emitToParent) this.dateTimeSaved.emit(dateTimeObject);
    if (closeModal) {
      this.datePickerActive = false;
      this.modal.getModal(this.modalId).close();
    }
    this.buttonEnabled = false;
  }

  onDatePickerOpened(): void {
    if (this.datePickerForm.get('date').value) this.movePickerToSelectedDate(this.datePickerForm.get('date').value);
    this.datePickerActive = true;
  }

  getInputDateValueString(dateTimeObject: DateTimeObject): string {
    if (!dateTimeObject.date) return '';
    const DD_MM_YYYY_Options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };

    switch (this.dateStringFormat) {
      case DateStringFormat.FULL_MONTH:
        return this.dateService.getLocalizedDateString(dateTimeObject.date, false);
      case DateStringFormat.FULL_MONTH_WITH_TIME:
        return this.dateService.getLocalizedDateString(dateTimeObject.date, this.includeTime);
      case DateStringFormat.NUMBERS:
        return dateTimeObject.date.toLocaleDateString('nl-nl', DD_MM_YYYY_Options);
      case DateStringFormat.NUMBERS_WITH_TIME:
        return this.includeTime ? `${ dateTimeObject.date.toLocaleDateString() }, ${ this.translate.instant('SHARED.AT') } ${ this.dateService.getTimeString(dateTimeObject.date) }` : dateTimeObject.date.toLocaleDateString();
    }
  }

  // note: setting the max attribute of an <input type="time"> does not have full browser compatibility. Needs to be further tested
  // setMaxTimeInput(): void {
  //   if (!this.maxEndDate) this.maxTimeInput = null;
  //
  //   // todo: only if the date selected !== the year, month and day of maxEndDate
  //
  //   let maxHour = this.maxEndDate.getHours().toString();
  //   let maxMinutes = this.maxEndDate.getMinutes().toString();
  //
  //   if (maxHour.length === 1) maxHour = '0' + maxHour;
  //   if (maxMinutes.length === 1) maxMinutes = '0' + maxMinutes;
  //
  //   // this.maxTimeInput = `${maxHour}:${maxMinutes}:59`;
  //   this.maxTimeInput = `${maxHour}:${maxMinutes}`;
  // }
}
