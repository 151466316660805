<div class="dashboard-onboarding-container">
  <div class="top-section">
    <skip-button class="skip-button" routerLink="/dashboard/home"></skip-button>
    <h2 class="dashboard-onboarding-title" [innerHTML]="'DASHBOARD.ONBOARDING_WEARABLES_APPS.TITLE' | translate"></h2>
    <p [innerHTML]="'DASHBOARD.ONBOARDING_WEARABLES_APPS.DESCRIPTION' | translate"></p>
    <div class="background-icon">
      <img src="assets/img/icn_onboarding-bluetooth.svg">
    </div>
  </div>
  <div class="middle-section">
    <wearables-apps class="wearables-apps"></wearables-apps>
  </div>
</div>

