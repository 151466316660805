import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../shared/api/api.service';
import {KnowledgeBaseInformation, KnowledgeEntry} from '../../../shared/models/knowledgeBaseInformation';

@Component({
  selector: 'app-knowledge-base',
  templateUrl: './knowledge-base.component.html',
  styleUrls: ['./knowledge-base.component.scss']
})
export class KnowledgeBaseComponent implements OnInit {

  // Knowledge entries (from API)
  knowledgeBaseData: KnowledgeBaseInformation;

  constructor(
    readonly apiService: ApiService,
  ) { }

  ngOnInit(): void {
    this.loadKnowledgeInformationFromAPI();
  }

  /**
   * Load knowledge entries from API
   */
  async loadKnowledgeInformationFromAPI(): Promise<void> {
    const knowledgeBaseData = await this.apiService.getKnowledgeBaseInformation();

    // put selfcare FAQ at the end
    const selfcareFaqIndex = knowledgeBaseData.entries.findIndex((elem: KnowledgeEntry) => elem.key === 'SELFCARE_FAQ');
    //
    // //put ggzAanhuis next to thuisarts
    const ggzAanHuis = knowledgeBaseData.entries.find((elem: KnowledgeEntry) => elem.key === 'GGZ_AAN_HUIS');
    const thuisArtsIndex = knowledgeBaseData.entries.findIndex((elem: KnowledgeEntry) => elem.key === 'THUISARTS');
    //
    if (selfcareFaqIndex !== -1) {
      const faq = knowledgeBaseData.entries.splice(selfcareFaqIndex, 1);
      knowledgeBaseData.entries.push(faq[0]);
    }

    if(thuisArtsIndex !== -1 && ggzAanHuis !== null) {
      knowledgeBaseData.entries.splice(thuisArtsIndex + 1, 0, ggzAanHuis)
      let indexes: number[] = [];
      knowledgeBaseData.entries.filter((elem: KnowledgeEntry, index: number) => {
        if(elem.key === 'GGZ_AAN_HUIS') {
          indexes.push(index);
        }
        return elem.key === 'GGZ_AAN_HUIS'
      });
      knowledgeBaseData.entries.splice(indexes[1], 1);
    }

    this.knowledgeBaseData = knowledgeBaseData;
  }
}
