<div #refCarouselWrapper class="carousel-wrapper">
  <div #refSlidesContainer
       class="slides-container">
    <div #refSlides
         *ngFor="let slide of carouselSlides; index as i"
         class="slide-box"
         (mousedown)="lockClear($event)"
         (touchstart)="lockClear($event)"
         (mousemove)="drag($event)"
         (touchmove)="drag($event)"
         (mouseup)="move($event)"
         (touchend)="move($event)">
      <img src="{{ slide }}" alt="{{ i }}" class="image-slide">
      <h2 class="carousel-title">{{ getSlideTitleStringKey(i, carouselSlides.length) | translate }}</h2>
      <p class="carousel-description" [innerHTML]="getSlideDescriptionStringKey(i, carouselSlides.length) | translate"></p>
    </div>
  </div>
  <ng-content></ng-content>
  <div class="dot-wrapper">
    <div *ngFor="let value of dotCollection; index as i" class="dot" (click)="navigateSlideClear(i + 1)"
         [ngClass]="i === current - 1 ? 'active' : ''">
    </div>
  </div>
</div>
