import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BUTTON_STYLES } from '../button/button.component';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.scss']
})
export class ConfirmPopupComponent implements OnInit {
  @Input() identifier = 'confirmPopup';
  @Input() title = 'SHARED.ARE_YOU_SURE_TO_DELETE';
  @Input() confirmText = 'SHARED.ARE_YOU_SURE_TO_DELETE_DESCRIPTION';
  @Input() entityName = '';
  @Output() confirmed = new EventEmitter();
  BUTTON_STYLES = BUTTON_STYLES;

  constructor(public modal: NgxSmartModalService) { }

  ngOnInit(): void {
  }

  confirm() {
    this.confirmed.emit();
    this.modal.close(this.identifier);
  }

}
