import { Component, Input, OnInit } from '@angular/core';
import { BUTTON_STYLES } from 'src/app/shared/components/button/button.component';
import { Router, RouterLink } from '@angular/router';

@Component({
  selector: 'error-component',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  readonly dashboardUrl = '/dashboard/home';
  readonly contactCustomerUrl = 'https://www.selfcare4me.com/contact/';

  @Input() description: string;
  @Input() extraInformation: string = null;
  @Input() imageSrc = '../../../../assets/img/img_error@3x.png';
  @Input() buttons: ErrorButton[] = [
    { buttonTextTranslateKey: 'ERROR_PAGE.TRY_AGAIN_BUTTON', buttonStyle: ErrorButtonStyle.Primary, click: this.reloadCausedErrorPage },
    { buttonTextTranslateKey: 'ERROR_PAGE.DASHBOARD_BUTTON', buttonStyle: ErrorButtonStyle.Secondary, routerLink: this.dashboardUrl }
  ];

  @Input() links: ErrorLink[] = [
    { linkTextTranslateKey: 'ERROR_PAGE.CUSTOMER_SERVICE_BUTTON', href: this.contactCustomerUrl, openInNewWindow: true }
  ];

  // URL of page that caused error
  causedErrorUrl: string;

  constructor(
    private readonly router: Router
  ) { }

  reloadCausedErrorPage() {
    window.location.reload();
  }

  get pageButtons(): ErrorButton[] {
    if (this.buttons) {
      return this.buttons;
    }

    return [];
  }

  getAppButtonStyle(errorButtonStyle: ErrorButtonStyle): string {
    switch(errorButtonStyle) {
      case ErrorButtonStyle.Primary:
        return BUTTON_STYLES.primaryShade2;
      case ErrorButtonStyle.Secondary:
        return BUTTON_STYLES.secondary;
    }
  }

  ngOnInit(): void {
    this.causedErrorUrl = this.router.url;
  }
}

export interface ErrorButton {
  buttonTextTranslateKey: string;
  buttonStyle: ErrorButtonStyle;
  href?: string;
  routerLink?: string;
  click?: () => void;
}

export enum ErrorButtonStyle {
  Primary,
  Secondary
}

export interface ErrorLink {
  linkTextTranslateKey: string;
  href: string;
  openInNewWindow: boolean;
}
