import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'duration-input-field',
  templateUrl: './duration-input-field.component.html',
  styleUrls: ['./duration-input-field.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DurationInputFieldComponent implements OnInit {
  measurementTypeTitleLowerCase = '';
  value: string = null;
  hours: number = null;
  minutes: number = null;
  validHours = true;
  validMinutes = true;

  @Output() onDurationChanged = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  onHoursInput(input: any) {
    this.hours = parseInt(input.target.value);
    this.value = ((this.hours * 60) + this.minutes).toString();
    if(this.value !== 'NaN' && this.validMinutes) {
      this.validHours = true;
      this.onDurationChanged.emit(this.value);
    }
    else {
      this.validHours = false;
      this.onDurationChanged.emit(null);
    }
    if(input.target.validity.valid) {
      this.validHours = true;
    }
    if(input.target.value === '') {
      this.hours = null;
    }
  }

  onMinutesInput(input: any) {
    this.minutes = parseInt(input.target.value);
    this.value = ((this.hours * 60) + this.minutes).toString();
    if(this.value !== 'NaN' && this.validHours) {
      this.validMinutes = true;
      this.onDurationChanged.emit(this.value);
    }
    else {
      this.validMinutes = false;
      this.onDurationChanged.emit(null);
    }
    if(input.target.validity.valid) {
      this.validMinutes = true;
    }
    if(input.target.value === '') {
      this.minutes = null;
    }
  }
}
