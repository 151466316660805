import { MoodId } from '../enums/MeasurementEnums';

export default interface SavedNote {
  id: string;
  type: NoteType;
  measuredValue: number;
  dateTime: string;
  mood: MoodId;
  content: string;
  attachments: NoteAttachment[];
}

export enum NoteType {
  TEXT = 'Text',
  IMAGE = 'Image',
  VOICE = 'Voice',
}

export interface NoteAttachment {
  fileName: string;
  url: string;
}
