<!--other users messages-->
<div
  *ngIf="message.isMessageFromOtherUser"
  class="message-wrapper message-received"
>
  <ng-container [ngTemplateOutlet]="userImageWrapper"></ng-container>
  <ng-container [ngTemplateOutlet]="messageContentOuterWrapper"></ng-container>
  <div class="spacer"></div>
</div>

<!--your own messages-->
<div
  *ngIf="!message.isMessageFromOtherUser"
  class="message-wrapper message-sent"
>
  <div class="spacer"></div>
  <ng-container [ngTemplateOutlet]="messageContentOuterWrapper"></ng-container>
  <ng-container [ngTemplateOutlet]="userImageWrapper"></ng-container>
</div>


<ng-template #messageContentOuterWrapper>
  <div class="message-content-outer-wrapper">
    <div
      [ngClass]="{ 'message-first-person': message.isFirstMessageFromOtherUser }"
      class="message-content-wrapper"
    >
<!--      <div *ngIf="isGroupChat && message.isFirstMessageFromOtherUser" class="username-container">{{ message.senderId }}</div>-->
      <div *ngIf="message.isFirstMessageFromOtherUser" class="username-container">{{ message.sender.name }}</div>
      <div class="chat-bubble">
        {{ message.message }}
      </div>
<!--      <div *ngIf="message.type === MessageType.TEXT" class="chat-bubble">-->
<!--        {{ message.content }}-->
<!--      </div>-->
<!--      <div *ngIf="message.type === MessageType.IMAGE" class="chat-bubble-image chat-bubble-media" (click)="downloadFile()">-->
<!--        &lt;!&ndash;        // todo right tactic for lazy loading&ndash;&gt;-->
<!--&lt;!&ndash;        <img [src]="message.file.src" [attr.loading]="message.isLoadedBySocket ? null : 'lazy'" (load)="onMediaFileLoaded($event)">&ndash;&gt;-->
<!--        <img [src]="message.file.src" (load)="onMediaFileLoaded($event)">-->
<!--      </div>-->
<!--      <div *ngIf="message.type === MessageType.VIDEO" class="chat-bubble-video chat-bubble-media">-->
<!--        <video controls (loadeddata)="onMediaFileLoaded($event)">-->
<!--          <source [src]="message.file.src">-->
<!--        </video>-->
<!--      </div>-->
<!--      <div *ngIf="message.type === MessageType.DOCUMENT" class="chat-bubble chat-bubble-file" (click)="downloadFile()">-->
<!--        <div class="chat-bubble-icon-container">-->
<!--          <div class="svg-container" inlineSVG="\chat\icn_clip.svg"></div>-->
<!--        </div>-->
<!--        <div class="file-details-column">-->
<!--          <div class="file-name-title">{{ message.file.name }}</div>-->
<!--          <div class="file-size-title">{{ message.file.size | fileSizeString }}</div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div *ngIf="isGroupChat" class="reaction-emoji-row">-->
<!--        <ng-template [ngIf]="message.emojiReactions && message.emojiReactions.length">-->
<!--          <div-->
<!--            *ngFor="let emojiReaction of message.emojiReactions"-->
<!--            [ngClass]="emojiReaction.isTickedByLoggedInUser ? 'is-ticked-by-logged-in-user' : ''"-->
<!--            (click)="addOrRemoveReactionEmoji(emojiReaction.id)"-->
<!--            class="reaction-emoji-block"-->
<!--          >-->
<!--            {{ emojiReaction.id }} {{ emojiReaction.tickCounter }}-->
<!--&lt;!&ndash;            <div class="reaction-emoji">{{ emojiReaction.id }}</div>&ndash;&gt;-->
<!--&lt;!&ndash;            <span class="reaction-emoji-counter">{{ emojiReaction.tickCounter }}</span>&ndash;&gt;-->
<!--          </div>-->
<!--        </ng-template>-->
<!--        <div class="reaction-emoji-block add-reaction-emoji-button" (click)="toggleReactionEmojiPicker($event)">-->
<!--          <div class="svg-container" inlineSVG="/chat/icn_add-reaction-emoji.svg"></div>-->
<!--&lt;!&ndash;          <div *ngIf="emojiReactionPickerOpened" class="emoji-picker">&ndash;&gt;-->
<!--&lt;!&ndash;            <div *ngFor="let emojiId of EMOJI_REACTIONS" class="emoji-pickable" (click)="toggleReactionEmoji(emojiId)">&ndash;&gt;-->
<!--&lt;!&ndash;              {{ emojiId }}&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</ng-template>

<ng-template #userImageWrapper>
  <div class="user-image-wrapper">
    <user-profile-image
      *ngIf="message.isFirstMessageFromOtherUser"
      [profileImage]="null"
    >
<!--      [profileImage]="message.sender.profileImage"-->
    </user-profile-image>
    <div class="time-wrapper">
      {{ message.createdAt | timeString }}
    </div>
  </div>
</ng-template>


