import { Component, OnInit } from '@angular/core';
import { DateService } from '../../utils/date.service';
import { ApiService } from '../../api/api.service';
import { Store } from '@ngrx/store';
import UserProfile from '../../models/userProfile';
import { setProfile } from '../../../store/userProfile/userProfile.actions';
import { take } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'greeting',
  templateUrl: './greeting.component.html',
  styleUrls: [ './greeting.component.scss' ],
})
export class GreetingComponent implements OnInit {

  customWelcomeText = '';
  firstName = '';

  private userProfile: UserProfile;

  constructor(private dateService: DateService,
              private apiService: ApiService,
              private oauthService: OAuthService,
              private store: Store<{ 'userProfile': UserProfile }>) {
  }

  async ngOnInit(): Promise<void> {
    await this.getWelcomeText();
  }

  async getWelcomeText() {
    if (!this.userProfile?.generalInformation) {
      const fullUserProfile = await this.apiService.getFullUserProfile();
      this.store.dispatch(setProfile({ userProfile: fullUserProfile }));
    }

    this.store.pipe(take(1)).subscribe(action => {
      this.userProfile = action.userProfile;

      this.customWelcomeText = this.getCustomWelcomeText(this.userProfile);
      this.firstName = `${ this.userProfile.generalInformation.firstName }`;
    });
  }

  /** Good morning, good evening, happy birthday etc. */
  getCustomWelcomeText(userProfileData: any): string {
    const baseIndexWelcomeString = 'DASHBOARD.WELCOME.';

    // check for happy birthday message
    const dateOfBirth = new Date(userProfileData.generalInformation.dateOfBirth);
    const today = new Date();
    if (dateOfBirth.getMonth() === today.getMonth() && dateOfBirth.getDate() === today.getDate()) {
      return baseIndexWelcomeString + 'BIRTHDAY';
    }
    if (today.getMonth() === 0 && today.getDate() === 1) {
      return baseIndexWelcomeString + 'NEW_YEAR';
    }
    return baseIndexWelcomeString + this.dateService.getTimeOfDayTranslationString();
  }

}
