import { Component, ElementRef, HostListener, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss']
})
export class DropdownMenuComponent implements OnInit, OnChanges {
  @Input() isOpened: boolean;
  @Input() positionFromTop = '2rem';
  @Input() positionFromRight = '0rem';

  @Input() menuItems: MenuItem[] = [];

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {

  }

  performMenuAction(action: any) {
    action();
    this.isOpened = false;
  }

}

export interface MenuItem {
  label: string;
  action: any;
}
