<ngx-smart-modal
  identifier="premiumExpirationModal"
  [closable]="false"
  [hideDelay]="200"
  customClass="include-padding small-modal"
  #premiumExpirationModal
>
  <h3 class="main-title" [innerHTML]="'PREMIUM_EXPIRATION_POPUP.TITLE' | translate"></h3>
  <img class="premium-img" src="assets/img/img_unlockpremium@3x.png" alt="Unlock premium">
  <p class="description-1" [innerHTML]="'PREMIUM_EXPIRATION_POPUP.DESCRIPTION_1' | translate"></p>
  <p class="description-2" [innerHTML]="'PREMIUM_EXPIRATION_POPUP.DESCRIPTION_2' | translate"></p>
  <div class="buttons-wrapper">
    <app-button class="premium-popup-button" (click)="premiumExpirationModal.close()" [buttonText]="('PREMIUM_POPUP.PURCHASE_MONTH' | translate) + MONTH_PRICE_STRING" [routerLink]="'dashboard/settings/financial'"  [queryParams]="{premiumForward:true}"></app-button>
<!--    <app-button class="premium-popup-button" [buttonText]="('PREMIUM_POPUP.PURCHASE_YEAR' | translate) + YEAR_PRICE_STRING"></app-button>-->
    <app-button class="premium-popup-button cancel-button-white" (click)="modal.getModal('premiumExpirationModal').close()" [buttonText]="'PREMIUM_POPUP.CANCEL_BUTTON' | translate" [styles]="[BUTTON_STYLES.secondaryAlternative]"></app-button>
  </div>
</ngx-smart-modal>
