<ngx-smart-modal class="confirm-popup-wrapper small-modal" [identifier]="identifier" [closable]="false">
  <h4 class="confirm-popup-title">
    {{title | translate}}
  </h4>
  <p>{{confirmText | translate: { entityName: entityName } }}</p>
  <div class="buttons-wrapper">
    <app-button class="confirm-button"
                (click)="confirm()"
                [buttonText]="'SHARED.YES' | translate">
    </app-button>
    <app-button [styles]="BUTTON_STYLES.secondary" (click)="modal.get(identifier).close()" [buttonText]="'SHARED.CANCEL' | translate"></app-button>
  </div>
</ngx-smart-modal>
