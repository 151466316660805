import { Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MeasurementService } from '../../../measurements/measurement.service';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import MeasurementTile from '../../../models/enums/MeasurementTile';
import { MeasurementIdEnum, MeasurementProgressionType } from '../../../models/enums/MeasurementEnums';
import { DashboardTile } from '../../../models/dashboard/DashboardResponse';

@Component({
  selector: 'measurement-block',
  templateUrl: './measurement-block.component.html',
  styleUrls: ['./measurement-block.component.scss']
})
export class MeasurementBlockComponent implements OnChanges {
  @Input() measurementTileModel: DashboardTile;
  @Input() cssClass: string = "";

  @Input() applyBoxShadow? : boolean = false;
  @Input() hideTitle = false;
  @Input() caregiverDashboard = false;

  readonly MeasurementProgressionType = MeasurementProgressionType;

  measurementTitle = '';
  measurementIcon = '';
  qualityInterpretationString = '';
  valueString = '';
  unitString = '';
  qualityColor = '';

  @HostBinding('style')
  get colorStyle(): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(
      `--measurement_color: ${this.qualityColor}`
    );
  }

  constructor(
    public measurementService: MeasurementService,
    private sanitizer: DomSanitizer,
  ) { }

  // ngOnInit() {
  // }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.measurementTileModel) {
      const measurementTileModel: MeasurementTile = changes.measurementTileModel.currentValue;

      this.measurementTitle = measurementTileModel.customName ?
        this.measurementService.getCustomMeasurementTitleString(measurementTileModel.customName):
        this.measurementService.getMeasurementTitleString(measurementTileModel.measurementType);

      const measurementType = measurementTileModel.customName ? MeasurementIdEnum.CUSTOM :  measurementTileModel.measurementType;
        this.measurementIcon = this.measurementService.getMeasurementIcon(measurementType);
        this.qualityColor = this.measurementService.getQualityColor(measurementType, measurementTileModel.limitIndication);
        this.unitString = measurementTileModel.customName ?
          this.measurementService.getCustomUnitString(measurementTileModel) :
          this.measurementService.getUnitString(measurementType);

      if (measurementTileModel.value === null) {
        this.qualityInterpretationString = this.measurementService.getProgressionInterpretationString(null);
        this.valueString = '? ';
      } else {
        this.valueString = this.measurementService.formatMeasurementValue(measurementType, measurementTileModel.value);
        this.qualityInterpretationString = this.measurementService.getProgressionInterpretationString(measurementTileModel.interpretation);
      }
    }
  }
}
