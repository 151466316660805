import { Component } from '@angular/core';
import {NgxSmartModalService} from "ngx-smart-modal";
import {Router} from "@angular/router";
import {OidcService} from "../../../../oidc/oidc.service";
import { BUTTON_STYLES } from '../../button/button.component';
import {take} from "rxjs/operators";
import {MedmijAuthService} from "../../../utils/medmij-auth.service";
import {MedMijAuthenticationClient} from "../../../api/api-medmij-afsprakenstelsel.service";
import {NativeAppService} from "../../../api/native-app.service";

@Component({
  selector: 'medmij-token-expired-modal',
  templateUrl: './medmij-token-expired-modal.component.html',
  styleUrls: ['./medmij-token-expired-modal.component.scss']
})
export class MedmijTokenExpiredModalComponent {
  BUTTON_STYLES = BUTTON_STYLES;

  constructor(public modal: NgxSmartModalService,
              private router: Router,
              private oidService: OidcService,
              private medMijAuthService: MedmijAuthService,
              private medMijAuthenticationClient: MedMijAuthenticationClient,
              private nativeAppService: NativeAppService) { }

  ngOnInit(): void {
  }

  async closeExpireModal() {
    this.modal.close('medMijTokenExpiredModal');
    localStorage.setItem('medMijCareProviderName', this.medMijAuthService.medMijCareProviderNameToConnect);
    localStorage.setItem('medMijDataServiceId', this.medMijAuthService.medMijDataServiceIdToConnect);
    this.medMijAuthenticationClient.getAuthorizationPage(
      this.medMijAuthService.medMijCareProviderNameToConnect,
      this.medMijAuthService.medMijDataServiceIdToConnect,
      false
    )
      .pipe(take(1)).subscribe(
      authPage => {
        if (authPage) {
          if(!this.nativeAppService.isIOS()) {
            this.openNewWindow(authPage);
          } else {
            this.nativeAppService.postMessageToNativeApp({
              action: 'connect-healthcare-provider',
              redirect_url: authPage
            })
          }
          return;
        }
      },
      error => false
    );
  }

  openNewWindow(authPage: string): void {
    const link = document.createElement('a');
    link.href = authPage;
    link.click();
    link.remove();
  }
}
