import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'checkbox',
  template: `
    <label class="checkbox-container" [ngClass]="disabled ? 'disabled' : ''">
      <input type="checkbox" [checked]="isChecked" (change)="onChanged($event)" (blur)="onBlur($event)" [disabled]="disabled">
      <span class="checkbox" ></span>
      <span class="checkbox-label" [ngClass]="turnGreenOnCheck && isChecked ? 'green-text-checkbox' : ''" [innerHTML]="labelText"></span>
    </label>
  `,
  styleUrls: ['./checkbox.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CheckboxComponent),
    multi: true
  }]
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() labelText: string;
  @Input() disabled? = false;
  @Input() turnGreenOnCheck? = false;

  isChecked = false;
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  onChange = ((_: any) => { });
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  onBlur = ((_: any) => { });

  writeValue(obj: boolean): void {
    this.isChecked = obj;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  registerOnTouched(fn: any): void {
    this.onBlur = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  onChanged($event: any) {
    this.isChecked = $event && $event.target && $event.target.checked;
    this.onChange(this.isChecked);
  }
}
