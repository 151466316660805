import { Component, forwardRef, Input, EventEmitter, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'devices-apps-item',
  templateUrl: './devices-apps-item.component.html',
  styleUrls: ['./devices-apps-item.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => WearableVendorItemComponent),
    multi: true
  }]
})
export class WearableVendorItemComponent implements ControlValueAccessor {
  @Input() iconSrc: string;
  @Input() title: string;
  @Input() isEnabled = false;
  @Input() isSelected = false;
  @Input() isDisabled = false;
  @Input() key: string;

  @Output() itemSelected = new EventEmitter();


  onChange = ((_: any) => {});

  writeValue(obj: any) {
    this.isEnabled = obj;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {

  }

  onSelected() {
    if(!this.isDisabled) {
      this.isSelected = !this.isSelected;
      this.itemSelected.emit(this.isSelected);
    }
  }

  onChanged() {
    this.isSelected = !this.isSelected;
    this.onChange(this.isSelected);
  }

  constructor() { }
}
