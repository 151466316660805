import { Component, Input, OnInit } from '@angular/core';
import { NgxSmartModalService } from "ngx-smart-modal";
import { KeyValue, KeyValuePipe } from "@angular/common";
import { SelectableOption } from "../../select-dropdown/select-dropdown.component";
import { TranslateService } from "@ngx-translate/core";
import { MeasurementService } from "../../../measurements/measurement.service";
import ConflictingMeasurementTypeResponse, {
  MultiVendor, SelectedSupplierBody,
} from "../../../models/settings/conflictingMeasurementTypeResponse";
import { ApiService } from "../../../api/api.service";

@Component({
  selector: 'measurement-type-source-modal',
  templateUrl: './measurement-type-source-modal.component.html',
  styleUrls: ['./measurement-type-source-modal.component.scss'],
  providers: [
    KeyValuePipe
  ]
})
export class MeasurementTypeSourceModalComponent implements OnInit {
  @Input() conflictingMeasurementTypes: ConflictingMeasurementTypeResponse = {};

  identifier = 'mTypeSourceModal';

  supplierOptions: { [key: string]: SelectableOption[] } = {};
  selectedOptions: { [key: string]: SelectableOption } = {};
  measurementIcons: { [key: string]: string } = {};

  constructor(readonly modal: NgxSmartModalService,
              readonly keyValuePipe: KeyValuePipe,
              readonly translate: TranslateService,
              readonly apiService: ApiService,
              public measurementService: MeasurementService) {

  }

  ngOnInit(): void {

  }

  onSupplierSelected(event: SelectableOption, measurementType: string) {
    let currentActiveSupplier = this.conflictingMeasurementTypes[measurementType].find((x: MultiVendor) => x.active);
    if(currentActiveSupplier) {
      currentActiveSupplier.active = false;
    }

    let newSupplier = this.conflictingMeasurementTypes[measurementType].find((x: MultiVendor) => x.value == event.id);

    if (event.id === 'none') {
      this.selectedOptions[measurementType] = this.supplierOptions[measurementType].find((s: SelectableOption) => s.id == 'none');
      return;
    }

    newSupplier.active = true;
    this.selectedOptions[measurementType] = this.supplierOptions[measurementType].find((s: SelectableOption) => s.id == newSupplier.value);
  }

  async generateSupplierOptionsAndSelectedOptions() {
    await this.resetData();

    const supplierData: Array<KeyValue<string, MultiVendor[]>> = this.keyValuePipe.transform(this.conflictingMeasurementTypes);
    supplierData.forEach(measurementType => {
      let selectableOptions: SelectableOption[] = [];
      measurementType.value.forEach(supplierName => {
        selectableOptions.push({id: supplierName.value, label: this.translate.instant(supplierName.displayName)});
      });
      this.supplierOptions[measurementType.key] = selectableOptions;

      const activeOptions = measurementType.value.filter((option: MultiVendor) => option.active);
      if(activeOptions && activeOptions.length) {
        this.selectedOptions[measurementType.key] = selectableOptions.find((s: SelectableOption) => s.id == activeOptions[0].value);
      } else {
        this.supplierOptions[measurementType.key].unshift({id: 'none', label: ''});
        this.selectedOptions[measurementType.key] = this.supplierOptions[measurementType.key].find((s: SelectableOption) => s.id === 'none');
      }

      this.measurementIcons[measurementType.key] = this.measurementService.getMeasurementIcon(measurementType.key);
    })
  }

  async saveSupplierSelection(): Promise<void> {
    let postObject: SelectedSupplierBody = {};
    const supplierData: Array<KeyValue<string, MultiVendor[]>> = this.keyValuePipe.transform(this.conflictingMeasurementTypes);

    supplierData.forEach((measurementType) => {
      postObject[measurementType.key] = measurementType.value.filter(supplier => supplier.active)[0].value;
    })

    await this.apiService.setConflictingMeasurementTypes(postObject);

    this.modal.close(this.identifier);
  }

  async resetData(): Promise<void> {
    this.supplierOptions = {};
    this.conflictingMeasurementTypes = await this.apiService.getConflictingMeasurementTypes();
  }

  public canSave(): boolean {
    const supplierData: Array<KeyValue<string, MultiVendor[]>> = this.keyValuePipe.transform(this.conflictingMeasurementTypes);

    for (let i = 0; i < supplierData.length; i++) {
      const measurementType = supplierData[i];
      if (measurementType.value.filter(supplier => supplier.active).length === 0) {
        return false;
      }
    }

    return true;
  }
}
