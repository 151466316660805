import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { OidcService } from '../oidc/oidc.service';
import { PartnerService } from '../shared/utils/partner.service';
import { environment } from '../../environments/environment';
import { AUTH_CONFIG, AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { ApiRegistrationService } from '../shared/api/api.registration.service';
import { authConfig } from '../../../src/app/oidc/oidc-config';

@Injectable()
export class PartnerRouteGuard implements CanActivate {

  constructor(private oidService: OidcService,
              private oauthService: OAuthService,
              private apiRegistrationService: ApiRegistrationService) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree>
  {
    //todo: change switch when multiple partners are supported
    localStorage.setItem('partner', 'vital10');
    const clientId = route.queryParamMap.get('clientId');
    const loggedIn = route.queryParamMap.get('loggedIn');

    if(loggedIn !== 'true') {
      localStorage.setItem('partnerRedirect', state.url);
    }

    const newConfig = authConfig;
    newConfig.redirectUri = `${environment.siteBaseUrl}login-vital10?clientId=vital10`;
    this.oauthService.configure(newConfig);
    await this.oauthService.loadDiscoveryDocument();
    await this.oidService.initializeAuthorizationAsync();

    if(clientId && !this.oidService.isAuthenticated()) {
      return false;
    } else {
      //todo: just a temp alert until, foresee extra page later
      if(!clientId) {
        alert('No clientId was given');
        return false;
      }

      return true;
    }
  }
}
