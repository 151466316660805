import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import {
  BuyCouponResponse,
  ExchangeResponse, MyCouponsResponse,
  PointsSummaryResponse, Policy, SetCouponAsUsedResponse, UsedCouponsResponse
} from '../models/game/reward/reward';
import {
  GetCouponStockResponse,
  GetExpensesPerWeekResponse,
  GetTotalExpensesResponse,
  GetTotalRedeemedCouponsPerWeekResponse,
  GetTotalRedeemedCouponsResponse
} from "../models/game/reward/reward-statistics";

@Injectable({
  providedIn: 'root'
})
export class ApiRewardsService {
  baseUrl = environment.apiRewardBaseUrl;
  userId: string = null;

  constructor(
    private apiService: ApiService,
    private http: HttpClient)
  {
    this.userId = this.apiService.getUserId();
  }

  /* POLICY CALLS */

  getPolicies(): Promise<Policy[]> {
    return this.http.get<Policy[]>(`${this.baseUrl}policy`).toPromise();
  }

  /* USER REWARD API CALLS */

  getSummary(): Promise<PointsSummaryResponse> {
    return this.http.get<PointsSummaryResponse>(`${this.baseUrl}reward/summary`).toPromise();
  }

  getExchangeCoupons(): Promise<ExchangeResponse> {
    return this.http.get<ExchangeResponse>(`${this.baseUrl}reward/exchange`).toPromise();
  }

  buyCoupon(customerId: number, vendorCouponId: number, expectedPointsRequired: number): Promise<BuyCouponResponse> {
    return this.http.post<BuyCouponResponse>(`${this.baseUrl}reward/coupons`, {customerId: customerId, vendorCouponId: vendorCouponId, expectedPointsRequired: expectedPointsRequired}).toPromise();
  }

  getUnusedCoupons(): Promise<MyCouponsResponse> {
    return this.http.get<MyCouponsResponse>(`${this.baseUrl}reward/coupons`).toPromise();
  }

  useCoupon(rewardId: number): Promise<SetCouponAsUsedResponse> {
    return this.http.put<SetCouponAsUsedResponse>(`${this.baseUrl}reward/coupons/${rewardId}`, {isUsed: true}).toPromise();
  }

  getUsedCoupons(): Promise<UsedCouponsResponse> {
    return this.http.get<UsedCouponsResponse>(`${this.baseUrl}reward/coupons/used`).toPromise();
  }

  linkUserToRewards(rewardsInviteToken: string) {
    return this.http.post<any>(`${this.baseUrl}reward/linkUserToRewards`, { tokenId: rewardsInviteToken }).toPromise();
  }

  rewardsActivated() {
    return this.http.get<any>(`${this.baseUrl}reward/activated`).toPromise();
  }

  /* HR DASHBOARD CALLS */

  getRedeemedCoupons(policyId: number): Promise<GetTotalRedeemedCouponsResponse> {
    return this.http.get<GetTotalRedeemedCouponsResponse>(`${this.baseUrl}statistics/customer/${policyId}/redeemedcoupons`).toPromise();
  }

  getRedeemedCouponsPerWeek(policyId: number): Promise<GetTotalRedeemedCouponsPerWeekResponse> {
    return this.http.get<GetTotalRedeemedCouponsPerWeekResponse>(`${this.baseUrl}statistics/customer/${policyId}/redeemedcoupons/week`).toPromise();
  }

  getExpenses(policyId: number): Promise<GetTotalExpensesResponse> {
    return this.http.get<GetTotalExpensesResponse>(`${this.baseUrl}statistics/customer/${policyId}/expenses`).toPromise();
  }

  getExpensesPerWeek(policyId: number): Promise<GetExpensesPerWeekResponse> {
    return this.http.get<GetExpensesPerWeekResponse>(`${this.baseUrl}statistics/customer/${policyId}/expenses/week`).toPromise();
  }

  getCouponsStock(policyId: number): Promise<GetCouponStockResponse> {
    return this.http.get<GetCouponStockResponse>(`${this.baseUrl}statistics/customer/${policyId}/couponstock`).toPromise();
  }
}
