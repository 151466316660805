<div class="avatar-wrapper">
<!--  (click)="setActiveRoom()"-->
<div *ngIf="room.unreadMessages > 0" class="counter-wrapper">{{ room.unreadMessages }}</div>
  <div class="profile-wrapper">
    <user-profile-image
      height="4.2rem"
      width="4.2rem"

    >
    </user-profile-image>
    <div *ngIf="room.hasActivity" class="icon-status"></div>
<!--    <div *ngIf="room.members && room.members.length < 1 && room.members && room.members.length && room.members[1].status === 'online'" class="icon-status"></div>-->
  </div>
  <div class="name-wrapper">
    {{ room.roomName }}
  </div>
</div>
