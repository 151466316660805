import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-access',
  template: `<error-component [description]="'ERROR.NO_ACCESS' | translate"></error-component>`
})
export class NoAccessComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
