<div class="select-dropdown-wrapper">
  <ngx-smart-modal
    [customClass]="'include-padding' + (isSmallModal ? ' small-modal' : '')"
    [ngClass]="isSmallModal ? 'small-modal' : null"
    [identifier]="identifier"
    [closable]="false">
    <div class="close-button" (click)="modal.getModal(identifier).close()" inlineSVG="icn_close.svg"></div>
    <div class="title-description-wrapper">
      <h2 class="title">{{ dropdownTitle | translate }}</h2>
      <p class="description">{{ description | translate }}</p>
    </div>
    <div
      class="selection-fields"
      #selectionField
      (mouseup)="focusOnItem()"
      (touchend)="focusOnItem()"
      (wheel)="onWheelScroll()"
    >
      <div class="selection-box"></div>
      <div
        *ngFor="let selectableOption of selectableOptions; let i = index"
        #selectionOptions
        [ngClass]="{ 'active': selectableOption?.id === focussedOption?.id }"
        class="selection-option"
        (click)="focusOnItem(i)"
      >{{ selectableOption.label | translate }}</div>
    </div>
    <div class="button-section">
      <app-button
        class="select-button"
        [includeLeftCheckmarkIcon]="checkmarkIncluded"
        [buttonText]="buttonText | translate"
        (click)="selectItem()"
      ></app-button>
    </div>
  </ngx-smart-modal>
</div>

<div
  *ngIf="orangeArrowBoxIncluded"
  class="dropdown-box"
  (click)="modal.getModal(identifier).open()"
  [ngClass]="{ 'grey-background': greyBackground }">
  <p class="dropdown-field-text" *ngIf="selectedOption"><span *ngIf="flagCountryCode" [ngClass]=" 'iti__' + countryCode " class="iti__flag"></span>{{ selectedOption.label | translate }}</p>
  <p class="dropdown-field-text placeholder" *ngIf="!selectedOption && placeholder">{{ placeholder }}</p>
  <div class="arrow-wrapper">
    <div inlineSVG="icn_dropdown.svg" class="arrow-icon"></div>
  </div>
</div>
