export interface ReceiveBadgeUpdate {
  badges: BadgeCounts[];
}

export interface BadgeCounts {
    badge: BadgeModel;
    count: number; // count
}

export interface BadgeModel {
    tenantId: string;
    domain: string;
    function: string;
    sourceObjectId: string;
    sourceObjectFormat?: string;
}

export enum BadgeFunctionIds {
    UnreadMessages = 'unread-messages',
    IncomingInvites = 'incoming-invites'
}
