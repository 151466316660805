import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'remove-connection-popup',
  templateUrl: './remove-connection-popup.component.html',
  styleUrls: ['./remove-connection-popup.component.scss']
})
export class RemoveConnectionPopupComponent implements OnInit {
  identifier = 'removeConnectionPopup';
  @Output() onRemoveMember = new EventEmitter();

  constructor(
    readonly modal: NgxSmartModalService,
  ) { }

  ngOnInit(): void {
  }

  removeMember() {
    this.onRemoveMember.emit();
  }
}
