import { Pipe, PipeTransform } from '@angular/core';
import { NumberService } from '../../../utils/number.service';

@Pipe({
  name: 'fileSizeString'
})
export class FileSizeStringPipe implements PipeTransform {
  constructor(
    public numberService: NumberService,
  ) {}

  // note filesize in bytes
  transform(value: number): string {
    return this.numberService.getFileSizeStringByFileSizeBytes(value);
  }
}
