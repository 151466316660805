import {
  Component,
  EventEmitter,
  Input,
  // OnChanges,
  // OnInit,
  Output,
  // SimpleChanges,
} from '@angular/core';
// import * as fromChat from '../../../../store/chat/chat.selector';
// import { Store } from '@ngrx/store';
// import { ChatUser } from '../../../models/chat/chat-user';
// import { GlobalService } from '../../../utils/global.service';
import { NumberService } from '../../../utils/number.service';
// import Message, { MessageFile, MessageType } from '../../../models/chat/message';
import { ApiService } from '../../../api/api.service';
// import { addMessage } from '../../../../store/chat/chat.actions';
// import AppState from '../../../../store';
import {ChatRoom, MessagesPerDay} from '../../../models/chat/chatRoom';



@Component({
  selector: 'chat-panel',
  templateUrl: './chat-panel.component.html',
  styleUrls: ['./chat-panel.component.scss']
})
// export class ChatPanelComponent implements OnInit, OnChanges {
export class ChatPanelComponent {
  @Input() activeRoom: ChatRoom;
  @Input() activeRoomMessages: MessagesPerDay[];

  // public chatPartner: ChatUser = undefined;

  @Output() messageSentEmitter = new EventEmitter();
  @Output() permissionModalTrigger = new EventEmitter();

  // public chatUsers: ChatUser[] = [];

  constructor(
    // private store: Store<AppState>,
    // private globalService: GlobalService,
    public numberService: NumberService,
    public apiService: ApiService,
  ) {
  }

  // ngOnInit(): void {
    // this.store.select(fromChat.getChatUsers)
    //   .subscribe((chatUsers: any) => this.setChatUser(chatUsers));
    // this.store.select(fromChat.getActiveRoom)
    //   .subscribe((room: any) => this.setActiveRoom(room));
    // this.store.select(fromChat.getUnreadRooms)
    //   .subscribe((count: any) => this.setUnreadRooms(count));
    // this.store.select(fromChat.getUnreadMessages)
    //   .subscribe((count: any) => this.setUnreadMessages(count));
  // }

  // ngOnChanges(changes: SimpleChanges): void {
    // if (changes && changes.activeRoom.currentValue) {
    //
    // }
    // console.log('changes chat-panel', changes);
  // }

  openPermissionsModal($event: any): void {
    const otherUser = this.activeRoom.members.find((member: any) => member.id !== this.apiService.getUserId());
    this.permissionModalTrigger.emit(otherUser.id);
  }

  // public setActiveRoom(room: ChatRoom): void {
  //   if (room) {
  //     this.activeRoom = room;
  //     // console.log('this.activeRoom', this.activeRoom);
  //     // this.setChatPartner();
  //   }
  // }

  // public setChatPartner() {
  //   if (this.chatUsers.length > 0) {
  //     this.chatPartner = this.chatUsers.find(user => user.id === this.activeRoom.members[0].id);
  //   }
  // }
  //
  // public setChatUser(chatUsers: ChatUser[]) {
  //   this.chatUsers = chatUsers;
  // }

  // public setUnreadRooms(count: any) {
  //   // console.log('room:', count);
  // }
  //
  // public setUnreadMessages(count: any) {
  //   // console.log('message', count);
  // }


  /** todo: old rng related code, leave for now */
  // randomMessages = [
  //   'Hey, how are you doing?',
  //   'Audio recording #1',
  //   'Funny meme recording',
  //   'Nice music',
  //   'Nice tune',
  //   'Hard practice session',
  //   'Slow session',
  //   'Wish I would have been faster!',
  //   'I was tired.',
  //   'Now my friend that sat two chairs down from me was also reading Artemis at the same time as me and with a quick look to him he knew exactly what I was planning. He took it out and passed it over without hesitation. I opened to a random spot and just pretended like I was reading. (At this point it was just to mess with my teacher.)',
  //   'When I was a kid, I was always excited to learn new vocabulary. When I was in first grade, my teacher taught me that “shin” was another word for leg.',
  //   'Drama at my drama class: One time my drama class’s teacher had gone home sick so we were just put in a classroom with a movie to entertain us for the period when an alarm went off. None of us were sure if it was the fire alarm or the lockdown alarm, so we all head out into the hall to check and no one’s out there, so we head back in and climb under our desks as is lockdown procedure. Cut to an hour or so later when a teacher bursts in and nearly dies of relief because the school was on fire and we were the only students not accounted for and half the faculty and fire department had been searching for us for ages. Literally, the whole school had filled with smoke while we’d kept super safe under our wooden desks.',
  //   'I failed the first quarter of a class in middle school, so I made a fake report card. I did this every quarter that year. I forgot that they mail home the end-of-year cards, and my mom got it before I could intercept with my fake. She was PISSED—at the school for their error. The teacher also retired that year and had already thrown out his records, so they had to take my mother’s “proof” (the fake ones I made throughout the year) and “correct” the “mistake.” I’ve never told her the truth.',
  //   'Now that’s what I call stupid: In my junior year of high school, this guy asked me on a date. He rented a Redbox movie and made a pizza. We were watching the movie and the oven beeped so the pizza was done. He looked me dead in the eye and said, “This is the worst part.” I then watched this boy open the oven and pull the pizza out with his bare hands, rack and all, screaming at the top of his lungs. We never had a second date.',
  //   'A long multi line message.\nA long multi line message.\nA long multi line message.\nA long multi line message.\nA long multi line message.\nA long multi line message.\nA long multi line message.\nA long multi line message.\nA long multi line message.\nA long multi line message.\nA long multi line message.\nA long multi line message.\nA long multi line message.\nA long multi line message.\nA long multi line message.\nA long multi line message.\n',
  //   'This is quite a very long sentence that I have just typed in here due to some reason.\nThis is quite a very long sentence that I have just typed in here due to some reason.\nThis is quite a very long sentence that I have just typed in here due to some reason.\nThis is quite a very long sentence that I have just typed in here due to some reason.\nThis is quite a very long sentence that I have just typed in here due to some reason.\nThis is quite a very long sentence that I have just typed in here due to some reason.\nThis is quite a very long sentence that I have just typed in here due to some reason.\nThis is quite a very long sentence that I have just typed in here due to some reason.\nThis is quite a very long sentence that I have just typed in here due to some reason.\nThis is quite a very long sentence that I have just typed in here due to some reason.\nThis is quite a very long sentence that I have just typed in here due to some reason.\nThis is quite a very long sentence that I have just typed in here due to some reason.\nThis is quite a very long sentence that I have just typed in here due to some reason.\nThis is quite a very long sentence that I have just typed in here due to some reason.\nThis is quite a very long sentence that I have just typed in here due to some reason.\nThis is quite a very long sentence that I have just typed in here due to some reason.\n',
  //   'Without water bottle...',
  // ];
  //
  // VIDEOS: MessageFile[] = [
  //   {
  //     id: this.globalService.randomId(),
  //     name: 'Ocean',
  //     lastModified: new Date(),
  //     size: 1200000,
  //     type: 'video/mp4',
  //     src: 'https://file-examples-com.github.io/uploads/2018/04/file_example_MOV_1280_1_4MB.mov',
  //   },
  //   {
  //     id: this.globalService.randomId(),
  //     name: 'Palmtrees',
  //     lastModified: new Date(),
  //     size: 1000000,
  //     type: 'video/mp4',
  //     src: 'https://file-examples-com.github.io/uploads/2017/04/file_example_MP4_640_3MG.mp4',
  //   },
  // ];
  //
  // IMAGES: MessageFile[] = [
  //   {
  //     id: this.globalService.randomId(),
  //     name: 'Wallpaper cave',
  //     lastModified: new Date(),
  //     size: 104000,
  //     type: 'image/jpeg',
  //     src: 'https://wallpapercave.com/wp/wp2550666.jpg',
  //   },
  //   {
  //     id: this.globalService.randomId(),
  //     name: 'Mountain area',
  //     lastModified: new Date(),
  //     size: 218000,
  //     type: 'image/jpeg',
  //     src: 'https://www.teahub.io/photos/full/78-784382_so-nice-wallpaper.jpg',
  //   },
  //   {
  //     id: this.globalService.randomId(),
  //     name: 'Thumbs up',
  //     lastModified: new Date(),
  //     size: 28000,
  //     type: 'image/png',
  //     src: 'https://www.freepnglogos.com/uploads/thumbs-up-png/thumbs-icon-png-outline-thumbs-up-8.png',
  //   },
  // ];
  // DOCS = [
  //   {
  //     id: this.globalService.randomId(),
  //     name: 'example.pdf',
  //     lastModified: new Date(),
  //     size: 4400,
  //     type: 'application/pdf',
  //     src: 'https://www.computerhope.com/jargon/p/example.pdf',
  //   },
  //   {
  //     id: this.globalService.randomId(),
  //     name: 'file-sample_100kB.docx',
  //     lastModified: new Date(),
  //     size: 100000,
  //     type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  //     src: 'https://file-examples-com.github.io/uploads/2017/02/file-sample_100kB.docx',
  //   },
  //   {
  //     id: this.globalService.randomId(),
  //     name: 'zip_5MB.zip',
  //     lastModified: new Date(),
  //     size: 5000000,
  //     type: 'application/zip',
  //     src: 'https://file-examples-com.github.io/uploads/2017/02/zip_5MB.zip',
  //   },
  // ];
  groupChat(activeRoom: ChatRoom) {
    return activeRoom?.roomId?.startsWith("selfcare_group");
  }
}
