import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MeasurementService } from '../../measurements/measurement.service';
import { ApiService } from '../../api/api.service';
import {
  MEASUREMENT_QUALITIES_BY_QUALITY_TYPE,
  MEASUREMENT_TYPES, MeasurementIdEnum,
  MeasurementQualityType,
} from '../../models/enums/MeasurementEnums';
import { TranslateService } from '@ngx-translate/core';
import { NumberService } from '../../utils/number.service';
import { MeasurementType } from '../../models/MeasurementType';
import { pairwise } from 'rxjs/operators';

const DEFAULT_LIMIT_FALLBACK = [
  {order: 2, value: 5000},
  {order: 1, value: 4000},
  {order: 0, value: 3000},
  {order: -1, value: 2000},
  {order: -2, value: 1000},
];

@Component({
  selector: 'measurement-settings',
  templateUrl: './measurement-settings.component.html',
  styleUrls: ['./measurement-settings.component.scss']
})

export class MeasurementSettingsComponent {
  @Input() measurementTypeId: string;
  @Input() measurementSvg: string;
  @Input() measurementUnitString: string;
  @Input() measurementTypeTitle: string;
  @Input() customId: string = null;
  @Input() isNewCustomMeasurementType = false;

  isCustomMeasurement = false;
  addCustomMeasurementButton = false;

  measurementType: MeasurementType;
  fromControlArray = MEASUREMENT_QUALITIES_BY_QUALITY_TYPE.HIGHER_IS_BETTER;

  // the default limit values for a measurement type. Will be filled by API before modal opens
  defaultLimits = DEFAULT_LIMIT_FALLBACK;

  customSensorForm: UntypedFormGroup = null;

  limitsForm = this.formBuilder.group({
    BEST: [0],
    BETTER: [0],
    TARGET_VALUE: [0],
    LOW: [0],
    TOO_LOW: [0],
  });

  // todo: link to API result once it works
  settingsForm = this.formBuilder.group({
    NOTIFY: new UntypedFormControl(false)
  });

  formError = '';

  confirmDeleteMeasurementTypeModalId = 'confirmDeleteMeasurementModal';

  @Output() measurementSavedEmitter = new EventEmitter();

  constructor(
    readonly modal: NgxSmartModalService,
    readonly apiService: ApiService,
    readonly measurementService: MeasurementService,
    readonly formBuilder: UntypedFormBuilder,
    readonly translate: TranslateService,
    readonly numberService: NumberService,
  ) {
  }

  setIsCustomMeasurement() {
    if(this.measurementTypeId && this.measurementTypeId.startsWith(MeasurementIdEnum.CUSTOM) || this.customId) {
      this.isCustomMeasurement = true;
    }
    this.addCustomMeasurementButton = this.measurementTypeId === MeasurementIdEnum.CUSTOM && this.customId.startsWith('CUSTOM');
  }

  ngOnInit(): void {
    this.setIsCustomMeasurement();
  }

  async fetchMeasurementTypeSettings(): Promise<void> {
    this.setIsCustomMeasurement();

    if (this.addCustomMeasurementButton) {
      this.customSensorForm = this.formBuilder.group({
        NAME: new UntypedFormControl('', Validators.required),
        UNIT: new UntypedFormControl('', Validators.required)
      });

      this.measurementType = MEASUREMENT_TYPES[this.measurementTypeId];
    }

    const requests = [
      this.customId ? await Promise.resolve() : await this.apiService.getDefaultLimitValuesForMeasurementType(this.measurementTypeId),
    ];

    if (!this.isNewCustomMeasurementType) {
      requests.push(this.apiService.getMeasurementTypeSettings(this.measurementTypeId));
    }

    const results = await Promise.all(requests);

    const personalLimitValuesApi = results[1];

    if (!this.isCustomMeasurement) {
      const defaultLimitValues = results[0];
      this.defaultLimits = defaultLimitValues.limitValues;

      if (!personalLimitValuesApi.limitValues || personalLimitValuesApi.limitValues.length !== 5) {
        personalLimitValuesApi.limitValues = defaultLimitValues.limitValues;
        console.warn('API limit values do not have 5 ascending values. Using default values of measurement type.');
      }
    }

    this.measurementType = MEASUREMENT_TYPES[this.measurementTypeId];
    if(this.isCustomMeasurement) {
      this.measurementType = MEASUREMENT_TYPES[MeasurementIdEnum.CUSTOM];
    }

    if(personalLimitValuesApi) {
    const limitValues = personalLimitValuesApi.limitValues.map((limitValue: any) => ({value: limitValue.value}));
      if (this.measurementType.qualityType === MeasurementQualityType.HIGHER_IS_BETTER) {
        // console.log('hit higher is better');
        this.limitsForm = this.formBuilder.group({
          BEST: [limitValues[0].value, Validators.required],
          BETTER: [limitValues[1].value, Validators.required],
          TARGET_VALUE: [limitValues[2].value, Validators.required],
          LOW: [limitValues[3].value, Validators.required],
          TOO_LOW: [limitValues[4].value, Validators.required],
        });
        this.fromControlArray = MEASUREMENT_QUALITIES_BY_QUALITY_TYPE.HIGHER_IS_BETTER;
      } else if (this.measurementType.qualityType === MeasurementQualityType.MIDDLE_IS_BETTER) {
        this.limitsForm = this.formBuilder.group({
          TOO_HIGH: [limitValues[0].value, Validators.required],
          HIGH: [limitValues[1].value, Validators.required],
          TARGET_VALUE: [limitValues[2].value, Validators.required],
          LOW: [limitValues[3].value, Validators.required],
          TOO_LOW: [limitValues[4].value, Validators.required],
        });
        this.fromControlArray = MEASUREMENT_QUALITIES_BY_QUALITY_TYPE.MIDDLE_IS_BETTER;
      } else if (this.measurementType.qualityType === MeasurementQualityType.LOWER_IS_BETTER) {
        // console.log('hit lower is better');
        this.limitsForm = this.formBuilder.group({
          TOO_HIGH: [limitValues[0].value, Validators.required],
          HIGH: [limitValues[1].value, Validators.required],
          TARGET_VALUE: [limitValues[2].value, Validators.required],
          BETTER: [limitValues[3].value, Validators.required],
          BEST: [limitValues[4].value, Validators.required],
        });
        this.fromControlArray = MEASUREMENT_QUALITIES_BY_QUALITY_TYPE.LOWER_IS_BETTER;
      }
    }

    this.settingsForm.controls['NOTIFY'].valueChanges.pipe(pairwise()).subscribe(([prev, next]) => {
      if(prev !== next) {
        this.limitsForm.markAsDirty();
      }
    });

    this.settingsForm.get('NOTIFY').setValue(personalLimitValuesApi.notify);

    this.validateForm();
    if(this.addCustomMeasurementButton) {
      this.limitsForm.markAsDirty();
    }

    // Validate form, on value change
    this.limitsForm.valueChanges.subscribe(() => {
      // Use Timeout to validate after values have been changes. Subscribe will be called, before form values have been changed
      setTimeout(() => {
        if (this.validateForm()) {
          this.formError = '';
        }
      });
    });
  }

  /**
   * Resets the measurement type limit values in the form back to the default values (provided by API)
   */
  resetToDefault(): void {
    Object.keys(this.limitsForm.controls).forEach((key, index) => {
      this.limitsForm.get(key).setValue(this.defaultLimits[index].value);
    });

    this.limitsForm.markAsDirty();
  }

  async saveMeasurementSettings(): Promise<void> {
    if (!this.validateForm()) {
      return;
    }
    this.formError = '';

    let apiBody: any = { limitValues: [] as { order: number; value: number }[] };
    if(!this.measurementTypeId.startsWith(MeasurementIdEnum.CUSTOM)) {
      apiBody = {
        notify: this.settingsForm.value.NOTIFY,
        notifySms: this.settingsForm.value.NOTIFY,
        limitValues: [] as { order: number; value: number }[],
      };
    }

    if (this.addCustomMeasurementButton) {
      apiBody = {
        name: this.customSensorForm.value.NAME,
        unit: this.customSensorForm.value.UNIT,
        limitValues: [] as { order: number; value: number }[],
      };
    }

    let formValueIndexCounter = 0;
    for (let i = 2; i >= -2; i--) {
      let formValue = parseFloat(this.limitsForm.get(this.fromControlArray[formValueIndexCounter]).value);
      // round value to decimals
      if (this.measurementType.roundValueToDecimals > 0) {
        formValue = this.numberService.roundNumberToDecimals(formValue, this.measurementType.roundValueToDecimals);
      }
      apiBody.limitValues.push({
        order: i,
        value: formValue,
      });
      formValueIndexCounter++;
    }

    if (!this.addCustomMeasurementButton) {
      await this.apiService.saveMeasurementTypeSettings(this.measurementTypeId, apiBody, this.customId);
    } else {
      await this.apiService.saveCustomMeasurementType(apiBody);
    }
    // await new Promise(r => setTimeout(r, 2000));
    // const test = await this.apiService.getMeasurementTypeSettings(this.measurementTypeId);
    // console.log('test refetch', test);

    this.measurementSavedEmitter.emit(apiBody);
    this.modal.getModal(this.customId === 'CUSTOM' ? 'customTypeModal' : 'measurementSettingsPopup').close();
  }

  async removeMeasurementType(): Promise<void> {
    await this.apiService.deleteMeasurementType(this.measurementTypeId, this.customId);

    this.measurementSavedEmitter.emit(true);
    this.modal.closeAll();
  }

  /**
   * Validates the measurement type settings numbers
   * Uses the measurement type to determine whether there are rights
   * @returns {boolean}
   */
  validateForm(): boolean {
    const measurementType = this.isCustomMeasurement ? MEASUREMENT_TYPES[MeasurementIdEnum.CUSTOM] : MEASUREMENT_TYPES[this.measurementTypeId];
    for (let i = 0; i < 5; i++) {
      const formValue = parseFloat(this.limitsForm.get(this.fromControlArray[i]).value);
      // if measurement type doesn't allow negative values, deny
      if (!measurementType.allowNegativeValues && formValue < 0) {
        this.formError = this.translate.instant('MEASUREMENT_SETTINGS.ERRORS.NEGATIVE_VALUE', {measurementType: this.measurementTypeTitle});
        return false;
      }

      /** make sure from top to bottom each subsequent value is bigger than the previous one */
      const nextFormElement = this.limitsForm.get(this.fromControlArray[i + 1]);
      const nextFormValue = nextFormElement ? parseFloat(nextFormElement.value) : null;
      if (nextFormValue && (formValue <= nextFormValue)) {
        this.formError = this.translate.instant('MEASUREMENT_SETTINGS.ERRORS.INVALID_SEQUENCE_QUALITY_VALUES');
        return false;
      }

      // dont allow numbers with decimal values for measurement types that do not allow it
      if (formValue && measurementType.roundValueToDecimals === 0 && formValue % 1 !== 0) {
        this.formError = this.translate.instant('MEASUREMENT_SETTINGS.ERRORS.DECIMAL_VALUE', {measurementType: this.measurementTypeTitle});
        return false;
      }
    }
    return true;
  }

  resetModal(): void {
    this.formError = '';
    this.defaultLimits = DEFAULT_LIMIT_FALLBACK;
    this.limitsForm = this.formBuilder.group({
      BEST: [0],
      BETTER: [0],
      TARGET_VALUE: [0],
      LOW: [0],
      TOO_LOW: [0]
    });
  }
}
