import { Injectable } from '@angular/core';
import * as SignalR from '@microsoft/signalr';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BadgeFunctionIds, ReceiveBadgeUpdate} from '../models/badgeCounts';

@Injectable({
  providedIn: 'root'
})
export class ApiBadgeService {
  private badgeHubConnection: SignalR.HubConnection;

  private readonly initialBadgeState: ReceiveBadgeUpdate = { badges: [] };
  private readonly _badgeState: BehaviorSubject<ReceiveBadgeUpdate> = new BehaviorSubject<ReceiveBadgeUpdate>(this.initialBadgeState);
  public get badgeState(): Observable<ReceiveBadgeUpdate> {
    return this._badgeState.asObservable();
  }

  private readonly _totalUnreadMessages: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public get totalUnreadMessages(): Observable<number> {
    return this._totalUnreadMessages.asObservable();
  }

  private readonly _totalIncomingInvites: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public get totalIncomingInvites(): Observable<number> {
    return this._totalIncomingInvites.asObservable();
  }

  constructor()  {}

  startBadgeListeners() {
    this.setupSocketConnection();
    this.trackBadgeStateDerivatives();
  }

  private setupSocketConnection(): void {
    const accessToken = localStorage.getItem('access_token');

    const badgeHubConnection = new SignalR.HubConnectionBuilder()
      .withUrl(environment.signalRHubBaseUrl + 'badge', { accessTokenFactory: () => accessToken})
      // .configureLogging(signalR.LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    badgeHubConnection.on('receiveBadgeUpdate', (badgeUpdate: ReceiveBadgeUpdate) => {
        this._badgeState.next(badgeUpdate);
    });

    badgeHubConnection.start()
      .then(() => {
        this.badgeHubConnection = badgeHubConnection;
      }).catch((err: unknown)=> console.log('An error occurred while trying to start the badge hub connection.', err));
  }

  private trackBadgeStateDerivatives(): void {
    this._badgeState.subscribe(state => {
      this._totalIncomingInvites.next(0);
      let totalUnreadMessagesCount = 0;


      state.badges.forEach(bc => {
        if (bc.badge.function === BadgeFunctionIds.UnreadMessages) {
          totalUnreadMessagesCount += bc.count;
        } else if (bc.badge.function === BadgeFunctionIds.IncomingInvites) {
          this._totalIncomingInvites.next(bc.count);
        }
      });

      this._totalUnreadMessages.next(totalUnreadMessagesCount);
    });
  }
}
