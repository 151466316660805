import { Injectable } from '@angular/core';
import { PremiumService } from '../shared/utils/premiumService';

@Injectable()
export class PremiumGuardService {

   constructor(private premiumService: PremiumService) {}

   canActivate(): boolean {
    return this.premiumService.userHasPremiumAccess(true);
   }
}
