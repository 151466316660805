import {
  Component,
  ElementRef,
  Input,
  OnInit,
  AfterViewInit,
  OnDestroy,
  ViewChildren,
  QueryList,
  ViewChild, Output, EventEmitter
} from '@angular/core';

@Component({
  selector: 'carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() carouselSlides: string[];
  // this prefix of the carousel translation keys, for example: 'ONBOARDING.CAROUSEL'
  @Input() baseCarouselTranslationKey: string;
  @Input() slidingInfinitely = false;
  @ViewChild('refCarouselWrapper') refCarouselWrapper: ElementRef;
  @ViewChild('refSlidesContainer') refSlidesContainer: ElementRef;
  @ViewChildren('refSlides') refSlides: QueryList<ElementRef>;
  // Will be called when carousel moves to a new slide, number passed is the new slide's ID
  @Output() onSlideUpdate: EventEmitter<number> = new EventEmitter<number>();

  private carouselWrapper: HTMLElement;
  private slidesContainer: HTMLElement;
  private slides: HTMLElement[];
  private xStart: number = null;
  private count = 0;
  public current = 1;
  private locked = false;
  private width = 0;
  private varNameCount = '--count';
  private classNameSmooth = 'smooth';
  private varNameCurrent = '--current';
  private varNameTranslateX = '--translate-x';
  private varNameFactor = '--factor';
  public dotCollection: number[];
  private sliderInterval: number;
  private touchStartEventClientY: number | null = null;

  /**
   * Last slide page index
   */
  get maxSlideCount() {
    return this.count - 2;
  }

  /**
   * First slide page index
   */
  get minSlideCount() {
    return 1;
  }

  ngOnInit(): void {
    this.dotCollection = Array(this.carouselSlides.length - 2).fill(0);
  }

  ngAfterViewInit(): void {
    window.addEventListener('resize', this.resize.bind(this), false);
    this.initializeCarousel();
    this.resize();
  }

  /**
   * Note that changing the currentSlide, nextSlide, and previousSlide is all that is needed for carousel to work
   * The classes and CSS take care of the rest
   */
  initializeCarousel(): void {
    this.carouselWrapper = this.refCarouselWrapper.nativeElement;
    this.slides = this.refSlides.map(slide => slide.nativeElement);
    this.count = this.slides.length;
    this.slidesContainer = this.refSlidesContainer.nativeElement;
    this.slidesContainer.style.setProperty(this.varNameCount, this.count.toString());
    // initialize auto play
    this.initializeCarouselTimer();
  }

  lock(e: MouseEvent | TouchEvent) {
    if (e.type === 'touchstart') {
      e = <TouchEvent>e;
      this.touchStartEventClientY = e.touches[0].clientY;
    }
    this.xStart = this.unify(e).clientX;
    this.slidesContainer.classList.toggle(this.classNameSmooth, !(this.locked = true));
  }

  lockClear(e: MouseEvent | TouchEvent) {
    if (e.type === 'touchstart') {
      e = <TouchEvent>e;
      this.touchStartEventClientY = e.touches[0].clientY;
    }
    this.xStart = this.unify(e).clientX;
    this.slidesContainer.classList.toggle(this.classNameSmooth, !(this.locked = true));
    // clear interval and from now on assign to another function without the interval check
    this.clearSliderInterval();
    this.lockClear = this.lock;
  }

  move(e: MouseEvent | TouchEvent) {
    if (this.locked) {
      let distanceX = this.unify(e).clientX - this.xStart,
        signed = Math.sign(distanceX),
        factor = +(signed * distanceX / this.width).toFixed(2);
      if (factor > 0.2) {
        this.slidesContainer.style.setProperty(this.varNameCurrent, (this.current -= signed).toString());
        factor = 1 - factor;
      }
      // remove extra translation
      this.slidesContainer.style.setProperty(this.varNameTranslateX, '0px');
      // add sliding effect
      this.slidesContainer.classList.toggle(this.classNameSmooth, !(this.locked = false));
      this.slidesContainer.style.setProperty(this.varNameFactor, factor.toString());
      this.xStart = null;
      // correct the index to fake a infinite sliding
      this.correctCarouselCurrent(factor);
    }
    this.touchStartEventClientY = null;
  }

  /**
   * Go to next slide
   */
  nextSlide(): void {
    if (this.current === this.slides.length - 1 && !this.slidingInfinitely) return;
    this.current++;
    this.updateAndCorrectCarousel();
  }

  /**
   * Go to previous slide
   */
  previousSlide(): void {
    this.current--;
    this.updateAndCorrectCarousel();
  }

  correctCarouselCurrent(factor: number = 1): void {
    const corrected = this.getCorrectedValue(this.current, this.count);
    this.onSlideUpdate.emit(corrected);
    if (this.current !== corrected) {
      this.current = corrected;
      setTimeout(() => {
        this.updateSlide(false);
      }, factor * 0.5 * 1000);
    }
  }

  updateSlide(animation: boolean = true): void {
    this.slidesContainer.classList.toggle(this.classNameSmooth, animation);
    this.slidesContainer.style.setProperty(this.varNameCurrent, this.current.toString());
  }

  drag(e: MouseEvent | TouchEvent): void {
    // if touchmove is mainly vertical, allow scrolling and block 'swiping' the carousel steps
    if (e.type === 'touchmove') {
      e = <TouchEvent>e;
      let differenceY = this.touchStartEventClientY - e.touches[0].clientY;
      if (differenceY < 0) {
        differenceY = differenceY * -1;
      }
      if (differenceY > 5) return;
    }

    e.preventDefault();
    if (this.locked) {
      this.slidesContainer.style.setProperty(this.varNameTranslateX, `${this.unify(e).clientX - this.xStart}px`);
    }
  }

  unify(e: any) {
    return e.changedTouches ? e.changedTouches[0] : e;
  }

  resize(): void {
    this.width = this.carouselWrapper.clientWidth;
  }

  /**
   * Navigates to specific slide
   * @param current new current slide
   */
  navigateSlide(current: number): void {
    this.current = current;
    this.updateAndCorrectCarousel();
  }

  navigateSlideClear(current: number): void {
    this.onSlideUpdate.emit(current);
    this.clearSliderInterval();
    this.initializeCarouselTimer();
    this.current = current;
    this.updateSlide();
    // Todo: Not sure what this line is for, but if you want the carousel to automatically continue after a dot is clicked, the following line shouldn't be executed.
    // this.navigateSlideClear = this.navigateSlide;
  }

  initializeCarouselTimer(): void {
    this.sliderInterval = window.setInterval(() => {
      if (!this.slidingInfinitely && this.current === this.slides.length - 2) return;
      this.current++;
      this.updateSlide();
      this.correctCarouselCurrent();
    }, 3000);
  }

  clearSliderInterval() {
    if (this.sliderInterval) {
      clearInterval(this.sliderInterval);
    }
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.resize.bind(this), false);
    this.clearSliderInterval();
  }

  getSlideTitleStringKey(index: number, total: number): string {
    index = this.getCorrectedValue(index, total);
    return `${this.baseCarouselTranslationKey}_STEP_${index}_TITLE`;
  }

  getSlideDescriptionStringKey(index: number, total: number): string {
    index = this.getCorrectedValue(index, total);
    return `${this.baseCarouselTranslationKey}_STEP_${index}_DESCRIPTION`;
  }

  private getCorrectedValue(index: number, total: number) {
    if (index === total - 1) {
      index = 1;
    } else if (index === 0) {
      index = total - 2;
    }
    return index;
  }

  /**
   * Updates slides & corrects current value
   */
  private updateAndCorrectCarousel() {
    this.updateSlide();
    this.correctCarouselCurrent();
  }
}
