<ngx-smart-modal
  [identifier]="modalId"
  [closable]="false"
  customClass="include-padding small-modal"
  class="allow-connection-wrapper">
  <h3 class="allow-connection-title">{{ 'CONNECTION_PERMISSION_MODAL.TITLE' | translate }}</h3>
  <p class="allow-connection-description">{{ 'CONNECTION_PERMISSION_MODAL.DESCRIPTION' | translate: { wearableVendor: wearableVendorName } }}</p>
  <div class="allow-connection-button-wrapper">
    <app-button
      class="allow-connection-button"
      (click)="proceed()"
      [styles]="partner === PartnerEnum.VITAL10 ? BUTTON_STYLES.vital10 : BUTTON_STYLES.primary"
      [buttonText]="'SHARED.PROCEED' | translate"></app-button>
    <app-button
      class="allow-connection-button"
      (click)="modal.close(modalId)"
      [styles]="partner === PartnerEnum.VITAL10 ? BUTTON_STYLES.vital10Secondary : BUTTON_STYLES.secondary"
      [buttonText]="'SHARED.CANCEL' | translate"></app-button>
  </div>
</ngx-smart-modal>
