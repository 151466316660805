import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChatRoom } from '../../../models/chat/chatRoom';
// import { Store } from '@ngrx/store';
// import * as fromChat from '../../../../store/chat/chat.selector';
// import AppState from "../../../../store";

@Component({
  selector: 'room-list',
  templateUrl: './room-list.component.html',
  styleUrls: ['./room-list.component.scss']
})
export class RoomListComponent implements OnInit {
  // all chat rooms
  @Input() chatRooms: ChatRoom[] = [];
  // currently 'selected/active' chatroom
  @Input() activeRoom: ChatRoom;

  @Output() activeRoomEmitter = new EventEmitter();

  constructor(
    // private store: Store<AppState>
  )
  {}

  ngOnInit(): void {
    // this.store.select(fromChat.getRooms)
    //   .subscribe((rooms: any) => this.addRooms(rooms));
    // this.store.select(fromChat.getActiveRoom)
    //   .subscribe((room: any) => this.setActiveRoom(room));
  }

  // public addRooms(rooms: any) {
  //   this.chatRooms = rooms;
  // }
  //
  // public setActiveRoom(room: ChatRoom): void {
  //   if (room) {
  //     this.activeRoom = room;
  //   }
  // }
}
