<ngx-smart-modal
  [identifier]="modalId"
  [closable]="false"
  [hideDelay]="200"
  (onAnyCloseEventFinished)="resetValues()"
>
  <form [formGroup]="addAudioForm" class="form-add-audio">
    <div class="add-audio-panel-content">
      <div class="title-wrapper">
        <div class="icon-add" inlineSVG="icn_audio.svg"></div>
        <h2 class="title-add-audio">{{ 'DASHBOARD.PANEL.ADD_AUDIO_TITLE' | translate }}</h2>
        <img
          (click)="modal.getModal(modalId).close()"
          class="close-button"
          src="/assets/img/icn_close.svg" alt="close"
        >
      </div>
      <p class="text-add-audio">
        {{ 'DASHBOARD.PANEL.ADD_AUDIO_TEXT_1' | translate }}<br>
        {{ 'SHARED.FILE_SIZE_OF' | translate }}
        {{ 'SHARED.FILE_MAX_SIZE' | translate : {size: 5} }}
      </p>
      <div class="field-wrapper">
        <div class="title-field">
          {{ 'SHARED.INPUT_LABEL_DATE' | translate }} *
        </div>
        <date-time-picker
          [includeTime]="true"
          [dateInputIsEditable]="false"
          [maxEndDate]="now"
          [date]="addAudioForm.get('date').value"
          (dateTimeSaved)="addAudioForm.get('date').setValue($event.date)"
        ></date-time-picker>
      </div>
      <div class="field-wrapper">
        <div class="title-field">
          {{ 'DASHBOARD.PANEL.INPUT_AUDIO_LABEL_UPLOAD' | translate }} *
        </div>
        <div class="uploader-wrapper">
          <file-uploader
            formControlName="audio"
            [required]="true"
            [maxFileSize]="5"
            icon="icn_audio.svg"
            [accept]="'.mp3,.ogg,.wav,.flac,.aac'"
            [callback]="setAudioFile.bind(this)"
            placeholder="{{ 'SHARED.FILE_MAX_SIZE' | translate : {size: 5} }}"></file-uploader>
        </div>
      </div>
      <div class="field-wrapper">
        <div class="title-field">
          {{ 'DASHBOARD.PANEL.INPUT_AUDIO_LABEL_TITLE' | translate }} ({{ 'SHARED.OPTIONAL' | translate }})
        </div>
        <input-text
          [backgroundStyle]="'grey'"
          formControlName="title"
          placeholder="{{ 'DASHBOARD.PANEL.INPUT_AUDIO_PLACEHOLDER_TITLE' | translate }}">
        </input-text>
      </div>
    </div>
    <div class="button-wrapper">
      <app-button
        [disabled]="!isFormValid"
        buttonText="{{ 'SHARED.SAVE' | translate }}"
        [styles]="[BUTTON_STYLES.shaped, BUTTON_STYLES.dark]"
        (click)="saveAudio()">
      </app-button>
    </div>
  </form>
</ngx-smart-modal>
