import { Component, OnInit } from '@angular/core';
import {NgxSmartModalService} from "ngx-smart-modal";
import {BUTTON_STYLES} from "../../button/button.component";
import {Router} from "@angular/router";
import {OidcService} from "../../../../oidc/oidc.service";

@Component({
  selector: 'token-expired-modal',
  templateUrl: './token-expired-modal.component.html',
  styleUrls: ['./token-expired-modal.component.scss']
})
export class TokenExpiredModalComponent implements OnInit {

  BUTTON_STYLES = BUTTON_STYLES;

  constructor(public modal: NgxSmartModalService,
              private router: Router,
              private oidService: OidcService) { }

  ngOnInit(): void {
  }

  async closeExpireModal() {
    this.modal.close('tokenExpiredModal');
    await this.oidService.logout();
  }
}
