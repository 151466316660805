import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlContainer, UntypedFormGroup } from '@angular/forms';
import { MEASUREMENT_CATEGORIES, MeasurementCategory, MeasurementIdEnum } from '../../models/enums/MeasurementEnums';
import { MeasurementType } from '../../models/MeasurementType';
import { TranslateService } from '@ngx-translate/core';
import { MeasurementService } from '../../measurements/measurement.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ApiService } from '../../api/api.service';
import { MeasurementTypeSelectionResponse } from '../../models/dashboard/MeasurementTypeSelectionGroup';



@Component({
  selector: 'measurement-category-blocks',
  templateUrl: './measurement-category-blocks.component.html',
  styleUrls: ['./measurement-category-blocks.component.scss'],
})
export class MeasurementCategoryBlocksComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('measurementCategoryBlocksWrapper') measurementCategoryBlocksWrapper: ElementRef;

  @Input() smallHeaders = false;
  @Input() oneMeasurementRestriction = false;
  @Input() measurementCategory: MeasurementCategory = MeasurementCategory.NONE;
  @Input() measurementIds: string[] = [];
  @Input() customTypes: MeasurementType[] = [];
  @Input() hideCustomAddButton = false;
  @Input() modalId = 'setMeasurementsModal';
  @Input() isModal = true;
  @Input() addedToDashboardIndicator = true;

  form: UntypedFormGroup;

  MEASUREMENT_CATEGORIES = MEASUREMENT_CATEGORIES;
  MEASUREMENT_CATEGORIES_SELECTION: MeasurementCategory[];
  MEASUREMENT_TYPES: {[index: string]: MeasurementType[]} = {};
  userMeasurementTypeConfiguration: MeasurementTypeSelectionResponse;

  @Output() onMeasurementSelected = new EventEmitter<MeasurementTypeClickedEvent>();

  constructor(
    readonly controlContainer: ControlContainer,
    readonly translate: TranslateService,
    readonly measurementService: MeasurementService,
    readonly apiService: ApiService,
    public modal: NgxSmartModalService,
  ) { }

  ngOnInit(): void {
    this.form = <UntypedFormGroup>this.controlContainer.control;
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    let modalRef;
    if(this.isModal) {
      modalRef = this.modal.getModal(this.modalId);
    }
    if (modalRef && modalRef.hasData()) {
      const data: any = modalRef.getData();

      if (data.measurementCategory) {
        this.measurementCategory = data.measurementCategory;
      }
    }

    await this.setMeasurementTypes();
  }

  ngAfterViewInit(): void {
    // provide horizontal mouse wheel scroll. Note: Firefox emit 'wheel' events, while Chromium 'mousewheel' events

    if (this.measurementCategoryBlocksWrapper) {
      const horizontalScrollBodies = this.measurementCategoryBlocksWrapper.nativeElement.querySelectorAll('.measurements-blocks-wrapper');
      if (horizontalScrollBodies) {
        horizontalScrollBodies.forEach((scrollBody: any) => {
          scrollBody.addEventListener('wheel', function(e: WheelEvent) {
            // console.log('hitting horizontal mousewheel');
            // @ts-ignore
            this.scrollLeft -= (e.wheelDelta);
            e.preventDefault();
          }, false);
        });
      }
    }
  }

  async setMeasurementTypes(): Promise<void> {
    this.MEASUREMENT_CATEGORIES_SELECTION = [];
    this.MEASUREMENT_TYPES = {};

    if (!this.apiService.userMeasurementTypeConfiguration) {
      this.userMeasurementTypeConfiguration = await this.apiService.getUserMeasurementTypeConfiguration();
    }
    else
    {
      this.userMeasurementTypeConfiguration = this.apiService.userMeasurementTypeConfiguration;
    }
    this.MEASUREMENT_CATEGORIES.forEach(category => {
      if (
        this.measurementCategory !== undefined &&
        this.measurementCategory !== MeasurementCategory.NONE &&
        this.measurementCategory !== category) {
        return;
      }

      this.MEASUREMENT_CATEGORIES_SELECTION.push(category);

      const measurementTypes = this.measurementService.getMeasurementsGroupedByCategory(category);
      this.MEASUREMENT_TYPES[category] = this.getSortedMeasurementTypes(measurementTypes);

      if (category === MeasurementCategory.CUSTOM_MEASUREMENTS) {
        if(this.hideCustomAddButton) {
          const customAddButtonIndex = this.MEASUREMENT_TYPES[category].findIndex(mt => mt.id === 'CUSTOM');
          if(customAddButtonIndex != -1) {
            this.MEASUREMENT_TYPES[category].splice(customAddButtonIndex, 1);
          }
        }
      }
    });
  }

  getSortedMeasurementTypes(measurementTypes: MeasurementType[]): MeasurementType[] {
    const measurementIdTranslateKeys: any = [];

    if (!this.userMeasurementTypeConfiguration)
    {
      return measurementTypes;
    }

    measurementTypes.forEach(type => {
      const userMeasurementType = this.userMeasurementTypeConfiguration.measurementGroups.find(mg => mg.group === type.measurementCategory as string)
      ?.measurementTypes.find(mt =>
          (mt.measurementType === type.id && !type.customName) ||
        mt.customName === type.customName);

      if (userMeasurementType && this.addedToDashboardIndicator)
      {
        type.addedToDashboard = userMeasurementType.isAddedToDashboard;
      } else {
        type.addedToDashboard = false;
      }


      if (type.measurementCategory !== MeasurementCategory.CUSTOM_MEASUREMENTS) {
        const translateKey: string = this.translate.instant(`DASHBOARD.ELEMENTS.${type.id}`);
        measurementIdTranslateKeys[translateKey] = type.id;
      } else {
        measurementIdTranslateKeys[type.customName] = type.customName;
      }
    });

    const sortedTranslations = Object.keys(measurementIdTranslateKeys).sort();
    const sortedIds = sortedTranslations.map(translation => measurementIdTranslateKeys[translation]);

    this.moveCustomButtonToTopOfArray(measurementTypes, sortedIds);
    return sortedIds.map(id => measurementTypes.find(type => type.id === id || type.customName === id));
  }

  moveCustomButtonToTopOfArray(measurementTypes: MeasurementType[], sortedIds: string[]): void {
    if(measurementTypes.some(type => type.measurementCategory === MeasurementCategory.CUSTOM_MEASUREMENTS)) {
      const customId = sortedIds.findIndex(id => id === MeasurementIdEnum.CUSTOM);
      const customType = sortedIds.splice(customId, 1);
      sortedIds.unshift(customType[0]);
    }
  }

  onChange(clickedMeasurementId: string, measurementCategory?: string, addedToDashboard?: boolean): void {
    if(clickedMeasurementId === MeasurementIdEnum.CUSTOM && measurementCategory === MeasurementCategory.CUSTOM_MEASUREMENTS) {
      this.modal.open('customTypeModal');
      return ;
    }

    this.onMeasurementSelected.emit({
      clickedMeasurementId: clickedMeasurementId,
      addedToDashboard: addedToDashboard
    });
  }

  get listedFormControls(): { [x: string]: any }[] | [] {
    if (!this.form) return [];

    const formControls: string[] = [];
    Object.keys(this.form?.value).forEach(category => Object.keys(this.form.get(category).value).forEach(key => formControls.push(key)));

    return formControls.map(measurementId => ({[measurementId]: this.form.get(`${this.measurementService.getMeasurementTypeById(measurementId).measurementCategory}.${measurementId}`).value}));
  }

  get enabledMeasurementIds(): string[] {
    return this.listedFormControls.filter(formControl => Object.values(formControl)[0]).map(measurement => Object.keys(measurement)[0]);
  }
}

export interface MeasurementTypeClickedEvent {
  clickedMeasurementId: string,
  addedToDashboard: boolean
}

