import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { BUTTON_STYLES } from '../../../components/button/button.component';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ApiService } from '../../../api/api.service';
import SavedNote, {NoteType} from '../../../models/note/SavedNote';
import NoteFormData from '../../../models/note/NoteFormData';

export interface EditedAudioItem {
  date: string;
  // Todo: include the audio src in the historyresponse so it can be edited
  // audioSrc: string;
  title?: string;
}

@Component({
  selector: 'add-audio-panel',
  templateUrl: './add-audio-panel.component.html',
  styleUrls: ['./add-audio-panel.component.scss']
})
export class AddAudioPanelComponent implements OnInit, OnChanges {
  // field used when editing an existing audio record
  @Input() editedItemId: string;

  @Output() audioNoteSaved = new EventEmitter();
  @Output() audioNoteModalClosed = new EventEmitter();

  readonly BUTTON_STYLES = BUTTON_STYLES;
  public addAudioForm: UntypedFormGroup;
  public formData: NoteFormData;

  editedItem: SavedNote;
  modalId = 'addAudioModal';
  file: File;
  isFormValid = false;

  now = new Date();

  constructor(
    public modal: NgxSmartModalService,
    private formBuilder: UntypedFormBuilder,
    private api: ApiService,
  ) {}

  ngOnInit(): void {
    this.addAudioForm = this.formBuilder.group({
      date: [new Date(), Validators.required],
      audio: ['', Validators.required],
      title: [''],
    });

    this.addAudioForm.valueChanges.subscribe(() => this.isFormValid = this.addAudioForm.valid);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.editedItemId?.currentValue && this.modal.get(this.modalId).isVisible()) {
      this.loadAudioNoteData();
    }
  }

  async loadAudioNoteData(): Promise<void> {
    this.editedItem = await this.api.getNoteByNoteId(this.editedItemId);
    this.addAudioForm.get('date').setValue(new Date(this.editedItem.dateTime), {emitEvent: false});
    if (this.editedItem.content) this.addAudioForm.get('title').setValue(this.editedItem.content, {emitEvent: false});
    if (this.editedItem.attachments.length) this.addAudioForm.get('audio').setValue(this.editedItem.attachments[0].url, {emitEvent: false});
  }

  public async saveAudio(): Promise<void> {
    const formData: NoteFormData = {
      date: this.addAudioForm.get('date').value.toISOString(),
      file: this.addAudioForm.get('audio').value,
      content: this.addAudioForm.get('title').value,
      type: NoteType.VOICE,
    };

    if (this.editedItemId) {
      // PUT API: editing existing note
      const editNoteResult = await this.api.editNote(formData, this.editedItemId);
    } else {
      // POST API: creating existing note
      const createNoteResult = await this.api.createNote(formData);
    }

    this.modal.close(this.modalId);
    this.audioNoteSaved.emit();

    // // this.editedItem ? await this.api.editNote(formData, this.editedItemId).subscribe(console.log) : await this.api.createNote(formData).subscribe(console.log);
    // this.modal.close(this.modalId);
    // this.addAudioForm.get('title').reset();
    // this.audioNoteSaved.emit();
  }

  setAudioFile(file: File): void {
    this.formData.file = file;
  }

  resetValues(): void {
    this.addAudioForm.setValue({
      date: new Date(),
      audio: '',
      title: '',
    });

    this.isFormValid = false;
    this.audioNoteModalClosed.emit();
  }
}
