import {Injectable} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {MEASUREMENT_CATEGORIES, MeasurementCategory, MeasurementIdEnum} from '../models/enums/MeasurementEnums';
import {MeasurementService} from '../measurements/measurement.service';


/**
 * A service used to help with generating forms for measurement selection block forms.
 */
@Injectable({
  providedIn: 'root'
})
export class MeasurementFilterBlockService {

  constructor(
    public form: UntypedFormBuilder,
    public measurementService: MeasurementService,
  ) {
  }

  getMeasurementCategories(enabledMeasurementIds: string[] = []): object {
    const enabledMeasurementBlocks: any = {};
    MEASUREMENT_CATEGORIES.forEach(measurementCategory => {
      enabledMeasurementBlocks[measurementCategory] = this.getMeasurementTypesForCategory(measurementCategory, enabledMeasurementIds);
    });
    return enabledMeasurementBlocks;
  }

  getMeasurementCategoriesFormGroup(enabledMeasurementIds: string[] = []): UntypedFormGroup {
    const enabledMeasurementBlocks: any = {};
    MEASUREMENT_CATEGORIES.forEach(measurementCategory => {
      enabledMeasurementBlocks[measurementCategory] = this.getMeasurementCategoryFormGroup(measurementCategory, enabledMeasurementIds);
    });
    return this.form.group(enabledMeasurementBlocks);
  }

  getEnabledMeasurementIdsByFormGroup(enabledMeasurementFormGroup: UntypedFormGroup) {
    const enabledMeasurementIds: MeasurementIdEnum[] = [];
    for (const category in enabledMeasurementFormGroup.value) {
      for (const measurement in enabledMeasurementFormGroup.value[category]) {
        if (enabledMeasurementFormGroup.get(`${category}.${measurement}`).value) {
          enabledMeasurementIds.push(measurement as MeasurementIdEnum);
        }
      }
    }
    return enabledMeasurementIds;
  }

  private getMeasurementTypesForCategory(measurementCategory: MeasurementCategory, enabledMeasurementIds: string[]): object {
    const typesForCategory: any = {};
    const measurements = this.measurementService.getMeasurementsGroupedByCategory(measurementCategory);
    measurements.forEach(measurementBlock => {
      typesForCategory[measurementBlock.id] = enabledMeasurementIds.includes(measurementBlock.id);
    });

    return typesForCategory;
  }

  private getMeasurementCategoryFormGroup(measurementCategory: MeasurementCategory, enabledMeasurementIds: string[]): UntypedFormGroup {
    const sectionGroup = this.getMeasurementTypesForCategory(measurementCategory, enabledMeasurementIds);
    return this.form.group(sectionGroup);
  }
}
