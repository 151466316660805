import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { BUTTON_STYLES } from '../../../../shared/components/button/button.component';
import { Partner } from '../../../../shared/models/partner';

@Component({
  selector: 'disconnect-popup',
  templateUrl: './disconnect-popup.component.html',
  styleUrls: ['./disconnect-popup.component.scss']
})
export class DisconnectPopupComponent implements OnInit {
  @Input() appName: string;
  @Input() appKey: string;
  @Input() partner: string = null;

  @Output() onDisconnect = new EventEmitter();
  @Output() onSync = new EventEmitter();

  identifier = 'disconnectPopup';
  appleHealthKit = 'apple_healthkit';
  BUTTON_STYLES = BUTTON_STYLES;
  PartnerEnum = Partner;

  constructor(
    readonly modal: NgxSmartModalService
  ) { }

  ngOnInit(): void {

  }

  disconnect() {
    this.onDisconnect.emit();
  }

  sync() {
    this.onSync.emit();
  }

}
