import {Injectable} from '@angular/core';
import {Store} from "@ngrx/store";
import UserProfile from "../models/userProfile";
import {Subscription} from "rxjs";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  private readonly sub: Subscription;
  private cultureCode: string;

  constructor(
    private store: Store<{ 'userProfile': UserProfile }>,
    private translate: TranslateService
  ) {
    this.sub = this.store.subscribe(userProfile => {
      this.cultureCode = userProfile?.userProfile?.cultureCode;
      if (userProfile?.userProfile?.language) {
        this.translate.use(userProfile.userProfile.language);
      }
    });
  }
}
