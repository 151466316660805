import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'user-profile-image',
  templateUrl: './user-profile-image.component.html',
  styleUrls: ['./user-profile-image.component.scss']
})
export class UserProfileImageComponent implements OnInit {
  @Input() profileImage: string | null = null;
  @Input() width = '3.2rem';
  @Input() height = '3.2rem';

  constructor() {
  }

  ngOnInit(): void {
  }

}
