<div class="date-input-wrapper">
  <!-- Cannot manually edit date with full months written out -->
  <input
    #manualDateInput
    *ngIf="!includeTime && dateStringFormat === DateStringFormat.NUMBERS"
    type="text"
    class="date-holder can-be-inputted"
    [formControl]="dateInput"
    (input)="handleDateInput()"
    (keypress)="handleDateKeypress($event)"
    maxlength="10"
    [value]="inputDateValueString"
    [ngClass]="{'include-box-shadow': includeBoxShadow, invalid: showErrorMessage, 'is-part-of-start-end-date-combo': isPartOfStartEndDateCombo, 'white-background': whiteBackground}"
    [placeholder]="placeholderIncluded ? ('DATE_TIME_PICKER.DATE_AND_TIME.PLACEHOLDER' | translate) : ''"
  >
  <div
    *ngIf="includeTime"
    class="date-holder"
    [ngClass]="includeBoxShadow ? 'include-box-shadow' : ''"
    (click)="modal.getModal(modalId).open(true)">{{ inputDateValueString }}
  </div>
  <div class="date-icon-container" (click)="modal.getModal(modalId).open(true)">
    <div inlineSVG="../../../../assets/img/icn_date-dropdown.svg"></div>
  </div>
</div>
<p
  *ngIf="showErrorMessage || dateInput.errors"
  class="invalid-date-message error-message"
>
  {{ dateInput.errors ? (dateInputError) : invalidMessage }}
</p>

<ngx-smart-modal
  [identifier]="modalId"
  [hideDelay]="200"
  [closable]="false"
  (onOpen)="onDatePickerOpened()"
  customClass="small-modal"
>
  <div class="date-time-modal-wrapper">
    <div class="close-button" inlineSVG="icn_close.svg" (click)="closeModal()"></div>
    <div class="top-section">
      <h3 class="modal-title">{{ (includeTime ? 'DATE_TIME_PICKER.DATE_AND_TIME.TITLE' : 'DATE_TIME_PICKER.DATE_ONLY.TITLE') | translate }}</h3>
      <div class="modal-subtitle">{{ (includeTime ? 'DATE_TIME_PICKER.DATE_AND_TIME.SUBTITLE' : 'DATE_TIME_PICKER.DATE_ONLY.SUBTITLE') | translate }}</div>
    </div>
    <div class="date-picker-section" [formGroup]="datePickerForm">
      <div class="date-picker-wrapper">
        <dp-day-calendar
          #datePicker
          [config]="datePickerConfig"
          formControlName="date"
          theme="calendar-host-container"
        ></dp-day-calendar>
      </div>
      <div *ngIf="includeTime" class="time-picker">
        <div class="time-picker-label">
          {{ 'DATE_TIME_PICKER.DATE_AND_TIME.TIME' | translate }}
        </div>
        <input
          formControlName="time"
          type="time"
          class="time-picker-input"
        >
<!--        [attr.max]="maxTimeInput ? maxTimeInput : null"-->
      </div>
      <div *ngIf="includeTime && invalidTime" class="error-message" style="margin-top: 8px">
        {{ 'DATE_TIME_PICKER.ERROR_MESSAGES.INVALID_TIME' | translate }}
      </div>
    </div>
    <div class="bottom-button-section">
      <app-button
        [buttonText]="'SHARED.SAVE' | translate"
        [includeLeftCheckmarkIcon]="true"
        [disabled]="!buttonEnabled || invalidTime"
        (click)="setDateTime(true, true)"
      ></app-button>
    </div>
  </div>
</ngx-smart-modal>
