import { Injectable, OnDestroy } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import {BehaviorSubject } from 'rxjs';
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class MeasurementHubService implements OnDestroy {

  private measurementSupplierHubConnection: HubConnection;

  supplierDisconnected$ = new BehaviorSubject<string>(null);

  public init(): void {

    if (this.measurementSupplierHubConnection != null) {
      return;
    }

    const accessToken = localStorage.getItem('access_token');

    const measurementSupplierHubConnection = new HubConnectionBuilder()
      .withUrl(environment.signalRHubMeasurementBaseUrl + 'supplier', { accessTokenFactory: () => accessToken})
      .withAutomaticReconnect()
      .build();

    measurementSupplierHubConnection.on('supplierDisconnected', (message) => this.onSupplierDisconnected(message));

    measurementSupplierHubConnection.start()
      .then(() => {
        this.measurementSupplierHubConnection = measurementSupplierHubConnection;
      })
      .catch((err: unknown) => console.log('An error occurred while trying to start the measurement hub connection.', err));
  }

  async ngOnDestroy(): Promise<void> {
    await this.measurementSupplierHubConnection.stop();
  }

  private onSupplierDisconnected(message: { supplierName: string }): void {
    this.supplierDisconnected$.next(message.supplierName);
  }

  public async acknowledgeSupplierDisconnect(supplierName: string): Promise<void> {
    await this.measurementSupplierHubConnection.send('AcknowledgeSupplierDisconnect', supplierName);
  }
}
