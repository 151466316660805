import {Injectable} from '@angular/core';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {SubscriptionState} from '../models/userProfile';
import {OAuthService} from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root',
})

// Todo: expand this
export class PremiumService {
  constructor(
    readonly modal: NgxSmartModalService,
    private oauthService: OAuthService
  ) {
  }

  userHasPremiumAccess(openModal = true): boolean {
    const claimsObj: any = this.oauthService.getIdentityClaims();

    let isPremium = false;
    if (claimsObj && claimsObj.subscriptionState) {
      if (claimsObj.subscriptionState === SubscriptionState.Premium
        || claimsObj.subscriptionState === SubscriptionState.Pro
        || claimsObj.subscriptionState === SubscriptionState.Trial) {
        isPremium = true;
      }
    }

    if (isPremium && this.oauthService.hasValidAccessToken()) {
      return true;
    }

    if (openModal) {
      this.openPremiumModal();
    }

    return false;
  }

  openPremiumModal(): void {
    this.modal.open('premiumModal');
  }
}
