import { Component, Input, OnInit } from '@angular/core';
import AggregateDataBlock from '../../../models/enums/aggregateDataBlock';
import { MeasurementType } from '../../../models/MeasurementType';

@Component({
  selector: 'aggregate-data-block',
  templateUrl: './aggregate-data-block.component.html',
  styleUrls: ['./aggregate-data-block.component.scss']
})
export class AggregateDataBlockComponent implements OnInit {
  @Input() dataBlockType: AggregateDataBlock;
  @Input() unit: string;
  @Input() unitAsDuration = false;
  @Input() value: string;

  AggregateDataBlock = AggregateDataBlock;

  labelString = '';
  dataBlockTypeSvg = '';

  // constructor(
  //
  // ) {}

  ngOnInit(): void {
    this.labelString = this.getAggregateBlockLabel(this.dataBlockType);
    this.dataBlockTypeSvg = this.getAggregateBlockSvg(this.dataBlockType);
  }

  getAggregateBlockLabel(dataBlockType: AggregateDataBlock): string {
    switch(dataBlockType) {
      case AggregateDataBlock.MINIMUM:
        return 'MEASUREMENT_DETAILS.MINIMUM';
      case AggregateDataBlock.AVERAGE:
        return 'MEASUREMENT_DETAILS.AVERAGE';
      case AggregateDataBlock.MAXIMUM:
        return 'MEASUREMENT_DETAILS.MAXIMUM';
    }
  }

  getAggregateBlockSvg(dataBlockType: AggregateDataBlock): string {
    switch(dataBlockType) {
      case AggregateDataBlock.MINIMUM:
        return 'bttn_redbox.svg';
      case AggregateDataBlock.AVERAGE:
        return 'bttn_yellowbox.svg';
      case AggregateDataBlock.MAXIMUM:
        return 'bttn_greenbox.svg';
    }
  }
}
