import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'settings-outlined-button',
  templateUrl: './settings-outlined-button.component.html',
  styleUrls: ['./settings-outlined-button.component.scss']
})
export class SettingsOutlinedButtonComponent {
  @Output() click = new EventEmitter<MouseEvent>();

  clicked(event: MouseEvent) {
    this.click.emit(event);
  }
}
