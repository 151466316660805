import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { BUTTON_STYLES } from '../../../components/button/button.component';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { MeasurementService } from '../../../measurements/measurement.service';
import { EmojiListComponent } from '../../../components/emoji-list/emoji-list.component';
import { ApiService } from '../../../api/api.service';
import { MEASUREMENT_TYPES, MeasurementIdEnum, SavedMeasurement } from '../../../models/enums/MeasurementEnums';
import { TranslateService } from '@ngx-translate/core';
import { NumberService } from '../../../utils/number.service';
import { MeasurementType } from '../../../models/MeasurementType';
import { PremiumService } from 'src/app/shared/utils/premiumService';
// import { processGlobalOptions } from '@adyen/adyen-web/dist/types/core/utils';
// import { getLocaleFirstDayOfWeek } from '@angular/common';

@Component({
  selector: 'add-measurement-data-popup',
  templateUrl: './add-measurement-data-popup.component.html',
  styleUrls: ['./add-measurement-data-popup.component.scss'],
})
export class AddMeasurementDataPopupComponent implements OnInit, OnChanges {
  @ViewChild('emojiListComponent') emojiListComponent: EmojiListComponent;

  @Input() measurementTypeId: string;
  @Input() customId: string;
  @Input() savedMeasurementId: string;

  measurementType: MeasurementType;
  measurementTypeTitle: '';
  measurementTypeTitleLowerCase = '';
  savedMeasurement: SavedMeasurement;

  readonly BUTTON_STYLES = BUTTON_STYLES;
  public addDataForm: UntypedFormGroup;

  isPremium = false;
  modalId = 'addDataModal';

  formError = '';
  isFormValid = false;
  resetValue = 0;
  initialFormData: {[formControlName: string]: any};

  isEdit = false;

  now = new Date();

  @Output() measurementSaved = new EventEmitter();

  constructor(
    public modal: NgxSmartModalService,
    public measurementService: MeasurementService,
    private premiumService: PremiumService,
    private apiService: ApiService,
    private formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private numberService: NumberService,
  ) {}

  ngOnInit(): void {
    this.isPremium = this.premiumService.userHasPremiumAccess(false);

    // console.log('ngoninit measurement', this.measurement);
    this.addDataForm = this.formBuilder.group({
      id: [null],
      date: [new Date(), Validators.required],
      value: ['', Validators.required], // the actual measurement value
      mood: [null],
      note: ['', {disabled: !this.isPremium}],
      image: [null],
      originalImage: [null],
    });

    this.addDataForm.valueChanges.subscribe(() => {
      this.isFormValid = this.addDataForm.valid;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('changes add-measurement-data-popup', changes);

    if (changes.measurementTypeId && changes.measurementTypeId.currentValue) {
      this.measurementTypeTitle = this.translate.instant(`MEASUREMENT_DETAILS.MEASUREMENTS.${changes.measurementTypeId.currentValue}.TITLE`);
      // change the casing of the measurement type title as it is 'in sentence'
      this.measurementTypeTitleLowerCase = this.measurementTypeTitle[1] === this.measurementTypeTitle[1].toUpperCase() ?
        this.measurementTypeTitle :
        this.measurementTypeTitle[0].toLocaleLowerCase() + this.measurementTypeTitle.slice(1)
      ;
    }

    if (changes.savedMeasurementId?.currentValue && this.modal.get(this.modalId).isVisible()) {
      this.isEdit = true;
      this.measurementType = this.measurementService.getMeasurementTypeById(this.measurementTypeId.startsWith(MeasurementIdEnum.CUSTOM) ? MeasurementIdEnum.CUSTOM : this.measurementTypeId);
      this.loadMeasurement().then();
    }

    if (changes.savedMeasurementId && !changes.savedMeasurementId.currentValue) {
      if (this.addDataForm) this.resetForm();
    }
    // if (changes.measurement && !changes.measurement.firstChange && changes.measurement.currentValue) {
    //   this.isEdit = true;
    //
    //   // console.log('measurement changed in add data popup', changes.measurement);
    //   const measurement: SavedMeasurement = changes.measurement.currentValue;
    //   this.addDataForm.get('id').setValue(measurement.id);
    //   this.addDataForm.get('date').setValue(new Date(measurement.dateTime));
    //   this.addDataForm.get('value').setValue(measurement.value);
    //   if (measurement.note) {
    //     if (measurement.note.content) {
    //       this.addDataForm.get('note').setValue(measurement.note.content);
    //     }
    //     if (measurement.note.mood) {
    //       this.addDataForm.get('mood').setValue(measurement.note.mood);
    //     }
    //     if (measurement.note.attachments && measurement.note.attachments.length) {
    //       this.addDataForm.get('originalImage').setValue(measurement.note.attachments[0].url);
    //     }
    //   }
    // }

    // if (changes.measurementTypeId?.currentValue) {
    //   this.measurementType = this.measurementService.getMeasurementTypeById(this.measurementTypeId);
    // }
    //
    // if (changes.savedMeasurementId?.currentValue) {
    //   this.loadMeasurement();
    // }

    // todo implement latest changes API!
    // this.addDataForm.get('mood').setValue(measurement.mood);
    // if (measurement.note) this.addDataForm.get('note').setValue(measurement.note);
    // if (measurement.image) this.addDataForm.get('image').setValue(measurement.note);
  }

  async loadMeasurement(): Promise<void> {
    this.savedMeasurement = await this.apiService.getMeasurementById(this.savedMeasurementId);
    this.setFormData();
    // make sure that if a mood is selected, that its in the viewport
    // setTimeout(() => {
    //   this.scrollSelectedEmojiIntoView();
    // });
  }

  setFormData(): void {
    this.addDataForm.get('id').setValue(this.savedMeasurement.id, {emitEvent: false});
    this.addDataForm.get('date').setValue(new Date(this.savedMeasurement.dateTime), {emitEvent: false});
    this.addDataForm.get('value').setValue(this.savedMeasurement.value.toString(), {emitEvent: false});
    if (this.savedMeasurement.note) {
      this.addDataForm.get('mood').setValue(this.savedMeasurement.note.mood);
      this.addDataForm.get('note').setValue(this.savedMeasurement.note.content);
      if(this.savedMeasurement.note.attachments && this.savedMeasurement.note.attachments.length) {
        this.addDataForm.get('originalImage').setValue(this.savedMeasurement.note.attachments[0]?.url);
        this.addDataForm.get('image').setValue(this.savedMeasurement.note.attachments[0]?.url);
      }
    }

    this.initialFormData = this.addDataForm.value;
  }

  public setUploadedImage(file: File): void {
    this.addDataForm.get('image').setValue(file);
  }

  public async saveMeasurement(): Promise<void> {
    // this.formData.id = this.addDataForm.get('id').value;
    // this.formData.date = this.addDataForm.get('date').value;
    // this.formData.value = this.addDataForm.get('value').value;
    // this.formData.mood = this.addDataForm.get('mood').value;
    // this.formData.note = this.addDataForm.get('note').value;
    // this.formData.image = this.addDataForm.get('image').value;
    let measurementValue = parseFloat(this.addDataForm.get('value').value.replace(',', '.'));
    if (!this.validateMeasurementNumber(measurementValue)) return;

    const measurementType = this.measurementTypeId.startsWith(MeasurementIdEnum.CUSTOM) ? MEASUREMENT_TYPES[MeasurementIdEnum.CUSTOM] : MEASUREMENT_TYPES[this.measurementTypeId];
    if (measurementType.roundValueToDecimals > 0) {
      measurementValue = this.numberService.roundNumberToDecimals(measurementValue, measurementType.roundValueToDecimals);
    }

    this.isEdit ?
      await this.apiService.editManualMeasurement(
        this.addDataForm.get('id').value,
        this.addDataForm.get('date').value,
        measurementValue,
        this.measurementTypeId,
        this.addDataForm.get('mood').value,
        this.addDataForm.get('note').value,
        this.addDataForm.get('image').value,
        this.customId
      ) :
      await this.apiService.createManualMeasurement(
        this.addDataForm.get('date').value,
        measurementValue,
        this.measurementTypeId,
        this.addDataForm.get('mood').value,
        this.addDataForm.get('note').value,
        this.addDataForm.get('image').value,
        this.customId
      );

    this.measurementSaved.emit(this.addDataForm.value);
    this.modal.getModal(this.modalId).close();
  }

  onMeasurementValueInput($event: any): void {
    const measurementValue = $event.target.value.replace(',','.');
    this.validateMeasurementNumber(measurementValue);
  }

  /**
   * Validates the measurement type settings numbers
   * Uses the measurement type to determine whether there are rights
   * @returns {boolean}
   */
  validateMeasurementNumber(measurementValue: any): boolean {
    // console.log('validateMeasurementNumber measurementValue', measurementValue);

    const measurementType = this.measurementTypeId.startsWith(MeasurementIdEnum.CUSTOM) ? MEASUREMENT_TYPES[MeasurementIdEnum.CUSTOM] : MEASUREMENT_TYPES[this.measurementTypeId];

    if (measurementValue === '' || isNaN(measurementValue)) {
      // console.log('measurement value isNan');
      this.formError = this.translate.instant('MEASUREMENT_DETAILS.NO_MEASUREMENT_VALUE_ERROR');
      this.isFormValid = false;
      this.addDataForm.controls['value'].setErrors({'incorrect': true});
      return false;
    }

    if (!measurementType.allowNegativeValues && measurementValue < 0) {
      this.formError = this.translate.instant('MEASUREMENT_SETTINGS.ERRORS.NEGATIVE_VALUE', {measurementType: this.measurementTypeTitleLowerCase});
      this.isFormValid = false;
      this.addDataForm.controls['value'].setErrors({'incorrect': true});
      return false;
    }
    // dont allow numbers with decimal values for measurement types that do not allow it
    if (measurementType.roundValueToDecimals === 0 && measurementValue % 1 !== 0) {
      this.formError = this.translate.instant('MEASUREMENT_SETTINGS.ERRORS.DECIMAL_VALUE', {measurementType: this.measurementTypeTitleLowerCase});
      this.isFormValid = false;
      this.addDataForm.controls['value'].setErrors({'incorrect': true});
      return false;
    }

    // todo: merge
    this.formError = '';
    this.isFormValid = true;
    this.addDataForm.controls['value'].setErrors(null);
    return true;
  }

  resetForm(): void {
    if (this.savedMeasurementId) {
      this.addDataForm.setValue(this.initialFormData);
    } else {
      this.formError = '';
      this.savedMeasurement = null;
      this.savedMeasurementId = null;

      this.addDataForm.reset({
        id: null,
        date: new Date(),
        value: '', // the actual measurement value
        mood: null,
        note: '',
        image: null,
        originalImage: null,
      });

      this.resetValue++;

      this.isEdit = false;
    }
    this.isFormValid = false;
  }

  onModalOpenFinished(): void {
    // auto focus the measurement input
    const measurementValueInput: any = document.querySelector('#measurement-value-input input');
    if (measurementValueInput) measurementValueInput.focus();
  }

  scrollSelectedEmojiIntoView(): void {
    const selectedMoodHtmlEl = this.emojiListComponent.emojiListRef.nativeElement.querySelector('.tile-wrapper.selected');
    if (selectedMoodHtmlEl) selectedMoodHtmlEl.scrollIntoView({ inline: 'center' });
  }

  setDurationValue(duration: string) {
    this.addDataForm.controls['value'].setValue(duration);
  }

  showDurationInput(measurementTypeId: string) {
    return MEASUREMENT_TYPES[measurementTypeId] && MEASUREMENT_TYPES[measurementTypeId].timeAsDuration;
  }
}
