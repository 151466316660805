import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'info-block-sm',
  templateUrl: './info-block-sm.component.html',
  styleUrls: ['./info-block-sm.component.scss']
})
export class InfoBlockSmComponent implements OnChanges {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() translateSubtitle: string;
  @Input() imgSrc: string;

  isSvg = false;

  constructor(
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.title?.currentValue) {
      const separatedString = this.imgSrc.split('.');
      this.isSvg = separatedString[separatedString.length - 1] === 'svg';
      // console.log(`IS SVG? ${this.isSvg}`);
    }
  }
}
