import { Routes } from '@angular/router';
import { NotFoundComponent } from '../modules/not-found/not-found.component';
import { SsoErrorComponent } from '../modules/sso-error/sso-error.component';
import {NoAccessComponent} from "../modules/no-access/no-access.component";
import { TwoFactorErrorComponent } from '../modules/twofactor-error/twofactor-error.component';

const errorRoutes: Routes = [
  {
    path: 'sso-error',
    component: SsoErrorComponent
  },
  {
    path: 'no-access',
    component: NoAccessComponent
  },
  {
    path: 'sso-2fa-error',
    component: TwoFactorErrorComponent
  }
];

export default errorRoutes;
