<ng-container
  *ngIf="activeRoomMessages && activeRoomMessages.length"
>
<!--  <div *ngIf="emojiReactionPickerOpened" #emojiReactionPickerRef class="emoji-picker">-->
<!--  <div-->
<!--    #emojiReactionPickerRef-->
<!--    [ngClass]="emojiReactionPickerOpened ? null : 'display-none'"-->
<!--    class="emoji-picker"-->
<!--  >-->
<!--&lt;!&ndash;    <div *ngFor="let emojiId of EMOJI_REACTIONS" class="emoji-pickable">&ndash;&gt;-->
<!--    <div *ngFor="let emojiId of EMOJI_REACTIONS" class="emoji-pickable" (click)="addOrRemoveReactionEmoji({emojiId: emojiId, message: emojiReactionPickerMessage})">-->
<!--      {{ emojiId }}-->
<!--    </div>-->
<!--  </div>-->

<!--  *ngFor="let messagePerDay of messagesPerDay, let i = index; trackBy: trackNgforBy"-->
  <div

    *ngFor="let messagePerDay of activeRoomMessages, let i = index"
    class="room-messages-container"
  >
    <div class="date-divider-wrapper">
      <div class="date-divider">
        {{ (activeRoomMessages && activeRoomMessages.length) ? messagePerDay.dateDividerString : '' }}
      </div>
    </div>
<!--    *ngFor="let message of messagePerDay.messages, let i = index; trackBy: trackNgforBy"-->
      <chat-message-bubble
        *ngFor="let message of messagePerDay.messages, let i = index;"
        [message]="message"
        [activeRoom]="activeRoom"
        [isGroupChat]="false"
      >
<!--        (uploadedFilePreviewLoaded)="scrollToBottom()"-->
<!--        (emojiReactionPickerToggled)="toggleReactionEmojiPicker($event)"-->
<!--        (emojiReactionAddedOrRemoved)="addOrRemoveReactionEmoji($event)"-->
<!--        [isGroupChat]="activeRoom.isGroupChat"-->
      </chat-message-bubble>
  </div>
</ng-container>
