<ngx-smart-modal class="redeem-popup-wrapper small-modal" [identifier]="identifier" [closable]="false" [dismissable]="false" [escapable]="false">
  <div class="popup-title-container">
    <h2 class="redeem-popup-title">
      <span *ngIf="type === ConsentTypes.TermsAndConditions">{{ 'CONSENT_MODAL.TERMS_AND_CONDITIONS.TITLE' | translate }}</span>
      <span *ngIf="type === ConsentTypes.RewardsConditions">{{ 'CONSENT_MODAL.REWARDS_CONDITIONS.TITLE' | translate}}</span>
    </h2>
  </div>
  <p class="source-explanation" *ngIf="type === ConsentTypes.TermsAndConditions" [innerHTML]="'CONSENT_MODAL.TERMS_AND_CONDITIONS.REVIEW_CHANGES' | translate">
  </p>
  <p class="source-explanation" *ngIf="type === ConsentTypes.RewardsConditions" [innerHTML]="'CONSENT_MODAL.REWARDS_CONDITIONS.REVIEW_CHANGES' | translate">
  </p>
  <div class="buttons-wrapper">
    <app-button class="redeem-button"
                (click)="agreeConsent()"
                [buttonText]="'SHARED.READ_AND_APPROVED' | translate"></app-button>
    <app-button class="redeem-button"
                (click)="disagreeConsent()"
                [styles]="BUTTON_STYLES.secondary"
                [buttonText]="'SHARED.CANCEL' | translate"></app-button>
  </div>
</ngx-smart-modal>
