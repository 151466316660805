import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

/** the final selfcare config, once everything works */
export const authConfig: AuthConfig = {
  requireHttps: environment.ssoRequiresHttps,
  // issuer: 'https://uat-sso.selfcare4me.com',
  issuer: environment.ssoIssuer,
  // clientId: 'selfcareweb',
  clientId: 'selfcare-2.0-web',
  // clientId: 'selfcare-2.0-swagger',
  // dummyClientSecret: 'secret',
  // scope: 'openid profile',
  scope: 'offline_access openid profile web.selfcare.httpaggregator challenge.personal.api notification.api medmij.api payment.api reward.api measurement.api identity.api health.api research.api profile.api accounting.api',
  // scope: 'openid profile email offline_access api',
  responseType: 'code',
  redirectUri: window.location.origin + '/login',
  // logoutUrl: window.location.origin,
  postLogoutRedirectUri: window.location.origin,
  // redirectUriAsPostLogoutRedirectUriFallback: false,

  // showDebugInformation: true, // Also requires enabling "Verbose" level in devtools
  // useSilentRefresh: true, // Needed for Code Flow to suggest using iframe-based refreshes
  // silentRefreshShowIFrame: true,
  // silentRefreshRedirectUri: '',
  // silentRefreshTimeout: 1, // For faster testing
  // timeoutFactor: 0.25, // For faster testing
  // sessionChecksEnabled: true,
  // showDebugInformation: true, // Also requires enabling "Verbose" level in devtools
  // disablePKCE: false,
};

/** https://idsvr4.azurewebsites.net: A PUBLIC IdentityServer backend config mentioned in the Angular oidc plugin,  https://github.com/manfredsteyer/angular-oauth2-oidc */
// export const authConfig: AuthConfig = {
//   // Url of the Identity Provider
//   issuer: 'https://idsvr4.azurewebsites.net',
//
//   // URL of the SPA to redirect the user to after login
//   // redirectUri: window.location.origin + '/index.html',
//   redirectUri: window.location.origin + '/login',
//
//   // The SPA's id. The SPA is registerd with this id at the auth-server
//   // clientId: 'server.code',
//   clientId: 'spa',
//
//   // Just needed if your auth server demands a secret. In general, this
//   // is a sign that the auth server is not configured with SPAs in mind
//   // and it might not enforce further best practices vital for security
//   // such applications.
//   // dummyClientSecret: 'secret',
//
//   responseType: 'code',
//
//   // clearHashAfterLogin: false,
//
//   // set the scope for the permissions the client should request
//   // The first four are defined by OIDC.
//   // Important: Request offline_access to get a refresh token
//   // The api scope is a usecase specific one
//   scope: 'openid profile email offline_access api',
//
//   showDebugInformation: true,
// };

/** https://demo.identityserver.io: Another PUBLIC IdentityServer 4 backend config, hosted by IdentityServer itself. This one is more future-rich then the above */
// export const authConfig: AuthConfig = {
//   issuer: 'https://demo.identityserver.io',
//   // clientId: 'interactive.public', // The "Auth Code + PKCE" client
//   clientId: 'interactive.confidential', // note this requires the 'dummyClientSecret' -> 'secret'
//   dummyClientSecret: 'secret',
//   responseType: 'code',
//   redirectUri: window.location.origin + '/login',
//   postLogoutRedirectUri: window.location.origin,
//   // silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
//   scope: 'openid profile email offline_access api',
//   showDebugInformation: true,
//   // sessionChecksEnabled: true,
//   // useSilentRefresh: false, // Needed for Code Flow to suggest using iframe-based refreshes
//   // silentRefreshTimeout: 5000, // For faster testing
//   // timeoutFactor: 0.25, // For faster testing
//   // sessionChecksEnabled: true,
//   // showDebugInformation: true, // Also requires enabling "Verbose" level in devtools
//   // clearHashAfterLogin: false, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
//   // nonceStateSeparator : 'semicolon' // Real semicolon gets mangled by IdentityServer's URI encoding
// };
