  <div class="empty-state-container">
  <h3 class="empty-state-title" [innerHTML]="titleKey | translate"></h3>
  <div class="empty-state-icon" [inlineSVG]="icon"></div>
  <p *ngIf="description" class="empty-state-description" [innerHTML]="description | translate"></p>
  <p *ngIf="descriptionParts" class="empty-state-description">
    <span [innerHTML]="(descriptionParts[0] | translate) + ' '"></span>
    <span class="add-button-wrapper">
      <span class="add-button">
        <span class="add-icon" [inlineSVG]="descriptionIcon"></span>
      </span>
    </span>
    <span [innerHTML]="' ' + (descriptionParts[1] | translate)"></span>
  </p>
</div>
