import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

export interface ItemToSearch {
  id: string;
  query: string;
}

@Component({
  selector: 'input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.scss']
})
export class InputSearchComponent implements OnInit {
  @Input() placeholder: string;
  @Input() itemsToSearch: ItemToSearch[];
  @Input() highlightedQueryStrings = false;

  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;

  @Output() onSearchInputChange = new EventEmitter();

  ngOnInit() {
  }

  onSearchChangeEvent() {
    if (this.itemsToSearch) {
      this.onSearchInputChange.emit(this.getResults());
    } else {
      this.onSearchInputChange.emit(this.searchInput.nativeElement.value);
    }
  }

  getResults() {
    const inputValue = this.searchInput.nativeElement.value;
    if (!inputValue) {
      return [];
    }
    const regex = `(?=.*${inputValue.replace(/\s/g, ')(?=.*')}).*`;
    const filteredItems = this.itemsToSearch.filter(item => item.query.match(new RegExp(regex, 'gi')));
    return this.highlightedQueryStrings ? this.getColorQueryStrings(filteredItems) : filteredItems;
  }

  getColorQueryStrings(query: {id: string; query: string}[]) {
    const coloredQueryStrings: {id: string; query: string}[] = [];
    query.forEach(item => {
      const specificRegex = this.searchInput.nativeElement.value.replace(/\s/g, '|');
      coloredQueryStrings.push({
        id: item.id,
        query: item.query.replace(new RegExp(specificRegex, 'gi'), match => `<span class="highlighted">${match}</span>`)
      });
    });
    return coloredQueryStrings;
  }
}
