import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { MeasurementService } from '../../../measurements/measurement.service';
import { PremiumService } from '../../../utils/premiumService';
import { MeasurementCategory, MeasurementIdEnum } from 'src/app/shared/models/enums/MeasurementEnums';

export interface FloatingMenuItem {
  id: string;
  routerLink?: string;
  modalId?: string;
  icon: string;
  hasBackground: boolean;
  positionClass: string;
  isPremium?: boolean;
}

@Component({
  selector: 'floating-menu',
  templateUrl: './floating-menu.component.html',
  styleUrls: ['./floating-menu.component.scss'],
  animations: [
    trigger('toggleAnimation', [
        transition(':enter',
          [
            style({ opacity: 0 }),
            animate('0.25s', style({ opacity: 0.6 }))
          ]
        ),
        transition(':leave',
          [
            animate('0.25s', style({ opacity: 0 }))
          ]
        )
      ]
    )
  ]
})

export class FloatingMenuComponent implements OnInit, OnChanges {
  @Input() floatingMenuItems: FloatingMenuItem[] = [];
  @Input() floatingMenuIcon: string;
  @Input() menuOpened: boolean;
  @Input() baseTranslationId: string;
  @Input() measurementTypeId: MeasurementIdEnum;
  @Input() customId: string | null;
  @Input() isRelativeToWidescreen = true;

  measurementTypeString = '';
  floatingMenuItemIcons: {[index: string]: boolean} = null;
  isPremium = false;

  @Output() onFloatingMenuToggle = new EventEmitter();
  @Output() onMenuItemSelected = new EventEmitter<string>();

  constructor(
    public modalService: NgxSmartModalService,
    public measurementService: MeasurementService,
    private premiumService: PremiumService
  ) { }

  ngOnInit(): void {
    this.setIsSvg();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.measurementTypeId && changes.measurementTypeId.currentValue) {
      this.measurementTypeString = this.measurementService.getMeasurementTitleString(this.measurementTypeId);
      this.setIsSvg();
    }
  }

  handleClickFloatingMenuItem(modalId: string | null, checkForPremium = false, floatingMenuItemId: string): void {
    if (checkForPremium) {
      this.premiumService.userHasPremiumAccess(true);
    }

    this.onMenuItemSelected.emit(floatingMenuItemId);

    if (modalId) {
      const modalRef = this.modalService.get(modalId).open();

      if (modalId == 'setMeasurementsModal') {
        modalRef.setData({ measurementCategory: MeasurementCategory.NONE });
      }

      modalRef.onAnyCloseEventFinished.subscribe(() => modalRef.removeData());
    }

    this.toggleFloatingMenu();
  }

  toggleFloatingMenu(): void {
    this.onFloatingMenuToggle.emit(true);
  }

  setIsSvg(): void {
    const floatingMenuItemIcons: {[index: string]: boolean} = {};
    this.floatingMenuItems.forEach(item => {
      floatingMenuItemIcons[item.id] = this.isSvg(item.icon);
    });
    this.floatingMenuItemIcons = floatingMenuItemIcons;
  }

  isSvg(src: string): boolean {
    return src?.includes('svg');
  }
}
