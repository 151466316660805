<ngx-smart-modal identifier="tokenExpiredModal" customClass="include-padding small-modal" [closable]="false" [dismissable]="false" [escapable]="false">
  <div class="session-expired-popup-wrapper">
    <div (click)="modal.close('tokenExpiredModal')" class="close-button" inlineSVG="icn_close.svg"></div>
    <h3 class="session-expired-popup-title">{{ 'TOKEN_EXPIRED_MODAL.TITLE' | translate }}</h3>
    <p class="session-expired-instructions">{{ 'TOKEN_EXPIRED_MODAL.DESCRIPTION' | translate }}</p>
    <div class="buttons-wrapper session-expired-button">
      <app-button class="reauthenticate-button" (click)="closeExpireModal()" [buttonText]="'TOKEN_EXPIRED_MODAL.REAUTHENTICATE' | translate"></app-button>
    </div>
  </div>
</ngx-smart-modal>
