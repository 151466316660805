import { Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'yes-no-checkbox',
  templateUrl: './yes-no-checkbox.component.html',
  styleUrls: ['./yes-no-checkbox.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => YesNoCheckboxComponent),
    multi: true
  }]
})
export class YesNoCheckboxComponent implements ControlValueAccessor {
  enabled = false;

  constructor() { }

  onChange = ((_: any) => {});

  writeValue(obj: any) {
    this.enabled = obj;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
  }

  onChanged() {
    this.enabled = !this.enabled;
    this.onChange(this.enabled);
  }
}
