import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input, OnChanges, OnInit,
  Output,
  QueryList, SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';


export interface SelectableOption {
  id: string;
  label: string;
}

@Component({
  selector: 'select-dropdown',
  templateUrl: './select-dropdown.component.html',
  styleUrls: ['./select-dropdown.component.scss'],
})
export class SelectDropdownComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() dropdownTitle: string;
  @Input() description: string;
  @Input() selectableOptions: SelectableOption[] = [];
  @Input() buttonText: string;
  @Input() selectedOption: SelectableOption;
  @Input() identifier: string;
  @Input() orangeArrowBoxIncluded = true;
  @Input() checkmarkIncluded = false;
  @Input() isSmallModal = true;
  @Input() placeholder: string;
  @Input() greyBackground = false;
  @Input() flagCountryCode: string;

  @Output() onOptionSelected = new EventEmitter();

  @ViewChild('selectionField') selectionField: ElementRef<HTMLElement>;
  @ViewChildren('selectionOptions') selectionOptions: QueryList<ElementRef<HTMLElement>>;
  dirty = false;
  focussedOption: SelectableOption;
  private initialized = false;
  countryCode: string;

  constructor(
    readonly modal: NgxSmartModalService,
  ) { }


  // todo question: why??
  // ngOnChanges(): void {
  //   if (this.initialized) {
  //     this.dirty = true;
  //   }
  // }

  ngOnInit(): void {
    if (!this.selectedOption && !this.placeholder && this.selectableOptions && this.selectableOptions.length) {
      this.selectedOption = this.selectableOptions[0];
    }
    this.setCountryCode();
  }

  ngAfterViewInit(): void {
    this.initialized = true;
    this.modal.getModal(this.identifier).onOpenFinished.subscribe(() => {
      this.focusOnInitialItem();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.flagCountryCode) {
      this.setCountryCode();
    }
  }

  get selectedItemIndex(): number {
    const selectionOptionHeight = this.selectionOptions.first.nativeElement.clientHeight;
    const scrollTopSelectionField = this.selectionField.nativeElement.scrollTop;
    return scrollTopSelectionField === 0 ? 0 : Math.round(scrollTopSelectionField / selectionOptionHeight);
  }

  selectItem(): void {
    this.onOptionSelected.emit(this.selectableOptions[this.selectedItemIndex]);
    this.focussedOption = this.selectableOptions[this.selectedItemIndex];
    this.dirty = true;
    this.modal.getModal(this.identifier).close();
  }

  focusOnItem(itemIndex = this.selectedItemIndex): void {
    this.focussedOption = this.selectableOptions[itemIndex];
    const selectionField = this.selectionField.nativeElement;
    const selectionOptionHeight = this.selectionOptions.first.nativeElement.clientHeight;
    const scrollDistance = selectionOptionHeight * itemIndex;
    selectionField.scrollTo({
      top: scrollDistance,
      behavior: 'smooth',
    });
  }

  focusOnInitialItem(): void {
    if (!this.selectedOption) return;
    // this.focussedOption = this.selectedOption;
    const selectionOptionHeight = this.selectionOptions.first.nativeElement.clientHeight;
    const selectionField = this.selectionField.nativeElement;
    const indexSelectedItem = this.selectableOptions.indexOf(this.selectableOptions.find(option => option.id === this.selectedOption.id));

    selectionField.scrollTo(0, selectionOptionHeight * indexSelectedItem);
  }

  onWheelScroll(): void {
    setTimeout(() => this.focusOnItem(), 200);
  }

  setCountryCode() {
    if (!this.flagCountryCode) return;
    // todo: Find a better way of determing the countryflag based on the lang-country code
    this.countryCode = this.flagCountryCode.split('-')[1];
  }
}
