<h2 class="patient-portal-search-title">{{ 'CONNECT_HEALTHCARE.SEARCH_HEALTHCARE_TITLE' | translate }}</h2>
<input-search
  class="healthcare-input-search"
  [itemsToSearch]="itemsToSearch"
  [placeholder]="'SHARED.SEARCH' | translate"
  (onSearchInputChange)="onSearchInputChanged($event)"></input-search>
<div class="search-result-holder">

    <div *ngIf="searchResultsToShow.length > 0 else noResults">
      <accordion class="patient-portal-search-result" *ngFor="let searchResult of searchResultsToShow; let i = index" [openByDefault]="true">
        <div header class="search-result-header">
          <!--     Todo: softcode number of patients when api is fixed     -->
          <p class="search-result-city">{{ searchResult.city + '(1)' }}</p>
        </div>
        <div class="search-result-content-wrapper">
          <img src="{{searchResult.imgPath}}" class="search-result-item-image">
          <div class="search-result-text">
            <p class="search-result-item-title">{{searchResult.title}}</p>
            <div class="search-result-item-visit-page-wrapper">
              <p class="search-result-item-visit-text">VISIT PAGE</p>
              <div class="search-result-item-visit-title-arrow"></div>
            </div>
          </div>
        </div>
      </accordion>
    </div>
</div>

<ng-template #noResults>
  <p class="search-no-result-title">No results</p>
</ng-template>

