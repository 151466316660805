import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {
  AddContactGetBody,
  GetContactPermissionsResponse,
  GetContactsResponse,
  UpdateContactPermissionsPutBody
} from '../models/connections/contact';
import { ApiService } from './api.service';
import { CommunityMember } from '../models/connections/communityMembersResponse';

@Injectable({
  providedIn: 'root'
})
export class ApiCommunityService {
  private readonly baseUrl: string;
  private _selectedContactName: string = null;
  private _selectedChatKey: string = null;
  private _addConnectionByMail: string = null;

  constructor(
    private apiService: ApiService,
    private http: HttpClient,
  ) {
    this.baseUrl = environment.apiChallengesBaseUrl;
  }

  /** CONTACT **/
  addContact(body: AddContactGetBody): Promise<any> {
    // this should send an 'invitation' email
    return this.http.post<string>(`${this.baseUrl}Contact/${this.apiService.getUserId()}`, body).toPromise();
  }

  getContact(contactUserId: string): Promise<any> {
    return this.http.get<CommunityMember>(`${this.baseUrl}Contact/${this.apiService.getUserId()}/${contactUserId}`).toPromise();
  }

  getContacts(): Promise<any> {
    return this.http.get<GetContactsResponse>(`${this.baseUrl}Contact/${this.apiService.getUserId()}`).toPromise();
  }

  // todo awaiting API to add accept contact invitation
  // acceptContactInvitation(contactId: string): Promise<any> {
    // return this.http.post<string>(`${this.baseUrl}Contact/${this.apiService.getUserId()}/declineInvitation/${contactId}`, null).toPromise();
  // }

  declineContactInvitation(contactId: string): Promise<any> {
    return this.http.post<string>(`${this.baseUrl}Contact/${this.apiService.getUserId()}/declineInvitation/${contactId}`, null).toPromise();
  }
  acceptCompetitionInvitation(contactId: string): Promise<void> {
    return this.http.put<void>(`${this.baseUrl}Contact/acceptCompetition/${contactId}`, null).toPromise();
  }

  acceptConnectionInvitation(contactId: string): Promise<void> {
    return this.http.put<void>(`${this.baseUrl}Contact/${this.apiService.getUserId()}/acceptInvitation/${contactId}`, null).toPromise();
  }

  declineCompetitionInvitation(contactId: string): Promise<any> {
    return this.http.put<string>(`${this.baseUrl}Contact/declineCompetition/${contactId}`, null).toPromise();
  }

  deleteContact(contactId: string): Promise<any> {
    return this.http.delete<string>(`${this.baseUrl}Contact/${this.apiService.getUserId()}/${contactId}`).toPromise();
  }

  getContactPermissions(contactId: string): Promise<any> {
    return this.http.get<GetContactPermissionsResponse>(`${this.baseUrl}Contact/${this.apiService.getUserId()}/permissions/${contactId}`).toPromise();
  }

  updateContactPermissions(contactId: string, postBody: UpdateContactPermissionsPutBody): Promise<void> {
    return this.http.put<void>(`${this.baseUrl}Contact/${this.apiService.getUserId()}/permissions/${contactId}`, postBody).toPromise();
  }

  revokeContactInvitation(contactId: string): Promise<any> {
    return this.http.post<string>(`${this.baseUrl}Contact/${this.apiService.getUserId()}/revokeInvitation/${contactId}`, null).toPromise();
  }

  //used on accepting invite e-mail without existing account
  confirmContact(contactId: string, hash: string): Promise<any> {
    return this.http.post<string>(`${this.baseUrl}Contact/${this.apiService.getUserId()}/confirm/${contactId}?hash=${hash}`, null).toPromise();
  }

  get selectedContactName() {
    return this._selectedContactName;
  }

  set selectedContactName(name: string) {
    this._selectedContactName = name;
  }

  get addConnectionByMail() {
    return this._addConnectionByMail;
  }

  set addConnectionByMail(name: string) {
    this._addConnectionByMail = name;
  }

  get selectedChatKey() {
    return this._selectedChatKey;
  }

  set selectedChatKey(key: string) {
    this._selectedChatKey = key;
  }
}
