<div>
  <canvas
    baseChart
    #targetAchievementChart
    type="bar"
    [datasets]="barChartData"
    [options]="chartOptions"
    [legend]="false"
    [plugins]="chartPlugins"
  >
  </canvas>
</div>
