export interface GlobalState {
  apiCallIsActive: boolean;
  isPremium: boolean;
  // windowDimensions: {width: number; height: number};
}

export const initialState: GlobalState = {
  apiCallIsActive: false,
  isPremium: false,
  // windowDimensions: {width: window.innerWidth, height: window.innerHeight}
};
