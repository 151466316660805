import { Injectable } from '@angular/core';
import {
  CareProviderService,
  DataServiceSelectionModel,
} from '../api/api-medmij-afsprakenstelsel.service';
import {BehaviorSubject} from "rxjs";
import SelectedCareprovider from "../models/selectedCareprovider";


@Injectable({
  providedIn: 'root'
})
export class MedmijAuthService {

  medMijDataServices: DataServiceSelectionModel[] = [];
  medMijDataServiceIdToConnect: string = null;
  medMijCareProviderNameToConnect: string = null;
  medMijTestUserSelected = false;

  selectedCareProvider$ = new BehaviorSubject<SelectedCareprovider>(null);
  tokenExpired$ = new BehaviorSubject(false);

  //still used for Atalmedial
  private _medMijAccessToken: string = null;
  private _medMijAccessTokenExpiration: Date = null;

  constructor() { }

  public get medMijAccessToken(): string {
    if (this._medMijAccessToken) {
      if (!this._medMijAccessTokenExpiration) {
        this._medMijAccessToken = null;
      } else if (this._medMijAccessTokenExpiration.getTime() <= new Date().getTime()) {
        this._medMijAccessToken = null;
        this._medMijAccessTokenExpiration = null;
      }
    }

    return this._medMijAccessToken;
  }

  public set medMijAccessToken(token: string) {
    this._medMijAccessToken = token;
    this._medMijAccessTokenExpiration = new Date(new Date().getTime() + 15 * 60000);
  }
}
