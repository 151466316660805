<div class="shared-contact-data-wrapper">
  <section class="top-row-section">
    <header class="header-top-row">
      <div class="back-button-wrapper">
        <back-button (click)="resetContactIdData()" [styles]="'back-button-grey-2'"></back-button>
      </div>
      <div class="shared-contact-data-top-icons">
        <div class="icon-button-base">
          <div class="chat-icon" inlineSVG="icn_chat.svg" (click)="goToConversation()"></div>
        </div>
      </div>
      <h2 class="title-shared-contact-data">
        <div class="profile-pic-wrapper">
          <img class="member-profile-pic" src="{{sharedContactData?.profileIcon}}" alt="profile-icon"/>
        </div>
        <div class="name-wrapper">
          {{ 'SHARED_CONTACT.DASHBOARD_OF' | translate }} {{ sharedContactData?.fullName }}
        </div>
      </h2>
    </header>
  </section>


  <tabbed-content>
    <tab [name]="'SHARED_CONTACT.MEASUREMENTS' | translate">
      <section class="dynamic-section">
        <div class="loading-data" *ngIf="!isDataLoaded">
          {{'SHARED_CONTACT.LOADING_MEASUREMENTS_OF' | translate: {fullName: sharedContactData?.fullName} }}
        </div>
        <ng-container *ngIf="isDataLoaded">
          <empty-state
            *ngIf="!dashboardSections.length"
            titleKey="SHARED_CONTACT.EMPTY_DASHBOARD_TITLE"
            [description]="'SHARED_CONTACT.EMPTY_DASHBOARD_DESCRIPTION' | translate: {fullName: sharedContactData?.fullName}"
          ></empty-state>

          <div *ngFor="let dashboardSection of dashboardSections; let i = index" class="dashboard-element-row">
            <div class="dashboard-element-title">
              <h3>{{ 'DASHBOARD.ELEMENTS.' + dashboardSection.key | translate }}</h3>
            </div>
            <!-- Measurement rows / dashboard sections -->
            <!-- Measurement rows / dashboard sections -->
            <!-- Measurement rows / dashboard sections -->
            <div class="dashboard-tile-row-container">
              <div *ngFor="let measurementTile of dashboardSection.tiles; let j = index" class="dashboard-tile-container"
                   [attr.data-order]="measurementTile.order">
                <measurement-block
                  [measurementTileModel]="measurementTile"
                  [applyBoxShadow]="j === 0"
                  (click)="setSelectedMeasurementType(measurementTile)"
                >
                </measurement-block>
              </div>
            </div>
          </div>
        </ng-container>
      </section>
    </tab>
  </tabbed-content>

</div>

<measurement-details
  [measurementTypeId]="selectedMeasurementTypeId"
  (modalClosed)="selectedMeasurementTypeId = null"
  [supplier]="selectedMeasurementSupplierName"
  [forContactId]="sharedContactData.contactId"
  [forUserId]="sharedContactData.userId"
  [includeFloatingMenu]="false"
  [customId]="customId"
>
</measurement-details>
