import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sso-error',
  template: `<error-component [description]="'ERROR.SSO' | translate"></error-component>`,
})
export class SsoErrorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
