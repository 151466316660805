// import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Component, Input } from '@angular/core';
import { MeasurementService } from '../../../measurements/measurement.service';

@Component({
  selector: 'chart-legend',
  templateUrl: './chart-legend.component.html',
  styleUrls: ['./chart-legend.component.scss']
})
// export class ChartLegendComponent implements OnInit, OnChanges {
export class ChartLegendComponent {

  @Input() legendData: { colorClass: string; label: string }[];

  constructor(
    public measurementService: MeasurementService,
  ) {}

  // ngOnChanges(changes: SimpleChanges): void {
  // }
  //
  // ngOnInit(): void {
  // }
}
