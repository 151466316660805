import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class EventQueueService {
    private eventBroker = new Subject<AppEvent<unknown>>();

    public on<T>(eventType: string): Observable<T> {
        return this
        .eventBroker
        .pipe(filter(event => event.type === eventType))
        .pipe(map(event => event.payload as T));
    }

    public dispatch<T>(eventType: string, payload: T): void {
        this.eventBroker.next({type: eventType, payload: payload});
    }
}

class AppEvent<T> {
    constructor(
        public type: string,
        public payload: T
    ) {}
}
