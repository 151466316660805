import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import PeriodId from '../models/periodId';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class DateService {
  timezoneOffsetInMinutes = new Date().getTimezoneOffset();

  constructor(
    public translate: TranslateService,
    public apiService: ApiService
  ) {
  }

  // from 31-12-2020 to 2020-12-31
  static europeanToIso8601(dateString: string, separator = '-') {
    return dateString.split(separator).reverse().join('-');
  }

  static getDivisionSign(dateString: string): string {
    return /\D/.test(dateString[2]) ? dateString[2] : /\D/.test(dateString[1]) ? dateString[1] : null;
  }

  /** Clones a Date object */
  cloneDate(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
  }

  deepCloneDate(date: Date): Date {
    return new Date(date.toISOString());
  }

  /**
   * Returns a localized formatted date string, with time optionally included
   * @param {Date} date
   * @param {boolean} includeTime
   * @param {string} timeSeparator If timeSeparator is null, the separator will be localized with ', at' ,i.e.:  '12-13-2000, at 2:22PM'
   * @param locale
   * @returns {string}
   */
  getLocalizedDateString(date: Date, includeTime = false, timeSeparator: string | null = null, locale = this.translate.getBrowserCultureLang()): string {
    date = new Date(date);
    if (includeTime) {
      timeSeparator = timeSeparator === null ? `, ${this.translate.instant('SHARED.AT')}` : ` ${timeSeparator}`;
      return `${date.toLocaleDateString(locale, { year: 'numeric', month: 'long', day: 'numeric' })}${timeSeparator} ${date.toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit' })}`;
    }
    return date.toLocaleDateString(locale, { year: 'numeric', month: 'long', day: 'numeric' });
  }

  /**
   * Returns whether the targetDate is between two dates (min and max)
   * @param {Date} targetDate
   * @param min
   * @param max
   * @returns {boolean}
   */
  dateIsInRange(targetDate: Date, min: Date, max: Date): boolean {
    const targetDateTime = targetDate.getTime();
    return (targetDateTime >= min.getTime()) && (targetDateTime <= max.getTime());
  }

  // note: ignores time.
  isDateXAfterDateY(d1: Date, d2: Date): boolean {
    const date1 = new Date(d1.getFullYear(), d1.getMonth(), d1.getDate());
    const date2 = new Date(d2.getFullYear(), d2.getMonth(), d2.getDate());
    return date1.getTime() > date2.getTime();
  }

  /** Calculates age based on birthDate */
  calculateAgeFromDate(birthDate: Date): number {
    if (!birthDate) {return 0;}
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  /** A friendly welcome string based on time of day: Good night, morning, afternoon, evening) */
  getTimeOfDayTranslationString(): string {
    const now = new Date();
    const hour = now.getHours();
    if (hour < 6) return 'NIGHT';
    else if (hour < 12) return 'MORNING';
    else if (hour < 18) return 'AFTERNOON';
    else return 'EVENING';
  }

  getWeekNumber(date: Date): { weekYear: number; weekNumber: number }  {
    // Copy date so don't modify original
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay()||7));
    // Get first day of year
    const yearStart = new Date(Date.UTC(date.getUTCFullYear(),0,1));
    // Calculate full weeks to nearest Thursday
    // @ts-ignore
    const weekNumber = Math.ceil(( ( (date - yearStart) / 86400000) + 1)/7);
    // Return array of year and week number
    return { weekYear: date.getUTCFullYear(), weekNumber };
  }

  getQuarter(date: Date): number {
    const month = date.getMonth();
    if (month < 3) return 1;
    if (month < 6) return 2;
    if (month < 9) return 3;
    return 4;
  }

  // returns the monday as Date in the week of the given date
  getMondayOfWeekOfDate(date: Date): Date {
    const tempDate = new Date(date);
    const day = tempDate.getDay();
    const diff = tempDate.getDate() - day + (day == 0 ? -6:1);
    return new Date(tempDate.setDate(diff));
  }

  // returns the sunday date in the week of the given date, the last second of the week
  getSundayOfWeekOfDate(date: Date): Date {
    const sunday = this.getMondayOfWeekOfDate(date);
    sunday.setDate(sunday.getDate() + 6);
    sunday.setHours(23);
    sunday.setMinutes(59);
    sunday.setSeconds(59);
    return sunday;
  }

  getFirstDayOfQuarter(date: Date): Date {
    let firstMonthOfQuarter = 0;
    const month = date.getMonth();
    if (month < 3) firstMonthOfQuarter = 0;
    else if (month < 6) firstMonthOfQuarter = 3;
    else if (month < 9) firstMonthOfQuarter = 6;
    else firstMonthOfQuarter = 9;
    return new Date(date.getFullYear(), firstMonthOfQuarter, 1, 0, 0, 0);
  }

  getLastDayOfQuarter(date: Date): Date {
    let lastMonthOfQuarter: number;
    const month = date.getMonth();
    if (month < 3) lastMonthOfQuarter = 2;
    else if (month < 6) lastMonthOfQuarter = 5;
    else if (month < 9) lastMonthOfQuarter = 8;
    else lastMonthOfQuarter = 11;
    const dateCopy = new Date(date.getFullYear(), lastMonthOfQuarter, 1, 0 ,0);
    const lastDayOfMonth = this.getTotalDaysInMonthByDate(dateCopy);
    return new Date(date.getFullYear(), lastMonthOfQuarter, lastDayOfMonth, 0, 0, 0);
  }

  getLastDayOfMonthAsDate(date: Date): Date {
    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    lastDayOfMonth.setHours(23);
    lastDayOfMonth.setMinutes(59);
    lastDayOfMonth.setSeconds(59);
    return lastDayOfMonth;
  }
  getTotalDaysInMonthByDate(date: Date): number {
    return this.getLastDayOfMonthAsDate(date).getDate();
  }

  // MON, TUE, WED
  getDayOfWeekString(date: Date, long = false, toUpperCase = true): string {
    let dayOfWeekString = date.toLocaleString(this.translate.getBrowserCultureLang(), {  weekday: long ? 'long': 'short' });
    if (toUpperCase) dayOfWeekString = dayOfWeekString.toUpperCase();
    return dayOfWeekString;
  }

  getYearAndMonthString(monthNumber: number, year: number): string {
    const date = new Date(year, monthNumber);
    return date.toLocaleDateString(this.translate.getBrowserCultureLang(), { year: 'numeric', month: 'long' });
  }

  // i.e. 11 September (no year)
  getLongMonthDateString(date: Date): string {
    const monthString = date.toLocaleString('locale', {month: 'long'});
    const day = date.getDate();
    return `${day} ${monthString}`;
  }

  // note: its zero indexed (0 is quarter 1, 3 is quarter 4)
  getPreviousQuarter(quarter: number): number {
    if (quarter === 0) return 3;
    return quarter - 1;
  }

  /**
   * Whether they the dates fall within the same day
   * @param {Date} date1
   * @param {Date} date2
   * @returns {boolean}
   */
  isSameDay(date1: Date, date2: Date): boolean {
    return date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate();
  }

  /**
   * Returns a new date with same year, month, day, but to first second of the day (midnight: 00:00:00)
   * @param {Date} d
   * @returns {Date}
   */
  setDateToBeginningOfDay(d: Date): Date {
    const dateCloned = this.cloneDate(d);
    dateCloned.setHours(0);
    dateCloned.setMinutes(0);
    dateCloned.setSeconds(0);
    return dateCloned;
  }

  /**
   * Returns a new date with same year, month, day, but to first second of the day (midnight: 00:00:00)
   * @param {Date} d
   * @returns {Date}
   */
  setDateToMiddleOfDay(d: Date): Date {
    const dateCloned = this.cloneDate(d);
    dateCloned.setHours(12);
    dateCloned.setMinutes(0);
    dateCloned.setSeconds(0);
    return dateCloned;
  }

  setDateToEndOfDay(d: Date): Date {
    d.setHours(23);
    d.setMinutes(59);
    d.setMinutes(59);
    return d;
  }

  getMidPointBetweenTwoDates(d1: Date, d2: Date): Date {
    const midPoint = new Date((d1.getTime() + d2.getTime()) / 2);
    return midPoint;
  }

  /**
   * If today is Sep 5th,
   * and date given is Sep 3th, it will return 0,
   * and date given is June 3th, it will return 3
   */
  getMonthsAgoDate(d1: Date): number {
    // console.log('d1', d1);
    const now = new Date();
    if (d1 > now) {
      console.error('cant call months ago function with a date in the future');
      return null;
    }

    const monthDiff = (d1: Date, d2: Date) => {
      let months;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth();
      months += d2.getMonth();
      return months <= 0 ? 0 : months;
    };

    return monthDiff(d1, now);
  }

  // 03:23
  getTimeString(d: Date): string {
    return d.toLocaleTimeString(this.translate.getBrowserCultureLang(),
      {
        hour12: false,
        hour: '2-digit',
        minute: '2-digit'
      });
  }

  /**
   * Gets the amount of days between two dates
   * todo: force the date to have hours, minutes, seconds set at 0?
   * @param {Date} startDate
   * @param {Date} endDate
   * @returns {number}
   */
  getDaysBetweenDates(startDate: Date, endDate: Date): number {
    const diffInMs = endDate.getTime() - startDate.getTime();
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
    return Math.round(diffInDays);
  }

  getMinimumAgeBirthdate(): Date {
    const today = new Date();
    const minimumAgeYear = today.getFullYear() - 16;
    today.setFullYear(minimumAgeYear);
    return today;
  }

  getStartDateByPeriod(periodId: PeriodId): string {
    const today = new Date();
    switch(periodId) {
      case PeriodId.ALL:
        return new Date(0).toISOString();
      case PeriodId.LAST_7_DAYS:
        return new Date(today.setDate(today.getDate() - 7)).toISOString();
      case PeriodId.LAST_6_MONTHS:
        return new Date(today.setMonth(today.getMonth() - 6)).toISOString();
      case PeriodId.LAST_12_MONTHS:
        return new Date(today.setMonth(today.getMonth() - 12)).toISOString();
    }
  }

  /**
   * Returns the browser timezone offset (compared to UTC) in minutes
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getTimezoneOffset
   * @returns {number}
   */
  getTimezoneOffsetInMinutes(): number {
    return this.timezoneOffsetInMinutes;
  }

  /**
   * Applies timezone offset to the given date.
   * I.e. 11 am in a timezone UTC/GMT +2 will become 9am.
   * Optionally clone date
   * @param {Date} date
   * @param {boolean} cloneDate
   * @returns {Date}
   */
  applyTimezoneOffset(date: Date, cloneDate = true): Date {
    // console.log('this.timezoneOffsetInMinutes', this.timezoneOffsetInMinutes);

    if (cloneDate) date = this.cloneDate(date);
    date.setMinutes(date.getMinutes() + this.timezoneOffsetInMinutes);
    return date;
  }

  /**
   * Calculates one month back relatively to today's date
   * @returns {Date}
   */
  getDateOneMonthEarlier() {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    return date;
  }

  /*
  * Return JAN, FEB, MAR...
   */
  getShortMonth(monthNumber: number, year: number): string {
    const date = new Date(year, monthNumber);
    return date.toLocaleString('default', {month: "short"}).replace('.', '');
  }
}
