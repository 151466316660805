import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

@Component({
  selector: 'input-block-selector',
  template: `
    <div class="block-selector-container">
      <ng-container *ngFor="let option of options; index as i; first as isFirst"  >
        <div
          class="block-selector"
          (click)="setSelectedOption(option)"
          [ngClass]="selectedOption !== null && option.id === selectedOption ? 'selected' : ''"
        >
          {{ option.label | translate }}
          <ng-component *ngIf="option.icon" class="block-selector-icon" [inlineSVG]="option.icon"></ng-component>
        </div>
      </ng-container>
    </div>
  `,
  styleUrls: ['./input-block-selector.component.scss']
})

/**
 * This component is a vertical list of 'clickable' blocks. In theory, it works similar to a select dropdown or a radio button.
 */
export class InputBlockSelectorComponent implements OnInit {
  @Input() options: ({ id: string; label: string; icon?: string })[];
  @Input() input: null | string;

  @Output() optionSelectedChanged = new EventEmitter();

  selectedOption: null | string;

  ngOnInit(): void {
    this.selectedOption = this.input;
  }


  setSelectedOption(option: { id: string; label: string; icon?: string }): void {
    this.selectedOption = option.id;
    this.optionSelectedChanged.emit(option.id);
  }

}
