<ngx-smart-modal class="confirm-modal-wrapper small-modal" [identifier]="identifier" [closable]="false" [dismissable]="false" [escapable]="false">
  <div class="confirm-modal-title-container">
    <h2 class="confirm-modal-title">
      {{ title | translate }}
    </h2>
  </div>
  <p class="text-paragraph" [innerHTML]="text | translate">
  </p>
  <div class="buttons-wrapper">
    <app-button class="confirm-modal-button"
                (click)="modal.close(identifier)"
                [buttonText]="'SHARED.OK' | translate"></app-button>
  </div>
</ngx-smart-modal>
