import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'table-component',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
  @Input() striped = true;
  @Input() id: string;

  activeSort$ = new BehaviorSubject<string>(null);

  tableState = {
    sort: '',
    sortDesc: false
  };

  constructor() { }

  ngOnInit(): void {
    if(this.id) {
      const tableState = localStorage.getItem('table_' + this.id);
      if (!tableState) {
        localStorage.setItem('table_' + this.id, JSON.stringify(this.tableState));
      } else {
        this.tableState = JSON.parse(tableState);
        this.restoreTableState();
      }
    }
  }

  sort(sortBy: string, sortDesc: boolean) {
    this.tableState.sort = sortBy;
    this.tableState.sortDesc = sortDesc;
    this.activeSort$.next(sortBy);
    this.updateTableState();
  }

  restoreTableState() {
    if(this.tableState.sort) {
      this.sort(this.tableState.sort, this.tableState.sortDesc);
    }
  }

  updateTableState() {
    localStorage.setItem('table_' + this.id, JSON.stringify(this.tableState));
  }


}
