import { Component, AfterViewInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'fullscreen-overlay',
  templateUrl: './fullscreen-overlay.component.html',
  styleUrls: ['./fullscreen-overlay.component.scss']
})

/**
 * A component meant as a 'full screen overlay' with orange app color background and white text.
 * Used for onboarding / tutorial type content.
 * Note: there should only be 1 of these elements in the DOM at the same time
 */
export class FullscreenOverlayComponent implements AfterViewInit, OnDestroy {
  includeBackButton = false;
  // goBackClicked = null;


  ngAfterViewInit(): void {
    document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
  }

  ngOnDestroy(): void {
    document.getElementsByTagName('body')[0].style.overflowY = '';
  }
}
