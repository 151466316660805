import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

/** This class contains globally reused features that do not necessarily fit in a more specific service. */
@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  constructor(

  ) { }

  isMobileUserAgent(): boolean {
    const userAgent = navigator.userAgent;
    // tablet
    return /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent) ||
    // phone
    (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(userAgent));
  }

  /** Cryptographically strong random string (uuid) */
  randomId(): string {
    return uuidv4();
  }
}
