import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MeasurementIdEnum } from '../../../models/enums/MeasurementEnums';
import { MeasurementService } from '../../../measurements/measurement.service';

@Component({
  selector: 'app-measurement-details-wide-screen',
  templateUrl: './measurement-details-wide-screen.component.html',
  styleUrls: ['./measurement-details-wide-screen.component.scss']
})
export class MeasurementDetailsWideScreenComponent implements OnInit {
  measurementId: MeasurementIdEnum;

  constructor(
    readonly activatedRoute: ActivatedRoute,
    readonly router: Router,
    readonly measurementService: MeasurementService,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      if (!this.measurementService.getMeasurementTypeById(params.measurementId)) this.router.navigate(['/dashboard/measurements/physical']);
      this.measurementId = params.measurementId;
    });
  }
}
