import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
// import * as fromChat from '../../../../store/chat/chat.selector';
// import ChatRoom, { MessagesPerDay } from '../../../models/chat/chatRoom';
// import { Store } from '@ngrx/store';
import { GlobalService } from '../../../utils/global.service';
import { ChatRoom, MessagesPerDay } from '../../../models/chat/chatRoom';
// import Message from '../../../models/chat/message';
// import { addMessage, toggleMessageEmojiReaction } from '../../../../store/chat/chat.actions';
// import { getLocaleFirstDayOfWeek } from '@angular/common';
// import AppState from "../../../../store";

@Component({
  selector: 'message-list',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.scss'],
  // animations: [
  //   trigger('fadeContent', [
  //     // transition(':leave', [
  //     //   animate('0.2s', style({opacity: 0}))
  //     // ]),
  //     transition(':enter', [
  //       style({opacity: 0}),
  //       animate('0.2s', style({opacity: 1}))
  //       // animate('0.2s 0.25s', style({opacity: 1}))
  //     ]),
  //   ])
  // ],
})
export class MessageListComponent implements OnInit {
// export class MessageListComponent implements OnInit, OnChanges {
  // @ViewChild('emojiReactionPickerRef') emojiReactionPickerRef: ElementRef;

  @Input() activeRoom: ChatRoom;
  @Input() activeRoomMessages: MessagesPerDay[] = [];

  // public activeRoom: ChatRoom;
  // public chatPartner: ChatUser = undefined;
  // public chatUsers: ChatUser[] = [];


  // public emojiReactionPickerOpened = false;
  // public emojiReactionPickerMessage: Message;

  // todo normalize state to prevent this
  public preventScroll = false;

  // public EMOJI_REACTIONS = ['😀', '😁', '😂', '🤣', '😃', '😄', '😅', '😆', '😉', '😊', '😋', '😎', '😍', '😘', '😗', '😙', '😚', '🙂', '🤗', '🤔', '😐', '😑', '😶', '🙄', '😏', '😣', '😥', '😮', '🤐', '😯', '😪', '😫', '😴', '😌', '🤓', '😛', '😜', '😝', '🤤', '😒', '😓', '😔', '😕', '🙃', '🤑', '😲', '☹', '🙁', '😖', '😞', '😟', '😤', '😢', '😭', '😦', '😧', '😨', '😩', '😬', '😰', '😱', '😳', '😵', '😡', '😠', '😇', '🤠', '🤡', '🤥', '😷', '🤒', '🤕', '🤢', '🤧', '😈', '👿', '👹', '👺', '💀', '☠', '👻', '👽', '👾', '🤖', '💩', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '😿', '😾', '🙈', '🙉', '🙊', '👦', '👦🏻', '👦🏼', '👦🏽', '👦🏾', '👦🏿', '👧', '👧🏻', '👧🏼', '👧🏽', '👧🏾', '👧🏿', '👨', '👨🏻', '👨🏼', '👨🏽', '👨🏾', '👨🏿', '👩', '👩🏻', '👩🏼', '👩🏽', '👩🏾'];

  constructor(
    // private store: Store<AppState>,
    private messageListRef: ElementRef,
    private globalService: GlobalService,
  ) {
  }

  ngOnInit(): void {
    // this.store.select(fromChat.getChatUsers)
    //   .subscribe((chatUsers: any) => this.setChatUser(chatUsers));
    // this.store.select(fromChat.getActiveRoom)
    //   .subscribe((room: any) => {
    //     // console.log('roomChanged?', room);
    //     this.setActiveRoom(room);
    //   });
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   console.log('message list changes', changes);
  //   if (changes.activeRoomMessages && changes.activeRoomMessages.currentValue) {
  //     this.scrollToBottom();
  //   }
  // }

  // public setActiveRoom(room: ChatRoom): void {
  //   if (room) {
  //     this.activeRoom = room;
  //     // this.setChatPartner();
  //     setTimeout(() => {
  //       // scroll to bottom of chat list (most recent messages on bottom)
  //       if (!this.emojiReactionPickerOpened && !this.preventScroll) {
  //         this.scrollToBottom();
  //       }
  //     });
  //   }
  // }

  // scrollToBottom(): void {
  //   console.log('scrolling to bottom');
  //   this.messageListRef.nativeElement.scrollTop = this.messageListRef.nativeElement.scrollHeight;
  // }

  /** Important: this prevents old already rendered messages from being re-rendered (while content is the same) */
  // trackNgforBy(index: number, item: Message | MessagesPerDay): string {
  //   return item.id;
  // }

  // toggleReactionEmojiPicker($event: {$event: Event; message: Message}): void {
  //   // console.log('$event toggleReactionEmojiPicker', $event);
  //   let addReactionButtonHtmlEl = $event.$event.target as HTMLElement;
  //   if (!addReactionButtonHtmlEl.classList.contains('add-reaction-emoji-button')) {
  //     addReactionButtonHtmlEl = addReactionButtonHtmlEl.closest('.add-reaction-emoji-button');
  //   }
  //   // console.log('target', addReactionButtonHtmlEl);
  //   this.emojiReactionPickerMessage = $event.message;
  //   // todo check actual overflow for positioning. Now long lists of different emoji reactions will cause the picker to be outside view.
  //   // position the reaction emoji picker on top of the 'add reaction button'
  //   this.emojiReactionPickerRef.nativeElement.style.top = (addReactionButtonHtmlEl.offsetTop - 232) + 'px';
  //   // if the message is from another use, place picker on the right, otherwise on the left
  //   this.emojiReactionPickerRef.nativeElement.style.left = (addReactionButtonHtmlEl.offsetLeft - ($event.message.isMessageFromOtherUser ? 0 : (341 - addReactionButtonHtmlEl.clientWidth))) + 'px';
  //   this.emojiReactionPickerOpened = !this.emojiReactionPickerOpened;
  // }
  //
  // addOrRemoveReactionEmoji($event: { message: Message; emojiId: string }): void {
  //   this.preventScroll = true;
  //   this.store.dispatch(toggleMessageEmojiReaction({ roomId: this.activeRoom.roomId, message: $event.message, emojiId: $event.emojiId }));
  //   setTimeout(() => {
  //     this.preventScroll = false;
  //   }, 500);
  //   this.emojiReactionPickerOpened = false;
  // }

  // public setChatUser(chatUsers: ChatUser[]) {
  //   this.chatUsers = chatUsers;
  // }

  // public setChatPartner() {
  //   if (this.chatUsers.length > 0) {
  //     this.chatPartner = this.chatUsers.find(user => user.id === this.activeRoom.members[0].id);
  //     setTimeout(() => {
  //       // scroll to bottom of chat list (most recent messages on bottom)
  //
  //       // focus chat input
  //
  //     });
  //     // }, 100);
  //
  //   }
  // }

}
