<nav class="tabs" #tabbedContentNav>
  <div
    *ngFor="let tab of tabs"
    [ngClass]="{ 'active': tab.id === activeTabId }"
    class="tab"
    (click)="selectTab(tab.id)"
  >
    <div class="tab-title">{{ tab.label | translate }}</div>
    <div class="line" [ngClass]="{ 'active': tab.id === activeTabId }"></div>
  </div>
</nav>

<ng-content></ng-content>
