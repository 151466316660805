<div class="file-uploader-wrapper" (click)="showPremiumPopup()">
  <label
    #buttonFileRef
    class="tile-wrapper form-label-file"
    [attr.for]="fieldName"
    [ngClass]="{'label-file-uploaded': file, 'label-error': !!error}"
  >
    <span *ngIf="!file && !imagePreview" class="icon-file-uploader not-uploaded" [inlineSVG]="icon"></span>
    <span *ngIf="file && !imagePreview" class="icon-file-uploader uploaded" [inlineSVG]="icon"></span>
    <img *ngIf="imagePreview" [src]="imagePreview" class="image-preview">
    <span [hidden]="!!error" class="file-text">{{ file ? file.name : placeholder }}</span>
    <span [hidden]="!error" class="file-text">{{ error }}</span>
    <input [accept]="accept"
           (change)="loadFile($event.target)"
           [id]="fieldName"
           [name]="fieldName"
           [multiple]="isMultiple"
           [disabled]="isDisabled"
           type="file"
           class="form-input-file"
    >
  </label>
</div>
