import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

interface FakeTab {
  id: string;
  label: string;
}

/**
 * As opposed to the normal 'tabbed-content' component, this is technically only 1 tab and does not have slots for each tab.
 * Useful for situations where changing tabs mainly changes data of components, instead of the actual components inside the tabs (like the measurement details graphs modal).
 */
@Component({
  selector: 'tabbed-content-fake-tab',
  templateUrl: './tabbed-content-fake-tab.component.html',
  styleUrls: ['./tabbed-content-fake-tab.component.scss']
})
export class TabbedContentFakeTabComponent implements OnInit, AfterViewInit {
  @ViewChild('tabbedContentNav') tabbedContentNav: ElementRef;
  @Input() tabs: FakeTab[] = [];
  @Input() activeTabId: string | null = null;

  @Output() tabChanged = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  selectTab(tabId: string): void {
    this.tabChanged.emit(tabId);
    this.activeTabId = tabId;
    this.scrollActiveTabIntoView(true);
  }

  ngAfterViewInit(): void {
    this.scrollActiveTabIntoView(false);
  }

  scrollActiveTabIntoView(scrollBehaviorSmooth = true): void {
    if (this.tabbedContentNav) {
      setTimeout(() => {
        const ifSelectedTab = this.tabbedContentNav.nativeElement.querySelector('.tab.active');
        if (ifSelectedTab) ifSelectedTab.scrollIntoView({ inline: 'center', block: 'center', behavior: scrollBehaviorSmooth ? 'smooth' : 'auto' });
      });
    }
  }
}
