import { Action, createReducer, on } from '@ngrx/store';
import { initialState, GlobalState } from './global.state';
import { apiCallStart, apiCallFinish, setIsPremium } from './global.actions';
// import { apiCallStart, apiCallFinish, setIsPremium, setWindowDimensions } from './global.actions';

export const KEY_GLOBAL = 'global';

/**
 * Encapsulated object which prevents misuse so that delta never drops below 0.
 */
let activeApiCallsCounter = 0;
//
// const apiCallDelta = new (class {
//   private val = 0;
//
//   get isActive() {
//     return this.val > 0;
//   }
//
//   plus() {
//     this.val++;
//   }
//
//   minus() {
//     if (this.val > 0) {
//       this.val--;
//     } else {
//       console.warn('Action prevented: attempted to reduce api call delta, which is already at 0.');
//     }
//   }
// })();

const _globalReducer = createReducer(
  initialState,
  on(apiCallStart, (state: GlobalState) => {
    activeApiCallsCounter++;
    // apiCallDelta.plus();
    return {
      ...state,
      apiCallIsActive: activeApiCallsCounter > 0,
    };
  }),
  on(apiCallFinish, (state: GlobalState) => {
    activeApiCallsCounter--;
    // apiCallDelta.minus();
    return {
      ...state,
      apiCallIsActive: activeApiCallsCounter > 0,
    };
  }),
  on(setIsPremium, (state: GlobalState, {value}) => ({
    ...state,
    isPremium: value
  })),
  // on(setWindowDimensions, (state: GlobalState, windowDimensions) => ({
  //   ...state,
  //   windowDimensions
  // }))
);

export function reducer(state: GlobalState | undefined, action: Action): GlobalState {
  return _globalReducer(state, action);
}
