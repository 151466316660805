import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {ApiService} from "./api.service";
import {HttpClient} from "@angular/common/http";
import {ConsentResponse, ConsentTypes, UpdateConsentRequest} from "../models/consentTypes";

@Injectable({
  providedIn: 'root'
})
export class ApiIdentityService {

  private readonly baseUrl = environment.apiIdentityBaseUrl;
  private readonly userId: string;

  constructor(
    private apiService: ApiService,
    private httpClient: HttpClient
  ) {
    this.userId = apiService.getUserId();
  }

  changePassword(currentPassword: string, newPassword: string, validatePassword: string): Promise<unknown> {
    return this.httpClient.post(`${this.baseUrl}user/changePassword`, { currentPassword, newPassword, validatePassword }).toPromise();
  }

  updateConsent(request: UpdateConsentRequest) {
    return this.httpClient.put(`${this.baseUrl}user/consent`, { accepted: request.accepted, type: request.type }).toPromise();
  }

  getConsents(consentType: ConsentTypes): Promise<ConsentResponse> {
    return this.httpClient.get<ConsentResponse>(`${this.baseUrl}user/consent/${consentType}`).toPromise();
  }
}
