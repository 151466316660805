import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChatRoom, Message } from '../../../models/chat/chatRoom';
// import Message, { EmojiReaction, MessageType } from '../../../models/chat/message';
// import { addMessage, toggleMessageEmojiReaction } from '../../../../store/chat/chat.actions';


@Component({
  selector: 'chat-message-bubble',
  templateUrl: './chat-message-bubble.component.html',
  styleUrls: ['./chat-message-bubble.component.scss']
})
export class ChatMessageBubbleComponent implements OnInit {
  @Input() activeRoom: ChatRoom;
  @Input() message: Message;
  @Input() isGroupChat: boolean;

  // readonly MessageType = MessageType;

  // @Output() uploadedFilePreviewLoaded = new EventEmitter();
  // @Output() emojiReactionPickerToggled = new EventEmitter();
  // @Output() emojiReactionAddedOrRemoved = new EventEmitter();

  constructor(
  )
  {}

  ngOnInit(): void {
  }

  // todo note, creating a hidden anchor tag is not needed when files are not base64 encoded anymore
  // downloadFile(): void {
  //   const a = document.createElement('a');
  //   a.href = this.message.file.src;
  //   if (!this.message.file.src.startsWith('data:')) {
  //     a.setAttribute('target', '_blank');
  //   }
  //
  //   a.download = this.message.file.name;
  //   a.click();
  //   a.remove();
  // }

  // onMediaFileLoaded(onMediaFileLoadedEvent: Event): void {
  //   // console.log('onMediaFileLoadedEvent', onMediaFileLoadedEvent);
  //   // if (this.message.isLoadedBySocket) this.uploadedFilePreviewLoaded.emit();
  //   this.uploadedFilePreviewLoaded.emit();
  // }
  //
  // toggleReactionEmojiPicker($event: Event): void {
  //   // console.log('$event addEmojiReactionButtonClicked', $event);
  //   this.emojiReactionPickerToggled.emit({$event, message: this.message});
  // }
  //
  // addOrRemoveReactionEmoji(emojiReactionId: string): void {
  //   this.emojiReactionAddedOrRemoved.emit({message: this.message, emojiId: emojiReactionId});
  // }
}
