import { Component, OnInit } from '@angular/core';
import { ErrorButton, ErrorButtonStyle } from 'src/app/shared/components/error-component/error.component';

@Component({
  selector: 'app-twofactor-error',
  template: `<error-component 
    imageSrc="../../../../assets/img/img_sitework.png"
    [extraInformation]="'ERROR.SSO_2FA.INFO' | translate"
    [buttons]="buttons"></error-component>`,
})
export class TwoFactorErrorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  buttons: ErrorButton[] = [
    { buttonTextTranslateKey: 'ERROR.SSO_2FA.TO_LOGIN_BUTTON', buttonStyle: ErrorButtonStyle.Primary, href: "/" },
  ]
}
