<div class="emoji-list-wrapper" #emojiListRef>
  <div
   *ngFor="let emoji of emojis; index as i"
   [ngClass]="{'selected': value === emoji.id, disabled: isDisabled}"
   class="tile-wrapper title-emoji-wrapper"
   (click)="selectEmoji(emoji.id)"
  >
    <div class="emoji-box">{{ emoji.emoji }}</div>
    <div class="text-emoji">{{ 'DASHBOARD.PANEL.INPUT_EMOJI_' + emoji.text | translate }}</div>
  </div>
  <input
    #emojiInputRef
    [ngModel]="value"
    [required]="required"
    type="hidden"
  >
</div>
