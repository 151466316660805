import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { TableComponent } from '../table.component';

@Component({
  selector: 'table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss']
})
export class TableHeaderComponent implements OnInit {
  @Input() displayName: string;
  @Input() columnName: string;
  @Input() sortByName: string;
  @Input() sortDesc = false;
  @Input() width = 'inherit';
  @Input() sortable = false;
  @Input() tableComponent: TableComponent;

  isActive = false;

  constructor() {
  }

  ngOnInit(): void {
    this.tableComponent.activeSort$.subscribe(activeSort => {
      this.isActive = activeSort === this.columnName;
    });
  }

  sortBy(name: string) {
    this.tableComponent.sort(name, this.sortDesc);
  }

}
