import {take} from "rxjs/operators";
import {MedmijAuthService} from "../shared/utils/medmij-auth.service";
import {ActivatedRouteSnapshot, CanActivate} from "@angular/router";
import {AtalmedialClient} from '../shared/api/api-medmij-partner.service';
import {Injectable} from "@angular/core";
import * as uuid from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class AtalmedialGuard implements CanActivate
{
  constructor(private medMijAuthService: MedmijAuthService,
              private atalmedialClient: AtalmedialClient) {}

  canActivate(route:ActivatedRouteSnapshot): boolean {
    const params = route.queryParams;

    if(!this.medMijAuthService.medMijAccessToken && params['token']) {
      this.medMijAuthService.medMijAccessToken = params['token'];
    }

    if (this.medMijAuthService.medMijAccessToken) {
      this.medMijAuthService.medMijCareProviderNameToConnect = localStorage.getItem('medMijCareProviderName');
      this.medMijAuthService.medMijDataServiceIdToConnect = localStorage.getItem('medMijDataServiceId');
      localStorage.removeItem('medMijCareProviderName');
      localStorage.removeItem('medMijDataServiceId');

      this.setApplicationInsightsTracking();

      return true;
    } else {
      localStorage.removeItem("AI_Guid");
      this.atalmedialClient.getAuthorizationPage().pipe(take(1)).subscribe(url => window.location.href = url);
      return false;
    }
  }

  setApplicationInsightsTracking() {
    let aiGuid = localStorage.getItem("AI_Guid");
    if(!aiGuid || aiGuid == "") {
      aiGuid = uuid.v4();
    }
    localStorage.setItem('partnerId', 'AM')
    localStorage.setItem('AI_Guid', aiGuid)
  }
}
