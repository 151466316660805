<div class="line-indicator-container">
  <div class="line-indicator">
    <span class="pic arrow-down" [style.margin-left]="'calc(' + valuePercentage + '% - 10px)'"></span>
  </div>
  <div class="line">
    <ng-container *ngIf="indicatorType === IndicatorType.LOW">
      <div class="medium-zone" [style.width.%]="lowValuePercentage"></div>
      <div class="good-zone" [style.width.%]="remainingPercentage"></div>
    </ng-container>
    <ng-container *ngIf="indicatorType === IndicatorType.BOTH">
      <div class="medium-zone" [style.width.%]="lowValuePercentage"></div>
      <div class="good-zone" [style.width.%]="highValuePercentage"></div>
      <div class="medium-zone" [style.width.%]="remainingPercentage"></div>
    </ng-container>
    <ng-container *ngIf="indicatorType === IndicatorType.HIGH">
      <div class="good-zone" [style.width.%]="highValuePercentage"></div>
      <div class="medium-zone" [style.width.%]="remainingPercentage"></div>
    </ng-container>
  </div>
  <div class="zone-values">
    <ng-container *ngIf="indicatorType === IndicatorType.BOTH">
      <div class="medium-zone" [style.width.%]="lowValuePercentage">
        <div class="medium-zone-min">{{valueA}}</div>
        <div class="medium-zone-max">{{valueB}}</div>
      </div>
      <div class="good-zone" [style.width.%]="highValuePercentage">
        <div class="good-zone-min"></div>
        <div class="good-zone-max">{{valueC}}</div>
      </div>
      <div class="good-zone" [style.width.%]="remainingPercentage">
        <div class="good-zone-min"></div>
        <div class="good-zone-max">{{valueD}}</div>
      </div>
    </ng-container>
    <ng-container *ngIf="indicatorType === IndicatorType.LOW">
      <div class="medium-zone" [style.width.%]="lowValuePercentage">
        <div class="medium-zone-min">{{valueA}}</div>
        <div class="medium-zone-max">{{valueB}}</div>
      </div>
      <div class="good-zone" [style.width.%]="remainingPercentage">
        <div class="good-zone-min"></div>
        <div class="good-zone-max">{{valueD}}</div>
      </div>
    </ng-container>
    <ng-container *ngIf="indicatorType === IndicatorType.HIGH">
      <div class="medium-zone" [style.width.%]="highValuePercentage">
        <div class="medium-zone-min">{{valueA}}</div>
        <div class="medium-zone-max"></div>
      </div>
      <div class="good-zone" [style.width.%]="remainingPercentage">
        <div class="good-zone-min">{{valueC}}</div>
        <div class="good-zone-max">...</div>
      </div>
    </ng-container>
  </div>
</div>
