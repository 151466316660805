import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DateService } from '../utils/date.service';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'unit'
})
export class UnitPipe implements PipeTransform {
  translatableUnits = [
    'floors',
    'steps'
  ];

  constructor(private translate: TranslateService,
              private sanitizer: DomSanitizer) {}

  transform(value: any, measurementUnitString: string, duration = false, twentyFour = false, dataPointsWithinXAxisTimeSpan?: number, hideUnit = false, noHTML = false): any {
    if(measurementUnitString === 'h' || measurementUnitString === 'm') {
      value = parseInt(value);
      return this.minutesToHHMM(value, measurementUnitString, duration, twentyFour, noHTML);
    } else {
      // && dataPointsWithinXAxisTimeSpan
      if(measurementUnitString && !hideUnit) {
        const translatedMeasurementString = this.translatableUnits.find(unitString => unitString === measurementUnitString) ? this.translate.instant('SHARED.' + measurementUnitString.toUpperCase()) : measurementUnitString;
        if(!noHTML) {
          return this.sanitizer.sanitize(SecurityContext.HTML, `${value} <sup class="unit">${translatedMeasurementString}</sup>`);
        } else {
          return this.sanitizer.sanitize(SecurityContext.HTML, `${value} ${translatedMeasurementString}`);
        }
      }
      return value;
    }
  }

  minutesToHHMM(mins: any, unit: string, duration = false, twentyFour = false, noHTML = false) {
    let h: any = Math.floor(mins / 60);

    let m: any = mins % 60;

    isNaN(h) ? h = '0' : h;
    isNaN(m) ? m = '0' : m;

    m = m < 10 ? '0' + m : m;

    if (twentyFour) {

      h = h < 10 ? '0' + h : h;

      return `${h}:${m}`;

    }
    else if(duration) {
      const unit = this.translate.instant('SHARED.H');
      if(noHTML) {
        return `${h}${unit} ${m}`;
      }
      return this.sanitizer.sanitize(SecurityContext.HTML, `${h}<sup class="unit">${unit}</sup> ${m}`);
    }
    else {

      let a = 'am';

      if (h >= 12) a = 'pm';

      if (h > 12) h = h - 12;

      return `${h}:${m} ${a}`;

    }
  }
}
