<devices-apps *ngIf="isDataLoaded" [formGroup]="wearablesAppsForm" [suppliers]="wearablesAppsItems.suppliers"></devices-apps>
<app-button class="connect-device-button"
            (click)="openModal()"
            [disabled]="!isSelected"
            [buttonText]="buttonText | translate"
            [styles]="partner === PartnerEnum.VITAL10 ? BUTTON_STYLES.vital10 : BUTTON_STYLES.primary"
></app-button>
<disconnect-popup
  (onDisconnect)="disconnectDevice()"
  (onSync)="syncAppleHealthKit()"
  [appKey]="selectedItem?.key"
  [appName]="selectedItem?.title"
  [partner]="partner"
></disconnect-popup>
<wearable-connection-popup
  *ngIf="selectedItem"
  [wearableVendorName]="selectedItem.title"
  (onProceed)="connectDevice()"
  [partner]="partner"
>
</wearable-connection-popup>
