import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from '../dropdown-menu/dropdown-menu.component';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'admin-profile-button',
  templateUrl: './admin-profile-button.component.html',
  styleUrls: ['./admin-profile-button.component.scss']
})
export class AdminProfileButtonComponent implements OnInit {
  arrowColor = '#70B3E2';
  isOpened = false;

  menuItems: MenuItem[] = [];

  @ViewChild('expandArrow') expandArrow: ElementRef;

  @HostListener('document:click', ['$event'])
  closeDropdownMenu(event: Event) {
    if(this.eRef.nativeElement.contains(event.target)) {
    } else {
      const menuVisible = this.eRef.nativeElement.getBoundingClientRect().width > 0;
      if(menuVisible) {
        this.isOpened = false;
        this.rotateArrow();
      }
    }
  }

  constructor(private eRef: ElementRef, private translate: TranslateService) {
    const environmentUrl = environment.production ? 'https://we.selfcare4all.com/nl-nl/' : 'https://staging-we.selfcare4all.com/nl-nl/';
      this.menuItems = [
        {
          label: this.translate.instant('ADMIN.DROPDOWN.PARTICIPANTS'),
          action: () => document.location.href = environmentUrl + 'deelnemers'
        },
        {
          label: this.translate.instant('ADMIN.DROPDOWN.CHALLENGE'),
          action: (): any => document.location.href = environmentUrl + 'challenge'
        },
        {
          label: this.translate.instant('ADMIN.DROPDOWN.PROFILE'),
          action: (): any => document.location.href = environmentUrl + 'mijn-gegevens'
        },
        {
          label: this.translate.instant('ADMIN.DROPDOWN.COMPANY'),
          action: (): any => document.location.href = environmentUrl + 'bedrijfsbeheer'
        }
      ];
  }

  ngOnInit(): void {
  }

  toggleMenu() {
    this.isOpened = !this.isOpened;
    this.rotateArrow();
  }

  rotateArrow() {
    const arrowElement = <HTMLElement>this.expandArrow.nativeElement;
    if (!this.isOpened) {
      arrowElement.style.transform = 'rotate(45deg)';
    } else {
      arrowElement.style.transform = 'rotate(225deg)';
    }
  }

}
