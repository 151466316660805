<nav class="bottom-menu">
  <div class="bottom-nav-bar-item-container" *ngFor="let menuItem of MENU_ITEMS" [routerLink]="menuItem.routerLink">
    <a class="bottom-nav-bar-item" routerLinkActive="router-link-active" #link="routerLinkActive">
      <img *ngIf="menuItem.iconActive"
           [src]="link.isActive ? 'assets/img/nav-bottom-menu/' + menuItem.iconActive : 'assets/img/nav-bottom-menu/' + menuItem.icon"
           class="bottom-nav-bar-icon">
      <div *ngIf="!menuItem.iconActive" class="bottom-nav-bar-icon bottom-nav-bar-icon-svg"
           [inlineSVG]="'/nav-bottom-menu/' + menuItem.icon">
      </div>
      {{ 'DASHBOARD.NAV_BOTTOM_MENU.' + menuItem.id | translate }}
    </a>
    <blue-dot *ngIf="menuItem.id === 'NETWORK'"></blue-dot>
  </div>
</nav>
