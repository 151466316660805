import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { BUTTON_STYLES } from '../../../components/button/button.component';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../../../api/api.service';
import { DateStringFormat } from '../../../components/date-time-picker/date-time-picker.component';
import SavedNote, {NoteType} from '../../../models/note/SavedNote';
import NoteFormData from '../../../models/note/NoteFormData';

@Component({
  selector: 'add-image-panel',
  templateUrl: './add-image-panel.component.html',
  styleUrls: ['./add-image-panel.component.scss']
})
export class AddImagePanelComponent implements OnInit, OnChanges {
  // field used when editing an existing image record
  @Input() editedItemId: string;

  @Output() imageNoteCreated = new EventEmitter();

  readonly DateStringFormat = DateStringFormat;
  readonly BUTTON_STYLES = BUTTON_STYLES;
  public addImageForm: UntypedFormGroup;
  public formData: NoteFormData;
  editedItem: SavedNote;
  modalId = 'addImageModal';
  isFormValid = false;

  fileResetter = 0;
  now = new Date();

  constructor(
    public modal: NgxSmartModalService,
    private formBuilder: UntypedFormBuilder,
    private api: ApiService,
    // private translate: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.addImageForm = this.formBuilder.group({
      date: [new Date(), Validators.required],
      image: ['', Validators.required],
      title: ['']
    });

    this.addImageForm.valueChanges.subscribe(() => {
      this.isFormValid = this.addImageForm.valid;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.editedItemId?.currentValue && this.modal.get(this.modalId).isVisible()) {
      this.loadData().then(() => {
        this.addImageForm.get('date').setValue(new Date(this.editedItem.dateTime));
        this.addImageForm.get('image').setValue(this.editedItem.attachments[0].fileName);
        if (this.editedItem.content) this.addImageForm.get('title').setValue(this.editedItem.content);
      });
    }
  }

  async loadData(): Promise<void> {
    this.editedItem = await this.api.getNoteByNoteId(this.editedItemId);
  }

  public setUploadedImage(file: File): void {
    this.formData.file = file;
  }

  public async saveImage(): Promise<void> {
    const formData: NoteFormData = {
      date: this.addImageForm.get('date').value.toISOString(),
      content: this.addImageForm.get('title').value,
      file: this.addImageForm.get('image').value,
      type: NoteType.IMAGE
    };
    if (this.editedItem) {
      const editImageSaved = await this.api.editNote(formData, this.editedItemId);
    } else {
      const createdImageSaved = await this.api.createNote(formData);
    }
    this.modal.close(this.modalId);

    this.imageNoteCreated.emit();
  }

  resetModal(): void {
    this.addImageForm = this.formBuilder.group({
      date: [new Date(), Validators.required],
      image: ['', Validators.required],
      title: ['']
    });
    this.editedItem = null;
    this.fileResetter++;
  }
}
