<div class="chart-wrapper">
  <canvas baseChart
    #chartCanvas
    [datasets]="datasets"
    [options]="options"
    [legend]="false"
    [type]="chartTypeSelected"
  >
  </canvas>
<!--  todo note leave these here just in case we'll use it later -->
<!--  (chartClick)="chartClicked($event)"-->
  <!--(chartHover)="chartHovered($event)"-->

  <chart-tooltip
    [tooltipData]="tooltipData"
    [atalmedialMeasurement]="atalmedialMeasurement"
    [measurementTypeId]="measurementTypeId"
    [measurementSvg]="measurementSvg"
    [measurementUnitString]="measurementUnitString"
    [measurementTypeTitle]="measurementTypeTitle"
    [forContactId]="forContactId"
    [isCaregiver]="isCaregiver"
    (tooltipClosed)="tooltipClose()"
    (onAddNote)="addNote($event)"
  >
  </chart-tooltip>
</div>
