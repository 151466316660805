import { Component, Input } from '@angular/core';

@Component({
  selector: 'back-button',
  template: `
    <div
      class="back-button-container"
      [ngClass]="[styles ? getButtonStyles() : 'back-button-primary']"
      inlineSVG="icn_arrow.svg">
    </div>
  `,
  styles: [`
    .back-button-container {
      cursor: pointer;
    }

    .back-button-primary ::ng-deep path {
      fill: #FFFFFF;
    }

    .back-button-grey ::ng-deep path {
      fill: var(--text_grey_color);
    }

    .back-button-grey-2 ::ng-deep path {
      fill: var(--text-grey_color_2);
    }

    .back-button-text-color ::ng-deep path {
      fill: var(--text_color);
    }
  `],
})
export class BackButtonComponent {
  @Input() styles?: string | string[];

  getButtonStyles(): string {
    if (this.styles) {
      if (typeof this.styles === 'string') {
        return this.styles;
      } else {
        let styles = '';
        for (const i in this.styles) {
          styles += this.styles[i] + ' ';
        }
        return styles;
      }
    }
    return '';
  }
}

/** Alternative button styles */
export const BACK_BUTTON_STYLES = {
  grey: 'back-button-grey',
  grey2: 'back-button-grey-2',
  textColor: 'back-button-text-color'
};
