import { AfterViewInit, Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'input-text',
  template: `
    <div class="input-container" [ngClass]="[localIcon ? 'includes-icon' : '' ,showGreenCheckMarkWhenValid ? 'green-checkmark-when-valid' : '']">
      <label>{{ labelText }}{{ required ? ' *' : '' }}</label>
      <div class="input-element-container">
        <input
          #inputRef
          [type]="type"
          (input)="onChanged($event)"
          (blur)="onBlur($event)"
          (paste)="checkForAllowPaste($event)"
          [disabled]="disabled"
          [placeholder]="placeholder"
          [value]="value"
          [required]="required"
          [ngClass]="backgroundStyle ? styles[backgroundStyle] ? styles[backgroundStyle] : '' : ''"
          [maxLength]="maxLength"
          autocomplete="{{ type === 'password' ? 'new-password' : 'off' }}"
          class="input-element"
        >
        <div *ngIf="localIcon" class="input-icon" [inlineSVG]="localIcon" (click)="toggleShowPasswordInput($event)"></div>
        <div *ngIf="showGreenCheckMarkWhenValid" class="green-checkmark" [inlineSVG]="'icn_checkmark.svg'"></div>
      </div>
    </div>
  `,
  styleUrls: ['./input-text.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputTextComponent),
    multi: true
  }]
})
export class InputTextComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  @ViewChild('inputRef') inputRef: ElementRef<HTMLInputElement>;

  @Input() labelText: string;
  @Input() type = 'text';
  @Input() disabled = false;
  @Input() placeholder = '';
  @Input() required = false;
  // optional link to icon showed on the right of the input
  @Input() icon: null | string;
  @Input() allowPasting? = true;
  @Input() showGreenCheckMarkWhenValid? = false;
  @Input() backgroundStyle?: string;
  @Input() maxLength = 524288;
  @Input() focusOnInit = false;

  localIcon: null | string;
  isPasswordType: boolean;
  value = '';
  styles: { [key: string]: string } = {
    grey: 'background-grey'
  };

  ngOnInit(): void {
    if (this.type === 'password') {
      this.isPasswordType = true;
      this.localIcon = 'icn_hidden-pass.svg';
    } else {
      this.isPasswordType = false;
      if (this.icon) {
        this.localIcon = this.icon;
      }
    }
  }

  ngAfterViewInit() {
    if (this.focusOnInit) this.inputRef.nativeElement.focus();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  onChange = ((_: any) => {
  });
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  onBlur = ((_: any) => {
  });

  writeValue(newValue: string): void {
    this.value = newValue;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  registerOnTouched(fn: any): void {
    this.onBlur = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChanged($event: Event): void {
    const targetElement = (<HTMLInputElement>$event.target);
    this.value = targetElement.value;
    this.onChange(this.value);
  }

  toggleShowPasswordInput($event: Event): void {
    // $event.stopPropagation();
    this.inputRef.nativeElement.focus();
    if (this.isPasswordType) {
      if (this.inputRef.nativeElement.type === 'password') {
        this.inputRef.nativeElement.type = 'text';
        this.localIcon = 'icn_unhidden-pass.svg';
      } else {
        this.inputRef.nativeElement.type = 'password';
        this.localIcon = 'icn_hidden-pass.svg';
      }
    }
  }

  checkForAllowPaste($event: Event): void {
    if (this.allowPasting === false) {
      $event.preventDefault();
    }
  }
}
