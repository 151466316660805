<div class="accordion-wrapper" [ngClass]="{ 'border-bottom': isLast, 'add-shadow': addShadow }" #accordionElement>
  <div class="header-wrapper" [ngClass]="{'active-header': isOpened, 'main-accordion': isMainAccordion}" (click)="toggleAccordion()">
    <ng-content select="[header]"></ng-content>
    <div class="expand-arrow" [ngStyle]="{ borderColor: arrowColor }" #expandArrow></div>
  </div>
  <div #accordionContentClosed class="accordion-content-closed">
    <ng-content select="[contentClosed]"></ng-content>
  </div>
  <div class="accordion-content" #accordionContent (cdkObserveContent)="onContentChange($event)">
    <ng-content></ng-content>
  </div>
</div>
