import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-button',
  template: `
    <button
      [attr.id]="id ? id : null"
      class="button-base"
      [type]="buttonType"
      [disabled]="disabled"
      [ngClass]="[styles ? getButtonStyles() : 'button-primary', fakeDisabled ? 'disabled' : '']"
    >
      <img *ngIf="includeLeftCheckmarkIcon" src="/assets/img/icn_checkmark.svg" class="checkmark-icon">
      <ng-content></ng-content>
      {{ buttonText }}
    </button>
  `,
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  @Input() id?: string;
  @Input() buttonText: string;
  @Input() disabled = false;
  // only apply styling of a disabled button, but keep pointer events so errors can still be triggered
  @Input() fakeDisabled: boolean;
  // defines extra styles for the button. If no styles are given, defaults to 'button-primary'
  @Input() styles?: BUTTON_STYLES | BUTTON_STYLES[] | string | string[];
  @Input() buttonType = 'button';
  @Input() includeLeftCheckmarkIcon = false;

  ngOnInit(): void {
    // if (!this.buttonType) {
    //     this.buttonType = 'button';
    // }
  }

  getButtonStyles(): string {
    if (this.styles) {
      if (typeof this.styles === 'string') {
        return this.styles;
      } else {
        let styles = '';
        for (const i in this.styles) {
          styles += this.styles[i] + ' ';
        }
        return styles;
      }
    }
    return '';
  }
}

/** Alternative button styles */
export enum BUTTON_STYLES {
  primary = 'button-primary',
  // inverted color and gray background-color
  primaryDangerous = 'button-primary-dangerous',
  secondary = 'button-secondary',
  // inverted color and background-color
  secondaryAlternative = 'button-secondary-alternative',
  secondaryDangerous = 'button-secondary-dangerous',
  // alternative border radius (top right is flat)
  shaped = 'button-shaped',
  // extra dark
  dark = 'button-dark',
  primaryShade2 = 'button-primary-shade-2',
  small = 'button-small',
  vital10 = 'button-vital10',
  vital10Secondary = 'button-vital10-secondary',
  vital10Dark = 'button-vital10-dark'
}
