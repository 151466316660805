<div class="table-wrapper">
  <table class="data-table">
    <thead>
        <ng-content select="table-headers"></ng-content>
    </thead>
    <tbody [ngClass]="{'striped': striped}">
        <ng-content select="table-body"></ng-content>
    </tbody>
  </table>
</div>
