<ngx-smart-modal
  [identifier]="modalId"
  [closable]="false"
>
  <div class="close-button" inlineSVG="icn_close.svg" (click)="closeModal()"></div>
<!--  [autostart]="true"-->
  <div class="c-lost-container">
    <!-- Lost Connection Title -->
    <h3 class="connection-lost-title">{{'ERROR.CONNECTION_LOST.TITLE' | translate}}</h3>
    <!-- Bluetooth Image -->
    <div class="c-lost-icn" inlineSVG='icn_onboarding-bluetooth.svg'></div>
    <div class="connection-description-wrapper">
      <!-- First Description -->
      <p class="connection-lost-text">{{ 'ERROR.CONNECTION_LOST.DESCRIPTION.1' | translate: { device: deviceName } }}</p>
      <!-- Second Description -->
      <p class="connection-lost-text">{{ 'ERROR.CONNECTION_LOST.DESCRIPTION.2' | translate: { device: deviceName } }}</p>
      <!-- Third Description -->
      <p class="connection-lost-text">{{'ERROR.CONNECTION_LOST.DESCRIPTION.3' | translate}}</p>
    </div>
    <!-- Connect Device Button -->
    <app-button
      class="connection-lost-button"
      [buttonText]="'ERROR.CONNECTION_LOST.BUTTON' | translate"
      [styles]="[BUTTON_STYLES.primaryShade2]"
      (click)="goToSources()"
    ></app-button>
  </div>
</ngx-smart-modal>
