import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MeasurementService } from '../../../measurements/measurement.service';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { DateService } from '../../../utils/date.service';
import { DataPointTooltipModel, TimePeriod } from '../../../models/enums/GraphEnums';
import { MOODS } from '../../../models/enums/MeasurementEnums';
import { ApiService } from '../../../api/api.service';
import { PremiumService } from 'src/app/shared/utils/premiumService';

@Component({
  selector: 'chart-tooltip',
  templateUrl: './chart-tooltip.component.html',
  styleUrls: ['./chart-tooltip.component.scss']
})
export class ChartTooltipComponent implements OnChanges {
  @ViewChild('tooltipElement') tooltipElement: ElementRef;

  @Input() measurementTypeId: string | null;
  @Input() measurementSvg: string;
  @Input() measurementUnitString: string;
  @Input() measurementTypeTitle: string;

  @Input() tooltipData: DataPointTooltipModel;
  @Input() atalmedialMeasurement = false;
  @Input() forContactId: string;

  @Input() isCaregiver = false;

  @Output() tooltipClosed = new EventEmitter<boolean>();
  @Output() onAddNote = new EventEmitter<string>();

  expandedViewNotes = false;
  qualityColor = '#333333';
  qualityColorShade = 'black';
  qualityColorText = 'white';
  dateString = '';

  // readonly MEASUREMENT_SOURCES = MeasurementSource;

  @HostBinding('style')
  get tooltipColorStyle(): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(`
        --tooltip_color: ${this.qualityColor};
        --tooltip_color_shade: ${this.qualityColorShade};
        --measurement_color: ${this.qualityColorText};
      `
    );
  }

  constructor(
    public measurementService: MeasurementService,
    public sanitizer: DomSanitizer,
    public translate: TranslateService,
    public dateService: DateService,
    public apiService: ApiService,
    private premiumService: PremiumService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.tooltipData || changes.tooltipData.isFirstChange()) return;

    const tooltipElement = this.tooltipElement.nativeElement;
    if (this.tooltipData.dataPoint.element.active) {
      this.setTooltipOpenedStyling(tooltipElement, changes.tooltipData.currentValue);
    }
    else {
      this.closeTooltip();
    }
  }

  async setTooltipOpenedStyling(tooltipElement: HTMLElement, tooltipData: DataPointTooltipModel): Promise<void> {
    // set dateString, tooltip color, fade in animation and positioning
    // console.log('tooltipData', tooltipData);
    let color = this.measurementService.getQualityColor(this.measurementTypeId, tooltipData.dataPoint.quality);

    if(this.atalmedialMeasurement) {
      color = this.measurementService.getAtalmedialQualityColor(tooltipData.dataPoint.quality);
    }

    this.dateString = this.getDateString(tooltipData);
    this.qualityColor = color;
    this.qualityColorShade = this.measurementService.getQualityColorShadeByOriginalColor(color);
    tooltipElement.style.opacity = '1';
    tooltipElement.style.left = tooltipData.xPos + 'px';
    tooltipElement.style.top = tooltipData.yPos + 'px';
    tooltipElement.style.pointerEvents = 'auto';

    // fetch a potential measurement note if the dataPoint consists of exactly 1 measurement
    if(!this.isCaregiver && !this.forContactId) {
      await this.loadMeasurementNote();
    }
  }

  async loadMeasurementNote(): Promise<void> {
    if (this.tooltipData.dataPoint.measurements && this.tooltipData.dataPoint.measurements.length === 1) {
      const measurement = await this.apiService.getMeasurementById(this.tooltipData.dataPoint.measurements[0].id);

      if (measurement.note) {
        if (measurement.note.attachments && measurement.note.attachments.length) {
          measurement.note.attachmentUrl = measurement.note.attachments[0].url;
        }
        this.tooltipData.note = measurement.note;
        this.tooltipData.mood = MOODS.find(mood => mood.id === measurement.note.mood);
      }
    }
  }

  getDateString(tooltipData: DataPointTooltipModel): string {
    const startDateCloned = this.dateService.cloneDate(tooltipData.dataPoint.x);
    // todo better solution for iso <-> browser timezone date
    // startDateCloned.setHours(startDateCloned.getHours() + 2);

    // for day, include time
    if (tooltipData.timePeriod === TimePeriod.DAY) {
      return this.dateService.getLocalizedDateString(startDateCloned, true);
    }

    // for week and month, just return 1 date of the day
    if (tooltipData.timePeriod === TimePeriod.WEEK || tooltipData.timePeriod === TimePeriod.MONTH) {
      return this.dateService.getLocalizedDateString(startDateCloned, false);
    }

    return `
      ${this.dateService.getLocalizedDateString(startDateCloned, false)}
      –
      ${this.dateService.getLocalizedDateString(tooltipData.dataPoint.endDate)}
    `;
  }

  setTooltipClosedStyling(tooltipElement: HTMLElement): void {
    // fade out tooltip and move it outside of client viewport or remove pointerEvents
    tooltipElement.style.opacity = '0';
    // tooltipElement.style.left = '-10000px';
    // tooltipElement.style.top = '-10000px';
    tooltipElement.style.pointerEvents = 'none';
    setTimeout(() => {
      // prevent closing notes until fade out animation is completed
      this.expandedViewNotes = false;
    }, 250);
  }

  openEditMeasurementModal(): void {
    this.onAddNote.emit(this.tooltipData.dataPoint.measurements[0].id);
  }

  closeTooltip(): void {
    this.setTooltipClosedStyling(this.tooltipElement.nativeElement);
    this.tooltipClosed.emit(true);
  }

  bottomSectionClick(): void {
    if (!this.premiumService.userHasPremiumAccess(true)) {
      return;
    }

    if (this.tooltipData.dataPoint.totalMeasurements === 1 && (!this.tooltipData.note || (!this.tooltipData.note.content && !this.tooltipData.note.attachmentUrl))) {
      this.openEditMeasurementModal();
    }
    else {
      this.expandedViewNotes = !this.expandedViewNotes;
    }
  }
}
