<ngx-smart-modal class="disconnect-popup-wrapper small-modal" [identifier]="identifier" [closable]="false">
  <h4 class="disconnect-popup-title">
    {{ (appKey !== appleHealthKit ? 'SETTINGS.SOURCES.WEARABLES_APPS.DISCONNECT_APP': 'SETTINGS.SOURCES.WEARABLES_APPS.APPLE_HEALTH_APP') | translate: {appName: appName} }}
  </h4>
  <p class="disconnect-popup-description">
    <ng-container *ngIf="appKey !== appleHealthKit">{{ 'SETTINGS.SOURCES.WEARABLES_APPS.DISCONNECT_DESCRIPTION' | translate: { appName: appName } }}</ng-container>
    <ng-container *ngIf="appKey === appleHealthKit">{{ 'SETTINGS.SOURCES.WEARABLES_APPS.APPLE_HEALTH_DESCRIPTION_1' | translate: { appName: appName } }}</ng-container>
  </p>
  <p class="disconnect-popup-description" *ngIf="appKey === appleHealthKit">
    <span [innerHTML]="'SETTINGS.SOURCES.WEARABLES_APPS.APPLE_HEALTH_DESCRIPTION_2' | translate: { appName: appName }"></span>
  </p>
  <div class="buttons-wrapper">
    <app-button class="disconnect-button"
                (click)="disconnect()"
                [styles]="partner === PartnerEnum.VITAL10 ? BUTTON_STYLES.vital10 : BUTTON_STYLES.primary"
                [buttonText]="'SETTINGS.SOURCES.WEARABLES_APPS.YES_DISCONNECT' | translate">
    </app-button>
    <app-button class="disconnect-button"
                (click)="sync()"
                *ngIf="appKey === appleHealthKit"
                [styles]="partner === PartnerEnum.VITAL10 ? BUTTON_STYLES.vital10 : BUTTON_STYLES.primary"
                [buttonText]="'SETTINGS.SOURCES.WEARABLES_APPS.SYNC' | translate"></app-button>
    <app-button [styles]="partner === PartnerEnum.VITAL10 ? BUTTON_STYLES.vital10Secondary : BUTTON_STYLES.secondary" (click)="modal.get(identifier).close()" [buttonText]="'SHARED.CANCEL' | translate"></app-button>
  </div>
</ngx-smart-modal>
