import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss']
})
export class EmptyStateComponent implements OnInit {
  @Input() titleKey: string;
  @Input() icon = 'icn_selfcare_grey.svg';
  @Input() description: string;
  @Input() descriptionParts: string[];
  @Input() descriptionIcon = 'icn_add.svg';

  constructor() { }

  ngOnInit(): void {
  }

}
