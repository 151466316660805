import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Observable, Subject } from 'rxjs';
import AdyenCheckout from '@adyen/adyen-web';
import { ApiPaymentService } from '../../shared/api/api-payment.service';
import { Router } from '@angular/router';
import {Store} from "@ngrx/store";
import UserProfile from "../../shared/models/userProfile";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'test-page',
  templateUrl: './test-page.component.html',
  styleUrls: ['./test-page.component.scss'],
})
/** Just a route under /test to quickly experiment with some features without being bothered by any other components */
export class TestPageComponent implements OnInit, AfterViewInit, OnDestroy {

  loaded = new Subject<string>();
  loaded$: Observable<any>;
  i = 0;
  countryCode = 'NL';
  unsubscribe$ = new Subject();

  constructor(
    // private http: HttpClient,
    public modal: NgxSmartModalService,
    private apiPaymentService: ApiPaymentService,
    private router: Router,
    private store: Store<{ 'userProfile': UserProfile }>
  ) { }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.loaded$ = this.loaded.asObservable();
    this.loaded$.subscribe((loaded) => {
      console.log('loaded', loaded);
    });
    this.store.pipe(takeUntil(this.unsubscribe$)).subscribe(userProfile => this.countryCode = userProfile.userProfile.generalInformation.nationality)
  }

  ngAfterViewInit(): void {

  }

  testMakePayment(): void {

    this.apiPaymentService.getPaymentMethods(this.countryCode).subscribe(res => {
      const configuration = {
        paymentMethodsResponse: {
          paymentMethods: res.paymentMethods
        }, // The `/paymentMethods` response from the server.
        clientKey: 'test_F5USWJVXTVHKVBCKY2NVD2AP3IFVNWNS', // Web Drop-in versions before 3.10.1 use originKey instead of clientKey.
        locale: 'nl-NL',
        environment: 'test',
        returnUrl: 'http://localhost:4200/test',
        amount: {
          currency: 'EUR',
          value: 1000
        },
        // @ts-ignore
        onSubmit: (state: any, component: any) => {
          if (state.isValid) {
            this.initiatePayment(state, component);
          }
        },
        onAdditionalDetails: (state: any, component: any) => {
          this.submitAdditionalDetails(state, component);
        },

        // Global configuration for onSubmit

        // Your function calling your server to make the `/payments` request
        //   makePayment(state.data)
        //
        //     .then(response => {
        //
        //       if (response.action) {
        //
        //         // Drop-in handles the action object from the /payments response
        //
        //         dropin.handleAction(response.action);
        //
        //       } else {
        //
        //         // Your function to show the final result to the shopper
        //
        //         showFinalResult(response);
        //
        //       }
        //
        //     })
        //
        //     .catch(error => {
        //
        //       throw Error(error);
        //
        //     });
        //
        // },
        //
        // onAdditionalDetails: (state, dropin) => {
        //
        //   // Your function calling your server to make a `/payments/details` request
        //
        //   makeDetailsCall(state.data)
        //
        //     .then(response => {
        //
        //       if (response.action) {
        //
        //         // Drop-in handles the action object from the /payments response
        //
        //         dropin.handleAction(response.action);
        //
        //       } else {
        //
        //         // Your function to show the final result to the shopper
        //
        //         showFinalResult(response);
        //
        //       }
        //
        //     })
        //
        //     .catch(error => {
        //
        //       throw Error(error);
        //
        //     });
        //
        paymentMethodsConfiguration: {

          card: { // Example optional configuration for Cards

            hasHolderName: true,

            holderNameRequired: true,

            enableStoreDetails: true,

            hideCVC: false, // Change this to true to hide the CVC field for stored cards

            name: 'Credit or debit card',

            // onSubmit: () => {
            // }, // onSubmit configuration for card payments. Overrides the global configuration.

          }

        }
      };

      const checkout = AdyenCheckout(configuration);
      const dropin = checkout.then(checkout => {
        checkout.create('dropin', {

          // Starting from version 4.0.0, Drop-in configuration only accepts props related to itself and cannot contain generic configuration like the onSubmit event.

          openFirstPaymentMethod:false

        }).mount('#dropin-container');
      })

    });
  }

  async initiatePayment(state: any, component: any) {
    try {
      console.log(state, component);
      state.data.returnUrl = 'http://localhost:4200/test';
      state.data.amount = {
        currency: 'EUR',
        value: 1000
      };
      this.apiPaymentService.initiatePayment(state.data).subscribe((res) => {
        this.handleServerResponse(res, component);
      });
    } catch (error) {
      console.error(error);
      alert('Error occurred. Look at console for details');
    }
  }

  async submitAdditionalDetails(state: any, component: any) {
    try {
      this.apiPaymentService.submitAdditionalDetails(state.data).subscribe((res) => {
        this.handleServerResponse(res, component);
      });
    } catch (error) {
      console.error(error);
      alert('Error occurred. Look at console for details');
    }
  }

  handleServerResponse(res: any, component: any) {
    if (res.action) {
      component.handleAction(res.action);
    } else {
      switch (res.resultCode) {
        case 'Authorised':
          this.router.navigate(['/result/success']);
          break;
        case 'Pending':
        case 'Received':
          this.router.navigate(['/result/pending']);
          break;
        case 'Refused':
          this.router.navigate(['/result/failed']);
          break;
        default:
          this.router.navigate(['/result/error']);
          break;
      }
    }
  }

  testSubscribe() {
    this.loaded.next('loaded ' + this.i);
    this.i++;
  }
}

