<div>
  <canvas
    baseChart
    #teamPersonalProgressLineChart
    type="line"
    [datasets]="lineChartData"
    [options]="lineChartOptions"
    [legend]="false"
  >
  </canvas>
</div>
