<div class="measurement-block-container" [ngClass]="['progress-' + measurementTileModel.progression,
                                                     'quality-' + measurementTileModel.limitIndication,
                                                     applyBoxShadow ? 'apply-box-shadow' : '',
                                                     caregiverDashboard ? 'caregiver-dashboard' : '',
                                                     cssClass]">

  <ng-container *ngIf="!hideTitle">
    <div *ngIf="measurementIcon" class="measurement-icon" [inlineSVG]="measurementIcon"></div>
    <div class="top-section">
      {{ measurementTitle }}
    </div>
  </ng-container>

  <div class="middle-section">
    <span class="measurement" [innerHTML]="valueString | unit:unitString:true"></span>
  </div>

  <div class="bottom-section">
    <div
      *ngIf="measurementTileModel.progression !== MeasurementProgressionType.NONE"
      class="pointing-arrow"
      [ngClass]="['progression-' + measurementTileModel?.progression, 'progression-indication-' + measurementTileModel?.progressionIndication]"
    >
    </div>
    <div>{{ qualityInterpretationString }}</div>
  </div>
</div>

