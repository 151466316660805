import { Chart } from 'chart.js';

export function setChartDefaults(): void {
  Chart.defaults.color = '#8D8489';
  Chart.defaults.font.family = 'Museo Sans';
  Chart.defaults.font.size = 12;
  Chart.defaults.font.weight = '700';
  Chart.defaults.responsive = true;
  Chart.defaults.devicePixelRatio = window.devicePixelRatio;
}
