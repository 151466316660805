import { Component } from '@angular/core';

@Component({
  selector: 'skip-button',
  styleUrls: ['./skip-button.component.scss'],
  template: `
  <div class="skip-button-base">
    {{ 'SHARED.SKIP' | translate }}
  </div>
  `,
})
export class SkipButtonComponent {
}
