<div class="card-wrapper">
  <div class="card">
    <div class="card-title" *ngIf="!hideTitle">
      <div class="card-title-text">
        <ng-content select="[cardTitle]"></ng-content>
      </div>
<!--      <options-button></options-button>-->
    </div>
    <div class="card-content" [ngClass]="{'no-padding': noPadding, 'display-grid': displayGrid, 'rounded-edges': noFooter && hideTitle}" [style.min-height]="disableMinHeight ? '0px' : '300px'">
      <ng-content select="[cardContent]"></ng-content>
    </div>
    <div class="card-footer" *ngIf="!noFooter">
      <ng-content select="[cardFooter]"></ng-content>
    </div>
  </div>
</div>
