import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ItemToSearch } from '../input-search/input-search.component';

export interface SearchResult {
  id: string;
  city: string;
  imgPath: string;
  title: string;
}

@Component({
  selector: 'patient-portal-search',
  templateUrl: './patient-portal-search.component.html',
  styleUrls: ['./patient-portal-search.component.scss']
})
export class PatientPortalSearchComponent implements OnInit {
  @Input() searchResults: SearchResult[] = [
    {
      id: '123',
      city: 'Amsterdam',
      title: 'Henk Veenstra',
      imgPath: '../../../assets/img/brand_logo.png'
    },
    {
      id: '1312',
      city: 'Utrecht',
      title: 'Piet Jansen',
      imgPath: '../../../assets/img/brand_logo.png'
    },
    {
      id: '411',
      city: 'Delft',
      title: 'Dirk Achterdam',
      imgPath: '../../../assets/img/brand_logo.png'
    },
  ];
  searchResultsToShow: SearchResult[];
  // Emit number of results
  @Output() onUpdateNumberOfShowedResults = new EventEmitter<number>();

  constructor() {
  }

  itemsToSearch: ItemToSearch[] = [];

  ngOnInit(): void {
    this.searchResultsToShow = this.searchResults;
    this.itemsToSearch = this.searchResults.map(result => ({id: result.id, query: result.title}));
  }

  onSearchInputChanged(results: ItemToSearch[]) {
    this.searchResultsToShow = results.length ? results.map(result => this.searchResults.find(searchResult => searchResult.id === result.id)) : this.searchResults;
  }
}
