<!-- Error Title -->
<h2 class="error-title">{{'ERROR_PAGE.TITLE' | translate}}</h2>
<!-- Error Description -->
<h4 class="error-description">{{'ERROR_PAGE.DESCRIPTION' | translate}}</h4>
<!-- Error Image -->
<img class="error-image" [src]="imageSrc" alt="">
<!-- Error Tip -->
<h4 *ngIf="description" class="error-tip">{{ description }}</h4>

<div *ngIf="extraInformation" class="error-extra-info" [innerHtml]="extraInformation"></div>

<!-- Buttons -->
<ng-container *ngFor="let button of pageButtons">
  <app-button
  *ngIf="button.click"
  (click)="button.click()"
  class="error-try-again-button error-button"
  [styles]="[getAppButtonStyle(button.buttonStyle)]"
  [buttonText]="button.buttonTextTranslateKey | translate"></app-button>

  <a *ngIf="button.routerLink" [routerLink]="button.routerLink" class="error-button">
    <app-button [styles]="[getAppButtonStyle(button.buttonStyle)]" [buttonText]="button.buttonTextTranslateKey| translate"></app-button>
  </a>

  <a *ngIf="button.href" [href]="button.href" class="error-button">
    <app-button [styles]="[getAppButtonStyle(button.buttonStyle)]" [buttonText]="button.buttonTextTranslateKey| translate"></app-button>
  </a>
</ng-container>

<!-- Links -->
<ng-container *ngFor="let link of links">
  <a class="contact-customer-service-button" [href]="link.href" target="{{ link.openInNewWindow ? '_blank' : '_self'}}">
    <h4>{{link.linkTextTranslateKey| translate}}</h4>
  </a>
</ng-container>