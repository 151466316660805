<ngx-smart-modal
  [identifier]="identifier"
  customClass="include-padding"
  (onOpenFinished)="onOpen()"
  [closable]="false"
>
  <div *ngIf="!memberPermissionsDataLoaded" class="member-permissions-loading">
    {{ 'SHARED.LOADING_DOT_DOT_DOT' | translate}}
  </div>
  <div *ngIf="memberPermissionsDataLoaded" [formGroup]="connectionPermissionsForm">
    <div class="title-close-button-wrapper">
      <h3 class="connection-permissions-title">{{ 'NETWORK.CONNECTIONS.CONNECTION_PERMISSIONS.TITLE' | translate }}</h3>
      <div (click)="modal.close(identifier)" class="close-button" inlineSVG="icn_close.svg"></div>
    </div>
    <div class="connection-permissions-header">
      <p class="connection-permissions-instructions">{{ 'NETWORK.CONNECTIONS.CONNECTION_PERMISSIONS.CONNECTION_INSTRUCTIONS' | translate }}</p>
    </div>
    <div class="connection-permissions-section">
      <toggle-checkbox-item formControlName="startCompetition" class="start-competition-checkbox">
        <div class="connection-permissions-competition-wrapper">
          <div class="connection-permissions-profile-pic-wrapper">
            <img src="assets/img/img_default_profile.png" alt="Profile picture" class="connection-permissions-profile-pic">
          </div>
          <div class="connection-permissions-text-wrapper">
            <p class="connection-permissions-name">{{ member.name }}</p>
            <p class="connection-permissions-competition" [ngClass]="{ 'start-competition': !isCompetitionEnabled }">{{ (isCompetitionEnabled ? 'NETWORK.CONNECTIONS.CONNECTION_PERMISSIONS.STOP_COMPETITION' : 'NETWORK.CONNECTIONS.CONNECTION_PERMISSIONS.START_COMPETITION') | translate }}</p>
          </div>
        </div>
      </toggle-checkbox-item>
    </div>
    <div class="connection-permissions-section" formGroupName="myMeasurements">
      <h4 class="connection-permissions-section-title">{{ 'NETWORK.CONNECTIONS.CONNECTION_PERMISSIONS.MY_MEASUREMENTS' | translate }}</h4>
      <toggle-checkbox-item formControlName="MEDICAL">
        <div class="connection-permissions-option-wrapper">
          <p class="connection-permissions-option-name">{{ 'DASHBOARD.HAMBURGER_MENU.MEASUREMENTS_SUB_MENU.MEDICAL_MEASUREMENTS' | translate }}</p>
          <p class="connection-permissions-enabled-options" (click)="modal.open('addMeasurementPermissions')" *ngIf="enabledMeasurementStrings.MEDICAL_MEASUREMENTS">{{ enabledMeasurementStrings.MEDICAL_MEASUREMENTS }}<span class="navigation-arrow"></span></p>
        </div>
      </toggle-checkbox-item>
      <toggle-checkbox-item formControlName="LIFESTYLE">
        <div class="connection-permissions-option-wrapper">
          <p class="connection-permissions-option-name">{{ 'DASHBOARD.HAMBURGER_MENU.MEASUREMENTS_SUB_MENU.LIFESTYLE_MEASUREMENTS' | translate }}</p>
          <p class="connection-permissions-enabled-options" (click)="modal.open('addMeasurementPermissions')" *ngIf="enabledMeasurementStrings.LIFESTYLE_MEASUREMENTS">{{ enabledMeasurementStrings.LIFESTYLE_MEASUREMENTS }}<span class="navigation-arrow"></span></p>
        </div>
      </toggle-checkbox-item>
      <toggle-checkbox-item formControlName="PHYSICAL">
        <div class="connection-permissions-option-wrapper">
          <p class="connection-permissions-option-name">{{ 'DASHBOARD.HAMBURGER_MENU.MEASUREMENTS_SUB_MENU.PHYSICAL_MEASUREMENTS' | translate }}<span class="navigation-arrow"></span></p>
          <p class="connection-permissions-enabled-options" (click)="modal.open('addMeasurementPermissions')" *ngIf="enabledMeasurementStrings.PHYSICAL_MEASUREMENTS">{{ enabledMeasurementStrings.PHYSICAL_MEASUREMENTS }}<span class="navigation-arrow"></span></p>
        </div>
      </toggle-checkbox-item>
      <toggle-checkbox-item formControlName="BLOOD">
        <div class="connection-permissions-option-wrapper">
          <p class="connection-permissions-option-name">{{ 'NETWORK.CONNECTIONS.CONNECTION_PERMISSIONS.BLOOD' | translate }}</p>
          <p class="connection-permissions-enabled-options" (click)="modal.open('addMeasurementPermissions')" *ngIf="enabledMeasurementStrings.BLOOD_MEASUREMENTS">{{ enabledMeasurementStrings.BLOOD_MEASUREMENTS }}<span class="navigation-arrow"></span></p>
        </div>
      </toggle-checkbox-item>
      <toggle-checkbox-item formControlName="MENTAL">
        <div class="connection-permissions-option-wrapper">
          <p class="connection-permissions-option-name">{{ 'NETWORK.CONNECTIONS.CONNECTION_PERMISSIONS.MENTAL_HEALTH' | translate }}</p>
          <p class="connection-permissions-enabled-options" (click)="modal.open('addMeasurementPermissions')" *ngIf="enabledMeasurementStrings.MENTAL_MEASUREMENTS">{{ enabledMeasurementStrings.MENTAL_MEASUREMENTS }}<span class="navigation-arrow"></span></p>
        </div>
      </toggle-checkbox-item>
      <toggle-checkbox-item formControlName="CUSTOM">
        <div class="connection-permissions-option-wrapper">
          <p class="connection-permissions-option-name">{{ 'NETWORK.CONNECTIONS.CONNECTION_PERMISSIONS.CUSTOM' | translate }}</p>
          <p class="connection-permissions-enabled-options" (click)="modal.open('addMeasurementPermissions')" *ngIf="enabledMeasurementStrings.CUSTOM_MEASUREMENTS">{{ enabledMeasurementStrings.CUSTOM_MEASUREMENTS }}<span class="navigation-arrow"></span></p>
        </div>
      </toggle-checkbox-item>
    </div>
    <div class="connection-permissions-button-wrapper">
      <app-button
        (click)="saveConnectionPermissionsData()"
        [disabled]="!connectionPermissionsForm.dirty && !enabledMeasurements.dirty"
        [includeLeftCheckmarkIcon]="true"
        [buttonText]="'SHARED.SAVE' | translate"
      ></app-button>
      <app-button
        (click)="modal.open('removeConnectionPopup')"
        [styles]="BUTTON_STYLES.secondary"
        [buttonText]="'NETWORK.CONNECTIONS.CONNECTION_PERMISSIONS.REMOVE_CONNECTION' | translate"
      ></app-button>
    </div>
    <add-measurement-permissions-modal
      (onSaveMeasurements)="refreshData()"
      [formGroup]="enabledMeasurements"
      [name]="member.name"
    ></add-measurement-permissions-modal>
  </div>
</ngx-smart-modal>

<remove-connection-popup (onRemoveMember)="removeConnection()"></remove-connection-popup>
