import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms';
import {
  containsDigit,
  containsEmoji,
  containsSymbol,
  containsUppercase,
  isAsianChar,
  emailRegex,
  containsLowercase,
} from './regExUtil';
import { group } from '@angular/animations';

export type CheckboxRequiredErrors = {
  checkboxRequired: boolean;
};

export function checkboxRequiredValidator(): ValidatorFn {
  return (control: AbstractControl): CheckboxRequiredErrors | null => {
    // console.log(control, 'control')
    const forbidden = control.value !== true;
    return forbidden ? {checkboxRequired: true} : null;
  };
}

export type PasswordSafetyErrors = {
  atLeast8Char: boolean;
  atLeast1UpperCase: boolean;
  atLeast1LowerCase: boolean;
  atLeast1Digit: boolean;
  atLeast1Symbol: boolean;
};

export function passwordSafetyValidator(): ValidatorFn {
  return (control: AbstractControl): PasswordSafetyErrors | null => {
    const requirements: PasswordSafetyErrors = {
      atLeast8Char: false,
      atLeast1UpperCase: false,
      atLeast1LowerCase: false,
      atLeast1Digit: false,
      atLeast1Symbol: false,
    };
    const value = control.value as string;

    requirements.atLeast8Char = value.length < 8;
    requirements.atLeast1UpperCase = !containsUppercase(value);
    requirements.atLeast1LowerCase = !containsLowercase(value);
    requirements.atLeast1Digit = !containsDigit(value);
    requirements.atLeast1Symbol = !containsSymbol(value);

    const forbidden = Object.values(requirements).includes(true);
    return forbidden ? requirements : null;
  };
}

export type PasswordEqualErrors = {
  passwordEqual: boolean;
};

export function passwordEqualValidator(controlName?: string, controlNameRepeat?: string): ValidatorFn {
  return (control: AbstractControl): PasswordEqualErrors | null => {
    controlName = !controlName ? 'password' : controlName;
    controlNameRepeat = !controlNameRepeat ? 'passwordRepeat' : controlNameRepeat;
    const forbidden = control.get(controlName).value !== control.get(controlNameRepeat).value;
    forbidden ? control.get(controlNameRepeat).setErrors({passwordEqual: true}) : control.get(controlNameRepeat).setErrors(null);
    return forbidden ? {passwordEqual: true} : null;
  };
}

export type NameErrors = {
  nameValid: boolean;
};

export function nameValidator(): ValidatorFn {
  return (control: AbstractControl): NameErrors | null => {
    const name = control.value as string;
    const firstChar = name.charAt(0);

    const forbidden = !(
      name.length > 0 &&
      // check if first char of firstname / lastname is a 'letter' (latin and asian alphabets)
      (
        firstChar.toUpperCase() != firstChar.toLowerCase() ||
        isAsianChar(firstChar)
      )
      // check firstname and lastname do not contain emojis and digits
      && !containsEmoji(name)
      && !containsDigit(name)
    );

    return forbidden ? {nameValid: true} : null;
  };
}

export type ValueEqualsErrors = {
  passwordEqual: boolean;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function valueEqualsValidator(compare: any): ValidatorFn {
  return (control: AbstractControl): ValueEqualsErrors | null => {
    const forbidden = control.value !== compare;
    return forbidden ? {passwordEqual: true} : null;
  };
}

export type EmailErrors = {
  emailValid: boolean;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function emailValidator(): ValidatorFn {
  return (control: AbstractControl): EmailErrors | null => {
    const forbidden = !emailRegex.test(control.value);
    return forbidden ? {emailValid: true} : null;
  };
}

export type GlobalEmailErrors = {
  email: boolean;
  termsConditions: boolean;
  rewardsConditions?: boolean;
};

export function globalEmailValidator(includeRewardsConditions = false): ValidatorFn {
  return (control): GlobalEmailErrors | null => {
    const requirements: GlobalEmailErrors = {
      email: !emailRegex.test(control.get('emailAddress').value),
      termsConditions: !control.get('termsConditionsCheckbox').value
    };
    // if(includeRewardsConditions) {
    //   requirements['rewardsConditions'] = !control.get('rewardsConditionsCheckbox').value
    // }
    return Object.values(requirements).includes(true) ? requirements : null;
  };
}

export type ResearchErrors = {
  researchValid: boolean;
};

export const researchValidator: ValidatorFn = (control: AbstractControl): ResearchErrors | null => {
  const overSixteenYears = control.get('isPlusSixteen').value;
  const isGuardian = control.get('isGuardian').value;
  const statementOfConsent = control.get('statementOfConsent').value;
  return overSixteenYears && statementOfConsent ? null : !overSixteenYears && isGuardian && statementOfConsent ? null : {researchValid: true};
};

interface DateError {
  invalid: boolean | null;
  belowMinimum: boolean | null;
  aboveMaximum: boolean | null;
}


export function dateValidator(minDate: Date = null, maxDate: Date = null, required: boolean = true): ValidatorFn {
  return (control): {[key: string]: any} => {
    const errors: DateError = {
      invalid: false,
      belowMinimum: false,
      aboveMaximum: false,
    };

    if (!required && !control.value) {
      return errors;
    }

    // split on '-'
    const dateSplit = control.value.split('-');
    // todo support '/' slash?

    if (dateSplit.length < 3 || !dateSplit[2] || dateSplit[2].length !== 4 || !dateSplit[1] || !dateSplit[0]) {
      errors.invalid = true;
      return errors;
    }

    const parsedDate = new Date(dateSplit[2], dateSplit[1] - 1, dateSplit[0]);
    if (!parsedDate) {
      errors.invalid = true;
      return errors;
    }

    if (maxDate && parsedDate > maxDate) {
      errors.aboveMaximum = true;
      return errors;
    }
    if (minDate && parsedDate < minDate) {
      errors.belowMinimum = true;
      return errors;
    }

    return null;
  };
}

export function atLeastOneControlTrue(): ValidatorFn {
  return (control) => {
    const enabledControlNames: string[] = [];
    for (const groupChild in control.value) {
      if (control.get(groupChild).value) enabledControlNames.push(groupChild);
    }
    return enabledControlNames.length > 0 ? null : {noGroupChildSelected: true};
  };
}

interface StringValueErrors {
  invalid: boolean | null
}
export function nonEmptyStringValidator(): ValidatorFn {
  return (control): {[key: string]: any} => {
    const errors: StringValueErrors = {
      invalid: false,
    };

    if (!control.value || control.value.trim() === '') {
      errors.invalid = true;
      return errors;
    }

    return null;
  };
}

interface ChangesRequiredError {
  changesRequired: boolean;
}

/**
 * For requiring changes on the basis of a source object.
 *
 * IIFE serves to encapsulate the helper function.
 */
export const changesRequired = (() => {
  /**
   * Very generic function which checks for equality between two objects on the basis of equal amount of entries
   * and equal values.
   */
  function shallowObjectsAreIdentical(obj1: Record<string, unknown>, obj2: Record<string, unknown>) {
    const equalLength = Object.entries(obj1).length === Object.entries(obj2).length;
    const equalEntries = Object.keys(obj1).every(key => obj1[key] === obj2[key]);
    return equalEntries && equalLength;
  }

  return (controlObjGetter: () => Record<any, any>): ValidatorFn => (form) => {
      const noChangesMade = shallowObjectsAreIdentical(controlObjGetter(), form.value);
      return noChangesMade ? {
        changesRequired: true
      } : null;
  };
})();
