import {
  Component,
  ElementRef,
  forwardRef,
  HostListener,
  Input, OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { PremiumService } from '../../utils/premiumService';

const IMAGE_MIME_TYPES = ['image/jpeg','image/png'];

@Component({
  selector: 'file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FileUploaderComponent),
    multi: true
  }]
})
export class FileUploaderComponent implements OnInit, OnChanges, ControlValueAccessor {
  @ViewChild('buttonFileRef') buttonFileRef: ElementRef;

  @Input() fieldName: string;
  @Input() placeholder: string;
  @Input() accept: null | string = null;
  @Input() isMultiple = false;
  @Input() maxFileSize = 5; //in MB
  @Input() icon?: string;
  @Input() callback?: Function;
  @Input() isDisabled = false;
  @Input() originalImage: null | string;
  @Input() fileResetter = 0;
  @Input() isPremiumFeature = false;

  public file: File = null;
  public onChange: Function;
  public error = '';
  public acceptList: string[] = [];
  // preview image
  public imagePreview: null | string | ArrayBuffer;


  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList): void {
    // console.log('change event target files');
    const file = event && event.item(0);
    this.onChange(file);
    this.file = file;
  }

  constructor(
    private host: ElementRef<HTMLInputElement>,
    private translate: TranslateService,
    private premiumService: PremiumService
  ) {
  }

  ngOnInit(): void {
    if (this.isMultiple) {
      this.loadFile = this.loadFileMultiple;
      this.validateFile = this.validateFileMultiple;
    }
    if (this.placeholder === undefined || this.placeholder === null) {
      this.translate.get('SHARED.INPUT_PLACEHOLDER_FILE').subscribe(response => this.placeholder = response);
    }
    // after the function it will be an array like: ['png', 'jpg', 'svg', ...]
    this.acceptList = this.accept.split(',').map(acceptType => acceptType.substr(1));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fileResetter) {
      this.imagePreview = null;
    }

    if (changes.originalImage) {
      this.imagePreview = changes.originalImage.currentValue;
    }
  }

  public loadFile(t: EventTarget): void {
    const target = t as HTMLInputElement;
    // define data
    const file: File = target.files[0];

    // validate
    this.validateFile(file);
    if (this.error) {
      this.file = file;
      // call the parent if needed
      this.updateParent(file);
    }
    if (IMAGE_MIME_TYPES.includes(file.type)) {
      const fileReader = new FileReader();
      fileReader.addEventListener('load', () => {
        // console.log('image preview loaded (base64 enc)', fileReader.result);
        this.imagePreview = fileReader.result;
      }, false);
      if (file) fileReader.readAsDataURL(file);
    }
  }

  public loadFileMultiple(t: EventTarget): void {
    const target = t as HTMLInputElement;
    // define data
    const files = target.files;
    // call the parent if needed
    this.updateParent(files);
  }

  public validateFile(file: any): void {
    if (file.size > this.maxFileSize * 1024 * 1024) {
      this.error = this.translate.instant('ERROR.FILE_SIZE', {size: this.maxFileSize});
      return;
    }
    // this will be e.g. image/png
    const extension = file.name.split('.').pop();

    if (!this.acceptList.find(acceptType => acceptType === extension)) {
      this.error = this.translate.instant('ERROR.FILE_TYPE');
      return;
    }
    this.error = '';
  }

  public validateFileMultiple(files: FileList): boolean {
    // todo: implement if multiple upload is supported
    return true;
  }

  // update parent component
  public updateParent(data: any): void {
    if (this.callback) {
      this.callback(data);
    }
  }

  // public clearFile(t: EventTarget): void {
  //   const target = t as HTMLInputElement;
  //   // clear the value to trigger change event for uploading the same image
  //   target.value = null;
  //   this.file = null;
  //   this.onChange(null);
  // }

  writeValue(obj: any): void {
    // clear file input
    this.host.nativeElement.value = '';
    this.file = null;

    if (!obj && !this.originalImage) this.imagePreview = null;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }

  showPremiumPopup() {
    if(this.isDisabled && this.isPremiumFeature) {
      return this.premiumService.openPremiumModal();
    }
  }
}
