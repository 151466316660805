<hamburger-menu
  [ngClass]="hamburgerMenuOpen ? 'opened' : ''"
  (OnCloseHamburgerMenu)="toggleHamburgerMenu()"
  [toggleHamburgerButtonHtmlId]="hamburgerToggleButtonId"
  [menuOpened]="hamburgerMenuOpen"
>
</hamburger-menu>
<div
  *ngIf="hamburgerIconShown"
  [id]="hamburgerToggleButtonId"
  (click)="toggleHamburgerMenu()"
  class="hamburger-open-button"
  inlineSVG="bttn_hamburgermenu.svg"
>
</div>

<router-outlet></router-outlet>
<connection-lost-popup [deviceName]="connectionLostSupplier"></connection-lost-popup>

<nav-bottom-menu *ngIf="!hasNativeMenu"></nav-bottom-menu>

<consent-update-modal [type]="ConsentTypes.TermsAndConditions"></consent-update-modal>
