import {Component, Input} from '@angular/core';
import {BUTTON_STYLES} from "../../button/button.component";
import {ConsentTypes} from "../../../models/consentTypes";
import {NgxSmartModalService} from "ngx-smart-modal";

@Component({
  selector: 'confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {
    @Input() identifier = 'confirmModal';
    @Input() title: string;
    @Input() text: string;

    protected readonly BUTTON_STYLES = BUTTON_STYLES;

    constructor(public modal: NgxSmartModalService) {

    }
}
