import {Component, Input} from '@angular/core';

@Component({
  selector: 'warning-box',
  templateUrl: './warning-box.component.html',
  styleUrls: ['./warning-box.component.scss']
})
export class WarningBoxComponent {
  @Input() warningText = "WARNING_TEXT";
}
