<ngx-smart-modal
  [identifier]="modalId"
  [hideDelay]="200"
  [closable]="false"
  (onAnyCloseEvent)="beforeModalClose()"
  (onAnyCloseEventFinished)="modalCloseFinished()"
  customClass="include-padding"
  class="measurement-details-modal"
>
<!--  (onOpen)="getMeasurementTypeLegendData()"-->
  <div class="measurement-top-bar">
    <div *ngIf="measurementSvg" class="measurement-svg" [inlineSVG]="measurementSvg"></div>
    <h4 class="measurement-type-title">{{ measurementTypeTitle }}</h4>
    <div *ngIf="supplier"
         class="measurement-top-bar-supplier-icon"
         [ngStyle]="{'background-image': 'url(' + supplierIconService.getIconSourceForSupplier(supplier) +')'}"
    >
    </div>
    <div class="close-button" inlineSVG="icn_close.svg" (click)="modal.get('MEASUREMENT_MODAL').close()"></div>
  </div>
  <tabbed-content-fake-tab
    [tabs]="timePeriodTabs"
    [activeTabId]="timePeriodSelected"
    (tabChanged)="changeTimePeriodSelected($event)"
  >
    <div class="chart-section">
      <div class="chart-measurement-details-section">
        <div class="latest-measurement-section">
          <div class="latest-measurement-section">
            {{ 'MEASUREMENT_DETAILS.LATEST_MEASUREMENT' | translate }}
          </div>
          <div>

            <div class="chart-selection-container" (click)="modal.open('chartSelection')">
              <div class="svg-container" [inlineSVG]="currentChartIcon"></div>
              <p class="current-chart-selection-text">{{ selectedChartTypeTranslationKey | translate }}</p>
              <div class="expand-arrow"></div>
              <select-dropdown
                identifier="chartSelection"
                [orangeArrowBoxIncluded]="false"
                (onOptionSelected)="changeChartType($event.id)"
                [selectableOptions]="timePeriodSelected === TimePeriods.MONTH ? selectableChartOptionsMonth : selectableChartOptions"
                [selectedOption]="{ id: chartOrCalendarSelected, label: chartOrCalendarSelected }"
                dropdownTitle="MEASUREMENT_DETAILS.CHARTS.SELECT_CHART"
                buttonText="SHARED.SELECT"></select-dropdown>
            </div>
          </div>
        </div>
        <div *ngIf="measurementTypeId" class="latest-measurement">
          <span class="measurement" [innerHTML]="latestMeasurementValue | unit:measurementUnitString:true:null:dataPointsWithinXAxisTimeSpan"></span>
        </div>
        <div class="date-range-section">
          {{ timeSpanString }}
<!--          <div *ngIf="timePeriodSelected !== TimePeriod.DAY && chartOrCalendarSelected !== 'calendar'" class="trendline-section">-->
<!--            <checkbox-->
<!--              [labelText]="'MEASUREMENT_DETAILS.CHARTS.VIEW_TRENDLINE' | translate"-->
<!--              [(ngModel)]="trendlineActivated"-->
<!--              [disabled]="chartData && chartData.dataPoints && chartData.dataPoints.length < MINIMUM_DATA_POINTS_FOR_TRENDLINE"-->
<!--              (ngModelChange)="onToggleTrendline()"-->
<!--            ></checkbox>-->
<!--            <p *ngIf="chartData && chartData.dataPoints && chartData.dataPoints.length < MINIMUM_DATA_POINTS_FOR_TRENDLINE" class="error-message" [innerHTML]="'MEASUREMENT_DETAILS.CHARTS.TRENDLINE_TOO_LITTLE_MEASUREMENTS' | translate"></p>-->
<!--          </div>-->
        </div>
      </div>

      <div class="chart-container">
        <!--          [ngClass]="['line', 'bar'].includes(chartOrCalendarSelected) ? '' : 'display-none'"-->
        <measurement-chart
          [ngClass]="chartOrCalendarSelected === 'line' || chartOrCalendarSelected === 'bar' ? '' : 'display-none'"
          [measurementTypeId]="measurementTypeId"
          [measurementUnitString]="measurementUnitString"
          [measurementSvg]="measurementSvg"
          [atalmedialMeasurement]="atalmedialMeasurement"
          [forContactId]="forContactId"
          [isCaregiver]="isCaregiver"
          [chartData]="chartData"
          [chartTypeSelected]="chartTypeSelected"
          [trendLineActivated]="trendLineActivated"
          (panCompletedEvent)="chartPanCompletedEvent($event)"
          (tooltipToggled)="toggleTooltipBackdrop($event)"
          (onAddNote)="setEditedMeasurementId($event)"
        >
        </measurement-chart>
        <div class="calendar-chart-wrapper">
          <calendar
            *ngIf="chartOrCalendarSelected === 'calendar'"
            [calendarData]="calendarData"
            calendarName="measurementCalendar"
            (calendarDayClicked)="onCalendarDayClicked($event)"
            (cameraPanCompletedEvent)="calendarPanCompletedEvent($event)"
          >
          </calendar>
        </div>
      </div>
    </div>
    <div class="aggregate-data-wrapper">
      <div class="aggregate-data-blocks-wrapper" *ngIf="!atalmedialMeasurement">
        <aggregate-data-block [dataBlockType]="AggregateDataBlock.MINIMUM" [value]="aggregateData.min" [unit]="measurementUnitString" [unitAsDuration]="true"></aggregate-data-block>
        <aggregate-data-block [dataBlockType]="AggregateDataBlock.AVERAGE" [value]="aggregateData.average" [unit]="measurementUnitString" [unitAsDuration]="true"></aggregate-data-block>
        <aggregate-data-block [dataBlockType]="AggregateDataBlock.MAXIMUM" [value]="aggregateData.max" [unit]="measurementUnitString" [unitAsDuration]="true"></aggregate-data-block>
      </div>
      <div class="measurement-text-wrapper">
        <p class="measurement-text">{{ 'MEASUREMENT_DETAILS.NUMBER_OF_MEASUREMENTS' | translate }}{{ thisTimePeriodString ? ' ' + thisTimePeriodString : '' }}: <span class="measurement-value">{{ totalAmountOfMeasurements }}</span></p>
        <p class="measurement-text">{{ 'MEASUREMENT_DETAILS.LATEST_MEASUREMENT' | translate }}:
          <span class="measurement-value" [innerHTML]="latestMeasurementValue | unit:measurementUnitString:true:null:dataPointsWithinXAxisTimeSpan"></span>
        </p>
      </div>
    </div>
<!--    <accordion class="accordion">-->
<!--      <div header>-->
<!--        <div class="accordion-header">-->
<!--          <div class="accordion-img" inlineSVG="/measurements/details/icn_fire.svg"></div>-->
<!--          <h4 class="accordion-title">{{'MEASUREMENT_DETAILS.COMPARATIVE_SUMMARY' | translate}}</h4>-->
<!--        </div>-->
<!--      </div>-->
<!--      <p style="margin-top: 20px; margin-bottom: 30px; text-align: left">Komt eraan in versie 2.1!</p>-->
<!--      <measurement-comparative-summary-->
<!--        *ngIf="timePeriodSelected !== TimePeriod.ALL"-->
<!--        [measurementTypeId]="measurementTypeId"-->
<!--        [comparativeSummaryDataset]="chartData && chartData.dataPoints ? chartData.dataPoints : []"-->
<!--        [timespanSelected]="timePeriodSelected"-->
<!--      >-->
<!--      </measurement-comparative-summary>-->
<!--    </accordion>-->
    <accordion class="accordion">
      <div header>
        <div class="accordion-header">
          <div class="accordion-img" [inlineSVG]="'/measurements/details/icn_info.svg'"></div>
          <h4 class="accordion-title">{{'MEASUREMENT_DETAILS.LEGEND_MEASUREMENT_COLOURS' | translate}}</h4>
        </div>
      </div>
      <p class="legend-description">{{ 'MEASUREMENT_DETAILS.LEGEND_DESCRIPTION' | translate }}</p>
      <chart-legend [legendData]="legendData"></chart-legend>
    </accordion>
    <floating-menu
      *ngIf="includeFloatingMenu && !isCaregiver"
      class="measurement-details-floating-menu"
      [floatingMenuItems]="floatingMenuItems"
      floatingMenuIcon="icn_addsteps.svg"
      [ngClass]="floatingMenuOpen ? 'opened' : ''"
      [menuOpened]="floatingMenuOpen"
      baseTranslationId="MEASUREMENT_DETAILS.FLOATING_MENU"
      [measurementTypeId]="measurementTypeId"
      [customId]="customId"
      [isRelativeToWidescreen]="false"
      (onFloatingMenuToggle)="toggleFloatingMenu()"
    >
    </floating-menu>
  </tabbed-content-fake-tab>

  <div #tooltipBackdrop class="tooltip-backdrop"></div>

</ngx-smart-modal>

<!--<div class="measurement-details-wide-screen">-->
<!--&lt;!&ndash;  todo navigate to home or #14 (measurements)&ndash;&gt;-->
<!--  <div class="go-back-to-overview-wrapper" (click)="location.back()">-->
<!--    <div class="go-back-icon" inlineSVG="icn_arrow.svg"></div>-->
<!--    <div class="go-back-text">{{ 'MEASUREMENT_DETAILS.RETURN_TO_OVERVIEW' | translate }}</div>-->
<!--  </div>-->
<!--  <div class="measurement-top-bar">-->
<!--    <div *ngIf="measurementSvg" class="measurement-svg" [inlineSVG]="measurementSvg"></div>-->
<!--    <h4 class="measurement-type-title">{{ measurementTypeTitle }}</h4>-->
<!--  </div>-->
<!--  <tabbed-content-fake-tab-->
<!--    [tabs]="timePeriodTabs"-->
<!--    [activeTabId]="timePeriodSelected"-->
<!--    (tabChanged)="changeTimePeriodSelected($event)"-->
<!--  >-->
<!--    <div class="measurement-details-content-wrapper">-->
<!--      <div class="measurement-details-left-side">-->
<!--        <div class="chart-section">-->
<!--          <div class="chart-measurement-details-section">-->
<!--            <div class="latest-measurement-section">-->
<!--              <div class="latest-measurement-section">-->
<!--                {{ 'MEASUREMENT_DETAILS.LATEST_MEASUREMENT' | translate }}-->
<!--              </div>-->
<!--              <div>-->

<!--                <div class="chart-selection-container" (click)="modal.open('chartSelection')">-->
<!--                  <div class="svg-container" [inlineSVG]="currentChartIcon"></div>-->
<!--                  <p class="current-chart-selection-text">{{ selectedChartTypeTranslationKey | translate }}</p>-->
<!--                  <div class="expand-arrow"></div>-->
<!--&lt;!&ndash;                  <select-dropdown&ndash;&gt;-->
<!--&lt;!&ndash;                    identifier="chartSelection"&ndash;&gt;-->
<!--&lt;!&ndash;                    [orangeArrowBoxIncluded]="false"&ndash;&gt;-->
<!--&lt;!&ndash;                    [selectableOptions]="timePeriodSelected === TimePeriods.MONTH ? selectableChartOptionsMonth : selectableChartOptions"&ndash;&gt;-->
<!--&lt;!&ndash;                    [selectedOption]="{ id: chartOrCalendarSelected, label: chartOrCalendarSelected }"&ndash;&gt;-->
<!--&lt;!&ndash;                    (onOptionSelected)="changeChartType($event.id)"&ndash;&gt;-->
<!--&lt;!&ndash;                    dropdownTitle="MEASUREMENT_DETAILS.CHARTS.SELECT_CHART"&ndash;&gt;-->
<!--&lt;!&ndash;                    buttonText="SHARED.SELECT"&ndash;&gt;-->
<!--&lt;!&ndash;                  >&ndash;&gt;-->
<!--&lt;!&ndash;                  </select-dropdown>&ndash;&gt;-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div *ngIf="measurementTypeId" class="latest-measurement">-->
<!--              <span class="measurement">{{ latestMeasurementValue }}</span>-->
<!--              <sup *ngIf="measurementUnitString && dataPointsWithinXAxisTimeSpan" class="unit">{{ measurementUnitString }}</sup>-->
<!--            </div>-->
<!--            <div class="date-range-section">-->
<!--              {{ timeSpanString }}-->
<!--              &lt;!&ndash;          <div *ngIf="timePeriodSelected !== TimePeriod.DAY && chartOrCalendarSelected !== 'calendar'" class="trendline-section">&ndash;&gt;-->
<!--              &lt;!&ndash;            <checkbox&ndash;&gt;-->
<!--              &lt;!&ndash;              [labelText]="'MEASUREMENT_DETAILS.CHARTS.VIEW_TRENDLINE' | translate"&ndash;&gt;-->
<!--              &lt;!&ndash;              [(ngModel)]="trendlineActivated"&ndash;&gt;-->
<!--              &lt;!&ndash;              [disabled]="chartData && chartData.dataPoints && chartData.dataPoints.length < MINIMUM_DATA_POINTS_FOR_TRENDLINE"&ndash;&gt;-->
<!--              &lt;!&ndash;              (ngModelChange)="onToggleTrendline()"&ndash;&gt;-->
<!--              &lt;!&ndash;            ></checkbox>&ndash;&gt;-->
<!--              &lt;!&ndash;            <p *ngIf="chartData && chartData.dataPoints && chartData.dataPoints.length < MINIMUM_DATA_POINTS_FOR_TRENDLINE" class="error-message" [innerHTML]="'MEASUREMENT_DETAILS.CHARTS.TRENDLINE_TOO_LITTLE_MEASUREMENTS' | translate"></p>&ndash;&gt;-->
<!--              &lt;!&ndash;          </div>&ndash;&gt;-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="chart-container">-->
<!--            &lt;!&ndash;          [ngClass]="['line', 'bar'].includes(chartOrCalendarSelected) ? '' : 'display-none'"&ndash;&gt;-->
<!--            <measurement-chart-->
<!--              [ngClass]="chartOrCalendarSelected === 'line' || chartOrCalendarSelected === 'bar' ? '' : 'display-none'"-->
<!--              [measurementTypeId]="measurementTypeId"-->
<!--              [measurementUnitString]="measurementUnitString"-->
<!--              [measurementSvg]="measurementSvg"-->
<!--              [chartData]="chartData"-->
<!--              [chartTypeSelected]="chartTypeSelected"-->
<!--              [trendLineActivated]="trendLineActivated"-->
<!--              (panCompletedEvent)="chartPanCompletedEvent($event)"-->
<!--              (tooltipToggled)="toggleTooltipBackdrop($event)"-->
<!--            >-->
<!--            </measurement-chart>-->
<!--            <div class="calendar-chart-wrapper">-->
<!--              <calendar-->
<!--                *ngIf="chartOrCalendarSelected === 'calendar'"-->
<!--                [calendarData]="calendarData"-->
<!--                [calendarName]="'measurementCalendar'"-->
<!--                (calendarDayClicked)="onCalendarDayClicked($event)"-->
<!--                (cameraPanCompletedEvent)="calendarPanCompletedEvent($event)"-->
<!--              >-->
<!--              </calendar>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="aggregate-data-wrapper">-->
<!--          <div class="aggregate-data-blocks-wrapper">-->
<!--            <aggregate-data-block [dataBlockType]="AggregateDataBlock.MINIMUM" [value]="aggregateData.min"></aggregate-data-block>-->
<!--            <aggregate-data-block [dataBlockType]="AggregateDataBlock.AVERAGE" [value]="aggregateData.average"></aggregate-data-block>-->
<!--            <aggregate-data-block [dataBlockType]="AggregateDataBlock.MAXIMUM" [value]="aggregateData.max"></aggregate-data-block>-->
<!--          </div>-->
<!--          <div class="measurement-text-wrapper">-->
<!--            <p class="measurement-text">{{ 'MEASUREMENT_DETAILS.NUMBER_OF_MEASUREMENTS' | translate }}{{ thisTimePeriodString ? ' ' + thisTimePeriodString : '' }}: <span class="measurement-value">{{ totalAmountOfMeasurements }}</span></p>-->
<!--            <p class="measurement-text">{{ 'MEASUREMENT_DETAILS.LATEST_MEASUREMENT' | translate }}: <span class="measurement-value">{{ latestMeasurementValue }}</span>-->
<!--              <sup *ngIf="measurementUnitString && dataPointsWithinXAxisTimeSpan" class="unit">{{ measurementUnitString }}</sup>-->
<!--            </p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="measurement-details-right-side">-->
<!--        <accordion class="accordion">-->
<!--          <div header>-->
<!--            <div class="accordion-header">-->
<!--              <div class="accordion-img" inlineSVG="/measurements/details/icn_fire.svg"></div>-->
<!--              <h4 class="accordion-title">{{'MEASUREMENT_DETAILS.COMPARATIVE_SUMMARY' | translate}}</h4>-->
<!--            </div>-->
<!--          </div>-->
<!--          <p style="margin-top: 20px; margin-bottom: 30px; text-align: left">Coming soon in version 2.1!</p>-->
<!--          &lt;!&ndash;      <measurement-comparative-summary&ndash;&gt;-->
<!--          &lt;!&ndash;        *ngIf="timePeriodSelected !== TimePeriod.ALL"&ndash;&gt;-->
<!--          &lt;!&ndash;        [measurementTypeId]="measurementTypeId"&ndash;&gt;-->
<!--          &lt;!&ndash;        [comparativeSummaryDataset]="chartData && chartData.dataPoints ? chartData.dataPoints : []"&ndash;&gt;-->
<!--          &lt;!&ndash;        [timespanSelected]="timePeriodSelected"&ndash;&gt;-->
<!--          &lt;!&ndash;      >&ndash;&gt;-->
<!--          &lt;!&ndash;      </measurement-comparative-summary>&ndash;&gt;-->
<!--        </accordion>-->
<!--        <accordion class="accordion">-->
<!--          <div header>-->
<!--            <div class="accordion-header">-->
<!--              <div class="accordion-img" [inlineSVG]="'/measurements/details/icn_info.svg'"></div>-->
<!--              <h4 class="accordion-title">{{'MEASUREMENT_DETAILS.LEGEND_MEASUREMENT_COLOURS' | translate}}</h4>-->
<!--            </div>-->
<!--          </div>-->
<!--          <p class="legend-description">{{ 'MEASUREMENT_DETAILS.LEGEND_DESCRIPTION' | translate }}</p>-->
<!--          <chart-legend [legendData]="legendData"></chart-legend>-->
<!--        </accordion>-->
<!--      </div>-->
<!--    </div>-->
<!--  </tabbed-content-fake-tab>-->

<!--  <floating-menu-->
<!--    class="measurement-details-floating-menu"-->
<!--    [floatingMenuItems]="floatingMenuItems"-->
<!--    floatingMenuIcon="icn_addsteps.svg"-->
<!--    [ngClass]="floatingMenuOpen ? 'opened' : ''"-->
<!--    [menuOpened]="floatingMenuOpen"-->
<!--    baseTranslationId="MEASUREMENT_DETAILS.FLOATING_MENU"-->
<!--    [measurementTypeId]="measurementTypeId"-->
<!--    (onFloatingMenuToggle)="toggleFloatingMenu()"-->
<!--  >-->
<!--  </floating-menu>-->

<!--  <div #tooltipBackdrop class="tooltip-backdrop"></div>-->
<!--</div>-->

<add-measurement-data-popup
  [measurementTypeId]="measurementTypeId"
  [customId]="customId"
  [savedMeasurementId]="editedMeasurementId"
  (measurementSaved)="onMeasurementSaved($event)"
></add-measurement-data-popup>
<measurement-settings
  *ngIf="includeFloatingMenu"
  [customId]="customId"
  [measurementTypeId]="measurementTypeId"
  [measurementSvg]="measurementSvg"
  [measurementUnitString]="measurementUnitString"
  [measurementTypeTitle]="measurementTypeTitle"
  (measurementSavedEmitter)="onMeasurementSaved($event)"
></measurement-settings>
