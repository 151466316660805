<h1>Welcome to the TestPage!</h1>

<button (click)="testMakePayment()">test make payment</button>

<div id="dropin-container"></div>


<!--<button (click)="testSubscribe()">test</button>-->

<!--<h1>Display grid test</h1>-->
<!--<style>-->
<!--  .block-container {-->
<!--    display: grid;-->
<!--    grid-gap: 10px;-->
<!--    grid-template-columns: repeat(auto-fit,minmax(80px, 1fr));-->
<!--  }-->
<!--  .block {-->
<!--    border: 1px solid red;-->
<!--    height: 80px;-->
<!--    display: flex;-->
<!--    align-items: center;-->
<!--    justify-content: center;-->
<!--  }-->
<!--</style>-->

<!--<div class="block-container">-->
<!--  <div class="block">-->
<!--    123-->
<!--  </div>-->
<!--  <div class="block">-->
<!--    123-->
<!--  </div>-->
<!--</div>-->


