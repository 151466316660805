import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';
import { TranslateService } from '@ngx-translate/core';
import { PersonalProgress, ProgressSummary } from '../../../models/game/challengeResponse';
import TeamProgress from '../../../../shared/models/game/teamProgress';
import { setChartDefaults } from '../chartjs';
import zoomPlugin from 'chartjs-plugin-zoom';
import {Chart} from "chart.js";

@Component({
  selector: 'team-personal-progress-chart',
  templateUrl: './team-personal-progress-chart.component.html',
  styleUrls: ['./team-personal-progress-chart.component.scss'],
})
export class TeamPersonalProgressChartComponent implements OnInit, OnChanges {
  @ViewChild('teamPersonalProgressLineChart') teamPersonalProgressLineChart: ElementRef;
  @ViewChild(BaseChartDirective) public chart: BaseChartDirective;

  @Input() chartData: ProgressSummary[] = [];
  @Input() personalProgress: PersonalProgress;
  @Input() teamProgress: TeamProgress;
  @Input() measurementTypeString: string;

  crownIconImageElementPositive: HTMLImageElement = new Image();
  crownIconImageElementNegative: HTMLImageElement = new Image();

  yAxesLabelString = '';
  public lineChartOptions: any = {
    // public chartConfig: (ChartOptions & { annotation: any }) = {
    // zoom: {
    //   enabled: true,
    //   mode: 'x',
    //   // mode: 'xy',
    //   sensitivity: 3,
    //   speed: 0.1,
    //   // @ts-ignore
    //   onZoomComplete: (): void => {
    //     this.onPanCompleted();
    //   }
    // },
    scales: {
      x: {
        // offset: true,
        type: 'time',
        // type: 'linear',
        // bounds: 'data', // this means data appear in between gridlines
        distribution: 'linear',
        // distribution: 'series',
        // bounds: 'ticks', // this means data points are averaged out to the nearest axis grid line
        // stacked: true,
        beginAtZero: true,
        time: {
          stepSize: 1,
          // minUnit: 'hour',
          // unit: 'millisecond',
          // unit: 'second',
          // unit: 'minute',
          // unit: 'hour',
          // unit: 'week',
          // unit: 'month',
          // unit: 'quarter',
          // unit: 'year',
          // stepSize: 2 // label every x hours
          unit: 'day',
          displayFormats: {
            day: 'D/M',
          },
          isoWeekday: true,
        },
        title: {
          display: true,
          text: this.translate.instant('MEASUREMENT_DETAILS.TIME_INTERVALS.DAYS').toUpperCase(),
          // padding: 14
        },
        grid: {
          display: false
        },
      },
      y: {
        type: 'linear',
        position: 'left',
        ticks: {
          // stepSize: 3,
          // min: 0,
          // max: 100,
          // suggestedMin: 2.5,
          // suggestedMax: 10.0,
          // padding: 20
        },
        title: {
          display: true,
          text: 'temp name',
        },
        grid: {
          borderDash: [3, 2],
          color: '#8D8489',
          drawBorder: false,
        },
      },
    },
    responsive: true,
    // events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
    events: null,
    layout: {
      padding: {
        left: 5,
        right: 5,
        top: 0,
        bottom: 0,
      },
    },
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    plugins: {
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
          threshold: 50,
          speed: 1,
        },
      },
    }
  };

  constructor(
    public translate: TranslateService,
  ) {
  }

  ngOnInit(): void {
    setChartDefaults();
    Chart.register(...[
      zoomPlugin,
    ]);
    this.loadDataPointSvgForGraph();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('graph changes', changes);
    if (changes.measurementTypeString && changes.measurementTypeString.currentValue) {
      this.lineChartOptions.scales.y.title.text = changes.measurementTypeString.currentValue.toUpperCase();
    }
    if (changes.chartData && changes.chartData.currentValue && changes.teamProgress && changes.teamProgress.currentValue) {
      this.formatGraphData(changes.chartData.currentValue, changes.personalProgress.currentValue, changes.teamProgress.currentValue);
    }
  }

  // note: we need to do it this way since the chart is a Canvas.
  loadDataPointSvgForGraph(): void {
    const baseUrl = `${window.location.protocol}//${window.location.host}`;
    this.crownIconImageElementNegative.src = `${baseUrl}/assets/img/games/icn_crown-gray.svg`;
    this.crownIconImageElementPositive.src = `${baseUrl}/assets/img/games/icn_crown-yellow.svg`;
  }

  getPointSvgPersonal = (context: any): HTMLImageElement | string => {
    const dataPoint = context.dataset.data[context.dataIndex];
    if (!dataPoint) {
      return this.crownIconImageElementNegative;
    }
    if (dataPoint.y < this.personalProgress.targetToday) {
      return this.crownIconImageElementNegative;
    }
    return this.crownIconImageElementPositive;
  };

  getPointSvgTeam = (context: any): HTMLImageElement | string => {
    const dataPoint = context.dataset.data[context.dataIndex];
    if (!dataPoint) {
      return this.crownIconImageElementNegative;
    }
    if (dataPoint.y < this.teamProgress.targetToday) {
      return this.crownIconImageElementNegative;
    }
    return this.crownIconImageElementPositive;
  };

  public lineChartData: any = [
    // personal dataset
    {
      data: [], // note: filled once data streams in
      fill: false,
      pointStyle: this.getPointSvgPersonal,
      borderColor: '#0C377F',
      tension: 0.4
    },
    // team dataset
    {
      data: [], // note: filled once data streams in
      fill: false,
      pointStyle: this.getPointSvgTeam,
      borderColor: '#1D70FF',
      tension: 0.4
    },
    // personal target line
    {
      data: [],
      fill: false,
      borderColor: '#BBD4FF',
      borderWidth: 10
    },
    // team target line
    {
      data: [],
      fill: false,
      borderColor: '#B6C3D8',
      borderWidth: 10
    },
  ];

  renderGraph(): void {
    // a neat trick to force rerender due to object contents not being fully reactive
    this.lineChartOptions = {...this.lineChartOptions};
  }

  formatGraphData(dataset: ProgressSummary[], personalProgress: PersonalProgress, teamProgress: TeamProgress): void {
    const personalLineGraphDataset = [];
    const teamLineGraphDataset = [];
    for (const i in dataset) {
      const dayDataPoint = dataset[i];
      const dayDate = new Date(dayDataPoint.date);
      dayDate.setHours(0);
      dayDate.setMinutes(0);
      dayDate.setSeconds(0);
      const personalDataPoint = {
        x: dayDate,
        y: dayDataPoint.personalValue,
      };
      const teamDataPoint = {
        x: dayDate,
        y: dayDataPoint.teamValue,
      };
      personalLineGraphDataset.push(personalDataPoint);
      teamLineGraphDataset.push(teamDataPoint);
    }
    this.lineChartData[0].data = personalLineGraphDataset;
    this.lineChartData[1].data = teamLineGraphDataset;
    // todo: use annotation plugin
    this.lineChartData[2].data = [{
      x: new Date(1990, 0, 1),
      y: personalProgress.targetToday,
    }, {x: new Date(2029, 0, 1), y: personalProgress.targetToday}];
    this.lineChartData[3].data = [{
      x: new Date(1990, 0, 1),
      y: teamProgress.targetToday,
    }, {x: new Date(2029, 0, 1), y: teamProgress.targetToday}];

    if(personalLineGraphDataset.length) {
      this.lineChartOptions.scales.x.min = personalLineGraphDataset[0].x;
      this.lineChartOptions.scales.x.max = personalLineGraphDataset[personalLineGraphDataset.length - 1].x;
    }

    this.renderGraph();
  }
}
