<div
  #measurementCategoryBlocksWrapper
  [formGroup]="form"
  [ngClass]="{ 'small-headers': smallHeaders}"
  class="measurement-category-blocks-wrapper"
>
  <div
    *ngFor="let measurementCategory of MEASUREMENT_CATEGORIES_SELECTION"
    [formGroupName]="measurementCategory"
    class="measurement-section"
  >
    <ng-container *ngIf="MEASUREMENT_TYPES[measurementCategory].length">
      <h4 class="measurement-section-title">{{ (measurementCategory === 'MENTAL_MEASUREMENTS' ? 'DASHBOARD.ELEMENTS.POSITIVE_HEALTH' : 'DASHBOARD.ELEMENTS.' + measurementCategory) | translate}}</h4>
      <div
        class="measurements-blocks-wrapper"
      >
        <set-measurements-block
          *ngFor="let measurementType of MEASUREMENT_TYPES[measurementCategory]"
          [customTypes]="measurementCategory === 'CUSTOM_MEASUREMENTS' ? customTypes : []"
          [formControlName]="measurementType.id"
          [measurementCategoryId]="measurementCategory"
          [addedToDashboard]="measurementType.addedToDashboard"
          [blockId]="measurementType.id"
          [customName]="measurementType.customName"
          [icon]="measurementType.icon"
          (click)="onChange(measurementType.id, measurementCategory, measurementType.addedToDashboard)"
        ></set-measurements-block>
      </div>
    </ng-container>
  </div>
</div>
