<ngx-smart-modal
  [identifier]="addCustomMeasurementButton ? 'customTypeModal' : 'measurementSettingsPopup'"
  [closable]="false"
  [hideDelay]="200"
  (onOpen)="fetchMeasurementTypeSettings()"
  (onAnyCloseEvent)="resetModal()"
  customClass="include-padding"
>
  <div
    class="close-button"
    inlineSVG="icn_close.svg"
    (click)="modal.getModal(addCustomMeasurementButton ? 'customTypeModal' : 'measurementSettingsPopup').close()"
  ></div>
  <div class="title-wrapper">
    <div *ngIf="measurementSvg" class="measurement-svg" [inlineSVG]="measurementSvg"></div>
    <h2 class="main-title">{{ measurementTypeTitle }} <span *ngIf="!isCustomMeasurement">{{ 'MEASUREMENT_SETTINGS.TITLE' | translate }}</span></h2>
  </div>
  <section class="custom-sensortype-section">
    <form *ngIf="customSensorForm" [formGroup]="customSensorForm">
      <div class="field-wrapper">
        <div class="title-field">
          {{ 'SHARED.NAME' | translate }} *
        </div>
        <input-text
          id="name-input"
          [backgroundStyle]="'grey'"
          type="text"
          formControlName="NAME"
          placeholder="{{ 'SHARED.NAME' | translate }}"
        >
        </input-text>
      </div>

      <div class="field-wrapper">
        <div class="title-field">
          {{ 'MEASUREMENT_SETTINGS.UNIT' | translate }} *
        </div>
        <input-text
          id="unit-input"
          [backgroundStyle]="'grey'"
          type="text"
          formControlName="UNIT"
          placeholder="{{ 'MEASUREMENT_SETTINGS.UNIT' | translate }}"
        >
        </input-text>
      </div>
    </form>
  </section>
  <section class="values-section">
    <div class="title-reset-wrapper">
      <h3 class="section-title">{{'MEASUREMENT_SETTINGS.LIMIT_VALUES.TITLE' | translate }}</h3>
      <div
        (click)="resetToDefault()"
        class="reset-button"
      >
        {{'MEASUREMENT_SETTINGS.RESET' | translate }}
      </div>
<!--      [ngClass]="{ 'disabled': !areFormsDirty }"-->
    </div>
    <form *ngIf="limitsForm" [formGroup]="limitsForm">
      <div *ngFor="let formControlId of fromControlArray" class="value-row">
        <label class="value-label">{{'MEASUREMENT_DETAILS.QUALITY.' + formControlId | translate }}</label>
        <input
          class="value-section-input"
          type="number"
          [formControlName]="formControlId"
          placeholder="{{ 'SHARED.ENTER_VALUE' | translate }}"
        >
      </div>
    </form>
  </section>
  <section class="settings-section" *ngIf="!isCustomMeasurement">
    <h3 class="section-title">{{ 'MEASUREMENT_SETTINGS.SETTINGS.TITLE' | translate }}</h3>
    <form [formGroup]="settingsForm">
<!--      *ngFor="let setting of settingsData.settings"-->
      <toggle-checkbox-item
        [isChecked]="settingsForm.get('NOTIFY').value"
        formControlName="NOTIFY"
      >
        <label class="value-label">{{ 'MEASUREMENT_SETTINGS.SETTINGS.NOTIFY' | translate: { measurementTypeString: measurementTypeTitle } }}</label>
      </toggle-checkbox-item>
    </form>
  </section>

  <p class="error-message" style="margin-top: 20px; min-height: 40px" [ngStyle]="{'visibility': formError ? 'visible' : 'hidden'}">
    {{ formError }}
  </p>

  <div class="bottom-button-container">
    <app-button
      [buttonText]="'MEASUREMENT_SETTINGS.SAVE' | translate"
      [includeLeftCheckmarkIcon]="true"
      [disabled]="!limitsForm || !limitsForm.valid || !limitsForm.dirty || (!!formError) || (customSensorForm && !customSensorForm.valid)"
      class="save-button"
      (click)="saveMeasurementSettings()"
    ></app-button>
    <app-button
      *ngIf="!addCustomMeasurementButton"
      [buttonText]="'MEASUREMENT_SETTINGS.REMOVE_MEASUREMENT_TYPE' | translate"
      [styles]="'button-secondary'"
      class="save-button"
      (click)="modal.getModal(confirmDeleteMeasurementTypeModalId).open()"
    ></app-button>
  </div>

  <ngx-smart-modal
    [identifier]="confirmDeleteMeasurementTypeModalId"
    [closable]="false"
    [hideDelay]="200"
    customClass="include-padding"
  >
    <div
      class="close-button"
      inlineSVG="icn_close.svg"
      (click)="modal.getModal(confirmDeleteMeasurementTypeModalId).close()"
    ></div>
    <div class="title-wrapper-delete-modal">
      <h2 class="main-title">{{ 'MEASUREMENT_SETTINGS.DELETE_MEASUREMENT_TYPE' | translate }}</h2>
    </div>
    <section class="delete-measurement-type-section">
      <p>
        {{ 'MEASUREMENT_SETTINGS.DELETE_MEASUREMENT_DESCRIPTION' | translate }}
      </p>
    </section>
    <div class="bottom-button-container">
      <app-button
        [buttonText]="'MEASUREMENT_SETTINGS.DELETE_MEASUREMENT_BUTTON' | translate"
        class="save-button"
        (click)="removeMeasurementType()"
      ></app-button>
    </div>

  </ngx-smart-modal>

</ngx-smart-modal>


