import {Component, Input, OnInit} from '@angular/core';
import {NgxSmartModalService} from "ngx-smart-modal";
import {ApiIdentityService} from "../../../api/api-identity.service";
import {ConsentTypes, UpdateConsentRequest} from "../../../models/consentTypes";
import {BUTTON_STYLES} from "../../button/button.component";
import {OidcService} from "../../../../oidc/oidc.service";
import {Router} from "@angular/router";

@Component({
  selector: 'consent-update-modal',
  templateUrl: './consent-update-modal.component.html',
  styleUrls: ['./consent-update-modal.component.scss']
})
export class ConsentUpdateModalComponent implements OnInit {
  @Input() type: ConsentTypes;
  @Input() identifier = 'consentUpdateModal';
  BUTTON_STYLES = BUTTON_STYLES;

  ConsentTypes = ConsentTypes;

  constructor(readonly modal: NgxSmartModalService,
              private oidService: OidcService,
              private apiIdentityService: ApiIdentityService,
              private router: Router) { }

  async ngOnInit(): Promise<void> { }

  async agreeConsent() {
    let request: UpdateConsentRequest = {
      type: this.type,
      accepted: true
    };
    await this.apiIdentityService.updateConsent(request);
    this.modal.close(this.identifier);
  }

  async disagreeConsent() {
    let request: UpdateConsentRequest = {
      type: this.type,
      accepted: false
    };
    await this.apiIdentityService.updateConsent(request);
    this.modal.close(this.identifier);

    if(this.type === ConsentTypes.TermsAndConditions) {
      await this.oidService.logout();
    }
    if(this.type === ConsentTypes.RewardsConditions) {
      await this.router.navigateByUrl('/dashboard/games/competition')
    }
  }
}
