<div class="floating-content-wrapper" [ngClass]="{ 'widescreen': isRelativeToWidescreen }">
  <div (click)="toggleFloatingMenu()" *ngIf="!menuOpened" class="floating-menu-button open">
    <img [src]="'/assets/img/floating-menu/' + ( floatingMenuIcon ? floatingMenuIcon : 'bttn_extraoption.svg')" [ngStyle]="{ width: isSvg(floatingMenuIcon) ? 'auto' : '100%' }">
  </div>
  <div (click)="toggleFloatingMenu()" *ngIf="menuOpened" class="floating-menu-button closed">
    <img src="/assets/img/icn_close.svg">
  </div>

  <div class="floating-menu-wrapper">
    <nav *ngIf="menuOpened" @toggleAnimation>
      <div
        *ngFor="let floatingMenuItem of floatingMenuItems"
        [ngClass]="[floatingMenuItem.positionClass, !isPremium && floatingMenuItem.isPremium ? 'disabled' : '']"
        [routerLink]="floatingMenuItem.routerLink && (isPremium || (!floatingMenuItem.isPremium && !isPremium)) ? floatingMenuItem.routerLink : null"
        (click)="handleClickFloatingMenuItem(floatingMenuItem.modalId ? floatingMenuItem.modalId : null, !!floatingMenuItem.isPremium, floatingMenuItem.id)"
        class="floating-menu-item"
      >
        <div class="floating-item-icon-wrapper" *ngIf="!floatingMenuItem.hasBackground">
          <div class="floating-item-icon" [inlineSVG]="'/floating-menu/' + floatingMenuItem.icon"></div>
        </div>
        <img *ngIf="floatingMenuItem.hasBackground" class="floating-item-icon" [src]="'/assets/img/floating-menu/' + floatingMenuItem.icon">
        <div class="floating-menu-text">
          {{ floatingMenuItem.id === 'MEASUREMENT_TYPE_SETTINGS' ? (customId ? customId : measurementTypeString) + ' ' + ( baseTranslationId + '.' + floatingMenuItem.id | translate ) : baseTranslationId + '.' + floatingMenuItem.id | translate }}
        </div>
      </div>
    </nav>
  </div>
</div>

<div
  @toggleAnimation
  *ngIf="menuOpened"
  class="floating-menu-overlay"
  (click)="toggleFloatingMenu()">
</div>
