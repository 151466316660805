import { Component, Input, OnInit } from '@angular/core';
import { BUTTON_STYLES } from 'src/app/shared/components/button/button.component';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Router } from '@angular/router';
import {MeasurementHubService} from "../../shared/api/hubs/measurement-hub.service";

@Component({
  selector: 'connection-lost-popup',
  templateUrl: './connection-lost-popup.component.html',
  styleUrls: ['./connection-lost-popup.component.scss']
})
export class ConnectionLostPopupComponent implements OnInit {
  @Input() deviceName: string;

  readonly BUTTON_STYLES = BUTTON_STYLES;
  modalId = 'connectionLostPopup';
  settingsSourcesUrl = '/dashboard/settings/sources';

  constructor(
    readonly modal: NgxSmartModalService,
    private router: Router,
    private measurementHubService: MeasurementHubService
  ) {}

  ngOnInit() {
  }

  async goToSources() {
    await this.measurementHubService.acknowledgeSupplierDisconnect(this.deviceName)
    this.modal.close(this.modalId);
    await this.router.navigateByUrl(this.settingsSourcesUrl);
  }

  async closeModal() {
    await this.measurementHubService.acknowledgeSupplierDisconnect(this.deviceName)
    this.modal.close(this.modalId);
  }
}
