import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class SupplierIconService {
    getIconSourceForSupplier(supplierName: string) : string {
        return environment.cdnBaseUrl + 'Suppliers/' + supplierName + '.png'
    }
}