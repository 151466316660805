<div class="small-dropdown-wrapper">
  <div class="selected-option" (click)="toggleDropdown()">
    <p class="selected-option-name">{{ selectedOption }}</p>
    <div class="expand-arrow" [ngClass]="{ 'opened': isOpened }"></div>
  </div>
  <div class="other-dropdown-options" [ngClass]="{ opened: isOpened }" #dropdownOptions>
    <div (click)="onChanged(option)" *ngFor="let option of otherOptions" class="dropdown-option">
      <p class="dropdown-option-name">{{ option }}</p>
    </div>
  </div>
</div>
