<div class="main-wrapper">
  <ng-content select="[main]"></ng-content>
</div>
<nav class="tabs" #tabbedContentNav>
  <div
    *ngFor="let tab of tabs"
    class="tab"
    (click)="selectTab(tab)"
    [style.display]="tab.hideTab ? 'none': 'flex'"
    [ngClass]="{ 'active': tab.isActive }"
  >
    <div class="tab-title-wrapper">
      <h4 class="tab-title">{{ tab.name | translate }}</h4>
      <div [ngClass]="{ 'active': tab.isActive }" class="line"></div>
    </div>
    <div *ngIf="tab.counterValue" class="tab-counter-wrapper">{{ tab.counterValue }}</div>
  </div>
</nav>
<ng-content></ng-content>
