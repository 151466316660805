<ngx-smart-modal [identifier]="identifier" customClass="include-padding" (onClose)="setMeasurements()" [closable]="false">
  <div (click)="modal.close(identifier)" class="close-button" inlineSVG="icn_close.svg"></div>
  <div class="add-measurement-title-description-wrapper">
    <h2 class="add-measurement-title">{{ 'NETWORK.CONNECTIONS.CONNECTION_PERMISSIONS.ADD_MEASUREMENT' | translate }}</h2>
    <p class="add-measurement-description">{{ 'NETWORK.CONNECTIONS.CONNECTION_PERMISSIONS.CHOOSE_MEASUREMENT' | translate: { name: name } }}</p>
  </div>
  <measurement-category-blocks [formGroup]="form" [modalId]="identifier" [hideCustomAddButton]="true" (onMeasurementSelected)="checkAddedToDashboard($event)"></measurement-category-blocks>
  <div class="add-measurement-save-button-wrapper">
    <app-button
      [disabled]="!form.dirty"
      [buttonText]="'SHARED.SAVE' | translate"
      [includeLeftCheckmarkIcon]="true"
      (click)="setMeasurements()"></app-button>
  </div>
</ngx-smart-modal>
<ngx-smart-modal
  [identifier]="'notAddedToDashboardWarning'"
  [closable]="false"
>
  <div class="close-button" inlineSVG="icn_close.svg" (click)="modal.close('notAddedToDashboardWarning')"></div>
  <div class="warning-container">
    <h3 class="warning-title">{{'ERROR.WARNING.TITLE' | translate}}</h3>
    <div class="warning-description-wrapper">
      {{'ERROR.WARNING.NOT_ADDED_TO_DASHBOARD' | translate: {name: name} }}
    </div>
    <div class="modal-button-wrapper">
      <app-button
        class="warning-ok-button"
        [buttonText]="'SHARED.OK' | translate"
        [styles]="[BUTTON_STYLES.primary]"
        (click)="modal.close('notAddedToDashboardWarning')"
      ></app-button>
    </div>
  </div>
</ngx-smart-modal>
