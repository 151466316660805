export default interface UserProfile {
  userId: string;
  isAuthenticated: boolean;
  cultureCode: string;
  language: string;

  generalInformation: {
    firstName: string;
    lastName: string;
    gender: string;
    nationality: string;
    dateOfBirth: string;
    email: string;
    phoneNumber: string;
    memberSince: string;
    profilePicture: string;
    subscriptionState: SubscriptionState;
  };
  healthcareProvider: {
    insuredBy: string;
    policyNumber: string;
  };
  medicalBackground: MedicalBackground;
}

interface MedicalBackground {
  bloodType: string;
  donorshipTypes: string[];
  donorshipTypeIds: string[];
  eyesightMeasurement: {
    left: {
      strength: number;
      cylinder: number;
      as: number;
      pupilDistance: number;
    };
    right: {
      strength: number;
      cylinder: number;
      as: number;
      pupilDistance: number;
    };
  };
  hearingCheckup: {
    lastCheckupDate: string;
    status: string;
  };
  height: number;
  dietaryRequirements: string;
  supplements: string;
  pregnancy: boolean;
}

export const emptyHealtcareProvider = {
  insuredBy: '',
  policyNumber: ''
};

export const emptyMedicalBackground: MedicalBackground = {
  bloodType: '',
  donorshipTypes: [],
  donorshipTypeIds: [],
  eyesightMeasurement: {
    left: {
      strength: 0,
      cylinder: 0,
      as: 0,
      pupilDistance: 0
    },
    right: {
      strength: 0,
      cylinder: 0,
      as: 0,
      pupilDistance: 0
    }
  },
  hearingCheckup: {
    lastCheckupDate: null,
    status: ''
  },
  height: 0,
  dietaryRequirements: '',
  supplements: '',
  pregnancy: false
};

export interface UserProfileReqBody {
  generalInformation: {
    firstName: string;
    lastName: string;
    gender: string;
    nationality: string;
    dateOfBirth: string;
    email: string;
    phoneNumber: string;
  };
  healthcareProvider: {
    insuredBy: string;
    policyNumber: string;
  };
  medicalBackground: {
    bloodType: string;
    donorshipTypeIds: string[];
    eyesightMeasurement: {
      left: {
        strength: number;
        cilinder: number;
        as: number;
        pupilDistance: number;
      };
      right: {
        strength: number;
        cilinder: number;
        as: number;
        pupilDistance: number;
      };
    };
    hearingCheckup: {
      lastCheckupDate: string;
      status: string;
    };
    height: number;
    dietaryRequirements: string;
    supplements: string;
    pregnancy: boolean;
  };
}

export enum SubscriptionState {
  None = 'None',
  Freemium = 'Freemium',
  Trial = 'Trial',
  Premium = 'Premium',
  Pro= 'Pro'
}
