import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ControlContainer, UntypedFormGroup } from '@angular/forms';
import { NgxSmartModalService } from 'ngx-smart-modal';
import {
  MeasurementTypeClickedEvent
} from "../../../components/measurement-category-blocks/measurement-category-blocks.component";
import {BUTTON_STYLES} from "../../../components/button/button.component";

@Component({
  selector: 'add-measurement-permissions-modal',
  templateUrl: './add-measurement-permissions-modal.component.html',
  styleUrls: ['./add-measurement-permissions-modal.component.scss']
})
export class AddMeasurementPermissionsModalComponent implements OnInit {
  @Input() name: string;

  @Output() onSaveMeasurements = new EventEmitter();

  identifier = 'addMeasurementPermissions';
  form: UntypedFormGroup;
  BUTTON_STYLES = BUTTON_STYLES;

  constructor(
    readonly controlContainer: ControlContainer,
    readonly modal: NgxSmartModalService
  ) { }

  ngOnInit(): void {
    this.form = <UntypedFormGroup>this.controlContainer.control;
  }

  setMeasurements() {
    this.onSaveMeasurements.emit();
    this.modal.close(this.identifier);
  }

  checkAddedToDashboard($event: MeasurementTypeClickedEvent) {
    if(!$event.addedToDashboard) {
      this.modal.open('notAddedToDashboardWarning');
    }
  }
}
