import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.scss']
})
export class AdminHeaderComponent implements OnInit {

  @Input() companyName = 'Gemeente Rotterdam';
  @Input() careGiversLogin = false;
  options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  currentDate = new Date().toLocaleDateString('nl-NL', this.options);


  constructor() { }

  ngOnInit(): void {
  }

}
