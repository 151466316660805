import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, FormGroup } from '@angular/forms';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ApiService } from '../../../shared/api/api.service';

@Component({
  selector: 'app-dashboard-onboarding-wearables-apps',
  templateUrl: './dashboard-onboarding-wearables-apps.component.html',
  styleUrls: ['./dashboard-onboarding-wearables-apps.component.scss'],
})
export class DashboardOnboardingWearablesAppsComponent implements OnInit {

  constructor(
    readonly formBuilder: UntypedFormBuilder,
    readonly modal: NgxSmartModalService,
    readonly apiService: ApiService
  ) { }

  ngOnInit() {
  }
}

