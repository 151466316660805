import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl} from '@angular/forms';
import {emailValidator} from '../../../utils/formValidators';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {ApiCommunityService} from '../../../api/api-community.service';
import {TranslateService} from '@ngx-translate/core';
import {ApiHealthService} from "../../../api/api-health.service";
import LanguageThemeSettingsResponse from "../../../models/settings/languageThemeSettingsResponse";
import {ApiService} from "../../../api/api.service";
import {SelectableOption} from "../../../components/select-dropdown/select-dropdown.component";
import {Store} from "@ngrx/store";
import UserProfile from "../../../models/userProfile";
import {take} from "rxjs/operators";

@Component({
  selector: 'add-connection-popup',
  templateUrl: './add-connection-popup.component.html',
  styleUrls: ['./add-connection-popup.component.scss']
})
export class AddConnectionPopupComponent implements OnInit {
  email: string = null;
  @Output() onConnectionAdded = new EventEmitter();
  @Input() isCaregiver = false;

  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  identifier = 'addConnectionPopup';
  mailForm: UntypedFormControl;

  showErrorMessage = false;
  errorMessage = '';
  isFormValid = false;

  errorMessages: any = {
    CONNECTION_ALREADY_EXISTS: 'Connection already exists.',
    ADDING_YOURSELF_IS_NOT_SUPPORTED: 'Adding yourself is not supported.',
    ADDRESS_ALREADY_INVITED: 'This email address is already invited.'
  };

  languagesOptions: SelectableOption[];
  selectedLanguageCultureCode: string;
  invitesLocked = false;
  invitesLockedTime: number;
  lockedTimeInterval: any = null;

  constructor(
    readonly form: UntypedFormBuilder,
    readonly modal: NgxSmartModalService,
    readonly apiCommunityService: ApiCommunityService,
    readonly apiHealthService: ApiHealthService,
    readonly apiService: ApiService,
    private translate: TranslateService,
    private store: Store<{ 'userProfile': UserProfile }>
  ) {
  }

  get selectedLanguage() {
    if (this.languagesOptions) {
      return this.languagesOptions.find(languageOption => languageOption.id === this.selectedLanguageCultureCode);
    }
    return null;
  }

  async ngOnInit() {
    this.email = this.apiCommunityService.addConnectionByMail;
    this.mailForm = this.form.control(this.email ? this.email : '', {validators: emailValidator()});
    this.mailForm.valueChanges.subscribe(() => this.isFormValid = this.mailForm.valid);
    this.mailForm.updateValueAndValidity();

    if (this.isCaregiver) {
      await this.loadLanguages();
      this.store.pipe(take(1)).subscribe(userProfile => this.selectedLanguageCultureCode = userProfile.userProfile.cultureCode);
    }
  }

  focus() {
    this.input.nativeElement.focus();
  }

  async addConnection(): Promise<void> {
    if (this.isCaregiver) {
      await this.finalizeCaregiverDashboardConnection();
      await this.setInviteLockFields();
    } else {
      await this.finalizeDefaultContact();
    }
  }

  async loadLanguages() {
    const languageThemeSettingsResponse: LanguageThemeSettingsResponse = await this.apiService.getLanguageThemeSettings();
    this.selectedLanguageCultureCode = languageThemeSettingsResponse.selectedLanguageCode;

    this.languagesOptions = languageThemeSettingsResponse.languages.map(language => {
      const selectableOption: any = {};
      // Todo: Replace with the right label text
      selectableOption['label'] = this.translate.instant('SETTINGS.LANGUAGE_THEME.' + language.toUpperCase());
      selectableOption['id'] = language;
      return selectableOption;
    });
  }

  onLanguageSelected(option: SelectableOption) {
    this.selectedLanguageCultureCode = option.id;
  }

  onEmailChanged() {
    if (this.showErrorMessage) {
      this.showErrorMessage = false;
      this.errorMessage = null;
    }
  }

  async checkInviteLock() {
    if(this.isCaregiver) {
      await this.setInviteLockFields();

      this.lockedTimeInterval = setInterval(() => {
        this.invitesLockedTime -= 1;

        if (this.invitesLockedTime === 0) {
          clearInterval(this.lockedTimeInterval);
          this.invitesLocked = null;
        }
      }, 1000);
    }
  }

  clearInterval(interval: any) {
    clearInterval(interval);
  }

  private async finalizeCaregiverDashboardConnection() {
    await this.apiHealthService.addUser(this.mailForm.value, this.selectedLanguage.id).then(() => {
      this.modal.close(this.identifier);
      this.mailForm.setValue('');
      this.onConnectionAdded.emit();
    }, (error) => {
      const errorMessage = error.error.errorMessage;
      this.showErrorMessage = true;
      let errorTranslation = null;
      for (const key in this.errorMessages) {
        if (this.errorMessages[key] === errorMessage) {
          errorTranslation = this.translate.instant('ERROR.' + key);
          break;
        }
      }
      this.errorMessage = errorTranslation ? errorTranslation : errorMessage;
    });
  }

  private async finalizeDefaultContact() {
    await this.apiCommunityService.addContact({
      email: this.mailForm.value,
      isCareGiver: this.isCaregiver
    }).then(() => {
      this.modal.close(this.identifier);
      this.mailForm.setValue('');
      this.onConnectionAdded.emit();
    }, (error) => {
      const errorMessage = error.error.errorMessage;
      this.showErrorMessage = true;
      let errorTranslation = null;
      for (const key in this.errorMessages) {
        if (this.errorMessages[key] === errorMessage) {
          errorTranslation = this.translate.instant('ERROR.' + key);
          break;
        }
      }
      this.errorMessage = errorTranslation ? errorTranslation : errorMessage;
    });
  }

  private async setInviteLockFields() {
    let inviteLock = await this.apiHealthService.isHealthInviteLocked()
    this.invitesLocked = inviteLock.ttl && inviteLock.ttl > 0;
    this.invitesLockedTime = inviteLock.ttl === 0 ? null : inviteLock.ttl;
  }
}


