<ngx-smart-modal
  [identifier]="modalId"
  [closable]="false"
  [hideDelay]="200"
  (onAnyCloseEventFinished)="resetModal()"
>
  <form [formGroup]="addImageForm" class="form-add-image">
    <div class="add-image-panel-content">
      <div class="title-wrapper">
        <div class="icon-add" inlineSVG="icn_image.svg"></div>
        <h2 class="title-add-image">{{ 'DASHBOARD.PANEL.ADD_IMAGE_TITLE' | translate }}</h2>
        <img
          (click)="modal.getModal(modalId).close()"
          class="close-button"
          src="/assets/img/icn_close.svg" alt="close"
        >
      </div>
      <p class="text-add-image">
        {{ 'DASHBOARD.PANEL.ADD_IMAGE_TEXT_1' | translate }}<br>
        {{ 'SHARED.FILE_SIZE_OF' | translate }}
        {{ 'SHARED.FILE_MAX_SIZE' | translate : {size: 5} }}
      </p>
      <div class="field-wrapper">
        <div class="title-field">
          {{ 'SHARED.INPUT_LABEL_DATE' | translate }} *
        </div>
        <date-time-picker
          [includeTime]="true"
          [dateInputIsEditable]="false"
          [maxEndDate]="now"
          [date]="addImageForm.get('date').value"
          [dateStringFormat]="DateStringFormat.NUMBERS_WITH_TIME"
          (dateTimeSaved)="addImageForm.get('date').setValue($event.date)"
        ></date-time-picker>
      </div>
      <div class="field-wrapper">
        <div class="title-field">
          {{ 'DASHBOARD.PANEL.INPUT_IMAGE_LABEL_UPLOAD' | translate }} *
        </div>
        <div class="uploader-wrapper">
          <file-uploader
            formControlName="image"
            [required]="true"
            [maxFileSize]="5"
            icon="icn_image.svg"
            [accept]="'.jpg,.jpeg,.png,.svg'"
            [originalImage]="editedItem?.attachments.length ? editedItem.attachments[0].url : null"
            placeholder="{{ 'SHARED.FILE_MAX_SIZE' | translate : {size: 5} }}"
          >
          </file-uploader>
        </div>
      </div>
      <div class="field-wrapper">
        <div class="title-field">
          {{ 'DASHBOARD.PANEL.INPUT_IMAGE_LABEL_TITLE' | translate }} ({{ 'SHARED.OPTIONAL' | translate }})
        </div>
        <input-text
          [maxLength]="50"
          [backgroundStyle]="'grey'"
          formControlName="title"
          placeholder="{{ 'DASHBOARD.PANEL.INPUT_IMAGE_PLACEHOLDER_TITLE' | translate }}">
        </input-text>
      </div>
    </div>
    <div class="button-wrapper">
      <app-button
        [disabled]="!isFormValid"
        buttonText="{{ 'SHARED.SAVE' | translate }}"
        [styles]="[BUTTON_STYLES.shaped, BUTTON_STYLES.dark]"
        (click)="saveImage()">
      </app-button>
    </div>
  </form>
</ngx-smart-modal>
