import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { CarouselComponent } from '../carousel/carousel.component';

@Component({
  selector: 'onboarding-carousel-base',
  templateUrl: './onboarding-carousel-base.component.html',
  styleUrls: ['./onboarding-carousel-base.component.scss']
})
export class OnboardingCarouselBaseComponent implements OnInit {
  @Input() mainTitle: string;
  @Input() carouselSlides: string[];
  @Input() baseCarouselTranslationKey: string;
  @Input() isSlidingAutomatically = true;
  @Input() isSlidingInfinitely = true;

  @Output() onCarouselSlideUpdate: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild(CarouselComponent) carousel: CarouselComponent;

  public carouselSteps: string[];
  public loginRoutePath: string;

  constructor(private router: Router) {
    this.carouselSteps = [
      '../../../assets/img/img_tutorial-4.png',
      '../../../assets/img/img_tutorial-1.png',
      '../../../assets/img/img_tutorial-2.png',
      '../../../assets/img/img_tutorial-3.png',
      '../../../assets/img/img_tutorial-4.png',
      '../../../assets/img/img_tutorial-1.png'
    ];
  }

  emitSlideUpdate(slide: number) {
    this.onCarouselSlideUpdate.emit(slide);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    if (!this.isSlidingAutomatically) {
      this.carousel.clearSliderInterval();
    }
  }
}
