<div class="onboarding-content-wrapper">
  <div class="onboarding-content">
    <h1 class="title-onboarding" [innerHTML]="mainTitle | translate">
    </h1>
    <carousel
      [carouselSlides]="carouselSlides"
      [baseCarouselTranslationKey]="baseCarouselTranslationKey"
      [slidingInfinitely]="isSlidingInfinitely"
      (onSlideUpdate)="emitSlideUpdate($event)">
      <div class="button-container">
        <ng-content></ng-content>
      </div>
    </carousel>
  </div>
</div>
