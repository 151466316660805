<div
  class="badge-container"
  [ngClass]="isClicked ? 'is-clicked' : ''"
  (click)="isClicked = !isClicked"
>
  <div class="badge-svg-container" [inlineSVG]="svgPath"></div>
  <div class="badge-label">
    <div
      class="small-badge"
      [inlineSVG]="svgPath"
    >
    </div>
    <div class="badge-text">
      {{ badgeLabelKey | translate }}
    </div>
  </div>
</div>
