import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup } from '@angular/forms';
import { DevicesAppsComponent } from './devices-apps/devices-apps.component';
import { NgxSmartModalService } from 'ngx-smart-modal';
import GetSuppliersResponse from '../../../shared/models/settings/supplierResponse';
import { ApiService } from '../../../shared/api/api.service';
import { Router } from '@angular/router';
import { NativeAppService } from '../../../shared/api/native-app.service';
import { ApiMeasurementService } from '../../../shared/api/api-measurement.service';
import { BUTTON_STYLES } from '../../../shared/components/button/button.component';
import { Partner } from '../../../shared/models/partner';
import {PremiumService} from "../../../shared/utils/premiumService";

@Component({
  selector: 'wearables-apps',
  templateUrl: './wearables-apps.component.html',
  styleUrls: ['./wearables-apps.component.scss']
})
export class WearablesAppsComponent implements OnInit {
  @ViewChild(DevicesAppsComponent) devicesAppsComponent: DevicesAppsComponent;
  @Input() partner: string = null;

  isDataLoaded = false;
  wearablesAppsItems: GetSuppliersResponse;
  wearablesAppsForm: UntypedFormGroup;


  BUTTON_STYLES = BUTTON_STYLES;
  PartnerEnum = Partner;

  constructor(
    readonly formBuilder: UntypedFormBuilder,
    readonly modal: NgxSmartModalService,
    readonly apiService: ApiService,
    readonly router: Router,
    readonly nativeAppService: NativeAppService,
    readonly premiumService: PremiumService
  ) { }

  ngOnInit(): void {
    this.loadData();
  }

  convertToFormGroup(): UntypedFormGroup {
    const wearableAppsForm: any = {};
    this.wearablesAppsItems.suppliers.forEach(supplier => {
      wearableAppsForm[supplier.key] = supplier.connected;
    });
    return this.formBuilder.group(wearableAppsForm);
  }


  async loadData(): Promise<void> {
    this.wearablesAppsItems = await this.apiService.getSuppliers();
    this.isDataLoaded = true;
    this.wearablesAppsForm = this.convertToFormGroup();
  }

  get buttonText(): string {
    return this.devicesAppsComponent?.isEnabledAndSelected ? 'SETTINGS.SOURCES.WEARABLES_APPS.DISCONNECT_DEVICE' : 'SETTINGS.SOURCES.WEARABLES_APPS.CONNECT_DEVICE';
  }

  get isSelected(): boolean {
    return this.devicesAppsComponent?.isSelected;
  }

  get selectedItem(): any {
    return this.devicesAppsComponent?.selectedItem;
  }

  openModal(): void {
    if (this.devicesAppsComponent?.isEnabledAndSelected) {
      this.modal.get('disconnectPopup').open();
    } else {
      const numberOfConnectedSuppliers = this.wearablesAppsItems.suppliers.filter(supplier => supplier.connected).length;

      if(numberOfConnectedSuppliers == 1) {
        const premium = this.premiumService.userHasPremiumAccess(true);
        if(premium) {
          this.modal.open('wearableConnectionPopup');
        }
      } else {
        this.modal.open('wearableConnectionPopup');
      }
    }
  }

  async connectDevice(): Promise<void> {
    // alert('todo: oauth wearable vendor');
    const vendorKey = this.selectedItem.key;
    if(this.isAppleHealthKit) {
      this.handleAppleHealthKitConnect();
      await this.router.navigateByUrl('dashboard/settings/sources/wearables/success/apple_healthkit');
      return ;
    }
    const authorizeUrl = await this.apiService.getSupplierAuthorizeUrl(vendorKey);
    if (authorizeUrl && authorizeUrl.url) {
      // window.location.href = authorizeUrl.url;
      this.openNewWindow(authorizeUrl.url);
    }

    // this.wearablesAppsForm.patchValue({[this.selectedItem.key]: true});
  }

  openNewWindow(authPage: string): void {
    const link = document.createElement('a');
    link.href = authPage;
    link.click();
    link.remove();
  }

  async disconnectDevice() {
    // this.wearablesAppsForm.patchValue({[this.selectedItem.key]: false});
    this.modal.get('disconnectPopup').close();
    await this.apiService.disconnectSupplier(this.selectedItem.key);
    this.isDataLoaded = false;
    await this.loadData();
  }

  handleAppleHealthKitConnect() {
      // connect Apple HealthKit
      this.nativeAppService.postMessageToNativeApp({
        action: 'authorize',
        source: 'apple_healthkit'
      });
  }

  handleAppleHealthKitSync() {
    // connect Apple HealthKit
    this.nativeAppService.postMessageToNativeApp({
      action: 'sync',
      source: 'apple_healthkit'
    });
  }

  syncAppleHealthKit() {
    this.modal.get('disconnectPopup').close();
    this.handleAppleHealthKitSync();
  }

  get isAppleHealthKit() {
    return this.selectedItem.key === 'apple_healthkit';
  }
}
