import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {OAuthErrorEvent, OAuthEvent, OAuthService, OAuthSuccessEvent, UserInfo} from 'angular-oauth2-oidc';
import {Store} from '@ngrx/store';
import {ApiService} from '../shared/api/api.service';
import {OnboardingService} from '../shared/utils/onboarding.service';
import {BaseMeasurementSettings, MeasurementIdEnum} from '../shared/models/enums/MeasurementEnums';
import {BehaviorSubject, Observable} from 'rxjs';
import UserProfile from '../shared/models/userProfile';
import {ApiCommunityService} from '../shared/api/api-community.service';
import {ApiResearchService} from "../shared/api/api-research.service";
import {reset, setCulture, setProfile, setUserId} from "../store/userProfile/userProfile.actions";
import {ApiRegistrationService} from "../shared/api/api.registration.service";

const LOCAL_STORAGE_VERSION = '1';

@Injectable({providedIn: 'root'})
export class OidcService {
  constructor(
    private oauthService: OAuthService,
    private apiService: ApiService,
    private apiRegistrationService: ApiRegistrationService,
    private apiCommunityService: ApiCommunityService,
    private apiResearchService: ApiResearchService,
    private router: Router,
    private onboardingService: OnboardingService,
    private store: Store
  ) {
    this.getCultureInfo();
  }

  private _isAuthorized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public get isAuthorized(): Observable<boolean> {
    return this._isAuthorized.asObservable();
  }

  private _culture: string;

  public get culture(): string {
    return this._culture;
  }

  private static cleanLocalStorageInvitationVariables() {
    localStorage.removeItem('invitationContactId');
    localStorage.removeItem('invitationHash');
    localStorage.removeItem('invitationCompanyPid');
    localStorage.removeItem('invitationCompanyHash');
    localStorage.removeItem('invitationResearchId');
    localStorage.removeItem('careGiverName');
  }

  public initializeAuthorization(): void {
    if (this.oauthService.hasValidAccessToken()) {
      this.loadProfileAndRedirectToAuthorizedRedirectionUrl();
      this._isAuthorized.next(true);
    } else {
      this.oauthService.loadDiscoveryDocumentAndLogin()
        .then(result => {
          if (this.oauthService.hasValidAccessToken()) {
            this.loadProfileAndRedirectToAuthorizedRedirectionUrl();
          }
          return true;
        })
        .catch(error => this.router.navigateByUrl('/sso-error'));
    }
  }

  public initializeAuthorizationAsync(): Promise<boolean> {
    if (this.oauthService.hasValidAccessToken()) {
      this.loadProfileAndRedirectToAuthorizedRedirectionUrl();
      this._isAuthorized.next(true);
      return Promise.resolve(true);
    } else {
      return this.oauthService.loadDiscoveryDocumentAndLogin()
        .then(result => {
          if (this.oauthService.hasValidAccessToken()) {
            this.loadProfileAndRedirectToAuthorizedRedirectionUrl();
          }
          return Promise.resolve(true);
        })
        .catch(error => this.router.navigateByUrl('/sso-error'));
    }
  }

  async finalizeInvitationContactFlow() {
    const invitationContactId = localStorage.getItem('invitationContactId');
    const invitationHash = localStorage.getItem('invitationHash');

    if (invitationHash && invitationHash !== 'undefined') {
      const output: any = await this.apiCommunityService.confirmContact(invitationContactId, invitationHash);
      OidcService.cleanLocalStorageInvitationVariables();
      if (output.redirectToCompetition === true) {
        //if competition invite go to competition
        return this.router.navigateByUrl('dashboard/games/competition');
      }
      //if new user just go to dashboard
      return this.router.navigateByUrl('dashboard/home');
    }

    //if invite for existing user go to connections to accept manually
    return await this.router.navigateByUrl('dashboard/network/connections');
  }

  async finalizeInvitationCompanyFlow() {
    const invitationCompanyPid = localStorage.getItem('invitationCompanyPid');
    const invitationCompanyHash = localStorage.getItem('invitationCompanyHash');

    if (invitationCompanyHash && invitationCompanyHash !== 'undefined') {
      await this.apiService.putConnectPersonnel(invitationCompanyPid, invitationCompanyHash);
      OidcService.cleanLocalStorageInvitationVariables();
    }

    // return await this.router.navigateByUrl('dashboard/settings/financial');
  }

  async finalizeInvitationResearchFlow(researchId?: string) {
    OidcService.cleanLocalStorageInvitationVariables();
    await this.apiResearchService.confirm(researchId);
    if (researchId) {
      this.apiResearchService.activeResearchId = researchId;
    }
    return await this.router.navigateByUrl('dashboard/settings/research');
  }

  loadProfileAndRedirectToAuthorizedRedirectionUrl(): void {
    this.apiRegistrationService.getIncompleteRegistrationSteps().then(step => {
      const authorizedRedirectionUrl = step ? '/register/' + step : '/dashboard/home';

      this.oauthService.loadUserProfile().then(() => {
        const userInfo = this.oauthService.getIdentityClaims();
        this.store.dispatch(setUserId({ userId: userInfo['sub'] }));

        this.apiService.getFullUserProfile()
          .then((userProfile: UserProfile) => {
            this.store.dispatch(setProfile({userProfile: userProfile}));

            this.localStorageVersioningHandling();
            this.setupAutomaticSilentRefresh();

            this.router.navigateByUrl(authorizedRedirectionUrl).then();
          });
      });
    });
  }

  async silentRefreshTokenIfExpired(): Promise<OAuthEvent> {
    return this.oauthService.silentRefresh()
  };

  isAuthenticated(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  getAccessToken(): string {
    return this.oauthService.getAccessToken();
  }

  async refreshToken(): Promise<any> {
    if (!this.oauthService.discoveryDocumentLoaded) {
      await this.oauthService.loadDiscoveryDocument();
    }
    return this.oauthService.refreshToken();
  }

  public async logout(showDeleted = false): Promise<void> {
    let showDeletedParameter: any = null;
    if (showDeleted) {
      showDeletedParameter = {showDeleted: true};
    }
    if (this.oauthService.discoveryDocumentLoaded) {
      return this.oauthService.revokeTokenAndLogout(showDeletedParameter)
        .then((res) => {
          this._isAuthorized.next(false);
          this.clearOidcRelatedLocalStorageItems();
        });
    } else {
      return this.oauthService.loadDiscoveryDocument()
        .then(() => this.oauthService.revokeTokenAndLogout(showDeletedParameter))
        .then(() => {
          this._isAuthorized.next(false);
          this.clearOidcRelatedLocalStorageItems();
        });
    }
  }

  /**
   * 'Logs' the user softly out (without a redirect to the SSO server). access_token will stay 'valid' for a while on server. todo: make sure previous works
   * Opposing is revokeTokenAndLogout which really kills auth on SSO server
   */
  public softLogout(): void {
    this._isAuthorized.next(false);
    this.oauthService.logOut(true, 'stateParam');
    this.clearOidcRelatedLocalStorageItems();
  }

  public clearOidcRelatedLocalStorageItems(): void {
    this.store.dispatch(reset());
  }

  /**
   * Used for 'cache busting' localStorage
   */
  localStorageVersioningHandling(): void {
    if (!localStorage.getItem('localStorageVersioning')) {
      this.saveDefaultGraphSettingsInLocalStorage();
    }
    localStorage.setItem('localStorageVersioning', LOCAL_STORAGE_VERSION);
    this.updateDefaultGraphSettingsInLocalStorage();
  }

  saveDefaultGraphSettingsInLocalStorage(): void {
    const allMeasurementTypes = Object.keys(MeasurementIdEnum);

    const baseMeasurementSettings: any = {};

    allMeasurementTypes.forEach((measurementType: string) => {
      baseMeasurementSettings[measurementType] = new BaseMeasurementSettings();
    });

    localStorage.setItem('graphSettings', JSON.stringify(baseMeasurementSettings));
  }

  updateDefaultGraphSettingsInLocalStorage() {
    const allMeasurementTypes = Object.keys(MeasurementIdEnum);
    if (allMeasurementTypes.includes(MeasurementIdEnum.CUSTOM) && localStorage.getItem('localStorageVersioning')) {
      const graphSettings = JSON.parse(localStorage.getItem('graphSettings'));
      if (!graphSettings.hasOwnProperty(MeasurementIdEnum.CUSTOM)) {
        graphSettings[MeasurementIdEnum.CUSTOM] = new BaseMeasurementSettings();
        localStorage.setItem('graphSettings', JSON.stringify(graphSettings));
      }
    }
  }

  private getCultureInfo(): void {
    this.isAuthorized.subscribe(res => {
      if (res) {
        this.apiService.getLanguageThemeSettings().then(response => {
          this._culture = response.selectedLanguageCode;

          this.store.dispatch(setCulture({cultureCode: response.selectedLanguageCode}));
        });
      }
    });
  }

  setupAutomaticSilentRefresh(): void {
    this.oauthService.silentRefreshRedirectUri = window.location.origin + '/assets/silent-refresh.html';
    this.oauthService.setupAutomaticSilentRefresh({}, 'access_token');

    this.oauthService.events.subscribe(e => {
      if (e instanceof OAuthSuccessEvent && (e as OAuthSuccessEvent).type === 'token_refreshed') {
        this.oauthService.loadUserProfile().then();
      }
      if (e instanceof OAuthErrorEvent) {
        console.error(e);
      }
    });
  }

  private tokenExpired(token: string): boolean {
    if (token) {
      const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
      return (Math.floor((new Date()).getTime() / 1000)) >= expiry;
    } else {
      return true;
    }
  }
}
