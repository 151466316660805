import { createAction, props } from '@ngrx/store';

export const API_CALL_START = 'API_CALL_START';
export const API_CALL_FINISH = 'API_CALL_FINISH';
export const SET_IS_PREMIUM = 'SET_IS_PREMIUM';
// export const SET_WINDOW_DIMENSIONS = 'SET_WINDOW_DIMENSIONS';

export const apiCallStart = createAction(API_CALL_START);
export const apiCallFinish = createAction(API_CALL_FINISH);
export const setIsPremium = createAction(SET_IS_PREMIUM, props<{ value: boolean }>());
// export const setWindowDimensions = createAction(SET_WINDOW_DIMENSIONS, props<{ width: number; height: number }>());
