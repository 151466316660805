<ngx-smart-modal
  identifier="setMeasurementsModal"
  [hideDelay]="200"
  [closable]="false"
  (onOpen)="fetchEnabledMeasurementTypes()"
  (onAnyCloseEventFinished)="onModalClose()"
>
  <div class="set-measurements-wrapper">
    <div class="title-wrapper">
      <div class="top-row">
        <h3 class="main-title">{{ 'DASHBOARD.SET_MEASUREMENT.TITLE' | translate }}</h3>
        <img
          class="close-button"
          src="/assets/img/icn_close.svg"
          (click)="modal.getModal('setMeasurementsModal').close()"
        >
      </div>
      <p class="sub-title">{{ 'DASHBOARD.SET_MEASUREMENT.SUB_TITLE' | translate }}</p>
    </div>
    <measurement-category-blocks
      [formGroup]="enabledMeasurementTypesForm"
      [customTypes]="customTypes"
      [measurementCategory]="selectedMeasurementCategory"
      [measurementIds]="availableMeasurementIds"
      [modalId]="'setMeasurementsModal'"
    >
    </measurement-category-blocks>
    <app-button
      [ngStyle]="{'margin': 'auto'}"
      class="add-to-dashboard-button"
      [buttonText]="'DASHBOARD.SET_MEASUREMENT.BUTTON_TEXT' | translate"
      [disabled]="!hasSelectedValues()"
      (click)="saveMeasurementBlocks()"
    ></app-button>
  </div>
</ngx-smart-modal>
<measurement-settings
  [measurementTypeTitle]="'MEASUREMENT_DETAILS.MEASUREMENTS.CUSTOM.TITLE' | translate"
  [isNewCustomMeasurementType]="true"
  measurementSvg="measurements/icn_selfcare.svg"
  measurementTypeId="CUSTOM"
  customId="CUSTOM"
  (measurementSavedEmitter)="saveNewCustomType($event)"
></measurement-settings>
