import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {Observable} from "rxjs";
import {OAuthService} from "angular-oauth2-oidc";

@Injectable()
export class AdminGuard implements CanActivate {

  constructor(private oauthService: OAuthService,
              private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const claimsObj: any = this.oauthService.getIdentityClaims();

    if (claimsObj.customerAdmin) {
      return true;
    }

    this.router.navigateByUrl('no-access');
    return false;
  }

}
