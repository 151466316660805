import {
  AfterViewInit,
  Component,
  ElementRef, EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  // OnDestroy,
  OnInit, Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Calendar, CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
// import interactionPlugin from '@fullcalendar/interaction';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { TranslateService } from '@ngx-translate/core';
// import { Element } from '@angular/compiler';
import { DateService } from '../../utils/date.service';

@Component({
  selector: 'calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})

// export class CalendarComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
export class CalendarComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('fullCalendarPrev') fullCalendarPrev: FullCalendarComponent;
  @ViewChild('fullCalendar') fullCalendar: FullCalendarComponent;
  @ViewChild('fullCalendarNext') fullCalendarNext: FullCalendarComponent;
  @Input() calendarData: any;
  // name useless?
  @Input() calendarName: string;
  // @Input() calendarDate?: string;

  /** for swiping effects **/
  @ViewChild('refSwiperWrapper') refSwiperWrapper: ElementRef;
  @ViewChild('refSwiperContainer') refSwiperContainer: ElementRef;

  @Output() calendarDayClicked = new EventEmitter();
  @Output() cameraPanCompletedEvent = new EventEmitter();

  public calendarOptions: CalendarOptions;

  /** for swiping effects **/
  private swiperWrapper: HTMLElement;
  private swiperContainer: HTMLElement;
  private xStart: number = null;
  public current = 1;
  private locked = false;
  private width = 0;
  private classNameSmooth = 'smooth';
  private varNameCurrent = '--current';
  private varNameTranslateX = '--translate-x';
  private varNameFactor = '--factor';
  private yStart: number | null = null;

  constructor(
    private translateService: TranslateService,
    private dateService: DateService,
  )
  {}

  ngOnInit(): void {
    this.initializeCalendar();
  }

  initializeCalendar(): void {
    // need for load calendar bundle first
    forwardRef(() => Calendar);

    // console.log('this.calendarData', this.calendarData);

    // add CalendarOptions
    const calendarOptions: CalendarOptions = {
      // events: this.calendarData ? this.calendarData.calendarDataPoints : [],
      // plugins: [dayGridPlugin, interactionPlugin],
      plugins: [dayGridPlugin],
      editable: false,
      headerToolbar: null,
      fixedWeekCount: false,
      dayCellClassNames: 'calendar-cell-day',
      // eventClassNames: this.handleEventClassNames.bind(this),
      eventDidMount: this.handleEventDidMount.bind(this),
      displayEventTime: false,
      firstDay: 1,
      contentHeight: 310,
      locale: this.translateService.getBrowserCultureLang(),
      // initialDate: new Date(),
      // dateClick: this.handleDateClick.bind(this), // note requires interaction plugin: we added @click event ourselves
    };

    if (this.calendarData) {
      calendarOptions.initialDate = this.calendarData.startDate;
      calendarOptions.events = this.calendarData.calendarDataPoints;
    } else {
      calendarOptions.events = [];
    }

    // console.log('calendarOptions', calendarOptions);
    this.calendarOptions = calendarOptions;
    // set height
    // this.setCalendarHeight();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('changes calendarData', changes);

    if (changes && changes.calendarData && changes.calendarData.currentValue) {
      // console.log('changes.calendarData.currentValue', changes.calendarData.currentValue);

      // note: this is not effective: need to remove the 'events'
      // const previousExtraText = document.querySelectorAll('.calendar-extra-text');
      // console.log('previousExtraText', previousExtraText);
      // previousExtraText.forEach((elem: any) => {
      //   console.log('elem', elem);
      //   elem.remove();
      // });

      if (!changes.calendarData.firstChange) {
        // force rerender calendar if data has changed
        this.fullCalendar.getApi().destroy();
        this.fullCalendarNext.getApi().destroy();
        this.fullCalendarPrev.getApi().destroy();
        // this.initializeCalendar();
        this.fullCalendar.getApi().render();
        this.fullCalendarNext.getApi().render();
        this.fullCalendarPrev.getApi().render();
        this.initializeCalendar();
      }
    }
  }

  /** for swiping effects **/
  ngAfterViewInit(): void {
    // window.addEventListener('resize', this.resize.bind(this), false);
    this.initializeSwiper();
  }

  // setCalendarHeight(): void {
  //   this.date = !this.calendarDate ? new Date() : new Date(this.calendarDate);
  //   const days = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0).getDate();
  //   const weekCount = days < 29 ? this.getWeeksInMonth(this.date.getFullYear(), this.date.getMonth()) : 5;
  //   const finalHeight = weekCount * 40 + 70;
  //   console.log('finalHeight', finalHeight);
  //   this.calendarOptions.contentHeight = weekCount * 40 + 70;
  // }
  // //
  // getWeeksInMonth(year: number, month: number): number {
  //   const weeks = [],
  //     firstDate = new Date(year, month, 1),
  //     lastDate = new Date(year, month + 1, 0),
  //     numDays = lastDate.getDate();
  //   let dayOfWeekCounter = firstDate.getDay();
  //   for (let date = 1; date <= numDays; date++) {
  //     if (dayOfWeekCounter === 0 || weeks.length === 0) {
  //       weeks.push([]);
  //     }
  //     weeks[weeks.length - 1].push(date);
  //     dayOfWeekCounter = (dayOfWeekCounter + 1) % 7;
  //   }
  //   return weeks.filter((week) => !!week.length).length;
  // }

  // updateCalendarSize(data: { [key: string]: any }): void {
  //   if (data.calendar && data.calendar[this.calendarName]) {
  //     (this.fullCalendarPrev as any).getApi().render();
  //     (this.fullCalendarPrev as any).getApi().prev();
  //     (this.fullCalendar as any).getApi().render();
  //     (this.fullCalendarNext as any).getApi().render();
  //     (this.fullCalendarNext as any).getApi().next();
  //     this.resize();
  //   }
  // }

  // handleDayCellClassNames(): string {
  //   return 'calendar-cell-day';
  // }
  //
  // handleEventClassNames(): string {
  //   return 'event-text';
  // }

  /**
   * Handles styling for the day cells of the calendar
   * @param args
   */
  handleEventDidMount(args: any): void {
    const dayHtmlElem = args.el.closest('.fc-daygrid-day-frame');
    const dayNumberElem = dayHtmlElem.querySelector('.fc-daygrid-day-number');

    dayHtmlElem.querySelectorAll('.calendar-extra-text').forEach((e: any) => e.parentNode.removeChild(e));
    // console.log('args.event', args.event);
    // console.log('args.event.extendedProps', args.event.extendedProps);

    const extendedProps = args.event.extendedProps;

    dayNumberElem.classList.add(`day-has-measurement`);
    dayNumberElem.style.backgroundColor = extendedProps.qualityColor;

    if (extendedProps.extraText) {
      const extraTextElem = document.createElement('div');
      extraTextElem.classList.add('calendar-extra-text');
      extraTextElem.style.color = extendedProps.qualityColor;
      extraTextElem.innerHTML = extendedProps.extraText;
      dayHtmlElem.append(extraTextElem);
    }

    this.addCalendarEventHandler(dayNumberElem, args);
  }

  addCalendarEventHandler(elem: HTMLElement, args: any): void {
    /** get data from args (args has a start and end) and add event handler **/
    const dateData = {
      startDate: args.event.start,
      // end: args.event.end // needed? no probably
    };
    // console.log('dateData', dateData);

    elem.addEventListener('click', () => this.calendarDayClicked.emit(dateData));
  }

  initializeSwiper(): void {
    this.swiperWrapper = this.refSwiperWrapper.nativeElement;
    this.swiperContainer = this.refSwiperContainer.nativeElement;
  }

  lock(e: MouseEvent | TouchEvent): void {
    this.yStart = this.unify(e).clientY;
    this.xStart = this.unify(e).clientX;
    this.swiperContainer.classList.toggle(this.classNameSmooth, !(this.locked = true));
  }

  move(e: MouseEvent | TouchEvent): void {
    if (this.locked) {
      const distanceX = this.unify(e).clientX - this.xStart;
      const signed = Math.sign(distanceX);
      let factor = +(signed * distanceX / this.width).toFixed(2);
      // check if factor is big enough to move
      if (factor > 0.2) {
        this.swiperContainer.style.setProperty(this.varNameCurrent, (this.current -= signed).toString());
        factor = 1 - factor;
      }
      // remove extra translation
      this.swiperContainer.style.setProperty(this.varNameTranslateX, '0px');
      // add sliding effect
      this.swiperContainer.classList.toggle(this.classNameSmooth, !(this.locked = false));
      this.swiperContainer.style.setProperty(this.varNameFactor, factor.toString());
      this.xStart = null;
      // correct the index to fake a infinite sliding
      this.correctSwapperCurrent(factor);
    }
    this.yStart = null;
  }

  correctSwapperCurrent(factor: number = 1): void {
    if (this.current === 2) {
      this.removeAllEvents();
      this.current = 1;
      setTimeout(() => {
        this.renderNextMonths();
        this.onSwipeComplete(false);
        // this.swapMonthRefsNext();
      }, factor * 0.5 * 1000);
    } else if (this.current === 0) {
      this.removeAllEvents();
      this.current = 1;
      setTimeout(() => {
        // this.addPrevMonth();
        this.renderPrevMonths();
        this.onSwipeComplete(false);
      }, factor * 0.5 * 1000);
    }
  }

  // note: events in our case are 'data points of measurements'
  removeAllEvents(): void {
    (this.fullCalendarPrev as any).getApi().removeAllEvents();
    (this.fullCalendar as any).getApi().removeAllEvents();
    (this.fullCalendarNext as any).getApi().removeAllEvents();
  }

  renderNextMonths(): void {
    (this.fullCalendarPrev as any).getApi().next();
    (this.fullCalendarPrev as any).getApi().render();
    (this.fullCalendar as any).getApi().next();
    (this.fullCalendar as any).getApi().render();
    (this.fullCalendarNext as any).getApi().next();
    (this.fullCalendarNext as any).getApi().render();
  }

  renderPrevMonths(): void {
    (this.fullCalendarPrev as any).getApi().prev();
    (this.fullCalendarPrev as any).getApi().render();
    (this.fullCalendar as any).getApi().prev();
    (this.fullCalendar as any).getApi().render();
    (this.fullCalendarNext as any).getApi().prev();
    (this.fullCalendarNext as any).getApi().render();
  }

  onSwipeComplete(animation: boolean = true): void {
    this.removeAllEvents();
    // document.querySelectorAll('.calendar-extra-text').forEach(e => {
    //   console.log('removing calendar extra text', e);
    //   e.remove();
    // });
    // the calendar range is 1 sep -> 1 okt, but we want 1 sep -> 30 sep
    const dateRangeCalendar = this.fullCalendar.getApi().getCurrentData().dateProfile.currentRange;
    // dateRange.end.setDate(dateRange.end.getDate() - 1);
    const endDate = this.dateService.cloneDate(dateRangeCalendar.end);
    endDate.setDate(endDate.getDate() - 1);
    const dateRange: any = {
      start: this.dateService.cloneDate(dateRangeCalendar.start),
      end: endDate,
    };

    // console.log('dateRange', dateRange);

    this.cameraPanCompletedEvent.emit(dateRange);
    this.swiperContainer.classList.toggle(this.classNameSmooth, animation);
    this.swiperContainer.style.setProperty(this.varNameCurrent, this.current.toString());
  }

  drag(e: MouseEvent | TouchEvent): void {
    e.preventDefault();
    if (this.locked) {
      const distanceY = Math.abs(this.yStart - this.unify(e).clientY);
      const distanceX = Math.abs(this.xStart - this.unify(e).clientX);
      if (distanceY < distanceX) {
        this.swiperContainer.style.setProperty(this.varNameTranslateX, `${this.unify(e).clientX - this.xStart}px`);
      }
    }
  }

  unify(e: any) {
    return e.touches && e.touches[0] ? e.touches[0] : e.changedTouches ? e.changedTouches[0] : e;
  }

  // resize(): void {
  //   this.width = this.swiperWrapper.clientWidth;
  // }

  // ngOnDestroy() {
    // console.log('ngOnDestroy called for calendar');
  // }
}
