import { Injectable } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';

const ONBOARDING_KEY = 'onboardingState';

interface OnboardingSettings {
  healthcareProvidersOnboardingCompleted: boolean;
}

const BASE_ONBOARDING_OBJECT: OnboardingSettings = {
  healthcareProvidersOnboardingCompleted: false,
};

@Injectable({
  providedIn: 'root',
})

/**
 * A service used to keep track of onboarding states
 */
export class OnboardingService {
  constructor(
    readonly modal: NgxSmartModalService,
  ) {}

  onboardingKeyExistsInLocalstorage(): boolean {
    const onboardingEntry = localStorage.getItem(ONBOARDING_KEY);
    return onboardingEntry !== null;
  }

  createOnboardingKey(): void {
    localStorage.setItem(ONBOARDING_KEY, JSON.stringify(BASE_ONBOARDING_OBJECT));
  }

  setOnboarding(payload: OnboardingSettings): void {
    localStorage.setItem(ONBOARDING_KEY, JSON.stringify(payload));
  }

  removeOnboarding(): void {
    localStorage.removeItem(ONBOARDING_KEY);
  }

  getOnboardingEntry(): OnboardingSettings {
    let onboardingEntry: string | null = localStorage.getItem(ONBOARDING_KEY);
    if (!onboardingEntry) {
      console.warn('onboardingSettings not present in localStorage while they should be. Resetting');
      onboardingEntry = JSON.stringify(BASE_ONBOARDING_OBJECT);
    }
    return JSON.parse(onboardingEntry);
  }

  checkIfHealthcareProvidersOnboardingFinished(): boolean {
    let onboardingEntry: any = localStorage.getItem(ONBOARDING_KEY);
    if (!onboardingEntry) return false;
    onboardingEntry = JSON.parse(onboardingEntry);
    return onboardingEntry && onboardingEntry.healthcareProvidersOnboardingCompleted;
  }

  setHealthcareProvidersOnboardingFinished(): void {
    const onboardingEntryParsed = this.getOnboardingEntry();
    onboardingEntryParsed.healthcareProvidersOnboardingCompleted = true;
    this.setOnboarding(onboardingEntryParsed);
  }


}
