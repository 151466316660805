<div class="duration-field-container">
  <input-text
    id="measurement-hours-input"
    [backgroundStyle]="'grey'"
    type="number"
    (input)="onHoursInput($event)"
    placeholder="{{ 'DASHBOARD.PANEL.ADD_MANUAL_MEASUREMENT_INPUT_HOURS' | translate:{ measurementType: measurementTypeTitleLowerCase } }}"
    [ngClass]="{'ng-dirty': !validHours, 'ng-invalid': !validHours}"
  >
  </input-text>
  <div class="time-label">
    {{'DASHBOARD.PANEL.ADD_MANUAL_MEASUREMENT_INPUT_HOURS' | translate}}
  </div>
  <input-text
    id="measurement-minutes-input"
    [backgroundStyle]="'grey'"
    type="number"
    (input)="onMinutesInput($event)"
    placeholder="{{ 'DASHBOARD.PANEL.ADD_MANUAL_MEASUREMENT_INPUT_MINUTES' | translate:{ measurementType: measurementTypeTitleLowerCase } }}"
    [ngClass]="{'ng-dirty': !validMinutes, 'ng-invalid': !validMinutes}"
  >
  </input-text>
  <div class="time-label">
    {{'DASHBOARD.PANEL.ADD_MANUAL_MEASUREMENT_INPUT_MINUTES' | translate}}
  </div>
</div>
