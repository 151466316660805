import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class FeatureFlagsService {

    constructor() {
        if (environment.featureFlags !== undefined) {
            this.featureFlags.push(...environment.featureFlags);
        }

        // Make featureFlagService available in the browser console: (not on production)
        if (!environment.production) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (<any>window).featureFlagService = this;
        }
    }

    featureFlags: string[] = [];

    hasFeatureFlag(featureFlag: string): boolean {
        return this.featureFlags.includes(featureFlag);
    }

    enableFeatureFlag(featureFlag: string): void {
        if (!this.featureFlags.includes(featureFlag)) {
            this.featureFlags.push(featureFlag);
        }
    }

    disableFeatureFlag(featureFlag: string): void {
        this.featureFlags = this.featureFlags.filter(f => f != featureFlag);
    }
}
