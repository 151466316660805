import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError} from 'rxjs/operators';
import {EventQueueService} from '../utils/event-queue.service';
import {OAuthService} from "angular-oauth2-oidc";

@Injectable()
export class HealthInterceptor implements HttpInterceptor {

  constructor(private oauthService: OAuthService, private eventQueueService: EventQueueService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.shouldIntercept(request)) {
      return next.handle(request);
    }

    let req = request;

    const claims: any = this.oauthService.getIdentityClaims();

    if (claims.customerAdmin) {
      req = request.clone({
        headers: request.headers.set('X-CustomerId', claims.customerAdmin.toString())
      });
    }

    return next.handle(req).pipe(catchError(err => this.handleError(err)));
  }

  shouldIntercept(request: HttpRequest<unknown>): boolean {
    for (let route of environment.routesToAcceptInHealthInterceptor) {
      if (request.url.includes(route)) {
        return true;
      }
    }
    return false;
  }

  handleError(error: HttpErrorResponse | unknown): Observable<never> {
    if (error instanceof HttpErrorResponse) {
      this.eventQueueService.dispatch('Health-Http-Error', error);
    }

    return throwError(error);
  }
}
