export enum ConsentTypes {
  TermsAndConditions,
  RewardsConditions
}

export interface UpdateConsentRequest
{
  accepted: boolean;
  type: ConsentTypes;
}

export interface ConsentResponse {
  accepted: boolean;
  timestamp: Date;
}
