import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
// import { addMessage } from '../../../store/chat/chat.actions';
// import { Store } from '@ngrx/store';
import { ApiService } from '../../api/api.service';
import { GlobalService } from '../../utils/global.service';
import { NumberService } from '../../utils/number.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { TranslateService } from '@ngx-translate/core';
import { ChatRoom, MessageSent } from '../../models/chat/chatRoom';
// import { MessageFile } from '../../models/chat/message';
// import AppState from '../../../store';
// import * as fromChat from '../../../store/chat/chat.selector';

@Component({
  selector: 'chat-input-field',
  templateUrl: './chat-input-field.component.html',
  styleUrls: ['./chat-input-field.component.scss']
})
export class ChatInputFieldComponent implements OnInit, AfterViewInit {
  // ME = new ChatUser(this.apiService.getUserId(), 'Igor Willems', 'https://ca.slack-edge.com/T6HECLLP9-U9TNMCGSV-786427131504-512', USER_STATUS.ONLINE);

  @ViewChild('textInput') textInput: ElementRef;
  // @ViewChild('fileInput') fileInput: ElementRef;

  @Input() placeholder?: string;
  @Input() activeRoom: ChatRoom;

  @Output() messageSentEmitter = new EventEmitter();

  inputText = '';

  /** Todo version 3.0: files */
  /** Todo version 3.0: files */
  /** Todo version 3.0: files */
  // IMAGE_MIME_TYPES = ['image/png', 'image/jpeg', 'image/gif'];
  // VIDEO_MIME_TYPES = ['video/mp4', 'video/ogg', 'video/quicktime'];
  // DOCUMENT_MIME_TYPES = ['application/pdf', 'text/plain', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/zip', 'application/x-zip-compressed'];
  // // mime types that are allowed as chat attachment
  // ALLOWED_MIME_TYPES = [...this.IMAGE_MIME_TYPES, ...this.VIDEO_MIME_TYPES, ...this.DOCUMENT_MIME_TYPES];
  // // MAX_FILE_UPLOAD_SIZE = 5000000; // 5 mb
  // MAX_FILE_UPLOAD_SIZE = 100000000; // 100 mb
  // MAX_FILE_UPLOAD_SIZE = 1000000; // 1 mb

  errorModalId = 'chatAttachmentErrorModal';
  errorModalMessage = '';

  constructor(
    // private store: Store<AppState>,
    private apiService: ApiService,
    public globalService: GlobalService,
    public numberService: NumberService,
    public modal: NgxSmartModalService,
    public translate: TranslateService,
  ) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    // this.store.select(fromChat.getActiveRoom).subscribe(() => this.textInput.nativeElement.focus());
    this.textInput.nativeElement.focus();
  }

  public handleInput(inputEvent: Event): void {
    const target = inputEvent.target as HTMLInputElement;

    // console.log('e input event', e);
    // todo fix type
    // @ts-ignore
    if (inputEvent.inputType === 'insertParagraph' || inputEvent.inputType === 'insertLineBreak') {
      if (!this.inputText) {
        target.textContent = '';
        return;
      }
    }

    this.inputText = target.innerText;
  }

  /** Note: on mobile, we want a new line on enter, while on desktop we want to send the message.
   *  CTRL+Enter is best practice for newline on keyboard
   */
  onEnterKeyPressed(): void {
    if (!this.globalService.isMobileUserAgent()) {
      this.sendTextMessage();
    }
  }

  sendTextMessage(): void {
    // trim white spaces on start and end of message
    const input = this.textInput.nativeElement.innerText;
    let inputTrimmed = input.trim();
    // prevent sending empty chat messages
    if (!inputTrimmed) return;
    inputTrimmed = inputTrimmed.replaceAll('\n\n', '\n');

    // this.store.dispatch(addMessage({message: inputTrimmed, roomId: this.activeRoom.roomId, loggedInUserId: this.ME.id, sender: this.ME}));
    const messageSent: MessageSent = {
      roomId: this.activeRoom.roomId,
      message: inputTrimmed,
    };

    this.messageSentEmitter.emit(messageSent);

    this.textInput.nativeElement.textContent = '';
    // todo determine whether inputText is actually needed
    this.inputText = '';
  }

  // openFileSelector(): void {
  //   this.fileInput.nativeElement.click();
  // }

  // handleFileSelected(fileEvent: Event): void {
  //   const files: FileList = (<HTMLInputElement>fileEvent.target).files;
  //   const filesArray: File[] = Array.from(files);
  //   let passedValidation = true;
  //   Array.from(filesArray).forEach((file: File) => {
  //     console.log('file selected for upload pre parsing', file);
  //     if (!this.ALLOWED_MIME_TYPES.includes(file.type)) {
  //       this.errorModalMessage = `${this.translate.instant('NETWORK.ERROR_STATE.ERROR_UPLOADING_FILE')}: <span class="bold">${file.name}</span><br>${this.translate.instant('NETWORK.ERROR_STATE.ERROR_MESSAGE_WRONG_MIME')}`;
  //       this.modal.getModal(this.errorModalId).open();
  //       passedValidation = false;
  //       return;
  //     }
  //     if (file.size > this.MAX_FILE_UPLOAD_SIZE) {
  //       this.errorModalMessage = `${this.translate.instant('NETWORK.ERROR_STATE.ERROR_UPLOADING_FILE')}: <span class="bold">${file.name}</span><br>${this.translate.instant('NETWORK.ERROR_STATE.ERROR_MESSAGE_FILE_TOO_BIG', {fileSizeLimitString: this.numberService.getFileSizeStringByFileSizeBytes(this.MAX_FILE_UPLOAD_SIZE)})}`;
  //       this.modal.getModal(this.errorModalId).open();
  //       passedValidation = false;
  //       return;
  //     }
  //   });
  //
  //   if (passedValidation) {
  //     Array.from(filesArray).forEach((file: File) => {
  //       this.sendFileMessage(file);
  //     });
  //   }
  // }
  //
  // /** A message consisting of an attachment */
  // sendFileMessage(file: File): void {
  //   const fileReader = new FileReader();
  //   fileReader.onloadend = () => {
  //     const fileParsed: MessageFile = {
  //       id: this.globalService.randomId(),
  //       lastModified: new Date(file.lastModified),
  //       name: file.name,
  //       size: file.size,
  //       type: file.type,
  //       src: fileReader.result.toString()
  //     };
  //     // console.log('fileParsed pre upload', fileParsed);
  //     this.store.dispatch(addMessage({message: null, roomId: this.activeRoom.roomId, loggedInUserId: this.ME.id, sender: this.ME, file: fileParsed}));
  //   };
  //
  //   fileReader.readAsDataURL(file);
  // }
}

