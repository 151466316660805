import { Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MeasurementType } from '../../../../models/MeasurementType';

@Component({
  selector: 'set-measurements-block',
  templateUrl: './set-measurements-block.component.html',
  styleUrls: ['./set-measurements-block.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SetMeasurementsBlockComponent),
    multi: true,
  }],
})
export class SetMeasurementsBlockComponent implements ControlValueAccessor {
  @Input() measurementCategoryId: string;
  @Input() customTypes: MeasurementType[] = [];
  @Input() addedToDashboard = false;
  @Input() blockId: string;
  @Input() icon: string;
  @Input() customName: string;
  isEnabled = false;
  isSelected = false;

  readonly selectionCssColor: any = {
    MEDICAL_MEASUREMENTS: 'blue',
    LIFESTYLE_MEASUREMENTS: 'orange',
    PHYSICAL_MEASUREMENTS: 'purple',
    BLOOD_MEASUREMENTS: 'black',
    MENTAL_MEASUREMENTS: 'red',
    CUSTOM_MEASUREMENTS: 'black'
  };

  @ViewChild('measurementBlockIcon') d: ElementRef<HTMLElement>;

  onChange = ((_: any) => {});

  writeValue(obj: any) {
    this.isEnabled = obj;
    this.isSelected = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
  }

  onChanged() {
    this.isEnabled = !this.isEnabled;
    this.isSelected = !this.isSelected;
    this.onChange(this.isEnabled);
  }
}
