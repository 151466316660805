import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {

  // TODO replace all direct calls to localStorage to use this service
  constructor() {
  }

  setItem(key: LocalStorageKey, value: string): void {
    // TODO: console.log('localStorage.setItem', key, value);
    // TODO post to nativeApp all token changes?
    // const stringValue = JSON.stringify(value);
    localStorage.setItem(key, value);
  }

  getItem(key: LocalStorageKey): string | null {
    return localStorage.getItem(key)
      ?.replace('undefined', '')
      ?.replace('null', '');
  }

  removeItem(key: LocalStorageKey): void {
    localStorage.removeItem(key);
  }

  clear(): void {
    localStorage.clear();
  }
}

export type LocalStorageKey =
  | LocalStorageInvitationKey
  | 'partnerId'
  | 'partner'
  | 'completeRegistration'
  | 'localStorageVersioning'
  | string;

export type LocalStorageInvitationKey =
  | 'invitationContactId'
  | 'invitationResearchId'
  | 'careGiverNamed'
  | 'careGiverToken'
  | 'invitationChallengeToken'
  | string;
