import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BUTTON_STYLES } from '../button/button.component';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Partner } from '../../models/partner';

@Component({
  selector: 'wearable-connection-popup',
  templateUrl: './wearable-connection-popup.component.html',
  styleUrls: ['./wearable-connection-popup.component.scss'],
})
export class WearableConnectionPopupComponent implements OnInit {
  @Input() wearableVendorName: string;
  @Input() partner: string = null;

  @Output() onProceed = new EventEmitter();

  modalId = 'wearableConnectionPopup';
  BUTTON_STYLES = BUTTON_STYLES;
  PartnerEnum = Partner;

  constructor(
    readonly modal: NgxSmartModalService,
  ) { }

  ngOnInit(): void {
  }

  proceed(): void {
    this.onProceed.emit();
    this.modal.close(this.modalId);
  }
}
