import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MeasurementService } from '../../../../measurements/measurement.service';
import { MeasurementFilterBlockService } from '../../../../utils/measurement-filter-block.service';
import { ApiService } from '../../../../api/api.service';
import { MeasurementType } from '../../../../models/MeasurementType';
import {
  BaseMeasurementSettings,
  MEASUREMENT_TYPES,
  MeasurementCategory
} from '../../../../models/enums/MeasurementEnums';
import { MeasurementTypeSelectionResponse } from 'src/app/shared/models/dashboard/MeasurementTypeSelectionGroup';
import { OidcService } from '../../../../../oidc/oidc.service';

@Component({
  selector: 'set-measurements',
  templateUrl: './set-measurements.component.html',
  styleUrls: ['./set-measurements.component.scss'],
})
export class SetMeasurementsComponent implements OnInit {
  isButtonDisabled: boolean;
  enabledMeasurementTypesForm: UntypedFormGroup;
  customTypes: MeasurementType[] = [];

  @Input() selectedMeasurementCategory: MeasurementCategory;
  @Output() measurementTypesFormSaved = new EventEmitter();

  availableMeasurementIds: string[] = [];

  measurementTypeConfig: MeasurementTypeSelectionResponse;


  constructor(
    public modal: NgxSmartModalService,
    public form: UntypedFormBuilder,
    public measurementService: MeasurementService,
    public measurementFilterBlockService: MeasurementFilterBlockService,
    public apiService: ApiService,
    private oidcService: OidcService,
  ) { }

  ngOnInit(): void {
    this.loadCustomTypes().then(() => {
      this.enabledMeasurementTypesForm.valueChanges.subscribe(() => {
        this.isButtonDisabled = !this.enabledMeasurementTypesForm.dirty;
      });
      this.fetchEnabledMeasurementTypes();
    });
  }

  hasSelectedValues(): boolean{
    const measurementTypesFormValue = this.enabledMeasurementTypesForm.value;

    for (const measurementCategory in measurementTypesFormValue) {
      for (const measurementType in measurementTypesFormValue[measurementCategory]) {
        if (measurementTypesFormValue[measurementCategory][measurementType]) {
          return true;
        }
      }
    }

    return false;
  }

  updateAvailableMeasurementIds(): void {
    const availableMeasurementIds = [];
    for (const measurementTypesKey in MEASUREMENT_TYPES) {
      if(MEASUREMENT_TYPES[measurementTypesKey].measurementCategory === this.selectedMeasurementCategory) {
        availableMeasurementIds.push(MEASUREMENT_TYPES[measurementTypesKey].id);
      }
    }
    if (this.selectedMeasurementCategory === MeasurementCategory.CUSTOM_MEASUREMENTS) {
      this.measurementService.customMeasurementTypes.forEach(cM => availableMeasurementIds.push(cM.id));
    }
    this.availableMeasurementIds = availableMeasurementIds;
  }

  async fetchEnabledMeasurementTypes(): Promise<void> {
    this.availableMeasurementIds = [];
    this.updateAvailableMeasurementIds();
  }

  async loadCustomTypes(): Promise<void> {
    this.enabledMeasurementTypesForm = this.measurementFilterBlockService.getMeasurementCategoriesFormGroup();

    this.measurementTypeConfig = await this.apiService.getUserMeasurementTypeConfiguration();

    const customMeasurementTypesIndex = this.measurementTypeConfig.measurementGroups.findIndex(mg => mg.group === MeasurementCategory.CUSTOM_MEASUREMENTS as string);

    if (customMeasurementTypesIndex > -1) {
      this.customTypes = this.measurementService.loadCustomTypes(this.measurementTypeConfig);

      this.measurementTypeConfig.measurementGroups[customMeasurementTypesIndex].measurementTypes.forEach(mt => {
        const customMeasurementsGroup: UntypedFormGroup = this.enabledMeasurementTypesForm.get(MeasurementCategory.CUSTOM_MEASUREMENTS) as UntypedFormGroup;
        customMeasurementsGroup.addControl(mt.measurementType, new UntypedFormControl());
      });
    }
  }

  async saveMeasurementBlocks(): Promise<void> {
    const measurementTypesFormValue = this.enabledMeasurementTypesForm.value;
    const enabledMeasurementBlocks: string[] = [];
    const customMeasurementBlocks: string[] = [];

    for (const measurementCategory in measurementTypesFormValue) {
      for (const measurementType in measurementTypesFormValue[measurementCategory]) {
        if (measurementTypesFormValue[MeasurementCategory.CUSTOM_MEASUREMENTS][measurementType]) {
          //filters out add custom measurement button
          if(measurementType !== 'CUSTOM') {
            customMeasurementBlocks.push(measurementType);
          }
        } else if (measurementTypesFormValue[measurementCategory][measurementType]) {
          enabledMeasurementBlocks.push(measurementType);
        }
      }
    }

    this.measurementTypeConfig.measurementGroups.forEach(mg => mg.measurementTypes.forEach(
      mt  => {
        if(mt.isAddedToDashboard) {
          if (mg.group !== MeasurementCategory.CUSTOM_MEASUREMENTS) {
            if(enabledMeasurementBlocks.indexOf(mt.measurementType) === -1) {
              enabledMeasurementBlocks.push(mt.measurementType);
            }
          } else {
            if(mt.customName) {
              customMeasurementBlocks.push(mt.measurementType);
            }
          }
        }
      }));

    await this.apiService.saveUserMeasurementTypeConfiguration(enabledMeasurementBlocks, customMeasurementBlocks);
    await this.apiService.getUserMeasurementTypeConfiguration();
    this.updateLocalStorageWithMissingMeasurementTypes(enabledMeasurementBlocks);

    this.measurementTypesFormSaved.emit(true);
    this.modal.getModal('setMeasurementsModal').close();
  }

  onModalClose(): void {
    this.enabledMeasurementTypesForm.markAsUntouched();
    this.enabledMeasurementTypesForm.markAsPristine();
    this.isButtonDisabled = this.hasSelectedValues();
  }

  async saveNewCustomType(event: any): Promise<void> {
    this.measurementTypeConfig = await this.apiService.getUserMeasurementTypeConfiguration();
    this.customTypes = this.measurementService.loadCustomTypes(this.measurementTypeConfig);
    await this.fetchEnabledMeasurementTypes();


    const controlName = this.customTypes.find(cT => cT.customName == event.name).id;

    const customMeasurementsGroup: UntypedFormGroup = this.enabledMeasurementTypesForm.get(MeasurementCategory.CUSTOM_MEASUREMENTS) as UntypedFormGroup;
    customMeasurementsGroup.addControl(controlName, new UntypedFormControl());

    if(controlName) {
      const customMeasurementsGroup: UntypedFormGroup = this.enabledMeasurementTypesForm.get(MeasurementCategory.CUSTOM_MEASUREMENTS) as UntypedFormGroup;
      customMeasurementsGroup.controls[controlName].setValue(true);
      setTimeout(() => document.getElementById(controlName).scrollIntoView(), 500);
    }
  }

  updateLocalStorageWithMissingMeasurementTypes(enabledMeasurementBlocks: string[]): void {
    //for now we don't keep settings for individual custom types
    let graphSettingsLocalStorage = JSON.parse(localStorage.getItem('graphSettings'));
    if(!graphSettingsLocalStorage) {
      this.oidcService.saveDefaultGraphSettingsInLocalStorage();
      graphSettingsLocalStorage = JSON.parse(localStorage.getItem('graphSettings'));
    }
    enabledMeasurementBlocks.forEach(block => {
      if(graphSettingsLocalStorage && !graphSettingsLocalStorage.hasOwnProperty(block)) {
        graphSettingsLocalStorage[block] = new BaseMeasurementSettings();
      }
    });
    localStorage.setItem('graphSettings', JSON.stringify(graphSettingsLocalStorage));
  }
}
