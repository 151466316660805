import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'options-button',
  templateUrl: './options-button.component.html',
  styleUrls: ['./options-button.component.scss']
})
export class OptionsButtonComponent implements OnInit {

  arrowColor = '#70B3E2';
  isOpened = false;

  @ViewChild('expandArrow') expandArrow: ElementRef;

  constructor() { }

  ngOnInit(): void {
  }

  toggleMenu() {
    this.isOpened = !this.isOpened;
    const arrowElement = <HTMLElement>this.expandArrow.nativeElement;
    if (this.isOpened) {
      arrowElement.style.transform = 'rotate(45deg)';
      arrowElement.style.marginTop = '-0.35rem';
    } else {
      arrowElement.style.transform = 'rotate(225deg)';
      arrowElement.style.marginTop = '0.35rem';
    }
  }

}
