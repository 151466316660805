import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {
  IndicationRangeModel,
  LimitIndication, QuantityModel
} from '../../../../shared/api/api-medmij-partner.service';

@Component({
  selector: 'line-indicator',
  templateUrl: './line-indicator.component.html',
  styleUrls: ['./line-indicator.component.scss']
})
export class LineIndicatorComponent implements OnInit, OnChanges {
  @Input() referenceValues: IndicationRangeModel;
  @Input() currentValue: QuantityModel;
  @Input() limitIndication: LimitIndication;

  LimitIndication = LimitIndication;

  valueA = 0;
  valueB = 0;
  valueC = 0;
  valueCAcc = 0; //90% rangeEnd
  valueD = '...';

  lowValuePercentage = 0;
  highValuePercentage = 0
  remainingPercentage = 0;
  valuePercentage = 0;

  indicatorType: IndicatorType;
  IndicatorType = IndicatorType;

  numberOfZones: number[] = [];


  constructor() { }

  ngOnInit(): void {
    this.setIndicatorType();
    this.valueB = this.referenceValues.low?.value;
    this.valueCAcc = this.referenceValues.rangeEnd;
    this.valueC = this.referenceValues.high?.value;

    if(this.indicatorType === IndicatorType.BOTH) {
      const oneThird = 100 / 3;
      this.lowValuePercentage = oneThird;
      this.highValuePercentage = oneThird;
      this.remainingPercentage = oneThird;

      this.numberOfZones = Array(12).fill(0).map((x,i)=>i);
    } else {
      const half = 50;
      this.lowValuePercentage = half;
      this.highValuePercentage = half;
      this.remainingPercentage = half;

      this.numberOfZones = Array(10).fill(0).map((x,i)=>i);
    }


    if(this.currentValue?.value > this.valueCAcc) {
      this.valuePercentage = 90;
    } else {
      if(this.indicatorType === IndicatorType.BOTH) {
        if(this.currentValue?.value < this.valueB) {
          this.valuePercentage = (100 / 3) / this.valueB * this.currentValue.value;
        } else if(this.currentValue?.value >= this.valueB && this.currentValue?.value <= this.valueC) {
          let diff = this.valueC - this.valueB;
          let currentValueAcc = this.currentValue.value - this.valueB;
          this.valuePercentage = (100 / 3) + ((100 / diff * currentValueAcc) / 3);
        } else {
          this.valuePercentage = 90 / this.valueCAcc * this.currentValue.value;
        }
      } else {
        this.valuePercentage = 90 / this.valueCAcc * this.currentValue.value;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  setIndicatorType() {
    if(this.referenceValues.low?.value && !this.referenceValues.high?.value) {
      this.indicatorType = IndicatorType.LOW;
    }
    if(this.referenceValues.high?.value && !this.referenceValues.low?.value) {
      this.indicatorType = IndicatorType.HIGH;
    }
    if(this.referenceValues.high?.value && this.referenceValues.low?.value) {
      this.indicatorType = IndicatorType.BOTH;
    }
  }
}

export enum IndicatorType {
  LOW,
  HIGH,
  BOTH
}
