import { Component, Input, OnInit } from '@angular/core';
// import * as fromChat from '../../../../store/chat/chat.selector';
// import { Store } from '@ngrx/store';
import { ChatUser } from '../../../models/chat/chat-user';
import { ChatRoom } from '../../../models/chat/chatRoom';
// import { setActiveRoom } from '../../../../store/chat/chat.actions';
// import AppState from "../../../../store";

@Component({
  selector: 'chat-avatar',
  templateUrl: './chat-avatar.component.html',
  styleUrls: ['./chat-avatar.component.scss']
})
export class ChatAvatarComponent implements OnInit {
  @Input() room: ChatRoom;

  // public chatUsers: ChatUser[] = [];
  // public chatPartner: ChatUser = undefined;
  // public profileImage = '';
  // public roomName = '';

  constructor(
    // private store: Store<AppState>
  )
  {}

  ngOnInit(): void {
    // this.store.select(fromChat.getChatUsers)
    //   .subscribe((chatUsers: any) => this.setChatUser(chatUsers));
  }

  // public setChatUser(chatUsers: ChatUser[]): void {
  //   this.chatUsers = chatUsers;
  //   this.setRoomInfo();
  // }
  //
  // public setRoomInfo(): void {
  //   if (this.room.members?.length > 1) {
  //     this.roomName = this.room.roomName;
  //     this.profileImage = '/assets/img/chat/icn_emptythumbnail.svg';
  //   } else {
  //     this.chatPartner = this.chatUsers.find(user => this.room.members && user.id === this.room.members[0].id);
  //     this.roomName = this.chatPartner ? this.chatPartner.username : '';
  //     this.profileImage = this.chatPartner ? this.chatPartner.getProfileImage() : '';
  //   }
  // }
  //
  // public setActiveRoom(): void {
  //   // this.store.dispatch(setActiveRoom({roomId: this.room.roomId}));
  // }

}
