<div class="top-row-section">
  <header>
    <h2>
      <span class="bold">{{ 'DASHBOARD.KNOWLEDGE_BASE.TITLE' | translate }}</span>
    </h2>
  </header>
</div>

<div class="knowledge-content-wrapper">
  <div *ngIf="knowledgeBaseData && knowledgeBaseData.entries" class="knowledge-blocks">
    <a
      *ngFor="let knowledge of knowledgeBaseData.entries"
      [href]="knowledge.url"
      target="_blank"
      rel="noreferrer"
      class="knowledge-block-container"
    >
      <div class="knowledge-block-image-container">
        <img [src]="knowledge.imageUrl" class="knowledge-block-image" [alt]="knowledge.title">
      </div>
      <p class="knowledge-block-title">{{ knowledge.title }}</p>
      <p class="knowledge-block-description">{{ knowledge.description }}</p>
    </a>
  </div>
</div>

<!-- todo: <empty-state></empty-state>-->
