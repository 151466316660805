import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';
import {Chart, Plugin} from 'chart.js';
import { TranslateService } from '@ngx-translate/core';
import { MeasurementService } from '../../../measurements/measurement.service';
import TeamTargetResponse from '../../../models/game/teamTargetResponse';
import { setChartDefaults } from '../chartjs';
import zoomPlugin from 'chartjs-plugin-zoom';
import annotationPlugin from 'chartjs-plugin-annotation';

@Component({
  selector: 'target-achievement-chart',
  templateUrl: './target-achievement-chart.component.html',
  styleUrls: ['./target-achievement-chart.component.scss'],
})
export class TargetAchievementChartComponent implements OnInit, OnChanges {
  @ViewChild('targetAchievementChart') targetAchievementChart: ElementRef;
  @ViewChild(BaseChartDirective) public chart: BaseChartDirective;

  @Input() chartData: TeamTargetResponse;
  @Input() measurementTypeString: string;
  @Input() challengeEndDate: string;
  // personal or team, used for determining label
  @Input() isTeam: boolean;
  public barChartData: any = [
    {
      data: [], // note: filled once data streams in
      fill: false,
      barThickness: 20
    },
  ];
  public chartOptions: any = {
    // the target value line
    // public chartConfig: (ChartOptions & { annotation: any }) = {
    // zoom: {
    //   enabled: true,
    //   mode: 'x',
    //   // mode: 'xy',
    //   sensitivity: 3,
    //   speed: 0.1,
    //   // @ts-ignore
    //   onZoomComplete: (): void => {
    //     this.onPanCompleted();
    //   }
    // },
    scales: {
      x: {
        id: 'x-axis-0',
        type: 'time',
        // type: 'linear',
        // bounds: 'data', // this means data appear in between gridlines
        distribution: 'linear',
        // distribution: 'series',
        // bounds: 'ticks', // this means data points are averaged out to the nearest axis grid line
        // stacked: true,
        ticks: {
          // beginAtZero: true,
          // autoSkipPadding: 5,
        },
        time: {
          stepSize: 1,
          // minUnit: 'hour',
          // unit: 'millisecond',
          // unit: 'second',
          // unit: 'minute',
          // unit: 'hour',
          // unit: 'week',
          // unit: 'month',
          // unit: 'quarter',
          // unit: 'year',
          // stepSize: 2 // label every x hours
          unit: 'day',
          displayFormats: {
            day: 'D',
          },
          isoWeekday: true,
        },
        title: {
          display: true,
          text: this.translate.instant('MEASUREMENT_DETAILS.TIME_INTERVALS.DAYS').toUpperCase(),
          // padding: 14
        },
        grid: {
          display: false,
        },
      },
      y: {
        id: 'y-axis-0',
        type: 'linear',
        position: 'left',
        beginAtZero: true,
        ticks: {
          // stepSize: 3,
          // min: 0,
          // max: 100,
          // suggestedMin: 2.5,
          // suggestedMax: 10.0,
          // padding: 20
        },
        title: {
          display: true,
          text: 'temp name',
        },
        grid: {
          borderDash: [3, 2],
          color: '#CECECE',
          drawBorder: false,
        },
      },
    },
    responsive: true,
    // events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
    events: null,
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    plugins: {
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
          // overScaleMode: 'x',
          rangeMin: {},
          rangeMax: {},
          speed: 20,
        },
      },
      annotation: {
        annotations: {
          line1: {
            type: 'line',
            mode: 'horizontal',
            value: 6,
            borderColor: '#8D8489',
            borderWidth: 2.5,
            borderDash: [8, 1],
            label: {
              display: true,
              backgroundColor: 'rgba(255,255,255,0)',
              content: 'TEAM TARGET',
              font: {
                size: 11,
                family: 'Museo Sans',
                weight: 900
              },
              color: '#8D8489',
              padding: 0,
              position: 'end',
              xAdjust: 0,
              yAdjust: 10,
            },
          }
          // docs: https://github.com/chartjs/chartjs-plugin-annotation/blob/1ab782afce943456f958cac33f67edc5d6eab278/README.md
          // drawTime: 'afterDraw', // overrides annotation.drawTime if set
          //   id: 'a-line-1', // optional
          //   type: 'line',
          //   mode: 'horizontal',
          //   borderColor: '#8D8489',
          //   borderWidth: 2.5,
          //   borderDash: [8, 1],
          //   backgroundColor: 'rgba(255,255,255,0)',
          //   content: 'TEAM TARGET',
            // label: {
            //   backgroundColor: 'rgba(255,255,255,0)',
            //   fontFamily: 'Museo Sans',
            //   fontSize: 11,
            //   fontStyle: '900',
            //   fontColor: '#8D8489',
            //   xPadding: 0,
            //   yPadding: 0,
            //   // cornerRadius: 6,
            //   position: 'right',
            //   xAdjust: 0,
            //   yAdjust: 10,
            //   enabled: true,
            //   content: 'TEAM TARGET'
            // },
        }
      },
    }
  };
  chartPlugins: Plugin[] = [
    {
      id: 'gradient',
      // apply gradient to bar chart
      beforeLayout: (chart: Chart): void => {
        const ctx = chart.ctx;
        const dataset = chart.data.datasets[0];
        if (!ctx || !dataset || !chart.height) return;

        const gradientBarColor = ctx.createLinearGradient(0, 0, 0, chart.height);
        gradientBarColor.addColorStop(0, '#FFC154');
        gradientBarColor.addColorStop(1, '#FF8D52');
        dataset.borderColor = gradientBarColor;
        dataset.backgroundColor = gradientBarColor;
      },
    },
  ];

  constructor(
    public readonly translate: TranslateService,
    public readonly measurementService: MeasurementService,
  ) { }

  ngOnInit(): void {
    setChartDefaults();
    Chart.register(...[
      zoomPlugin,
      annotationPlugin
    ]);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isTeam) {
      this.chartOptions.plugins.annotation.annotations.line1.label.content = changes.isTeam.currentValue ?
        this.translate.instant('GAMES.CHALLENGE.PROGRESS.TEAM_TARGET.TITLE').toUpperCase() :
        this.translate.instant('GAMES.CHALLENGE.PROGRESS.TEAM_TARGET.TARGET').toUpperCase();
    }
    if (changes.measurementTypeString && changes.measurementTypeString.currentValue) {
      this.chartOptions.scales.y.title.text = changes.measurementTypeString.currentValue.toUpperCase();
    }
    if (changes.chartData && changes.chartData.currentValue) {
      this.formatGraphData(changes.chartData.currentValue);
    }
  }

  renderGraph(): void {
    // a neat trick to force rerender due to object contents not being fully reactive
    this.chartOptions = { ...this.chartOptions };
  }

  formatGraphData(dataset: TeamTargetResponse): void {
    const graphDataset = [];
    for (const i in dataset.days) {
      const dayDataPoint = dataset.days[i];
      const dayDate = new Date(dayDataPoint.date);
      dayDate.setHours(0);
      dayDate.setMinutes(0);
      dayDate.setSeconds(0);
      const dataPoint = {
        x: dayDate,
        y: dayDataPoint.value,
      };
      graphDataset.push(dataPoint);
    }

    this.setViewPortMinMax();

    this.barChartData[0].data = graphDataset;
    this.chartOptions.plugins.annotation.annotations.line1.yMin = dataset.dailyTarget;
    this.chartOptions.plugins.annotation.annotations.line1.yMax = dataset.dailyTarget;
    this.renderGraph();
  }

  setViewPortMinMax() {
    const today = new Date();
    const challengeEndDate = new Date(this.challengeEndDate);
    let referenceDate;
    if(today <= challengeEndDate) {
      referenceDate = today;
    } else {
      referenceDate = challengeEndDate;
    }
    const startDate = new Date(referenceDate).setDate(new Date(referenceDate).getDate() - 14);
    const endDate = referenceDate.getTime();
    this.chartOptions.scales.x.min = startDate;
    this.chartOptions.scales.x.max = endDate;
  }
}
