import { Component, OnDestroy } from '@angular/core';
import AppState from '../../../store';
import { Store } from '@ngrx/store';
import { selectApiCallIsActive } from '../../../store/global/global.selector';
import { Subscription } from 'rxjs';

@Component({
  selector: 'loading-modal',
  templateUrl: './loading-modal.component.html',
  styleUrls: ['./loading-modal.component.scss'],
})
export class LoadingModalComponent implements OnDestroy {
  showSpinner = false;
  timerActive = false;
  apiCallIsActiveSubscription: Subscription;

  constructor(
    private readonly store: Store<AppState>,
  ) {
    this.apiCallIsActiveSubscription = store.select(selectApiCallIsActive).subscribe(isActive => {
      // this.showSpinner = isActive;
      if (isActive) {
        this.timerActive = true;
        setTimeout(() => {
          if (this.timerActive) {
            this.showSpinner = true;
            this.timerActive = false;
          }
        }, 150);
      } else {
        this.timerActive = false;
        this.showSpinner = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.apiCallIsActiveSubscription.unsubscribe();
  }
}
