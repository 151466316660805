import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-found',
  template: `<error-component [description]="'ERROR_PAGE.TIP' | translate"></error-component>`
})
export class NotFoundComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
