import { Component, ElementRef, forwardRef, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'small-dropdown',
  templateUrl: './small-dropdown.component.html',
  styleUrls: ['./small-dropdown.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SmallDropdownComponent),
    multi: true
  }]
})
export class SmallDropdownComponent implements OnInit, ControlValueAccessor {
  @Input() options: string[];
  selectedOption = '';
  otherOptions: string[] = [];
  isOpened = false;

  @Output() onSelection = new EventEmitter();

  @ViewChild('dropdownOptions') dropdownOptions: ElementRef;

  constructor() { }

  ngOnInit() {
  }

  onChange = ((_: any) => {});

  writeValue(obj: any) {
    this.selectedOption = obj;
    this.otherOptions = this.options.filter(o => o !== this.selectedOption);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
  }

  onChanged(selectedOption: string) {
    this.selectedOption = selectedOption;
    this.otherOptions = this.options.filter(o => o !== this.selectedOption);
    this.toggleDropdown();
    this.onChange(this.selectedOption);
  }

  toggleDropdown() {
    const dropdownOptions = <HTMLElement>this.dropdownOptions.nativeElement;
    if (dropdownOptions.style.height && dropdownOptions.style.height !== '0px') {
      // closing
      dropdownOptions.style.height = null;
    } else {
      // opening
      dropdownOptions.style.height = dropdownOptions.scrollHeight + 'px';
    }
    this.isOpened = !this.isOpened;
  }
}
