import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {ApiService} from './api.service';
import HealthSettings, {HealthUserAnswers, InviteLock} from "../models/settings/healthSettings";
import {Store} from "@ngrx/store";
import UserProfile from "../models/userProfile";

@Injectable({
  providedIn: 'root'
})
export class ApiHealthService {
  baseUrl = environment.apiHealthBaseUrl;
  userId: string = null;
  activeHealthId: string = null;

  constructor(
    private apiService: ApiService,
    private http: HttpClient,
    private store: Store<{ 'userProfile': UserProfile }>) {
    this.store.subscribe(userProfile => {
      if (userProfile && userProfile.userProfile) {
        this.userId = userProfile.userProfile.userId;
      }
    });
  }

  public isHealthInviteLocked(): Promise<InviteLock> {
    return this.http.get<InviteLock>(`${this.baseUrl}dashboard/healthInviteLock`).toPromise();
  }

  public getActiveCaregiversForUser(): Promise<HealthSettings> {
    return this.http.get<HealthSettings>(`${this.baseUrl}dashboard/${this.userId}`).toPromise();
  }

  public deleteUser(userId: string): Promise<any> {
    return this.http.delete(`${this.baseUrl}dashboard/user/${userId}`).toPromise();
  }

  public getInvitedParticipants(): Promise<any> {
    return this.http.get(`${this.baseUrl}dashboard/invitedUsers`).toPromise();
  }

  public async addUser(email: string, cultureCode: string) {
    await this.apiService.dashboardCaregiverAddUser(email, cultureCode);
  }

  public shouldRedirect(): Promise<boolean> {
    return this.http.get<boolean>(`${this.baseUrl}dashboard/shouldRedirect`).toPromise();
  }

  public connectUserToCaregiver(tokenId: string): Promise<void> {
    const connectUserToCaregiverRequest: ConnectUserToCaregiverRequest = {
      tokenId: tokenId
    };

    return this.http.post<void>(`${this.baseUrl}dashboard/connectUserToCaregiver`, connectUserToCaregiverRequest).toPromise();
  }
}

interface ConnectUserToCaregiverRequest
{
  tokenId: string;
}
