import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as fromGlobal from './global.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(fromGlobal.KEY_GLOBAL, fromGlobal.reducer)
  ],
})

export class StoreGlobalModule {
}
