<div #tooltipElement id="tooltip-element" class="tooltip-element"
     [class.include-bottom-section]="!isCaregiver && (tooltipData.note || tooltipData.dataPoint.totalMeasurements === 1)"
>
  <div class="tooltip-close-button" inlineSVG="icn_close.svg" (click)="closeTooltip()"></div>
  <div class="tooltip-top-container">
    <div class="tooltip-row">
      <div class="tooltip-icon" inlineSVG="/measurements/details/icn_small_time.svg"></div>
      <div class="tooltip-row-value">
        {{ dateString }}
      </div>
    </div>
    <div class="tooltip-row">
      <div *ngIf="measurementSvg" class="tooltip-icon" [inlineSVG]="measurementSvg"></div>
      <div
        *ngIf="tooltipData.dataPoint.totalMeasurements < 2"
        class="tooltip-row-value">
        <span [innerHTML]="tooltipData.dataPoint.valueFormatted|unit:measurementUnitString:true"></span>
      </div>
      <div
        *ngIf="tooltipData.dataPoint.totalMeasurements > 1"
        class="tooltip-row-value"
      >
        {{ 'MEASUREMENT_DETAILS.TOOLTIP.AVERAGE' | translate }}
        <span [innerHTML]="tooltipData.dataPoint.valueFormatted|unit:measurementUnitString:true"></span>
        ({{ tooltipData.dataPoint.totalMeasurementsFormatted }} {{ 'MEASUREMENT_DETAILS.CHARTS.MEASUREMENTS' | translate }})
      </div>
    </div>
    <div class="tooltip-row">
      <div class="tooltip-icon" inlineSVG="/measurements/details/icn_small_addedmanually.svg"></div>
<!--      <div-->
<!--        *ngIf="!tooltipData.dataPoint.totalMeasurements"-->
<!--        class="tooltip-row-value"-->
<!--        [ngClass]="tooltipData.dataPoint.addedByExternalProviderCount > 0 ? 'underline' : ''"-->
<!--      >-->
<!--        {{ 'MEASUREMENT_DETAILS.ADDED_BY_CATEGORY.' + tooltipData.dataPoint.addedManuallyCount | translate }}-->
<!--      </div>-->
<!--      <div *ngIf="tooltipData && tooltipData.aggregateData && tooltipData.aggregateData.sumOfAddedByCategories">-->
<!--        <div *ngFor="let addedByRow of tooltipData.aggregateData.sumOfAddedByCategories" class="tooltip-row-value">-->
<!--          {{ 'MEASUREMENT_DETAILS.ADDED_BY_CATEGORY.' + addedByRow.addedById | translate }} ({{ addedByRow.sum }})-->
<!--        </div>-->
<!--      </div>-->
      <div>
<!--        <div *ngIf="tooltipData.dataPoint.addedManuallyCount" class="tooltip-row-value">-->
        <div class="tooltip-row-value">
          {{ 'MEASUREMENT_DETAILS.ADDED_BY_CATEGORY.MANUALLY' | translate }} ({{ tooltipData.dataPoint.addedManuallyCountFormatted }})
        </div>
<!--        <div *ngIf="tooltipData.dataPoint.addedByExternalProviderCount" class="tooltip-row-value">-->
        <div class="tooltip-row-value">
          {{ 'MEASUREMENT_DETAILS.ADDED_BY_CATEGORY.HEALTHCARE_PROVIDER' | translate }} ({{ tooltipData.dataPoint.addedByExternalProviderCountFormatted }})
        </div>
<!--        <div *ngIf="tooltipData.dataPoint.addedBySensorCount" class="tooltip-row-value">-->
        <div class="tooltip-row-value">
          {{ 'MEASUREMENT_DETAILS.ADDED_BY_CATEGORY.WEARABLE' | translate }} ({{ tooltipData.dataPoint.addedBySensorCountFormatted }})
        </div>
      </div>
    </div>

<!--    todo add notes once the client includes them in the API-->

    <div *ngIf="tooltipData.note">
      <div *ngIf="tooltipData.note.mood" class="tooltip-row">
        <div class="tooltip-emoticon">{{ tooltipData.mood.emoji }}</div>
        <div class="tooltip-row-value">{{ 'DASHBOARD.PANEL.INPUT_EMOJI_' + tooltipData.mood.text | translate }}</div>
      </div>
      <div *ngIf="expandedViewNotes" class="tooltip-row">
        <div class="tooltip-icon" inlineSVG="icn_note.svg"></div>
        <div class="tooltip-row-value">{{ 'DASHBOARD.NOTES' | translate }}:</div>
      </div>
      <div *ngIf="expandedViewNotes && tooltipData.note.content" class="tooltip-row note-row">
        “{{ tooltipData.note.content }}”
      </div>
      <div *ngIf="expandedViewNotes && tooltipData.note.attachmentUrl" class="tooltip-row image-row">
        <img [src]="tooltipData.note.attachmentUrl" class="tooltip-picture-attachment">
      </div>
    </div>
  </div>

  <div
    *ngIf="tooltipData.dataPoint.totalMeasurements < 2 && !isCaregiver && !forContactId && !atalmedialMeasurement"
    class="tooltip-bottom-section"
    (click)="bottomSectionClick()"
  >
    <div *ngIf="tooltipData.dataPoint.totalMeasurements === 1 && (tooltipData.note && (tooltipData.note.content || tooltipData.note.attachmentUrl))" class="view-notes-bottom-content">
      <p class="tooltip-bottom-text">{{ expandedViewNotes ? ('MEASUREMENT_DETAILS.TOOLTIP.HIDE_NOTES' | translate) : ('MEASUREMENT_DETAILS.TOOLTIP.VIEW_NOTES' | translate) }}</p>
      <div [ngClass]="expandedViewNotes ? 'expanded' : ''" class="expand-arrow">
      </div>
    </div>

    <div
      *ngIf="tooltipData.dataPoint.totalMeasurements === 1 && (!tooltipData.note || (!tooltipData.note.content && !tooltipData.note.attachmentUrl))"
      class="view-notes-bottom-content"
    >
      <div class="add-icon" inlineSVG="icn_add.svg"></div>
      <p class="tooltip-bottom-text">{{ 'MEASUREMENT_DETAILS.TOOLTIP.ADD_NOTES' | translate }}</p>
    </div>
  </div>
</div>
