import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { BUTTON_STYLES } from '../button/button.component';

@Component({
  selector: 'premium-expiration-popup',
  templateUrl: './premium-expiration-popup.component.html',
  styleUrls: ['./premium-expiration-popup.component.scss']
})
export class PremiumExpirationPopupComponent implements OnInit {

  BUTTON_STYLES = BUTTON_STYLES;
  MONTH_PRICE = 2.99;
  YEAR_PRICE = 29.99;

  MONTH_PRICE_STRING = ` (€${this.MONTH_PRICE})`;
  YEAR_PRICE_STRING = ` (€${this.YEAR_PRICE})`;

  constructor(
    public modal: NgxSmartModalService
  ) { }

  ngOnInit(): void {
  }
}
