<div
  class="measurements-block"
  [id]="blockId"
  [ngClass]="[(isSelected ? 'selected-' + selectionCssColor[measurementCategoryId] : '')]"
  (click)="onChanged()"
>
  <div class="measurement-block-icon-row">
    <div
      class="measurement-block-icon"
      #measurementBlockIcon
      [ngClass]="[selectionCssColor[measurementCategoryId] + '-icon']"
      [inlineSVG]="'/measurements/' + (blockId === 'CUSTOM' && measurementCategoryId === 'CUSTOM_MEASUREMENTS' ? 'icn_add.svg' : icon)"
    ></div>
  </div>
  <p class="measurement-block-title">{{ customName && customName !== '' ? customName : ('MEASUREMENT_DETAILS.MEASUREMENTS.' + blockId + '.TITLE' | translate)}}</p>
  <div class="check-mark" [ngClass]="addedToDashboard ? 'is-enabled' : ''" inlineSVG="icn_check.svg"></div>
</div>
