import {
  HttpClient,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Gender } from '../models/user';
import {IncompleteFieldsResponse} from "../models/register/incompleteFieldsResponse";

/** Api Service only used for the registration process */
// https://uat-sso.selfcare4me.com/swagger/index.html?url=/swagger/v1/swagger.json
@Injectable({providedIn: 'root'})
export class ApiRegistrationService {
  readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiIdentityBaseUrl;
  }

  /** REGISTRATION */
  public createUser(userName: string, country: string, personnelId: string = null, personnelInvitationHash: string = null, termsAndConditions: boolean = false, rewardsConditions: boolean = null): Observable<{ userId: string }> {
    const body = {userName, country, personnelId, personnelInvitationHash, termsAndConditions, rewardsConditions};
    return this.http.post<{ userId: string }>(`${this.baseUrl}user`, body);
  }

  public savePassword(userId: string, email: string, password: string, code: string): Observable<void> {
    const body = {email, password, code};
    return this.http.post<void>(`${this.baseUrl}user/${userId}/resetPassword`, body);
  }

  public savePhoneNumber(userId: string, phoneNumber: string, code: string): Observable<void> {
    const body = {phoneNumber, code};
    return this.http.post<void>(`${this.baseUrl}user/${userId}/updatePhone`, body);
  }

  public confirmSmsVerificationCode(userId: string, token: string): Observable<void> {
    const body = {token};
    return this.http.post<void>(`${this.baseUrl}user/${userId}/confirmPhone`, body);
  }

  public async getIncompleteRegistrationSteps(): Promise<number> {
    const incompleteFieldsResponse = await this.http.get<IncompleteFieldsResponse>(`${this.baseUrl}user/incompleteRegistrationFields`).toPromise();
    const currentRegisterForm = JSON.parse(localStorage.getItem('registerForm'));
    if (currentRegisterForm) {
      currentRegisterForm.step5.firstName = incompleteFieldsResponse.knownUserFields?.firstName;
      currentRegisterForm.step5.lastName = incompleteFieldsResponse.knownUserFields?.lastName;
      const dateOfBirthUtc = new Date(incompleteFieldsResponse.knownUserFields?.dateOfBirth);
      currentRegisterForm.step6.dateOfBirth = new Date(dateOfBirthUtc.getTime() - (dateOfBirthUtc.getTimezoneOffset() * 60000));
      currentRegisterForm.step7.gender = incompleteFieldsResponse.knownUserFields?.gender;
      currentRegisterForm.userId = incompleteFieldsResponse.userId;
    }
    if (incompleteFieldsResponse.step) {
      localStorage.setItem("completeRegistration", "true");
    }
    localStorage.setItem('registerForm', JSON.stringify(currentRegisterForm));
    return incompleteFieldsResponse.step;
  }

  public finishRegister(userId: string, body: { firstname: string; lastname: string; dateOfBirth: string; country: string; gender: Gender }): Observable<void> {
    const jsonPatch = [
      {op: 'replace', path: 'firstname', value: body.firstname},
      {op: 'replace', path: 'lastname', value: body.lastname},
      {op: 'replace', path: 'dateOfBirth', value: body.dateOfBirth},
      {op: 'replace', path: 'country', value: body.country},
      {op: 'replace', path: 'gender', value: body.gender},
    ];

    return this.http.patch<void>(`${this.baseUrl}user/${userId}`, jsonPatch);
  }

  async getCultureCode(): Promise<string> {
    var response: any = await this.http.get(`${this.baseUrl}user/culture-code`).toPromise();
    return response.cultureCode;
  }

  public sendDeleteConfirmationMail(): Promise<void> {
    const body = {};
    return this.http.post<void>(`${this.baseUrl}user/sendDeleteConfirmationMail`, body).toPromise();
  }

  public deleteUser(token: string): Promise<void> {
    return this.http.delete<void>(`${this.baseUrl}user?token=${token}`).toPromise();
  }

  /**
   * FORGOT PASSWORD
   */
  public sendResetPassword(userName: string): Promise<{ userId: string }> {
    const body = {userName};
    return this.http.post<{ userId: string }>(`${this.baseUrl}User/sendResetPassword`, body).toPromise();
  }

  public resetPassword(userId: string, code: string, password: string) {
    const body = {code, password};
    return this.http.post<void>(`${this.baseUrl}User/${userId}/resetPassword`, body).toPromise();
  }
}


