import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges, OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CountryISO, NgxIntlTelInputComponent, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input-gg';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PhoneNumberFormat as PNF, PhoneNumberUtil } from 'google-libphonenumber';
import {Country} from "ngx-intl-tel-input-gg/lib/model/country.model";

export interface PhoneNumberValidityInfo {
  phoneNumber: string;
  phoneNumberIsValid: boolean;
  phoneNumberCountryCode: string;
}

const COUNTRY_OPTIONS = ['be', 'nl'];

@Component({
  selector: 'input-phone',
  template: `
<form #f="ngForm" [formGroup]="phoneForm">
    <div class="phone-input-container">
      <ngx-intl-tel-input
        [cssClass]="'input-phone'"
        [onlyCountries]="[CountryISO.Netherlands, CountryISO.Belgium]"
        [enableAutoCountrySelect]="false"
        [enablePlaceholder]="true"
        [customPlaceholder]="customPlaceholder"
        [searchCountryFlag]="false"
        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
        [selectFirstCountry]="false"
        [selectedCountryISO]="getSelectedCountry()"
        [separateDialCode]="true"
        [numberFormat]="PhoneNumberFormat.National"
        (countryChange)="countryChanged($event)"
        maxlength="15"
        [phoneValidation]="true"
        inputId="input-phone"
        formControlName="phone"
        #phoneInputRef
      ></ngx-intl-tel-input>
      <div class="feedback-container">
        <!--        <div *ngIf="isValid && showValidMessage" class="success-message" [innerHTML]="'REGISTER.VALID_PHONE_NUMBER_TEXT' | translate:translatePhoneNumberParam"></div>-->
        <div *ngIf="!isValid && showErrorMessage" class="error-message">
          {{ errorMessageKey | translate }}
        </div>
      </div>
    </div>
</form>
  `,
  styleUrls: ['./input-phone.component.scss']
})
export class InputPhoneComponent implements AfterViewInit, OnInit, OnChanges {
  @ViewChild('phoneInputRef') phoneInputRef: NgxIntlTelInputComponent;
  phoneForm = new UntypedFormGroup({
    phone: new UntypedFormControl(undefined, [Validators.required])
  });

  @Input() countries: string[] = COUNTRY_OPTIONS;
  @Input() phoneNumber: PhoneNumberValidityInfo;
  @Input() showErrorMessage = false;
  @Input() focusOnInit = false;

  @Input() topCss: string | null = null;

  @Output() phoneNumberChanged = new EventEmitter();

  isValid = false;
  errorMessageKey = 'REGISTER.INVALID_PHONE_NUMBER_ERROR';

  translatePhoneNumberParam: {phoneNumber: string};

  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;

  customPlaceholder = "";

  ngOnInit(): void {
    this.initializePhoneInput();
  }

  ngAfterViewInit(): void {
    if (this.focusOnInit) document.getElementById('input-phone').focus();
    this.phoneForm.controls['phone'].valueChanges.subscribe((value: any) => {
      if(value) {
        if (this.phoneForm.controls['phone'].valid) {
          this.validPhoneNumber(value);
        } else {
          this.invalidPhoneNumber(value);
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {}


  getSelectedCountry() {
    return this.phoneNumber.phoneNumber.startsWith('+31') ? CountryISO.Netherlands : CountryISO.Belgium;
  }

  initializePhoneInput(): void {
    this.customPlaceholder = this.phoneNumber.phoneNumber.startsWith('+31') ? '6 12345678' : '470 12 34 56';

    if(this.phoneNumber && this.phoneNumber.phoneNumber) {
      this.phoneForm.controls['phone'].setValue(this.stripPrefix(this.phoneNumber.phoneNumber));
      this.phoneForm.updateValueAndValidity();
    }
  }

  validPhoneNumber(phoneNumber: any) {
    const countryIso = phoneNumber.countryCode;
    this.phoneNumberChanged.emit({phoneNumber: phoneNumber.e164Number, phoneNumberIsValid: true, phoneNumberCountryCode: countryIso});
    this.isValid = true;
    this.translatePhoneNumberParam = { phoneNumber: phoneNumber.e164Number };
  }

  invalidPhoneNumber(phoneNumber: any) {
    const countryIso = phoneNumber.countryCode;
    this.phoneNumberChanged.emit({phoneNumber: phoneNumber.e164Number, phoneNumberIsValid: false, phoneNumberCountryCode: countryIso});
    this.translatePhoneNumberParam = {phoneNumber: ''};
    this.isValid = false;
  }

  stripPrefix(phoneNumber: string) {
    const PNF = require('google-libphonenumber').PhoneNumberFormat;
    const phoneUtil = PhoneNumberUtil.getInstance();
    const number = phoneUtil.parseAndKeepRawInput(phoneNumber, this.phoneNumber.phoneNumberCountryCode);
    return phoneUtil.format(number, PNF.NATIONAL).replace('0', '');
  }

  countryChanged($event: Country) {
    this.customPlaceholder = $event.iso2 === CountryISO.Netherlands ? '6 12345678' : '470 12 34 56';
  }
}
