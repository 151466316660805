import { Component, Input, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'progress-bar-simple',
  templateUrl: './progress-bar-simple.component.html',
  styleUrls: ['./progress-bar-simple.component.scss']
})
export class ProgressBarSimpleComponent implements AfterViewInit, OnInit {
  @Input() color: string;
  @Input() percentage: number;

  animatedPercentage = 0;

  constructor() { }

  ngOnInit(): void {
  }

  async ngAfterViewInit(): Promise<void> {
    await new Promise(r => setTimeout(r, 500));
    this.animatedPercentage = this.percentage;
  }
}
