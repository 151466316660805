import { NgModule } from '@angular/core';
import { PreloadAllModules, Route, RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './modules/not-found/not-found.component';
import errorRoutes from './routes/error';

// Merge all routes in /routes folder
const routes: Routes = [
  ...errorRoutes,
  {
    path: '',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'care-givers-dashboard',
    loadChildren: () => import('./modules/care-givers-dashboard/care-givers-dashboard.module').then(m => m.CareGiversDashboardModule)
  },
  {
    path: 'partner',
    loadChildren: () => import('./modules/partner/partner.module').then(m => m.PartnerModule)
  },
  {
    path: 'atalmedial',
    loadChildren: () => import('./modules/atalmedial/atalmedial.module').then(m => m.AtalmedialModule)
  }
];

// Add 404 route at the end: any route that doesn't match a real route
const notFoundRoute: Route = {
  path: '**',
  component: NotFoundComponent
};
routes.push(notFoundRoute);

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})

export class RoutingModule {
}
