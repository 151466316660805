import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumKeyValue'
})
export class EnumKeyValuePipe implements PipeTransform {
  transform(value: any, includeZero: boolean = false): any {
    const keyValues = [];
    // tslint:disable-next-line:forin
    for (const enumMember in value) {
      const keyIntegerValue = parseInt(enumMember, 10);
      if (isNaN(keyIntegerValue) || (!includeZero && keyIntegerValue === 0)) {
        continue;
      }

      keyValues.push({ key: keyIntegerValue, value: value[keyIntegerValue] });
    }

    return keyValues;
  }
}
