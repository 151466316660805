import { Component, Input, OnDestroy } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { ApiBadgeService } from '../../api/api-badge.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'blue-dot',
  templateUrl: './blue-dot.component.html',
  styleUrls: ['./blue-dot.component.scss']
})
export class BlueDotComponent implements OnDestroy {
  @Input() hamburgerMenu = false;

  private _unsubscribe = new Subject();

  active = false;

  constructor(private readonly apiBadgeService: ApiBadgeService) {
    combineLatest([
      apiBadgeService.totalIncomingInvites,
      apiBadgeService.totalUnreadMessages
    ])
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(result => this.active = result[0] !== 0 || result[1] !== 0);
  }

  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
