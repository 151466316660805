import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, AfterViewInit } from '@angular/core';
import SmsVerification from '../../../shared/models/events/smsVerification';

@Component({
  selector: 'input-sms-code',
  template: `
    <form class="sms-code-container" #verifyCodeContainerRef (input)="handleInput($event)">
      <input #firstInput inputmode="numeric" type="text" maxlength="1" pattern="[0-9]*" (paste)="handlePaste($event)" (focus)="onFocusInput($event);">
      <input inputmode="numeric" type="text" maxlength="1" pattern="[0-9]*" (focus)="onFocusInput($event);">
      <input inputmode="numeric" type="text" maxlength="1" pattern="[0-9]*" (focus)="onFocusInput($event);">
      <input inputmode="numeric" type="text" maxlength="1" pattern="[0-9]*" (focus)="onFocusInput($event);">
      <input inputmode="numeric" type="text" maxlength="1" pattern="[0-9]*" (focus)="onFocusInput($event);">
      <input inputmode="numeric" type="text" maxlength="1" pattern="[0-9]*" (focus)="onFocusInput($event);">
    </form>
  `,
  styleUrls: ['./input-sms-code.component.scss']
})
export class InputSmsCodeComponent implements AfterViewInit {
  @ViewChild('verifyCodeContainerRef') verifyCodeContainerRef: ElementRef;
  @ViewChild('firstInput') firstInput: ElementRef;

  @Input() smsVerificationCode: string;
  @Output() smsVerificationChanged = new EventEmitter<SmsVerification>();

  ngAfterViewInit(): void {
    const codeInputs = this.verifyCodeContainerRef.nativeElement.querySelectorAll('input');
    for (let i = 0; i < codeInputs.length; i++) {
      if (this.smsVerificationCode && this.smsVerificationCode[i]) {
        codeInputs[i].value = this.smsVerificationCode[i];
      }
    }
    this.firstInput.nativeElement.focus();
  }

  handleInput($event: Event): void {
    const inputtedInput: HTMLInputElement = (<HTMLInputElement>$event.target);
    if (inputtedInput && inputtedInput.nextElementSibling && inputtedInput.value) {
      const nextInput: HTMLInputElement = <HTMLInputElement>inputtedInput.nextElementSibling;
      if (nextInput) {
        nextInput.focus();
      }
    }
    this.emitVerificationCode();
  }

  /** If a code input is focused and it already has a character in it, select it instantly */
  onFocusInput($event: FocusEvent): void {
    const focusedInput: HTMLInputElement = (<HTMLInputElement>$event.target);
    if (focusedInput.value) {
      focusedInput.select();
    }
  }

  /** If you perform a paste event at the first input, past the remaining chars in the remaining inputs */
  handlePaste($event: ClipboardEvent): void {
    if ($event && $event.clipboardData) {
      const clipboard = $event.clipboardData.getData('text');
      const codeInputs = this.verifyCodeContainerRef.nativeElement.querySelectorAll('input');
      for (let i = 0; i < codeInputs.length; i++) {
        codeInputs[i].value = clipboard[i] ? clipboard[i] : '';
      }
    }
    this.emitVerificationCode();
  }

  emitVerificationCode(): void {
    const codeInputs = this.verifyCodeContainerRef.nativeElement.querySelectorAll('input');
    let fullVerificationCode = '';
    for (const i in codeInputs) {
      if (codeInputs[i].value) {
        fullVerificationCode += codeInputs[i].value;
      }
    }
    this.smsVerificationChanged.emit({smsVerificationCode: fullVerificationCode, smsCodeInput6Digit: fullVerificationCode.length === 6});
  }
}
