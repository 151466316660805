<div class="aggregate-data-block-wrapper">
  <div class="box-border" [inlineSVG]="'/measurements/details/' + dataBlockTypeSvg"></div>
  <div class="text-wrapper">
    <p class="aggregate-text">{{ labelString | translate }}</p>
    <p class="value"
       [ngClass]="{
        'red-text': dataBlockType === AggregateDataBlock.MINIMUM,
        'yellow-text': dataBlockType === AggregateDataBlock.AVERAGE,
        'green-text': dataBlockType === AggregateDataBlock.MAXIMUM
      }" [innerHTML]="value | unit:unit:unitAsDuration:false:null:true"></p>
  </div>

</div>
