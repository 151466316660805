enum Invitation {
  CHALLENGE,
  RESEARCH,
  DEFAULT,
  REWARDS,
  HEALTH,
  CAREGIVER,
  CONNECTION
}

export default Invitation;
