import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { MedmijAuthService } from '../utils/medmij-auth.service';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { EventQueueService } from '../utils/event-queue.service';
import {NgxSmartModalService} from "ngx-smart-modal";

@Injectable()
export class MedMijInterceptor implements HttpInterceptor {

  constructor(private medMijAuthService: MedmijAuthService,
              private eventQueueService: EventQueueService,
              private modalService: NgxSmartModalService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.shouldIntercept(request)) {
      return next.handle(request);
    }

    let req = request;

    if (this.medMijAuthService.medMijAccessToken) {
      req = request.clone({
        headers: request.headers.set('medmij-access-token', this.medMijAuthService.medMijAccessToken)
      });

      const partnerId = localStorage.getItem('partnerId');
      const AIGuid = localStorage.getItem('AI_Guid');
      if(partnerId === "AM" && AIGuid) {
        req = req.clone({
          headers: req.headers
            .set('X-Partner-Id', partnerId)
            .set('X-AI-Guid', AIGuid)
        })
      }
    }

    if (req.method === 'GET') {
      req = req.clone({
        headers: req.headers
          .set('Cache-Control', 'no-cache')
          .set('Pragma', 'no-cache')
          .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
      });
    }

    return next.handle(req).pipe(catchError(err => this.handleError(err)));
  }

  shouldIntercept(request: HttpRequest<unknown>): boolean {
    const medmijUrl = new URL(environment.apiMedMijUrl);
    const requestUrl = new URL(request.url);

    return requestUrl.origin === medmijUrl.origin;
  }

  handleError(error: HttpErrorResponse | unknown): Observable<never> {
    if ((error as HttpErrorResponse).status === 601) {
      this.modalService.open('medMijTokenExpiredModal');
    }
    if (error instanceof HttpErrorResponse && (error as HttpErrorResponse).status !== 601) {
      this.eventQueueService.dispatch('MedMij-Http-Error', error);
    }

    return throwError(error);
  }
}
