<ngx-smart-modal
  [identifier]="modalId"
  [closable]="false"
  [hideDelay]="200"
  (onOpenFinished)="onModalOpenFinished()"
  (onAnyCloseEventFinished)="resetForm()"
>
  <form [formGroup]="addDataForm" class="form-add-data">
    <div class="add-data-panel-content">
      <div class="title-wrapper">
        <div *ngIf="measurementType" class="measurement-svg" [inlineSVG]="'measurements/' + measurementType.icon"></div>
        <h2 *ngIf="isEdit" class="title-add-data">{{ 'DASHBOARD.PANEL.EDIT_DATA_TITLE' | translate }}</h2>
        <h2 *ngIf="!isEdit" class="title-add-data">{{ 'DASHBOARD.PANEL.ADD_DATA_TITLE' | translate }}</h2>
        <img
          (click)="modal.getModal(modalId).close()"
          class="close-button"
          src="/assets/img/icn_close.svg" alt="close"
        >
      </div>
      <p class="text-add-data" *ngIf="measurementTypeTitleLowerCase">
        {{ 'DASHBOARD.PANEL.ADD_DATA_TEXT_1' | translate:{ measurementType: measurementTypeTitleLowerCase } }} {{ 'DASHBOARD.PANEL.ADD_DATA_TEXT_2' | translate }}
      </p>
      <div class="field-wrapper">
        <div class="title-field">
          {{ 'SHARED.INPUT_LABEL_DATE' | translate }} *
        </div>
        <date-time-picker
          [includeTime]="true"
          [dateInputIsEditable]="false"
          [date]="addDataForm.get('date').value"
          [maxEndDate]="now"
          (dateTimeSaved)="addDataForm.get('date').setValue($event.date)"
        ></date-time-picker>
      </div>
      <div class="field-wrapper"  *ngIf="!showDurationInput(measurementTypeId)">
        <div class="title-field">
          {{ 'DASHBOARD.PANEL.ADD_MANUAL_MEASUREMENT_INPUT_LABEL' | translate }} *
        </div>
<!--        *ngIf="measurementTypeTitleLowerCase"-->
        <input-text
          id="measurement-value-input"
          [backgroundStyle]="'grey'"
          type="text"
          formControlName="value"
          (input)="onMeasurementValueInput($event)"
          placeholder="{{ 'DASHBOARD.PANEL.ADD_MANUAL_MEASUREMENT_INPUT_PLACEHOLDER' | translate:{ measurementType: measurementTypeTitleLowerCase } }}"
        >
        </input-text>

      </div>
      <div class="field-wrapper" *ngIf="showDurationInput(measurementTypeId)">
        <div class="title-field">
          {{ 'DASHBOARD.PANEL.ADD_MANUAL_MEASUREMENT_INPUT_LABEL' | translate }} *
        </div>
        <duration-input-field (onDurationChanged)="setDurationValue($event)"></duration-input-field>
      </div>
      <div class="field-wrapper">
        <div class="title-field">
          {{ 'DASHBOARD.PANEL.INPUT_LABEL_EMOJI' | translate }} ({{ 'SHARED.OPTIONAL' | translate }})
        </div>
        <emoji-list
          #emojiListComponent
          [isDisabled]="!isPremium"
          formControlName="mood"
          (isClickedWhenDisabled)="modal.open('premiumModal')"
        >
        </emoji-list>
      </div>
      <div class="field-wrapper">
        <div class="title-field">
          {{ 'DASHBOARD.NOTES' | translate }} ({{ 'SHARED.OPTIONAL' | translate }})
        </div>
        <input-text-field
          formControlName="note"
          [fakeDisabled]="!isPremium"
          [maxCharacters]="300"
          [backgroundStyle]="'grey'"
          placeholder="{{ 'DASHBOARD.PANEL.INPUT_NOTE_PLACEHOLDER_NOTE' | translate }}"
          (isClickedWhenDisabled)="modal.open('premiumModal')"
        >
        </input-text-field>
      </div>
      <div class="field-wrapper">
        <div class="title-field">
          {{ 'DASHBOARD.PANEL.INPUT_IMAGE_LABEL_UPLOAD' | translate }} ({{ 'SHARED.OPTIONAL' | translate }})
        </div>
        <div class="uploader-wrapper" [ngClass]="{ disabled: !isPremium }">
          <file-uploader
            formControlName="image"
            [maxFileSize]="5"
            [isDisabled]="!isPremium"
            icon="icn_image.svg"
            [callback]="setUploadedImage.bind(this)"
            [originalImage]="addDataForm?.get('originalImage') ? addDataForm.get('originalImage').value : null"
            [isPremiumFeature]="true"
            accept=".jpg,.jpeg,.png,.webp,.gif"
            placeholder="{{ 'SHARED.FILE_MAX_SIZE' | translate : {size: 5} }}"></file-uploader>
        </div>
      </div>
    </div>
    <div *ngIf="formError" class="error-message" style="margin-top: 16px;">
      {{ formError }}
    </div>
    <div class="button-wrapper">
      <app-button
        [fakeDisabled]="!isFormValid"
        buttonText="{{ 'SHARED.SAVE' | translate }}"
        [styles]="[BUTTON_STYLES.shaped, BUTTON_STYLES.dark]"
        (click)="saveMeasurement()"
      >
      </app-button>
    </div>
  </form>
</ngx-smart-modal>

