import {Component, EventEmitter, Input, Output} from '@angular/core';
import MemberPermissionsResponse from '../../../models/connections/memberPermissionsResponse';
import {BUTTON_STYLES} from '../../../components/button/button.component';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ApiService} from '../../../api/api.service';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {CommunityMember} from '../../../models/connections/communityMembersResponse';
import {MeasurementService} from '../../../measurements/measurement.service';
import {MeasurementFilterBlockService} from '../../../utils/measurement-filter-block.service';
import {TranslateService} from '@ngx-translate/core';
import {MeasurementCategory} from '../../../models/enums/MeasurementEnums';
import {ApiCommunityService} from '../../../api/api-community.service';
import {UpdateContactPermissionsPutBody} from '../../../models/connections/contact';
import {MeasurementTypeSelectionResponse} from "../../../models/dashboard/MeasurementTypeSelectionGroup";

@Component({
  selector: 'connection-permissions-popup',
  templateUrl: './connection-permissions-popup.component.html',
  styleUrls: ['./connection-permissions-popup.component.scss']
})
export class ConnectionPermissionsPopupComponent {
  @Input() member: CommunityMember;

  @Output() onDataChanged = new EventEmitter();

  memberPermissionsData: MemberPermissionsResponse = null;
  memberPermissionsDataLoaded = false;

  BUTTON_STYLES = BUTTON_STYLES;

  identifier = 'connectionPermissionsModal';
  connectionPermissionsForm: UntypedFormGroup;
  enabledMeasurements: UntypedFormGroup;
  isCompetitionEnabled = false;
  currentCompetitionStatus: false;
  enabledMeasurementStrings: any;
  healthcareProvidersString = '';

  measurementTypeConfig: MeasurementTypeSelectionResponse;

  constructor(
    readonly formBuilder: UntypedFormBuilder,
    readonly apiService: ApiService,
    readonly apiCommunityService: ApiCommunityService,
    readonly modal: NgxSmartModalService,
    readonly measurementService: MeasurementService,
    readonly measurementFilterBlockService: MeasurementFilterBlockService,
    readonly translate: TranslateService,
  ) { }

  async loadMemberPermissionData(): Promise<void> {
    this.memberPermissionsDataLoaded = false;
    this.measurementTypeConfig = await this.apiService.getUserMeasurementTypeConfiguration();
    this.measurementService.loadCustomTypes(this.measurementTypeConfig);
    this.memberPermissionsData = await this.apiCommunityService.getContactPermissions(this.member.id);
    this.enabledMeasurements = this.measurementFilterBlockService.getMeasurementCategoriesFormGroup(this.memberPermissionsData.selectedMeasurementTypes);
    this.loadFormData();
    this.memberPermissionsDataLoaded = true;
  }

  async onOpen(): Promise<void> {
    const memberPermissionData = await this.loadMemberPermissionData();
    // For changing text 'start competition' / 'stop competition'
    this.isCompetitionEnabled = this.connectionPermissionsForm.get('startCompetition').value;
    this.enabledMeasurementStrings = this.getEnabledMeasurementStrings();
    this.healthcareProvidersString = this.getHealthcareProvidersString();
    this.subscribeMethods();
  }

  subscribeMethods(): void {
    this.connectionPermissionsForm.get('startCompetition').valueChanges.subscribe(value => {
      this.isCompetitionEnabled = value;
    });
    this.connectionPermissionsForm.get('healthcareProviders').valueChanges.subscribe(val => {
      this.healthcareProvidersString = val ? this.getHealthcareProvidersString() : '';
    });

    Object.keys(this.connectionPermissionsForm.get('myMeasurements').value).forEach(categoryId => {
      this.connectionPermissionsForm.get(`myMeasurements.${categoryId}`).valueChanges.subscribe(val => {
        this.openConnectionPermissionsMeasurements(val);
        if (!this.connectionPermissionsForm.get(`myMeasurements.${categoryId}`).value) {
          this.enabledMeasurementStrings[`${categoryId}_MEASUREMENTS`] = '';
          this.disableFormGroupChildren(this.enabledMeasurements.get(`${categoryId}_MEASUREMENTS`) as UntypedFormGroup);
        } else {
          this.enabledMeasurementStrings = this.getEnabledMeasurementStrings();
        }
      });
    });
  }

  loadFormData(): void {
    this.connectionPermissionsForm = this.formBuilder.group({
      startCompetition: this.memberPermissionsData.competitionStatus,
      myMeasurements: this.formBuilder.group(this.getMeasurementsFormData()),
      healthcareProviders: this.memberPermissionsData.selectedHealthcareProviderIds,
      extras: this.formBuilder.group({
        myProfile: this.memberPermissionsData.myProfile,
        receiveNotifications: this.memberPermissionsData.coachMessagesAndNotifications
      })
    });
  }

  disableFormGroupChildren(formGroup: UntypedFormGroup): void {
    Object.keys(formGroup.value).forEach(key => formGroup.get(key).setValue(false));
  }

  getMeasurementsFormData(): any {
    return {
      MEDICAL: this.getEnabledMeasurementsArray(MeasurementCategory.MEDICAL_MEASUREMENTS).length > 0,
      LIFESTYLE: this.getEnabledMeasurementsArray(MeasurementCategory.LIFESTYLE_MEASUREMENTS).length > 0,
      PHYSICAL: this.getEnabledMeasurementsArray(MeasurementCategory.PHYSICAL_MEASUREMENTS).length > 0,
      BLOOD: this.getEnabledMeasurementsArray(MeasurementCategory.BLOOD_MEASUREMENTS).length > 0,
      MENTAL: this.getEnabledMeasurementsArray(MeasurementCategory.MENTAL_MEASUREMENTS).length > 0,
      CUSTOM: this.getEnabledMeasurementsArray(MeasurementCategory.CUSTOM_MEASUREMENTS).length > 0
    };
  }

  async removeConnection(): Promise<void> {
    this.modal.close('removeConnectionPopup');
    const revokeContactRes = await this.apiCommunityService.deleteContact(this.member.id);

    this.modal.close(this.identifier);
    this.onDataChanged.emit();
  }

  getEnabledMeasurementStrings(): any {
    const enabledMeasurementStrings: any = {};
    const measurementCategories = Object.keys(this.enabledMeasurements.value);
    measurementCategories.forEach(category => {
      const enabledMeasurements = Object.keys(this.enabledMeasurements.value[category]).filter(m => this.enabledMeasurements.value[category][m]);
      let translatedMeasurementStrings = enabledMeasurements.map(typeKey => {
        if(category === MeasurementCategory.CUSTOM_MEASUREMENTS) {
          return this.measurementService.customMeasurementTypes.find(cmt => cmt.id === typeKey).customName;
        } else {
          return this.translate.instant(`MEASUREMENT_DETAILS.MEASUREMENTS.${typeKey}.TITLE`);
        }
      }).sort();
      let moreText = '';
      if (translatedMeasurementStrings.length > 3) {
        translatedMeasurementStrings = translatedMeasurementStrings.slice(0, 3);
        const moreLength = translatedMeasurementStrings.length - 1;
        moreText = ' ' + this.translate.instant('SETTINGS.MORE', {moreNum: moreLength});
      }
      enabledMeasurementStrings[category] = translatedMeasurementStrings.join(', ') + moreText;
    });
    return enabledMeasurementStrings;
  }

  getHealthcareProvidersString(): string {
    if (!this.memberPermissionsData.selectedHealthcareProviderIds) return '';
    let moreText = '';
    let shownHealthcareProviders = this.memberPermissionsData.allHealthcareProviders.filter((p: any) => this.memberPermissionsData.selectedHealthcareProviderIds.includes(p.id)).map(p => p.name);
    if (this.memberPermissionsData.selectedHealthcareProviderIds.length > 3) {
      shownHealthcareProviders = this.memberPermissionsData.selectedHealthcareProviderIds.sort().slice(0, 3);
      const moreLength = this.memberPermissionsData.selectedHealthcareProviderIds.length - 3;
      moreText = ' ' + this.translate.instant('SETTINGS.MORE', {moreNum: moreLength});
    }
    return shownHealthcareProviders.join(', ') + moreText;
  }

  openConnectionPermissionsMeasurements(value: boolean): void {
    if (value) {
      this.modal.open('addMeasurementPermissions');
    }
  }

  refreshData(): void {
    this.connectionPermissionsForm.get('myMeasurements').setValue(this.getMeasurementsFormData());
    this.enabledMeasurementStrings = this.getEnabledMeasurementStrings();
    this.subscribeMethods();
  }

  async saveConnectionPermissionsData(): Promise<void> {
    const body: UpdateContactPermissionsPutBody = {
      competitionStatus: this.connectionPermissionsForm.get('startCompetition').value,
      measurementTypes: [
        ...this.getEnabledMeasurementsArray(MeasurementCategory.MEDICAL_MEASUREMENTS),
        ...this.getEnabledMeasurementsArray(MeasurementCategory.LIFESTYLE_MEASUREMENTS),
        ...this.getEnabledMeasurementsArray(MeasurementCategory.PHYSICAL_MEASUREMENTS),
        ...this.getEnabledMeasurementsArray(MeasurementCategory.MENTAL_MEASUREMENTS),
        ...this.getEnabledMeasurementsArray(MeasurementCategory.BLOOD_MEASUREMENTS),
        ...this.getEnabledMeasurementsArray(MeasurementCategory.CUSTOM_MEASUREMENTS)
      ],
      healthcareProviders: [],
      myProfile: this.connectionPermissionsForm.get('extras.myProfile').value,
      coachMessagesAndNotifications: this.connectionPermissionsForm.get('extras.receiveNotifications').value
    };
    await this.apiCommunityService.updateContactPermissions(this.member.id, body);
    this.modal.close(this.identifier);
    this.onDataChanged.emit();
  }

  getEnabledMeasurementsArray(category: MeasurementCategory): any {
    const measurements = this.enabledMeasurements.value[category];
    return Object.keys(measurements).filter(mKey => measurements[mKey]);
  }
}
