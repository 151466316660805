import { Component, ElementRef, forwardRef } from '@angular/core';
import { FileUploaderComponent } from '../file-uploader.component';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BUTTON_STYLES } from '../../button/button.component';
import { ApiService } from 'src/app/shared/api/api.service';
import { PremiumService } from '../../../utils/premiumService';

@Component({
  selector: 'app-profile-picture',
  templateUrl: './profile-picture.component.html',
  styleUrls: ['./profile-picture.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ProfilePictureComponent),
    multi: true
  }]
})
export class ProfilePictureComponent extends FileUploaderComponent {

  readonly BUTTON_STYLES = BUTTON_STYLES;
  inputId = 'profile-picture-input';

  constructor(
    host: ElementRef<HTMLInputElement>,
    translate: TranslateService,
    premiumService: PremiumService,
    private api: ApiService,
    public modal: NgxSmartModalService,
  ){
    super(host, translate, premiumService);
  }

  // ngOnInit(): void {
  // }

  selectProfilePicture(): void {
    // console.log('selecting profile pic');
    const profilePicInput = document.getElementById(this.inputId);
    profilePicInput.click();
  }

  closeModal(t: EventTarget): void {
    if (t) {
      const target = t as HTMLInputElement;
      const file: File = target.files[0];
      this.api.userUpdateProfilePicture(file);
    }

    this.modal.getModal('uploadPhotoModal').close();
  }
}
