import { MeasurementLimitIndication, Mood, SavedMeasurementNote } from './MeasurementEnums';
import { TimeUnit } from 'chart.js';
import { MeasurementValueApi } from './MeasurementGraphApiResult';

export interface MeasurementGraphDataModel {
  timePeriod: TimePeriod;
  // todo: determine whether this model should include it. For now not.
  // graphType: GraphType;
  xAxisStartDate: Date;
  xAxisEndDate: Date;
  yAxisMinValue: number;
  yAxisMaxValue: number;
  yAxisLabel: string;
  dataPoints: DataPoint[];
  xAxisTicksUnit: TimeUnit;
  // for the ticks the dates are a little expanded on both sides to nicely render the labels at the edges of the x-axis
  xAxisTicksStartDate: Date;
  xAxisTicksEndDate: Date;
  xAxisLabel: string;
  animateDataPoints: boolean;
  gradientDataLineGraph: GradientDataLineGraph[];
}

// note: the startDate and the value need to be called x and y for the graph
export interface DataPoint {
  // timestamp of the datapoint
  x: Date;
  startDateIsoString: string;
  endDate: Date;
  endDateIsoString: string;
  // value of the datapoint
  y: number;
  valueFormatted: string;
  quality: MeasurementLimitIndication;
  qualityColor: string;
  // how many measurements are part of the data point
  totalMeasurements: number;
  totalMeasurementsFormatted: string;
  // the source of each measurement
  addedManuallyCount: number;
  addedManuallyCountFormatted: string;
  addedBySensorCount: number;
  addedBySensorCountFormatted: string;
  addedByExternalProviderCount: number;
  addedByExternalProviderCountFormatted: string;
  measurements: MeasurementValueApi[];
  isPrecedentDataPoint?: boolean;
  isSubsequentDataPoint?: boolean;
  element: any;
}

// calendar can use way simplified DataPoints
export interface CalendarDataPoint {
  // timestamp of the datapoint
  date: Date;
  qualityColor: string;
  extraText?: string;
}

export interface DataPointTooltipModel {
  dataPoint: DataPoint;
  timePeriod: TimePeriod;
  // position in px
  xPos: number;
  yPos: number;
  note?: SavedMeasurementNote;
  mood?: Mood;
  // sources: {
  //   addedManuallyCount: number;
  //   addedBySensorCount: number;
  //   addedByExternalProviderCount: number;
  // };
}

export interface GradientDataLineGraph {
  color: string;
  // just for debugging, leave
  // colorHumanString: string;
  // the value at which a gradient switches color
  value: number;
}

export interface GradientDataCanvas {
  width: number;
  height: number;
  gradient: CanvasGradient;
}

// bar chart, line chart, calendar
export enum GraphType {
  BAR_CHART = 'bar',
  LINE_CHART = 'line',
  CALENDAR = 'calendar',
}

/** The selectable time periods **/
export enum TimePeriod {
  DAY = 'Day',
  WEEK = 'Week',
  MONTH = 'Month',
  CALENDAR_MONTH = 'CalendarMonth',
  TRIMESTER = 'Trimester',
  // QUARTER = 'Quarter',
  YEAR = 'Year',
  ALL = 'All',
}

/** Translation keys of the TimePeriods */
export const TIME_PERIOD_TRANSLATION_KEYS = Object.freeze({
  Day: 'TIME_INTERVAL_STRINGS.THIS_DAY',
  Week: 'TIME_INTERVAL_STRINGS.THIS_WEEK',
  Month: 'TIME_INTERVAL_STRINGS.THIS_MONTH',
  CalendarMonth: 'TIME_INTERVAL_STRINGS.THIS_MONTH',
  Trimester: 'TIME_INTERVAL_STRINGS.THIS_TRIMESTER',
  // Quarter: 'TIME_INTERVAL_STRINGS.THIS_QUARTER',
  Year: 'TIME_INTERVAL_STRINGS.THIS_YEAR',
  All: 'TIME_INTERVAL_STRINGS.THIS_ALL',
});
