/**
 * Utility class for validating string inputs
 */

// regexes for Asian alphabets
const ASIAN_CHARACTER_REGEX = [
  // chinese
  /[\u4e00-\u9fff]|[\u3400-\u4dbf]|[\u{20000}-\u{2a6df}]|[\u{2a700}-\u{2b73f}]|[\u{2b740}-\u{2b81f}]|[\u{2b820}-\u{2ceaf}]|[\u3300-\u33ff]|[\ufe30-\ufe4f]|[\uf900-\ufaff]|[\u{2f800}-\u{2fa1f}]/u,
  // japanese
  /[\u3000-\u303f]|[\u3040-\u309f]|[\u30a0-\u30ff]|[\uff00-\uff9f]|[\u4e00-\u9faf]|[\u3400-\u4dbf]/,
  // indian
  /[\u0900-\u097F]/
];

const EMOJI_REGEX = /(\p{Emoji_Presentation}|\p{Extended_Pictographic})/gu;
const DIGIT_REGEX = /\d/;
const SYMBOL_REGEX = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;

// const EMAIL_REGEX = '(.+){2,}@(.+){2,}.(.+){2,}';
const EMAIL_REGEX = /.{2,}@.{2,}\..{2,}/;
// const EMAIL_REGEX = /(.+){2,}@(.+){2,}\.(.+){2,}/;

// const DIGIT_REGEX_CHAR = /^\d+$/;

export function containsEmoji(str: string): boolean {
  return EMOJI_REGEX.test(str);
}

export function containsDigit(str: string): boolean {
  return DIGIT_REGEX.test(str);
}

export function containsUppercase(str: string): boolean {
  return str.toLowerCase() !== str;
}

export function containsLowercase(str: string): boolean {
  return str.toUpperCase() !== str;
}

export function containsSymbol(str: string): boolean {
  return SYMBOL_REGEX.test(str);
}

export function charIsDigit(char: string): boolean {
  return DIGIT_REGEX.test(char);
}

export function isAsianChar(char: string): boolean {
  for (const i in ASIAN_CHARACTER_REGEX) {
    if (ASIAN_CHARACTER_REGEX[i].test(char)) {
      return true;
    }
  }
  return false;
}

/**
 * @param str
 * Valid email:
 * -Required chars: '.' and '@'
 * -At least 2 characters before the '@'
 * Domain:
 * -At least 2 characters after the '@' and before the '.'
 * -At least 2 characters after the '.'
 */
// export function validEmail(str: string): boolean {
//   return EMAIL_REGEX.test(str);
// }
export const emailRegex = EMAIL_REGEX;
