<div class="profile-photo-uploader-wrapper">
  <div class="file-upload" (click)="modal.getModal('uploadPhotoModal').open()">
    <div *ngIf="!file && !imagePreview" class="icon-file-uploader" [inlineSVG]="icon"></div>
    <div *ngIf="file || imagePreview" class="icon-file-uploader">
      <img class="uploaded-image-preview" src="{{ imagePreview }}">
    </div>
    <div class="icon-uploader" inlineSVG="icn_edit_pencil.svg"></div>
  </div>
</div>

<ngx-smart-modal
  identifier="uploadPhotoModal"
  [closable]="false"
  [hideDelay]="200"
  customClass="include-padding small-modal"
>
  <h3 class="main-title" [innerText]="'PROFILE_DETAILS.EDIT_PROFILE.UPLOAD_PROFILE' | translate"></h3>
  <p class="description-1" [innerText]="'PROFILE_DETAILS.EDIT_PROFILE.UPLOAD_PROFILE_POPUP.DESCRIPTION' | translate"></p>

  <div class="upload-photo-buttons">
    <!-- <div class="custom-upload-profile">
      TODO: Skipping for now
      <input type="file" hidden/>
      <app-button buttonText="{{ 'PROFILE_DETAILS.EDIT_PROFILE.UPLOAD_PROFILE_POPUP.TAKE_A_PHOTO' | translate }}"></app-button>
    </div>-->
    <div class="custom-upload-profile">
      <label [for]="inputId" [ngClass]="{'label-file-uploaded': file, 'label-error': !!error}"></label>
      <input
        [accept]="'.jpg,.jpeg,.png'"
        (change)="loadFile($event.target)"
        (input)="closeModal($event.target)"
        [id]="inputId"
        [name]="inputId"
        [multiple]="false"
        type="file"
      >
      <app-button
        buttonText="{{ 'PROFILE_DETAILS.EDIT_PROFILE.UPLOAD_PROFILE_POPUP.CHOOSE_FROM_GALLERY' | translate }}"
        (click)="selectProfilePicture()"
      ></app-button>
    </div>
    <div class="custom-upload-profile">
      <app-button
        buttonText="{{ 'SHARED.CANCEL' | translate }}"
        [styles]="[BUTTON_STYLES.secondary]"
        (click)="modal.getModal('uploadPhotoModal').close()"
      >
      </app-button>
    </div>
  </div>
</ngx-smart-modal>
