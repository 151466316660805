import {Pipe, PipeTransform} from "@angular/core";
import {DateService} from "../utils/date.service";
import {NumberService} from "../utils/number.service";

@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {
  constructor(
    public readonly numberService: NumberService,
  ) {}

  transform(value: number, decimals: number = 0, absoluteValue: boolean = false): any {
    return value || value === 0 ? this.numberService.formatNumber(value, decimals, absoluteValue) : null;
  }
}
