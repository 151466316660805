<ngx-smart-modal
  [identifier]="modalId"
  [closable]="false"
  [hideDelay]="200"
  (onAnyCloseEventFinished)="resetValues()"
>
<!--  (onOpenFinished)="onModalOpenFinished()"-->
  <form [formGroup]="addNoteForm" class="form-add-note">
    <div class="add-note-panel-content">
      <div class="title-wrapper">
        <div class="icon-add" inlineSVG="icn_note.svg"></div>
        <h2 class="title-add-note">{{ editedItemId ? ('DASHBOARD.PANEL.EDIT_NOTE_TITLE' | translate) : ('DASHBOARD.PANEL.ADD_NOTE_TITLE' | translate) }}</h2>
        <img
          (click)="modal.getModal(modalId).close()"
          class="close-button"
          src="/assets/img/icn_close.svg" alt="close">
      </div>
      <p class="text-add-note">
        {{ editedItemId ? ('DASHBOARD.PANEL.EDIT_NOTE_TEXT_1' | translate) : ('DASHBOARD.PANEL.ADD_NOTE_TEXT_1' | translate) }}
        <br>
        {{ editedItemId ? ('DASHBOARD.PANEL.EDIT_NOTE_TEXT_2' | translate) : ('DASHBOARD.PANEL.ADD_NOTE_TEXT_2' | translate) }}
      </p>
      <div class="field-wrapper">
        <div class="title-field">
          {{ 'SHARED.INPUT_LABEL_DATE' | translate }} *
        </div>
        <date-time-picker
          [includeTime]="true"
          [dateInputIsEditable]="false"
          [date]="addNoteForm.get('date').value"
          [dateStringFormat]="DateStringFormat.NUMBERS_WITH_TIME"
          [maxEndDate]="now"
          (dateTimeSaved)="addNoteForm.get('date').setValue($event.date)"
        ></date-time-picker>
      </div>
      <div class="field-wrapper">
        <div class="title-field">
          {{ 'DASHBOARD.PANEL.INPUT_LABEL_EMOJI' | translate }} ({{ 'SHARED.OPTIONAL' | translate }})
        </div>
        <emoji-list
          #emojiListComponent
          formControlName="mood"
        >
        </emoji-list>
      </div>
      <div class="field-wrapper">
        <div class="title-field">
          {{ 'DASHBOARD.NOTES' | translate }} *
        </div>
        <input-text-field
          formControlName="note"
          [required]="true"
          [maxCharacters]="300"
          [backgroundStyle]="'grey'"
          placeholder="{{ 'DASHBOARD.PANEL.INPUT_NOTE_PLACEHOLDER_NOTE' | translate }}">
        </input-text-field>
      </div>
    </div>
    <div class="button-wrapper">
      <app-button
        [disabled]="!isFormValid"
        buttonText="{{ 'SHARED.SAVE' | translate }}"
        [styles]="[BUTTON_STYLES.shaped, BUTTON_STYLES.dark]"
        (click)="saveNote()">
      </app-button>
    </div>
  </form>
</ngx-smart-modal>
