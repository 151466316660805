import { Component, HostBinding, Input, OnChanges, SimpleChanges} from '@angular/core';
import { trigger, animate, style, transition } from '@angular/animations';

@Component({
  selector: 'tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
  animations: [
    trigger('fadeContent', [
      // transition(':leave', [
      //   animate('0.2s', style({opacity: 0}))
      // ]),
      transition(':enter', [
        style({opacity: 0}),
        animate('0.2s', style({opacity: 1}))
        // animate('0.2s 0.25s', style({opacity: 1}))
      ]),
    ])
  ]
})
export class TabComponent implements OnChanges {
  @Input() name: string;
  @Input() isActive = false;
  @Input() routerLinkUrl: string;
  @Input() counterValue: number;
  @Input() hideTab = false;

  @HostBinding('class.is-active') activeClass = false;

  constructor(
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isActive) {
      this.activeClass = this.isActive;
    }
  }
}
