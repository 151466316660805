import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Partner } from '../models/partner';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {

  constructor(private oauthService: OAuthService) {
  }

  isPartnerVital10() {
    const claimsObj: any = this.oauthService.getIdentityClaims();
    if(claimsObj) {
      return claimsObj['idp'] === Partner.VITAL10;
    }
    return false;
  }
}
