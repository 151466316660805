import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NativeAppService {

  constructor() { }

  private getNativeApp(): NativeApp | undefined {
    return window.webkit?.messageHandlers?.NativeApp || window.NativeApp;
  }

  postMessageToNativeApp(message: any): void {
    const nativeApp = this.getNativeApp();
    if (nativeApp) {
      nativeApp.postMessage(JSON.stringify(message));
    }
  }

  nativeAppAvailable = (): boolean => !!this.getNativeApp();

  isIOS = (): boolean => !!window.webkit?.messageHandlers?.NativeApp;

  hasAndroidBridge = (name: string): boolean => !!window.hasOwnProperty(name);
}
