<div class="chat-input-field-wrapper">
<!--  <div class="upload-button-wrapper" (click)="openFileSelector()">-->
<!--    <img src="/assets/img/chat/icn_add-attachment.svg">-->
<!--  </div>-->
  <div
    class="text-input-wrapper"
  >
    <div
      *ngIf="!inputText.length"
      class="placeholder-wrapper">
      {{ placeholder }}
    </div>
    <div
      #textInput
      id="chat-text-input-field"
      class="chat-text-input-field"
      (input)="handleInput($event)"
      (keydown.enter)="onEnterKeyPressed()"
      contenteditable="true"
    >
    </div>
<!--    <input-->
<!--      #fileInput-->
<!--      type="file"-->
<!--      class="chat-file-input"-->
<!--      [accept]="ALLOWED_MIME_TYPES"-->
<!--      (change)="handleFileSelected($event)"-->
<!--      [multiple]="true"-->
<!--    >-->
  </div>
  <div
    class="button-send"
    (click)="sendTextMessage()"
    >
    <img src="/assets/img/chat/icn_send-message.svg">
  </div>
</div>

<ngx-smart-modal
  [identifier]="errorModalId"
  [hideDelay]="200"
  [closable]="false"
  customClass="small-modal include-padding"
>
  <div class="error-modal-wrapper">
    <h2>{{ 'NETWORK.ERROR_STATE.ERROR_UPLOADING_FILE' | translate }}</h2>
    <p [innerHTML]="errorModalMessage"></p>
    <div class="bottom-button-section">
      <app-button
        [buttonText]="'SHARED.OK' | translate"
        (click)="modal.getModal(errorModalId).close()"
      ></app-button>
    </div>
  </div>
</ngx-smart-modal>
