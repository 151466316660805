import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'input-text-field',
  templateUrl: './input-text-field.component.html',
  styleUrls: ['./input-text-field.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputTextFieldComponent),
    multi: true
  }]
})
export class InputTextFieldComponent implements OnInit, ControlValueAccessor {
  @ViewChild('textFieldRef') inputRef: ElementRef;

  @Input() labelText: string;
  @Input() disabled = false;
  @Input() fakeDisabled = false;
  @Input() placeholder: string;
  @Input() required = false;
  @Input() allowPasting? = true;
  @Input() input?: string;
  @Input() icon: null | string;
  @Input() showGreenCheckMarkWhenValid? = false;
  @Input() backgroundStyle?: string;
  @Input() maxCharacters = 0 ;

  @Output() isClickedWhenDisabled = new EventEmitter();

  public value = '';
  public onChange: Function;
  public onBlur: Function;
  public styles: { [key: string]: string } = {
    grey: 'background-grey'
  };

  constructor() {
  }

  ngOnInit(): void {
    if (this.input) {
      this.value = this.input;
    }
  }

  onInput($event: any): void {
    const targetElement = $event.target as HTMLInputElement;
    this.value = targetElement.value;
    this.onChange(this.value);
  }

  writeValue(newValue: string): void {
    this.value = newValue;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onBlur = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  clickTextField() {
    if(this.fakeDisabled || this.disabled) this.isClickedWhenDisabled.emit();
  }

}
