import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Mood, MOODS } from '../../models/enums/MeasurementEnums';

@Component({
  selector: 'emoji-list',
  templateUrl: './emoji-list.component.html',
  styleUrls: ['./emoji-list.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EmojiListComponent),
    multi: true,
  }],
})
export class EmojiListComponent implements AfterViewInit, ControlValueAccessor {
// export class EmojiListComponent implements OnInit, AfterViewInit, ControlValueAccessor {
  @ViewChild('emojiInputRef') emojiInputRef: ElementRef;
  @ViewChild('emojiListRef') emojiListRef: ElementRef;

  @Input() required = false;
  @Input() isDisabled = false;

  @Output() isClickedWhenDisabled = new EventEmitter();

  emojis: Mood[] = MOODS;
  public value: string | null = '';
  public onChange: any;

  // constructor() {
  // }

  // ngOnInit(): void {
  // }

  ngAfterViewInit(): void {
    // provide horizontal mouse wheel scroll. Note: Firefox emit 'wheel' events, while Chromium 'mousewheel' events
    this.emojiListRef.nativeElement.addEventListener('wheel', function(e: WheelEvent) {
    // this.emojiListRef.nativeElement.addEventListener('mousewheel', function(e: WheelEvent) {
    //   console.log('hitting horizontal mousewheel');
      // @ts-ignore
      this.scrollLeft -= (e.wheelDelta);
      e.preventDefault();
    }, false);

    // scroll 'selected emoji into view'
  }

  public selectEmoji(emojiId: string): void {
    if (this.isDisabled) return this.isClickedWhenDisabled.emit();
    // note: supports 'deselecting' the mood.
    this.value = this.value === emojiId ? null : emojiId;
    this.onChange(this.value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(newValue: string): void {
    this.value = newValue;
  }

}
