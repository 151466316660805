<img
  src="/assets/img/icn_close.svg"
  class="hamburger-close-button"
  (click)="closeHamburgerMenu()"
>

<h2 class="hamburger-menu-title">{{ 'DASHBOARD.HAMBURGER_MENU.TITLE' | translate }}</h2>

<div class="hamburger-menu-selfcare-icon-wrapper">
  <img *ngIf="!careGiversDashboard" class="hamburger-menu-selfcare-icon" src="assets/pwa-icons/icon-128x128.png" alt="Selfcare icon">
  <img *ngIf="careGiversDashboard" class="hamburger-menu-selfcare-icon" [src]="companyLogo" [alt]="companyName">
</div>

<nav>
  <ul class="hamburger-item-container">
    <li *ngFor="let menuItem of menuItems; index as i;" (click)="toggleSubmenu($event)" class="menu-item">
      <accordion
        *ngIf="menuItem.subMenu"
        #accordion
        (onToggle)="onSubMenuOpened($event, accordion)"
      >
        <div header>
          <div class="menu-item-container">
            <div *ngIf="!menuItem.iconActive || (activeMenuItemId !== menuItem.id && menuItem.iconActive)" class="menu-icon" [inlineSVG]="menuItem.icon"></div>
            <div *ngIf="activeMenuItemId === menuItem.id && menuItem.iconActive" class="menu-icon" [inlineSVG]="menuItem.iconActive"></div>
            <a class="menu-item-text" *ngIf="menuItem.routerLink" [routerLink]="menuItem.routerLink">
              {{ 'DASHBOARD.HAMBURGER_MENU.' + menuItem.id | translate }}
            </a>
            <span class="menu-item-text" *ngIf="!menuItem.routerLink" [ngClass]="{ 'active': menuItem.id === activeMenuItemId }">{{ 'DASHBOARD.HAMBURGER_MENU.' + menuItem.id | translate }}</span>
          </div>
        </div>
        <ul class="sub-menu-container">
          <li *ngFor="let subMenuItem of menuItem.subMenu" [routerLink]="subMenuItem.routerLink" class="sub-menu-item">
            <a class="menu-item-text" [routerLink]="subMenuItem.routerLink" (click)="closeHamburgerMenu()">
              {{ 'DASHBOARD.HAMBURGER_MENU.' + menuItem.id + '_SUB_MENU.' + subMenuItem.id | translate }}
            </a>
          </li>
        </ul>
      </accordion>

        <a
          *ngIf="!menuItem.subMenu && menuItem.routerLink"
          [routerLink]="menuItem.routerLink"
          class="menu-item-container"
          (click)="closeAllAccordions(null, true)"
        >
          <div
            *ngIf="!menuItem.iconActive || (activeMenuItemId !== menuItem.id && menuItem.iconActive)"
            class="menu-icon"
            [inlineSVG]="menuItem.icon"
          ></div>
  <!--        (click)="closeHamburgerMenu()"-->
          <div *ngIf="activeMenuItemId === menuItem.id && menuItem.iconActive" class="menu-icon" [inlineSVG]="menuItem.iconActive"></div>
          <div class="menu-item-text"  [ngClass]="{'active': menuItem.id === activeMenuItemId }">
            {{ 'DASHBOARD.HAMBURGER_MENU.' + menuItem.id | translate }}
          </div>
          <span *ngIf="!menuItem.routerLink">{{ 'DASHBOARD.HAMBURGER_MENU.' + menuItem.id | translate }}</span>
          <blue-dot [hamburgerMenu]="true" *ngIf="menuItem.id === 'NETWORK'"></blue-dot>
        </a>
    </li>
    <li class="menu-item" *ngIf="showLogout">
      <div class="menu-item-container" (click)="logout()">
        <div class="menu-icon" inlineSVG="icn_profile.svg"></div>
        <span>{{ 'DASHBOARD.HAMBURGER_MENU.LOGOUT' | translate }}</span>
      </div>
    </li>
  </ul>
</nav>

