import {createReducer, on} from '@ngrx/store';
import {reset, setCulture, setProfile, setUserId} from './userProfile.actions';
import UserProfile from '../../shared/models/userProfile';
import {Cultures} from '../../shared/models/settings/cultures';

export let userProfileInitialState: UserProfile;

export const userProfileReducer = createReducer(
  userProfileInitialState,
  on(setUserId, (state, action) => ({
    ...state,
    userId: action.userId
  })),
  on(setCulture, (state, action) => ({
    ...state,
    cultureCode: action.cultureCode,
    language: getUserLanguage(action.cultureCode)
  })),
  on(setProfile, (state, action) => ({
    ...state,
    generalInformation: action.userProfile.generalInformation,
    healthcareProvider: action.userProfile.healthcareProvider,
    medicalBackground: action.userProfile.medicalBackground
  })),
  on(reset, (state) => ({
    ...userProfileInitialState
  }))
);

function getUserLanguage(cultureCode:string) {
  let langToSet;
  if (cultureCode) {
    switch (cultureCode) {
      case Cultures.nl_nl:
      case Cultures.nl_be:
        langToSet = 'nl';
        break;
      case Cultures.en_us:
        langToSet = 'en';
        break;
    }
  }
  return langToSet;
}
