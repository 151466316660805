<ngx-smart-modal class="redeem-popup-wrapper small-modal" [identifier]="identifier" [closable]="false" (onOpen)="generateSupplierOptionsAndSelectedOptions()">
  <div class="popup-title-container">
    <div (click)="modal.close(identifier)" inlineSVG="icn_close.svg" class="close-button"></div>
    <div inlineSVG="icn_add.svg"></div>
    <h2 class="redeem-popup-title">
      {{ 'SETTINGS.SOURCES.CONFLICT_POPUP.CHOOSE_SOURCE' | translate }}
    </h2>
  </div>
  <p class="source-explanation">
    {{ 'SETTINGS.SOURCES.CONFLICT_POPUP.MULTIPLE_SOURCES' | translate }}
  </p>
  <ng-container *ngIf="(conflictingMeasurementTypes | json) != ({} | json)">
    <div *ngFor="let data of conflictingMeasurementTypes | keyvalue">
      <div class="field-wrapper">
        <div class="title-field">
          <div *ngIf="measurementIcons[data.key]" class="measurement-icon" [inlineSVG]="measurementIcons[data.key]"></div>
          <div class="measurement-title">{{ "MEASUREMENT_DETAILS.MEASUREMENTS." + data.key + ".TITLE" | translate }}</div>
        </div>
        <select-dropdown
          class="select-dropdown"
          dropdownTitle="{{'MEASUREMENT_DETAILS.MEASUREMENTS.' + data.key + '.TITLE' | translate}}"
          description="{{ 'SETTINGS.SOURCES.CONFLICT_POPUP.CHOOSE_SOURCE' | translate }}"
          buttonText="SHARED.SAVE"
          identifier="{{'select' + data.key}}"
                [selectableOptions]="supplierOptions[data.key]"
                [selectedOption]="selectedOptions[data.key]"
          (onOptionSelected)="onSupplierSelected($event, data.key)"
        ></select-dropdown>
      </div>
    </div>
  </ng-container>
  <div class="buttons-wrapper">
    <app-button class="redeem-button"
                (click)="saveSupplierSelection()"
                [disabled]="!canSave()"
                [buttonText]="'SHARED.SAVE' | translate"></app-button>
  </div>
</ngx-smart-modal>
